import styled, { css } from 'styled-components';

import Flex from '../Flex';
import { Heading } from '../Heading';
import { Text } from '../Text';

import { breakpoints } from '../utils/themeUtils';
import { HeroBannerProps } from '.';
import { omitProps } from '../_common';
import { Block } from '../Block';

type HeroWrapperProps = { backgroundImage?: string | null } & Pick<
  HeroBannerProps,
  | 'textColor'
  | 'backgroundColor'
  | 'borderColor'
  | 'borderRadius'
  | 'enableBorder'
>;

const IMAGE_WIDTH = 210;
const IMAGE_HEIGHT = 210;

export const HeroWrapper = styled(Flex)
  .withConfig<HeroWrapperProps>({
    shouldForwardProp: (prop) =>
      omitProps(
        [
          'textColor',
          'backgroundColor',
          'borderColor',
          'borderRadius',
          'enableBorder',
          'backgroundImage',
        ],
        prop,
      ),
  })
  .attrs({
    alignItems: 'stretch',
    justifyContent: 'stretch',
  })`
  ${({
    theme,
    textColor,
    backgroundColor,
    borderColor,
    borderRadius,
    enableBorder,
    backgroundImage,
  }) => css`
    position: relative;
    background: ${backgroundColor || theme.sys.color.neu['04']};
    overflow: hidden;
    color: ${textColor || theme.color.neutral.neu01};
    min-height: 56.2rem;

    ${backgroundImage &&
    css`
      background-image: url(${backgroundImage});
      background-position: center center;
      background-size: cover;
    `}

    ${enableBorder &&
    css`
      border: 1px solid ${borderColor || theme.sys.color.neu['03']};
      border-radius: ${`${borderRadius}px`};
    `}

    @media ${breakpoints.sm} {
      min-height: 27.4rem;
    }
  `}
`;

export const HeroContainer = styled(Flex).attrs({
  m: '0 auto',
  px: 2,
  width: 1,
  maxWidth: 754,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: { xs: 'column', sm: 'row' },
})``;

export const HeroContents = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
})`
  flex-basis: auto;
  width: auto;

  &:empty {
    display: none;
  }
`;

export const HeroTitle = styled(Heading)`
  ${({ theme, backgroundColor }) => css`
    display: inline;

    ${theme.sys.typeface['size-22']['3301-700']};

    ${backgroundColor &&
    css`
      background-color: ${backgroundColor};
      padding: 0 0.5rem;
    `}
  `}
`;

export const HeroText = styled(Text)`
  ${({ theme }) => css`
    ${theme.sys.typeface['size-14']['2101-400']};
  `}
`;

export const StyledHighlightImage = styled.img.attrs({
  width: IMAGE_WIDTH,
  height: IMAGE_HEIGHT,
})`
  object-fit: cover;
  min-width: 21rem;
  max-width: 100%;
  height: auto;
`;

export const WrapperCTAButton = styled(Block)`
  position: relative;
  width: fit-content;
  z-index: 3;
`;

export const OverlayLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`;

import { post } from '@worten-sardines/shared/utils-apis';

type PbiArgs = {
  [key: string]: string;
};

export const pbi = (arg?: PbiArgs, rawBody?: string) => {
  return post('/seller/v2/dashboard/generateAccess', {
    query: arg,
    rawBody,
  });
};

export default { pbi };

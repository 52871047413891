export const getSearchParamsUrl = (queryString = window.location.search) => {
  const urlParams = new URLSearchParams(queryString);
  const params: Record<string, string> = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (urlParams as any).entries(),
  );

  return params;
};

export const setSearchParamsUrl = (p: Record<string, string>) => {
  const host = window.location.href.split('?')[0];
  const params = new URLSearchParams(p);

  window.history.pushState({}, '', `${host}?${params.toString()}`);
};

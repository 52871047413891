import { Badge, Close, Svg } from '@worten-sardines/grill-ui';
import React from 'react';
import { CloseIcon, StyledTooltipContent } from './styled';

type TooltipContentProps = {
  children: React.ReactNode;
  showBelow: boolean;
  showPremiumBadge?: boolean;
  onClose?: () => void;
};

export const TooltipContent = ({
  children,
  showBelow,
  showPremiumBadge = true,
  onClose,
}: TooltipContentProps) => {
  return (
    <StyledTooltipContent showBelow={showBelow} role="tooltip">
      <CloseIcon onClick={onClose}>
        <Svg
          svg={<Close />}
          title="close-tooltip-progress-tracker"
          ariaLabel="close-tooltip-progress-tracker"
          width={12}
        />
      </CloseIcon>
      <span>{children}</span>
      {showPremiumBadge && <Badge type="premium">Premium</Badge>}
    </StyledTooltipContent>
  );
};

export default TooltipContent;

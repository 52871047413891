import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import {
  Emoji,
  Input,
  Loading,
  Submit,
  Svg,
  Theme,
} from '@worten-sardines/grill-ui';
import { usePopup } from '@worten-sardines/seller-center-ui-shared';
import { useSearchShop } from '@worten-sardines/shared/hooks';

import ShopIdCard from '../ShopIdCard';
import { PATHS } from './constants';
import messages from './messages';
import {
  InputFieldWrapper,
  InstructionsDetails,
  ResultContent,
  ShopIdPopupWrapper,
  SubmitButton,
} from './styled';

type ShopIdPopupProps = {
  defaultShopIDValue?: string;
};

type FormData = {
  shopId: string;
};

export const ShopIdPopup = ({ defaultShopIDValue = '' }: ShopIdPopupProps) => {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const [shopId, setShopId] = useState('');
  const { closePopup } = usePopup();

  const { shopInfo, loading, error } = useSearchShop(shopId);

  const labels = {
    inputLabel: formatMessage(messages.inputLabel),
    instruction: formatMessage(messages.instruction),
    searchButton: formatMessage(messages.searchButton),
    noResult: formatMessage(messages.noResult),
  };

  const defaultValues = {
    shopId: defaultShopIDValue,
  };

  const infoMessage =
    error?.status === 404
      ? labels.noResult
      : PATHS.includes(pathname) && (
          <span>
            <Emoji symbol="💡" label="tip" size={Theme.fontsize.desktop.s2} />{' '}
            {labels.instruction}
          </span>
        );

  const {
    register,
    resetField,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const InputValue = watch('shopId');

  const onSubmit = ({ shopId }: FormData) => {
    setShopId(shopId);
  };

  const handleSetFn = () => {
    if (!shopInfo) return;

    resetField('shopId');
    setShopId('');
    closePopup();
  };

  const renderResults = () => {
    return shopInfo ? (
      <ShopIdCard
        shopInfo={shopInfo}
        setFn={handleSetFn}
      />
    ) : (
      <InstructionsDetails>{infoMessage}</InstructionsDetails>
    );
  };

  return (
    <ShopIdPopupWrapper>
      <InputFieldWrapper onSubmit={handleSubmit(onSubmit)} noValidate>
        <Input
          type="number"
          label={labels.inputLabel}
          formNoValidate
          {...register('shopId', {
            pattern: /^\d*$/,
          })}
          error={errors}
          autoFocus
        />
        {InputValue && (
          <SubmitButton id="submit-popup-shopid" type="submit">
            <Svg
              name="search"
              svg={<Submit />}
              title={labels.searchButton}
              width={24}
              height={25}
            />
          </SubmitButton>
        )}
      </InputFieldWrapper>

      <ResultContent>
        {loading ? <Loading size="sm" /> : renderResults()}
      </ResultContent>
    </ShopIdPopupWrapper>
  );
};

export default ShopIdPopup;

import { Theme as theme } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

export type PayoutCellProps = {
  empty?: boolean;
};

export type PayoutCellStatusProps = {
  status: 'unpaid' | 'paid' | 'future';
};

export const PayoutCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const PayoutCellInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const PayoutCellTitle = styled.p<PayoutCellProps>`
  letter-spacing: 0.1px;
  line-height: ${theme.lineheight.desktop.s1};
  font-size: ${theme.fontsize.desktop.s1};
  font-weight: ${theme.weight.bold};
  color: ${theme.color.neutral['neu-11']};
  margin: 0;

  ${({ empty }) =>
    empty &&
    `
    color: ${theme.color.neutral['neu-06']};
  `}
`;

const crossStatus = {
  unpaid: ` ${theme.color.aditional['add-05']}`,
  paid: ` ${theme.color.gradiantcolor['gra-02']}`,
  future: ` ${theme.color.neutral['neu-06']}`,
};

export const PayoutCellStatus = styled.span<PayoutCellStatusProps>`
  letter-spacing: 0.1px;
  line-height: ${theme.lineheight.desktop.xs};
  font-size: ${theme.fontsize.desktop.xs};
  color: ${theme.color.gradiantcolor['gra-02']};

  ${({ status }) =>
    status &&
    `
    color: ${crossStatus[status]};
  `}
`;

export const PayoutCellIcon = styled.div`
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
`;

export const PayoutCellDownloadItem = styled.a`
  display: inline-flex;
  color: ${theme.color.neutral['neu-08']};
  font-size: ${theme.fontsize.desktop.s2};
  line-height: ${theme.fontsize.desktop.xl};
  width: 100%;
  white-space: nowrap;

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.color.neutral['neu-05']};
  }

  &:hover {
    color: inherit;
  }
`;

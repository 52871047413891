export const cycleDates = [
  {
    year: 'default',
    months: [
      {
        month: 'January',
        cycle1: {
          from: 'default-01-01',
          to: 'default-01-15',
        },
        cycle2: {
          from: 'default-01-16',
          to: 'default-01-31',
        },
      },
      {
        month: 'February',
        cycle1: {
          from: 'default-02-01',
          to: 'default-02-15',
        },
        cycle2: {
          from: 'default-02-16',
          to: 'default-02-28',
        },
      },
      {
        month: 'March',
        cycle1: {
          from: 'default-03-10',
          to: 'default-03-15',
        },
        cycle2: {
          from: 'default-03-16',
          to: 'default-03-31',
        },
      },
      {
        month: 'April',
        cycle1: {
          from: 'default-04-01',
          to: 'default-04-15',
        },
        cycle2: {
          from: 'default-04-16',
          to: 'default-04-30',
        },
      },
      {
        month: 'May',
        cycle1: {
          from: 'default-05-01',
          to: 'default-05-15',
        },
        cycle2: {
          from: 'default-05-16',
          to: 'default-05-31',
        },
      },
      {
        month: 'June',
        cycle1: {
          from: 'default-06-01',
          to: 'default-06-15',
        },
        cycle2: {
          from: 'default-06-16',
          to: 'default-06-30',
        },
      },
      {
        month: 'July',
        cycle1: {
          from: 'default-07-01',
          to: 'default-07-15',
        },
        cycle2: {
          from: 'default-07-16',
          to: 'default-07-31',
        },
      },
      {
        month: 'August',
        cycle1: {
          from: 'default-08-01',
          to: 'default-08-15',
        },
        cycle2: {
          from: 'default-08-16',
          to: 'default-08-31',
        },
      },
      {
        month: 'September',
        cycle1: {
          from: 'default-09-01',
          to: 'default-09-15',
        },
        cycle2: {
          from: 'default-09-16',
          to: 'default-09-30',
        },
      },
      {
        month: 'October',
        cycle1: {
          from: 'default-10-01',
          to: 'default-10-15',
        },
        cycle2: {
          from: 'default-10-16',
          to: 'default-10-31',
        },
      },
      {
        month: 'November',
        cycle1: {
          from: 'default-11-01',
          to: 'default-11-15',
        },
        cycle2: {
          from: 'default-11-16',
          to: 'default-11-30',
        },
      },
      {
        month: 'December',
        cycle1: {
          from: 'default-12-01',
          to: 'default-12-15',
        },
        cycle2: {
          from: 'default-12-16',
          to: 'default-12-31',
        },
      },
    ],
  },
  {
    year: '2022',
    months: [
      {
        month: 'January',
        cycle1: {
          from: '2022-01-01',
          to: '2022-01-13',
        },
        cycle2: {
          from: '2022-01-14',
          to: '2022-01-30',
        },
      },
      {
        month: 'February',
        cycle1: {
          from: '2022-01-31',
          to: '2022-02-14',
        },
        cycle2: {
          from: '2022-02-15',
          to: '2022-02-24',
        },
      },
      {
        month: 'March',
        cycle1: {
          from: '2022-02-25',
          to: '2022-03-13',
        },
        cycle2: {
          from: '2022-03-14',
          to: '2022-03-28',
        },
      },
      {
        month: 'April',
        cycle1: {
          from: '2022-03-29',
          to: '2022-04-11',
        },
        cycle2: {
          from: '2022-04-12',
          to: '2022-04-27',
        },
      },
      {
        month: 'May',
        cycle1: {
          from: '2022-04-28',
          to: '2022-05-12',
        },
        cycle2: {
          from: '2022-05-13',
          to: '2022-05-29',
        },
      },
      {
        month: 'June',
        cycle1: {
          from: '2022-05-30',
          to: '2022-06-12',
        },
        cycle2: {
          from: '2022-06-13',
          to: '2022-06-28',
        },
      },
      {
        month: 'July',
        cycle1: {
          from: '2022-06-29',
          to: '2022-07-13',
        },
        cycle2: {
          from: '2022-07-14',
          to: '2022-07-26',
        },
      },
      {
        month: 'August',
        cycle1: {
          from: '2022-07-27',
          to: '2022-08-11',
        },
        cycle2: {
          from: '2022-08-12',
          to: '2022-08-28',
        },
      },
      {
        month: 'September',
        cycle1: {
          from: '2022-08-29',
          to: '2022-09-13',
        },
        cycle2: {
          from: '2022-09-14',
          to: '2022-09-28',
        },
      },
      {
        month: 'October',
        cycle1: {
          from: '2022-09-29',
          to: '2022-10-13',
        },
        cycle2: {
          from: '2022-10-14',
          to: '2022-10-27',
        },
      },
      {
        month: 'November',
        cycle1: {
          from: '2022-10-28',
          to: '2022-11-14',
        },
        cycle2: {
          from: '2022-11-15',
          to: '2022-11-27',
        },
      },
      {
        month: 'December',
        cycle1: {
          from: '2022-11-28',
          to: '2022-12-13',
        },
        cycle2: {
          from: '2022-12-14',
          to: '2023-01-01',
        },
      },
    ],
  },
  {
    year: '2023',
    months: [
      {
        month: 'January',
        cycle1: {
          from: '2023-01-01',
          to: '2023-01-12',
        },
        cycle2: {
          from: '2023-01-13',
          to: '2023-01-26',
        },
      },
      {
        month: 'February',
        cycle1: {
          from: '2023-01-27',
          to: '2023-02-13',
        },
        cycle2: {
          from: '2023-02-14',
          to: '2023-02-26',
        },
      },
      {
        month: 'March',
        cycle1: {
          from: '2023-02-27',
          to: '2023-03-13',
        },
        cycle2: {
          from: '2023-03-14',
          to: '2023-03-26',
        },
      },
      {
        month: 'April',
        cycle1: {
          from: '2023-03-27',
          to: '2023-04-13',
        },
        cycle2: {
          from: '2023-04-14',
          to: '2023-04-26',
        },
      },
      {
        month: 'May',
        cycle1: {
          from: '2023-04-27',
          to: '2023-05-14',
        },
        cycle2: {
          from: '2023-05-15',
          to: '2023-05-25',
        },
      },
      {
        month: 'June',
        cycle1: {
          from: '2023-05-26',
          to: '2023-06-14',
        },
        cycle2: {
          from: '2023-06-15',
          to: '2023-06-27',
        },
      },
      {
        month: 'July',
        cycle1: {
          from: '2023-06-28',
          to: '2023-07-13',
        },
        cycle2: {
          from: '2023-07-14',
          to: '2023-07-26',
        },
      },
      {
        month: 'August',
        cycle1: {
          from: '2023-07-27',
          to: '2023-08-13',
        },
        cycle2: {
          from: '2023-08-14',
          to: '2023-08-27',
        },
      },
      {
        month: 'September',
        cycle1: {
          from: '2023-08-28',
          to: '2023-09-13',
        },
        cycle2: {
          from: '2023-09-14',
          to: '2023-09-26',
        },
      },
      {
        month: 'October',
        cycle1: {
          from: '2023-09-27',
          to: '2023-10-12',
        },
        cycle2: {
          from: '2023-10-13',
          to: '2023-10-26',
        },
      },
      {
        month: 'November',
        cycle1: {
          from: '2023-10-27',
          to: '2023-11-13',
        },
        cycle2: {
          from: '2023-11-14',
          to: '2023-11-27',
        },
      },
      {
        month: 'December',
        cycle1: {
          from: '2023-11-28',
          to: '2023-12-13',
        },
        cycle2: {
          from: '2023-12-14',
          to: '2023-12-31',
        },
      },
    ],
  },
  {
    year: '2024',
    months: [
      {
        month: 'January',
        cycle1: {
          from: '2024-01-01',
          to: '2024-01-11',
        },
        cycle2: {
          from: '2024-01-12',
          to: '2024-01-25',
        },
      },
      {
        month: 'February',
        cycle1: {
          from: '2024-01-26',
          to: '2024-02-13',
        },
        cycle2: {
          from: '2024-02-14',
          to: '2024-02-26',
        },
      },
      {
        month: 'March',
        cycle1: {
          from: '2024-02-27',
          to: '2024-03-13',
        },
        cycle2: {
          from: '2024-03-14',
          to: '2024-03-26',
        },
      },
      {
        month: 'April',
        cycle1: {
          from: '2024-03-27',
          to: '2024-04-11',
        },
        cycle2: {
          from: '2024-04-12',
          to: '2024-04-25',
        },
      },
      {
        month: 'May',
        cycle1: {
          from: '2024-04-26',
          to: '2024-05-13',
        },
        cycle2: {
          from: '2024-05-14',
          to: '2024-05-26',
        },
      },
      {
        month: 'June',
        cycle1: {
          from: '2024-05-27',
          to: '2024-06-13',
        },
        cycle2: {
          from: '2024-06-14',
          to: '2024-06-26',
        },
      },
      {
        month: 'July',
        cycle1: {
          from: '2024-06-27',
          to: '2024-07-11',
        },
        cycle2: {
          from: '2024-07-12',
          to: '2024-07-25',
        },
      },
      {
        month: 'August',
        cycle1: {
          from: '2024-07-26',
          to: '2024-08-13',
        },
        cycle2: {
          from: '2024-08-14',
          to: '2024-08-26',
        },
      },
      {
        month: 'September',
        cycle1: {
          from: '2024-08-27',
          to: '2024-09-12',
        },
        cycle2: {
          from: '2024-09-13',
          to: '2024-09-26',
        },
      },
      {
        month: 'October',
        cycle1: {
          from: '2024-09-27',
          to: '2024-10-13',
        },
        cycle2: {
          from: '2024-10-14',
          to: '2024-10-27',
        },
      },
      {
        month: 'November',
        cycle1: {
          from: '2024-10-28',
          to: '2024-11-13',
        },
        cycle2: {
          from: '2024-11-14',
          to: '2024-11-26',
        },
      },
      {
        month: 'December',
        cycle1: {
          from: '2024-11-27',
          to: '2024-12-12',
        },
        cycle2: {
          from: '2024-12-13',
          to: '2024-12-31',
        },
      },
    ],
  },
];

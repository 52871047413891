import { Theme as theme } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  gap: ${theme.bas.dimension[16]};

  padding: ${theme.bas.dimension[16]};
`;

export const ContentArea = styled.section`
  display: flex;
  flex-direction: column;
  padding: ${theme.bas.dimension[16]};
  width: 100%;

  background-color: ${theme.sys.color.neu['01']};
  border-radius: ${theme.spacing.xxs};
  margin-top: ${theme.bas.dimension[16]};
`;

import { Theme } from '@worten-sardines/grill-ui';
import styled, { css } from 'styled-components';
import { ShopIdCardProps } from '.';

type ShopIdCardStyledProps = Pick<ShopIdCardProps, 'isUnset'>;

export const StyledShopIdCard = styled.div<ShopIdCardStyledProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: ${Theme.spacing.xxs};
  background-color: ${Theme.color.neutral.neu01};

  border-radius: ${Theme.Radius.xxs};
  ${({ isUnset }) =>
    isUnset
      ? css`
          border: 1px solid ${Theme.color.neutral['neu-11']};
          margin-top: ${Theme.size.xs};
        `
      : css`
          border: 2px solid ${Theme.color.neutral['neu-05']};
          cursor: pointer;
        `};
`;

export const ShopIdDetails = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: ${Theme.fontsize.desktop.s2};
  color: ${Theme.color.neutral['neu-11']};
  overflow: hidden;

  & span {
    overflow: hidden;
    white-space: nowrap;

    text-overflow: ellipsis;
  }
`;

export const ShopNameText = styled.span`
  font-weight: ${Theme.weight.semibold};
`;

export const UnsetButton = styled.button`
  cursor: pointer;
`;

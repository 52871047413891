import { forwardRef, useCallback } from 'react';
import { DataTableActionsBar } from './DataTableActionsBar';
import Table, { TableHandle } from './Table';
import Flex from '../Flex';
import { Text } from '../Text';
import {
  getSearchParamsUrl,
  setSearchParamsUrl,
} from '../utils/search-params-url';
import { Theme } from '../theme';
import { DataTableProps } from './types';
import Loading from '../Loading';

type DataGridProps = DataTableProps;
export type DataGridHandle = TableHandle;

export const DataGrid = forwardRef<DataGridHandle, DataGridProps>(
  (
    {
      loading,
      dataTable,
      onFetchData,
      pagination,
      enableSorting,
      enableSelectRows,
      totalItens,
      freezeColumn,
      ...rest
    }: DataTableProps,
    ref,
  ) => {
    //TODO compactMode is true if it's mobile mode, forwhile it will be always false
    const compactMode = false;
    const getParams = getSearchParamsUrl();
    const onSubmitFilter = useCallback(
      (filters: Record<string, string>) => {
        setSearchParamsUrl(filters);

        const {
          sort_by,
          order_by,
          offset: _offset,
          limit: _limit,
          ...rest
        } = filters;

        const offset = _offset && { offset: _offset };
        const limit = _limit && { limit: _limit };
        const sorted = sort_by && order_by && { sorted: { sort_by, order_by } };
        const buildFilters = Object.entries(rest)
          .map(([id, value]) => ({
            id,
            value,
          }))
          .filter((f) => f);

        const filtered = buildFilters.length && { filtered: buildFilters };

        const filtersMapped = {
          ...offset,
          ...limit,
          ...sorted,
          ...filtered,
        };

        onFetchData(filtersMapped);
      },
      [onFetchData],
    );

    return (
      <>
        <DataTableActionsBar
          pagination={pagination}
          loading={loading}
          configFilter={dataTable.header}
          filtered={getParams}
          compactMode={compactMode}
          onSubmitFilter={onSubmitFilter}
          //TODO data export implementation is missing
          enableExport={false}
        />
        {loading ? (
          <Loading />
        ) : dataTable.data?.length ? (
          <Table
            {...rest}
            ref={ref}
            dataTable={{ ...dataTable, data: dataTable.data }}
            onFetchData={onSubmitFilter}
            freezeColumn={freezeColumn}
            pagination={pagination}
            totalItens={totalItens}
            enableSorting={enableSorting}
            enableSelectRows={enableSelectRows}
          />
        ) : (
          <Flex
            m={Theme.spacing.xs}
            justifyContent="center"
            backgroundColor={Theme.color.neutral.neu01}
          >
            <Text color={Theme.color.main['m-01']}>No results!</Text>
          </Flex>
        )}
      </>
    );
  },
);

export default DataGrid;

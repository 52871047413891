import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { sharedNavigatorLanguageHelper } from '@worten-sardines/shared/helpers';

import { LANGUAGE_DEFAULT } from '@worten-sardines/configs/config-seller-center';
import {
  getFromSharedLocalStorageHelper,
  setOnSharedLocalStorageHelper,
} from '@worten-sardines/shared/helpers';

const loadMessagesJson = (
  projectName: string,
  languageKey: string,
  languageDefault: string,
): Promise<JSON> => {
  return new Promise((res) => {
    return import(
      `../../../../../apps/${projectName}/src/translations/compiled-locales/${languageKey}.json`
    )
      .then((data) => res(data?.default))
      .catch(() => {
        setOnSharedLocalStorageHelper('locale', 'en');
        import(
          `../../../../../apps/${projectName}/src/translations/compiled-locales/${languageDefault}.json`
        ).then((data) => res(data?.default));
      });
  });
};

type IntlProviderWrapperProps = {
  projectName: string;
  languageDefault: string;
  children: React.ReactNode;
};

export function IntlProviderWrapperFeature({
  projectName,
  languageDefault = LANGUAGE_DEFAULT,
  children,
}: IntlProviderWrapperProps) {
  const [messages, setMessages] = useState({});

  const getNavigatorLanguageHelper = () => {
    const navigatorLanguage = sharedNavigatorLanguageHelper();
    if (navigatorLanguage) {
      setOnSharedLocalStorageHelper('locale', navigatorLanguage);

      return navigatorLanguage;
    }

    return null;
  };

  const locale =
    getFromSharedLocalStorageHelper('locale') ||
    getNavigatorLanguageHelper() ||
    languageDefault;

  useEffect(() => {
    const loadMessagesJsonAsync = async () => {
      const messagesResponse = await loadMessagesJson(
        projectName,
        locale,
        languageDefault,
      );
      setMessages(messagesResponse);
    };

    if (locale) {
      loadMessagesJsonAsync();
    }
  }, [locale, projectName, languageDefault]);

  if (!Object.keys(messages).length) {
    return null;
  }

  return (
    <IntlProvider messages={messages} locale={locale}>
      {children}
    </IntlProvider>
  );
}

export default IntlProviderWrapperFeature;

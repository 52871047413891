import { useEffect, useRef, useState } from 'react';
import { useDebounce } from '../../utils/hooks/use-debounce';
import Collapse from '../../Collapse';
import { FilterGroupStyled, Icon } from './styled';
import Button from '../../Button';
import { Block } from '../../Block';
import Input from '../../Input';
import { ChevronIcon } from '../../assets/images';
import { PAGINATION_CONFIG } from '../../utils/pagination-config';
import { ColumnPropsType, FilteredType } from '../types';

type DataTableFilterManagerProps = {
  filtered: FilteredType;
  mainFilters: Array<ColumnPropsType> | [];
  advancedFilters: Array<ColumnPropsType> | [];
  showAdvancedFilters: boolean;
  onSubmitFilter: (filters: FilteredType) => void;
  onHideAdvancedFilters: () => void;
  pagination?: boolean;
  compactMode?: boolean;
};

type FilterControlProps = {
  filterId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  disabled?: boolean;
  onChange: (id: string, value: unknown) => void;
  column: ColumnPropsType;
};

const DEBOUNCE_TIME = 500;

const getFilterId = (column: ColumnPropsType) => column?.id || column.accessor;

function FilterControl({
  filterId,
  column,
  value,
  onChange,
  disabled,
}: FilterControlProps) {
  const ResolvedFilterComponent = column.Filter;
  return (
    (ResolvedFilterComponent &&
      ResolvedFilterComponent({
        filter: {
          name: filterId,
          label: column.cell,
          value,
        },
        onChange: (choice: { id: string; value: object }) =>
          onChange(filterId, choice.value),
        disabled,
      })) || (
      <Input
        name={filterId}
        type="text"
        label={column.cell}
        defaultValue={value}
        onChange={(ev) => onChange(filterId, ev.target.value)}
        disabled={disabled}
      />
    )
  );
}

export const DataTableFilterManager = ({
  filtered,
  mainFilters,
  advancedFilters,
  showAdvancedFilters,
  onSubmitFilter,
  onHideAdvancedFilters,
  pagination,
  compactMode,
}: DataTableFilterManagerProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const initialFilters = () => {
    const f = [...mainFilters, ...advancedFilters].reduce((accum, col) => {
      const fieldId = getFilterId(col);
      const fieldValue = Object.keys(filtered).find(
        (filter) => filter === fieldId,
      );

      if (fieldValue && fieldId) {
        return {
          ...accum,
          [fieldId]: filtered[fieldValue as keyof typeof filtered],
        };
      }

      return accum;
    }, {});

    const offsetValue = filtered.offset;
    const limitValue = filtered.limit;
    const sortbyValue = filtered.sort_by;
    const orderbyValue = filtered.order_by;

    const offset = offsetValue
      ? { offset: offsetValue }
      : pagination
      ? { offset: PAGINATION_CONFIG.offset }
      : undefined;
    const limit = limitValue
      ? { limit: limitValue }
      : pagination
      ? { limit: PAGINATION_CONFIG.limit }
      : undefined;
    const sort_by = sortbyValue && { sort_by: sortbyValue };
    const order_by = orderbyValue && { order_by: orderbyValue };

    return {
      ...f,
      ...offset,
      ...limit,
      ...sort_by,
      ...order_by,
    };
  };

  const [filters, setFilters] = useState(() => initialFilters());

  const debouncedFilterTerm = useDebounce(filters, DEBOUNCE_TIME);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const applyFilter = (id: string, value: any) => {
    // Prevent unnecessary server calls and state changes
    // Control variable that updates to true when user explicitly changes filters
    if (filters[id as keyof typeof filters] !== value) {
      const filtersUpdated = Object.fromEntries(
        Object.entries({
          ...filtered,
          [id]: value,
        }).filter(([_, filterValue]) => {
          if (typeof filterValue === 'number') return filterValue.toString();
          return filterValue;
        }),
      );
      setFilters(filtersUpdated);
    }
  };

  useEffect(() => {
    onSubmitFilter(debouncedFilterTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilterTerm]);

  const renderFilters = (filterList: Array<ColumnPropsType>) => {
    return filterList.map((col) => {
      const filterId = getFilterId(col);

      if (filterId) {
        return (
          <FilterControl
            key={filterId}
            filterId={filterId}
            column={col}
            value={filters[filterId as keyof typeof filters]}
            onChange={applyFilter}
          />
        );
      }

      return null;
    });
  };

  return (
    <div ref={wrapperRef}>
      {!compactMode && (
        <FilterGroupStyled>{renderFilters(mainFilters)}</FilterGroupStyled>
      )}
      <Collapse expanded={showAdvancedFilters}>
        <FilterGroupStyled>
          {compactMode && renderFilters(mainFilters)}
          {renderFilters(advancedFilters)}
        </FilterGroupStyled>
        <Block textAlign="center">
          <Button
            onClick={() => {
              onHideAdvancedFilters();
            }}
            variation="tertiary"
            size="small"
            icon={<Icon svg={<ChevronIcon />} width={18} title="hide" />}
          >
            Hide filters
          </Button>
        </Block>
      </Collapse>
    </div>
  );
};

export default DataTableFilterManager;

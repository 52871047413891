import { TextProps } from '../Text';
import { Star } from '../assets/images';
import { spreadRatingValue } from './helpers';
import {
  IconContainer,
  RatingValue,
  Rate,
  WrapperRating,
  Wrapper,
} from './styled';

export type RatingProps = {
  value: number;
  max?: number;
  size?: number;
  showValue?: boolean;
  direction?: 'left' | 'right';
} & TextProps;

export const Rating = ({
  value,
  max = 5,
  size = 14,
  showValue = true,
  direction = 'left',
  ...rest
}: RatingProps) => {
  const ratingArray = spreadRatingValue(value, max);

  return (
    <WrapperRating flexDirection={direction === 'left' ? 'row' : 'row-reverse'}>
      {showValue && <RatingValue {...rest}>{value}</RatingValue>}
      <Wrapper role="img" aria-label={`${value} stars`}>
        {ratingArray.map((rate, index) => (
          <Rate key={`${rate}-${index}`}>
            <Star width={size} height={size} focusable={false} aria-hidden />
            <IconContainer className={rate}>
              <Star width={size} height={size} focusable={false} aria-hidden />
            </IconContainer>
          </Rate>
        ))}
      </Wrapper>
    </WrapperRating>
  );
};

export default Rating;

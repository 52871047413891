import { Table } from '@worten-sardines/grill-ui';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { Wrapper } from './styled';
import { ChannelType, DataTableObj, ListDataProps } from './types';
import { createListData } from './utils/createListData';

export type SellerPremiumListProps = {
  channel: ChannelType;
  evaluations: ListDataProps[];
  shopId: string;
};

export const SellerPremiumList = ({
  channel,
  evaluations,
  shopId,
}: SellerPremiumListProps) => {
  const { locale, formatMessage } = useIntl();
  const [data, setData] = useState<DataTableObj>({
    header: [],
    data: [],
  });

  const header = [
    {
      id: 'evaluation',
      cell: formatMessage(messages.evaluation),
    },
    {
      id: 'sellerMetricValue',
      cell: formatMessage(messages.sellerMetricValue),
    },
    {
      id: 'goalValue',
      cell: formatMessage(messages.goalValue),
    },
    {
      id: 'achievedStatus',
      cell: '',
    },
  ];

  useEffect(() => {
    const evaluationsData = createListData({
      header,
      data: evaluations,
      channel,
      locale,
    });

    setData(evaluationsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluations, channel, shopId]);

  if (!data.data.length) return null;

  return (
    <Wrapper>
      <Table
        dataTable={data}
        bordered
        borderRadius={{
          topLeft: '0',
          topRight: '0',
          bottomLeft: '0.8rem',
          bottomRight: '0.8rem',
        }}
        className="seller-premium-table-list"
      />
    </Wrapper>
  );
};

export default SellerPremiumList;

import Card from './Card';
import { CardsGrid } from './styled';
import { CardsSchema } from '../../types';

export const CardSet = ({ schema: { cards, position } }: { schema: CardsSchema }) => (
  <CardsGrid position={position}>
    {cards.map((card) => (
      <Card key={card.title} {...card} />
    ))}
  </CardsGrid>
);

export default CardSet;

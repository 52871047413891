import { css } from 'styled-components';
import {
  colorProps,
  flexChildProps,
  flexProps,
  responsiveProps,
  spacingProps,
  widthProps,
  heightProps,
  gridProps,
  gridChildProps,
  textProps,
} from '../utils';

export const GUTTER_DEFAULT_PADDING = { xs: 2, md: 4 };
export const GUTTER_CSS = css`
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const omitProps = (
  propTypes: Array<string>,
  prop: string,
  exceptions = ['children'],
) => {
  const propSet = Array.isArray(propTypes) ? propTypes : Object.keys(propTypes);
  return !propSet
    .concat(colorProps)
    .concat(flexChildProps)
    .concat(flexProps)
    .concat(gridProps)
    .concat(gridChildProps)
    .concat(responsiveProps)
    .concat(spacingProps)
    .concat(widthProps)
    .concat(heightProps)
    .concat(textProps)
    .filter((p) => !exceptions.includes(p))
    .includes(prop);
};

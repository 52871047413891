import { Flex } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

export const StyledNav = styled.ul`
  display: inline-flex;
  gap: 12px;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const StyledRightMenu = styled(Flex)`
  height: 100%;
`;

import * as Sentry from '@sentry/react';
import useSWR, {
  Middleware,
  SWRHook,
  BareFetcher,
  SWRConfiguration,
  Arguments,
} from 'swr';
import { get, PropsRequest } from '@worten-sardines/shared/utils-apis';
import { DEFAULT_SWR_RETRY_COUNT } from '@worten-sardines/configs/config-seller-center';

import toast from '@worten-sardines/grill-ui';

const NO_OP = (v: string) => v;
const SHOW_ERROR_TOAST = (error: string) => {
  toast.error(error);
};

// https://swr.vercel.app/docs/middleware#serialize-object-keys
const serialize: Middleware =
  (useSWRNext: SWRHook) =>
  (key: unknown, fetcher: BareFetcher | null, config: SWRConfiguration) => {
    // Serialize the key.
    const serializedKey =
      typeof key === 'function'
        ? key()
        : Array.isArray(key)
        ? JSON.stringify(key)
        : key;

    // Pass the serialized key, and unserialize it in fetcher.

    if (fetcher) {
      return useSWRNext(
        serializedKey,
        (k: string) => fetcher(...JSON.parse(k)),
        config,
      );
    }

    return typeof key === 'function'
      ? useSWRNext(key, fetcher, config)
      : useSWRNext(key as Arguments, fetcher, config);
  };

/**
 * useFetch
 *
 * Custom hooks that handles requests to server.
 *
 * @param {*} key a unique URI key for the request (ex: api/todo)
 * @param {*} initialFetch true/false: tell hook if fetch effect should run on first mount. Useful when you want to use this hook with POST requests, for instance, in form submissions.
 * @param {*} payload Array with the arguments of the apiCallback
 * @param {*} successCallback Callback function to be executed if promise is resolved
 * @param {*} errorCallback Callback function to be executed if promise is rejected (i.e. on error)
 *
 * @returns [data, error, loading, mutate]
 */

type ErrorType = {
  key: string;
  error: {
    message: string;
    status: number;
    uri: string;
  };
};

export const useFetch = (
  key: string,
  initialFetch = true,
  payload?: object,
  successCallback?: (dataSuccess: string) => void,
  errorCallback?: (dataError: ErrorType) => void,
  ...opt: unknown[]
) => {
  const fetcher = (k: string, p: PropsRequest) => get(k, p);
  const { data, error } = useSWR(
    initialFetch && key ? [key, payload].filter((n) => n) : null,
    fetcher,
    {
      ...opt,
      use: [serialize],
      onSuccess: (dataSuccess) =>
        successCallback ? successCallback(dataSuccess) : NO_OP,
      onError: (err) => {
        if (key === '/auth/v1/me' && err.status === 401) return true;

        if (err.status === 401)
          return errorCallback
            ? errorCallback({ key, error: err })
            : SHOW_ERROR_TOAST(
                `Access Denied: Check your authentication credentials.`,
              );

        Sentry.captureException(err);

        if (typeof err !== 'object')
          errorCallback
            ? errorCallback({ key, error: err })
            : SHOW_ERROR_TOAST(err);

        return errorCallback
          ? errorCallback({ key, error: err })
          : SHOW_ERROR_TOAST(
              `The requested URL ${key} was not found on this server`,
            );
      },
      onErrorRetry: (err, _key, _config, revalidate, { retryCount }) => {
        if (
          (key === '/auth/v1/me' && err.status === 401) ||
          // Never retry on 404.
          // eslint-disable-next-line eqeqeq
          err == 'SyntaxError: Unexpected token < in JSON at position 0'
        )
          return;

        // Only retry up to DEFAULT_SWR_RETRY_COUNT times.
        if (retryCount >= DEFAULT_SWR_RETRY_COUNT) return;

        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
    },
  );

  //clears data only on userInfo endpoint error.
  const resultData = key === '/auth/v1/me' && error ? null : data;

  return {
    data: resultData,
    error,
    loading: (!error && !data && initialFetch) || false,
  };
};

export default useFetch;

import DOMPurify from 'isomorphic-dompurify';

export function setLazyLoadingImages(id: string) {
  if ('loading' in HTMLImageElement.prototype) {
    const parent = document.getElementById(id);
    const lazyLoadedElements = parent?.querySelectorAll<HTMLImageElement>(
      'img[loading="lazy"]',
    );

    lazyLoadedElements?.forEach((elm) => {
      elm.setAttribute('src', elm?.src);
    });
  } else {
    // Dynamically import the LazySizes library when lazy-loading is not supported
    const script = document.createElement('script');
    script.src =
      'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.1.2/lazysizes.min.js';
    document.body.appendChild(script);
  }
}

export function sanitizeHTML(content: string, enableLazyLoading = true) {
  let htmlContent = null;
  // Hook to enable lazy-loading in supported elements (images)
  DOMPurify.addHook(
    'uponSanitizeElement',
    enableLazyLoading ? addLazyLoadingToElements : noOpHook,
  );

  // Sanitize HTML content
  htmlContent = DOMPurify.sanitize(content, {
    ADD_TAGS: ['a'],
    ADD_ATTR: ['onclick', 'target'],
    ADD_URI_SAFE_ATTR: ['onclick'],
  });
  DOMPurify.removeHook('uponSanitizeElement');
  return htmlContent;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addLazyLoadingToElements(currentNode: any, hookEvent: any) {
  /**
   * IMPORTANT NOTE:
   *
   * While some of these images may appear in the first visible viewport,
   * it's very unlikely that any of them will be the LCP (Largest Contentful Paint) element.
   *
   * So it's better to assume lazy-loading for all of them (which can a large number of images),
   * than trying to add complex logic (using IntersectionObserver or libraries) to check the
   * position of the element within the viewport. This would also not be possible to do with SSR.
   *
   * Finally, the trade-off is minimal as stated in this article:
   *
   * "In Chromium, the impact of images in the initial viewport being marked with loading=lazy on Largest Contentful Paint
   * is fairly small, with a regression of <1% at the 75th and 99th percentiles compared to eagerly loaded images."
   *
   * @see https://web.dev/browser-level-image-lazy-loading/#avoid-lazy-loading-images-that-are-in-the-first-visible-viewport
   *
   */
  if (hookEvent.tagName === 'img') {
    // Unsupported browsers will just safely ignore this attribute.
    currentNode.setAttribute('loading', 'lazy');

    /**
     * LAZY-LOADING IN UNSUPPORTED BROWSERS:
     *
     * To prevent images from being eagerly loaded, 'src' attribute needs to be removed from DOM.
     * 1) In supported browsers, attribute 'src' will be reset in useEffect.
     * 2) Unsupported browsers will use "lazysizes" library to handle lazy-loading.
     *    As of today, Safari and pre-Chromium versions of Edge are
     *    the only browsers that do not support lazy-loading.
     *
     * @see https://web.dev/browser-level-image-lazy-loading/
     */
    const elementSrc = currentNode.src;
    currentNode.setAttribute('data-src', elementSrc);
  }
  return currentNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function noOpHook(currentNode: any) {
  return currentNode;
}

import { Flex, Theme as theme } from '@worten-sardines/grill-ui';
import styled, { css } from 'styled-components';
import { AnchorHTMLAttributes, HTMLAttributes } from 'react';
import { VIEW_MODE_CAMPAIGN } from './components/constants';

type LegendTitleProps = {
  as?: React.ReactNode;
} & (AnchorHTMLAttributes<HTMLAnchorElement> | HTMLAttributes<HTMLElement>);

type ContainerActionsProps = {
  viewType: string;
};

export const CampaignsWrapper = styled.div`
  border: 1px solid ${theme.color.neutral['neu-03']};
  border-radius: ${theme.Radius.xxs};
  background-color: ${theme.color.neutral.neu01};
  font-size: ${theme.fontsize.desktop.s1};
  font-weight: ${theme.weight.semibold};
  color: ${theme.color.neutral['neu-08']};

  margin: ${theme.spacing.xs};

  display: grid;
  height: calc(100vh - 125px);
  overflow: hidden;
  overflow-y: auto;
  grid-template-columns: 100%;
  align-content: flex-start;
`;

export const Header = styled.div`
  padding: ${theme.spacing.xs};
`;

export const ContainerLegends = styled(Flex).attrs({
  justifyContent: 'space-between',
})`
  align-items: center;
  padding: ${theme.spacing.xs};
  font-size: ${theme.fontsize.desktop.s1};
  font-weight: ${theme.weight.regular};
  gap: ${theme.spacing.xxs};
`;

export const LegendTitle = styled.div<LegendTitleProps>`
  ${({ as }) => css`
    ${as === 'a' && 'text-decoration: underline !important;'}
  `}
  display: flex;
  align-items: center;

  font-size: ${theme.fontsize.desktop.xxs};
  font-weight: ${theme.weight.bold};
  color: ${theme.color.neutral['neu-07']};
`;

export const ContainerActions = styled.div<ContainerActionsProps>`
  border-right: 1px solid
    ${({ viewType }) =>
      viewType === VIEW_MODE_CAMPAIGN.first_option
        ? theme.color.neutral['neu-03']
        : 'transparent'};
`;

export const EventsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  position: relative;
`;

import { StyledDateDisplay } from './styled';
import { AtLeastOne } from '@worten-sardines/grill-ui';

type DateDisplaySource = {
  dateNumber: string;
  label: string;
};

type DateDisplayOptions = {
  isRounded?: boolean;
  isActived?: boolean;
  isStatic?: boolean;
  variation?: 'primary' | 'secondary' | 'alert';
};

export type DateDisplayProps = AtLeastOne<DateDisplaySource> &
  DateDisplayOptions;

export const DateDisplay = ({
  dateNumber,
  label,
  isRounded = false,
  isActived,
  isStatic = true,
  variation = 'primary',
}: DateDisplayProps) => {
  return (
    <StyledDateDisplay
      isRounded={isRounded}
      isActived={isActived}
      isStatic={isStatic}
      variation={variation}
    >
      {dateNumber && <span className="dateNumber">{dateNumber}</span>}
      {label && <span className="label">{label}</span>}
    </StyledDateDisplay>
  );
};

export default DateDisplay;

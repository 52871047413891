import React from 'react';
import useOnClickOutside from './hooks/use-click-outside';
import { Backdrop, PopupContainer } from './styled';

export type PopupProps = {
  isOpen: boolean;
  onClose: () => void;
  content: React.ReactNode;
  position?:
    | 'center'
    | 'top-right'
    | 'top-left'
    | 'bottom-right'
    | 'bottom-left';
  hasBackdrop?: boolean;
  outsideClick?: boolean;
};

export const Popup = ({
  content,
  isOpen,
  onClose,
  hasBackdrop = true,
  outsideClick = true,
  position = 'top-right',
}: PopupProps) => {
  const popupRef = React.useRef<HTMLDivElement>(null);

  const handleClickInsideModal = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  useOnClickOutside(popupRef, () => {
    if (isOpen && outsideClick) {
      onClose();
    }
  });

  if (!isOpen) return null;

  return (
    <>
      <PopupContainer
        ref={popupRef}
        position={position}
        onClick={handleClickInsideModal}
      >
        {content}
      </PopupContainer>
      {hasBackdrop && <Backdrop id="popup-backdrop" />}
    </>
  );
};

export default Popup;

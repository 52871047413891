import { useState } from 'react';

type PopupContextProps = {
  isOpen: boolean;
  openPopup: () => void;
  closePopup: () => void;
};

export const usePopup = (): PopupContextProps => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return { isOpen, openPopup, closePopup };
};

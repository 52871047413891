import { useEffect, useRef, useState } from 'react';
import { Milestone } from './styled';

type TimelineCallback = {
  timelineX: number;
  currentDayX: number;
  direction: 'prev' | 'next' | 'initial';
};

type MilestoneLineProps = {
  timelinePosition: TimelineCallback;
  parentRef: React.RefObject<HTMLDivElement>;
};

const MilestoneLine = ({ timelinePosition, parentRef }: MilestoneLineProps) => {
  const [parentWidth, setParentWidth] = useState<number>(0);
  const milestoneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (parentRef.current) {
      setParentWidth(Math.round(parentRef.current.offsetWidth / 3));
    }
  }, [parentRef, timelinePosition]);

  useEffect(() => {
    function handleWindowResize() {
      if (parentRef.current) {
        setParentWidth(Math.round(parentRef.current.offsetWidth / 3));
      }
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [parentRef]);

  function slideMilestone({ timelineX, currentDayX }: TimelineCallback) {
    if (milestoneRef.current) {
      const position = currentDayX - timelineX + parentWidth;

      milestoneRef.current.style.transform = `translateX(${position + 17.5}px)`;

      if (position < parentWidth) {
        milestoneRef.current.style.display = 'none';
      } else {
        milestoneRef.current.style.display = 'block';
      }
    }
  }

  useEffect(() => {
    if (timelinePosition) {
      slideMilestone(timelinePosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentWidth, timelinePosition]);

  return <Milestone ref={milestoneRef} />;
};

export default MilestoneLine;

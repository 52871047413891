import { format as fnsFormat, Locale } from 'date-fns';
import enLocale from 'date-fns/locale/en-GB';
import esLocale from 'date-fns/locale/es';
import ptLocale from 'date-fns/locale/pt';

export const getFnsLocale = (locale: string): Locale => {
  const [tag] = locale.split('-') || '';

  const localeMap = {
    en: enLocale,
    es: esLocale,
    pt: ptLocale,
  };

  return localeMap[`${tag}` as keyof typeof localeMap] || localeMap.en;
};

export interface DateFnsLocale {
  code: string;
  locale: Locale;
  format: (date: Date, format: string) => string;
}

export const formatWithLocale = (code: string): DateFnsLocale => {
  const locale = getFnsLocale(code);

  const format = (date: Date, format: string) => {
    return fnsFormat(date, format, { locale });
  };
  return { code, locale, format };
};

import { Text, Theme as theme } from '@worten-sardines/grill-ui';
import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    display: grid;
    place-items: center;
    height: 100vh;
    width: 100vw;
    text-align: center;
    margin: auto;
    background: ${theme.color.gradient['g-03']};
  `}
`;

export const Header = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: ${theme.spacing.xxs};
`;

export const Subtitle = styled(Text)`
  font-size: ${theme.fontsize.desktop.m};
  font-weight: ${theme.weight.semibold};
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  font-size: ${theme.fontsize.desktop.s1};
  color: ${theme.color.neutral['neu-09']};
`;

import styled, { css } from 'styled-components';
import Badge from '../../Badge';
import Flex from '../../Flex';

export const StyledDataTableActionsBarButtonWrapper = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'flex-end',
})`
  ${({ theme }) => css`
    overflow: hidden;
    & > :not(:last-child) {
      margin-right: ${theme.spacing.xxxs};
    }
  `}
`;

export const StyledBadge = styled(Badge)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.xxxs};
  `}
`;

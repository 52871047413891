import styled, { css } from 'styled-components';

export const StyledPaper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    width: auto;
    max-width: 470px;
    background-color: ${theme.color.neutral.neu01};
    padding: ${theme.spacing.xxl};
    box-shadow: ${theme.level3};
    border-radius: ${theme.Radius.sm};
    position: relative;
  `}
`;

export const StyledContent = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const StyledFooter = styled.div`
  width: 100%;
`;

export const HR = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 1px;
    margin: 1rem 0 0;

    background-color: ${theme.color.neutral['neu-02']};
  `}
`;

import { StatusProps } from '@worten-sardines/seller-center-ui-shared';
import { ChannelType } from '../components/SellerPremiumList/types';

/**
 * Returns the channel to show based on the given channels and status.
 * If there is only one channel, it returns that channel.
 * Otherwise, it checks the status to determine the channel to show.
 * If the channel to show is 'pt', it returns 'WRT_PT_ONLINE'.
 * Otherwise, it returns 'WRT_ES_ONLINE'.
 *
 * @param channels - The array of channels.
 * @param status - The status props.
 * @returns The channel to show.
 */
export const getChannelToShow = (
  status: StatusProps,
  channels?: ChannelType[],
) => {
  if (channels && channels?.length === 1) return channels[0];

  return status.find((channel) => channel.show)?.channel === 'pt'
    ? 'WRT_PT_ONLINE'
    : 'WRT_ES_ONLINE';
};

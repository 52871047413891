/* eslint-disable @typescript-eslint/no-explicit-any */
import { PBI } from '@worten-sardines/configs/config-seller-center';
import { Loading } from '@worten-sardines/grill-ui';
import {
  ShopIdCard,
  UsefulLinks,
} from '@worten-sardines/seller-center-ui-layout';
import { pbi } from '@worten-sardines/seller-center/data-access';
import { MainTemplate, PbiIframe } from '@worten-sardines/shared-ui';
import {
  UserSessionType,
  useMutate,
  useSessionStorage,
} from '@worten-sardines/shared/hooks';
import { REGISTER_DATALAYER } from '@worten-sardines/trackings/tracking-seller-center';
import { models, service } from 'powerbi-client';
import { useCallback, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

type ValuesPBI = {
  embedTokenId: string;
  embedUrl: string;
  embedToken: string;
};

type Dispatch = <A>(action: A) => void;

type State = {
  data?: ValuesPBI;
  loading: boolean;
};

type SellerAnalyticsProps = {
  user: UserSessionType;
};

const dispatchEvent = (
  page: string,
  user: UserSessionType,
  countries = 'PT',
) => {
  const siteSection = `${(page || '')
    .replace(/\s/g, '')
    .toLowerCase()}${countries}`;

  REGISTER_DATALAYER('VIRTUAL_PAGEVIEW', {
    pageType: 'reports',
    siteSection,
    userType: user.data?.type,
    userShopId: user.data.shop?.id,
    userShopName: user.data?.shop?.name,
    userShopTier: user.data?.shop?.tier,
  });
};

export function SellerCenterFeatureSellerAnalytics() {
  const { user }: SellerAnalyticsProps = useOutletContext();
  const { value: shopInfo } = useSessionStorage('shop', null);
  const isOperator = user.data?.type === 'operator';
  const shopId = isOperator ? shopInfo?.id : '';
  let countries = 'PT';
  const [{ data, loading }, PbiMutate]: [State, Dispatch] = useMutate(
    pbi,
    [],
    false,
  );

  const pbiFn = useCallback(() => {
    const rawBody = JSON.stringify({
      reportId: PBI.REPORTS['report-id'],
      datasetId: PBI.REPORTS['dataset-id'],
      workspaceId: PBI.REPORTS['workspace-id'],
    });

    PbiMutate([[[`/v2/dashboard/generateAccess`]], { shopId }, rawBody]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PbiMutate, shopInfo]);

  useEffect(() => {
    pbiFn();
  }, [pbiFn]);

  if (loading || !data) {
    return (
      <MainTemplate.Content>
        <Loading mt="40vh" />
      </MainTemplate.Content>
    );
  }

  return (
    <>
      <MainTemplate.Content>
        <PbiIframe
          reportSettings={{
            type: 'report',
            //id: data?.embed_token_id, //must be embed_report_id
            embedUrl: data?.embedUrl,
            accessToken: data?.embedToken,
            tokenType: models.TokenType.Embed,
          }}
          eventHandlers={
            new Map([
              [
                'pageChanged',
                (event?: service.ICustomEvent<any>) => {
                  dispatchEvent(
                    event?.detail.newPage.displayName,
                    user,
                    countries,
                  );
                },
              ],
              [
                'dataSelected',
                (event?: service.ICustomEvent<any>) => {
                  const pageSales = '2e441ac0b27d47fb3b08';
                  const pageDelivery = 'da4766208adadc82accd';
                  const pagePricing = '5fae2d0fe41ea8d68f23';
                  const defaultTitleEvent = 'Slicer Pais Filter';

                  if (
                    event?.detail.visual.name === pageSales ||
                    event?.detail.visual.name === pageDelivery ||
                    event?.detail.visual.name === pagePricing ||
                    event?.detail.visual.title === defaultTitleEvent
                  ) {
                    countries = event.detail.dataPoints
                      ?.map((i: any) => i.identity[0].equals)
                      .join('');
                    dispatchEvent(
                      event.detail.page.displayName,
                      user,
                      countries,
                    );
                  }
                },
              ],
            ])
          }
        />
      </MainTemplate.Content>
      <MainTemplate.SidebarRight>
        {isOperator && <ShopIdCard isUnset />}
        <UsefulLinks />
      </MainTemplate.SidebarRight>
    </>
  );
}

export default SellerCenterFeatureSellerAnalytics;

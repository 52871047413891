/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  addDays,
  addMonths,
  endOfMonth,
  isAfter,
  isSameDay,
  startOfMonth,
} from 'date-fns';

import { formatWithLocale } from '@worten-sardines/grill-ui';

import { DaysOfMonth, TimeLine, UseTimelineProps } from '../types';

export const useTimeline = ({
  totalMonths,
  locale,
  limitDate,
}: UseTimelineProps) => {
  const initialTimelineLoaded = useRef(false);

  const { format } = formatWithLocale(locale);
  const date = addMonths(new Date(), -1);
  const limit = limitDate || false;

  const [timeline, setTimeline] = useState<TimeLine[]>([]);
  const [currentDate] = useState<Date>(new Date());
  const [firstMonth, setFirstMonth] = useState<Date>(date);
  const [lastMonth, setLastMonth] = useState<Date>(date);
  const [disableLoadNext, setDisableLoadNext] = useState<boolean>(false);

  const createWeeks = useCallback(
    (
      startOfTheCurrentMonth: Date,
      endOfTheCurrentMonth: Date,
      format: (date: Date, format: string) => string,
    ) => {
      const allWeeks: DaysOfMonth[] = [];

      while (startOfTheCurrentMonth < endOfTheCurrentMonth) {
        if (limit && isAfter(startOfTheCurrentMonth, limit)) {
          setDisableLoadNext(true);
          break;
        }

        allWeeks.push({
          day: format(startOfTheCurrentMonth, 'dd'),
          dayOfWeek: format(startOfTheCurrentMonth, 'EE'),
          variation:
            startOfTheCurrentMonth >= currentDate ? 'primary' : 'secondary',
          isToday: isSameDay(startOfTheCurrentMonth, currentDate),
        });
        startOfTheCurrentMonth = addDays(startOfTheCurrentMonth, 1);
      }
      return allWeeks;
    },
    [currentDate, limit],
  );

  const getFirstLastDayOfMonth = (month: Date) => ({
    startDay: startOfMonth(month),
    endDay: endOfMonth(month),
  });

  const loadPrev = () => {
    const month = addMonths(firstMonth, -1);
    const { startDay, endDay } = getFirstLastDayOfMonth(month);

    const allWeeks = createWeeks(startDay, endDay, format);

    setFirstMonth(month);
    setTimeline((prevTimeline) => [
      {
        year: format(month, 'yyyy'),
        month: format(month, 'MMMM'),
        weeks: [...allWeeks],
      },
      ...prevTimeline,
    ]);
  };

  const loadNext = () => {
    if (disableLoadNext) return;

    const month = addMonths(lastMonth, 1);
    const { startDay, endDay } = getFirstLastDayOfMonth(month);

    const allWeeks = createWeeks(startDay, endDay, format);

    if (allWeeks.length) {
      setLastMonth(month);
      setTimeline((prevTimeline) => [
        ...prevTimeline,
        {
          year: format(month, 'yyyy'),
          month: format(month, 'MMMM'),
          weeks: [...allWeeks],
        },
      ]);
    }
  };

  const createTimeline = useCallback(() => {
    const timeline: TimeLine[] = [];
    let currentMonth: Date = date;
    for (let nextMonth = 0; nextMonth < totalMonths; nextMonth++) {
      currentMonth = startOfMonth(addMonths(lastMonth, nextMonth));
      const startOfTheCurrentMonth = startOfMonth(
        addMonths(lastMonth, nextMonth),
      );
      const endOfTheCurrentMonth = endOfMonth(addMonths(lastMonth, nextMonth));

      const allWeeks = createWeeks(
        startOfTheCurrentMonth,
        endOfTheCurrentMonth,
        format,
      );

      timeline.push({
        year: format(currentMonth, 'yyyy'),
        month: format(currentMonth, 'MMMM'),
        weeks: [...allWeeks],
      });
    }
    setLastMonth(currentMonth);
    setTimeline((prevTimeline) => [...prevTimeline, ...timeline]);
  }, [createWeeks, date, format, lastMonth, totalMonths]);

  useEffect(() => {
    if (initialTimelineLoaded.current) {
      return;
    }

    createTimeline();
    initialTimelineLoaded.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { timeline, loadNext, loadPrev, disableLoadNext };
};

import { useLayoutEffect, ReactNode } from 'react';
import { createPortal } from 'react-dom';

type PopoverPortalProps = {
  children: ReactNode;
  container: Element;
  element: Element;
};

export const PopoverPortal = ({
  children,
  container,
  element,
}: PopoverPortalProps) => {
  useLayoutEffect(() => {
    container.appendChild(element);
    return () => {
      container.removeChild(element);
    };
  }, [container, element]);

  return createPortal(children, element);
};

import { isAfter, isBefore } from 'date-fns';
import { monthArray, monthArrayES, monthArrayPT } from './constants';

export const monthConverter = (month: string | number, locale: string) => {
  const monthArr = monthArrByLocale(locale);

  if (typeof month === 'string') {
    return monthArr.indexOf(month);
  }

  return monthArr[month];
};

export const formatDayMonth = (date: string, locale: string): string => {
  if (!date) {
    return '';
  }

  const day = Intl.DateTimeFormat(locale, {
    day: 'numeric',
  }).format(new Date(date));

  const month = Intl.DateTimeFormat(locale, {
    month: 'short',
  }).format(new Date(date));

  const monthCapitalized = month.charAt(0).toUpperCase() + month.slice(1);

  return `${day} ${monthCapitalized.slice(0, 3)}`;
};

export const getNextMonth = (month: string, locale: string): string => {
  const monthArr = monthArrByLocale(locale);

  const monthIndex = monthArr.indexOf(month);

  if (monthIndex === 11) {
    return monthArr[0];
  }

  return monthArr[monthIndex + 1];
};

export const monthArrByLocale = (locale: string): string[] => {
  switch (locale) {
    case 'pt':
      return monthArrayPT;
    case 'es':
      return monthArrayES;
    default:
      return monthArray;
  }
};

export const getPayoutStatus = (
  cycle: number,
  endDate: Date,
  noItems: boolean,
) => {
  const now = new Date();
  const statusDay = cycle === 1 ? 22 : 7;

  const limitDate =
    cycle === 2
      ? new Date(
          new Date(endDate).getFullYear(),
          new Date(endDate).getMonth() + 1,
          statusDay,
        )
      : new Date(endDate.setDate(statusDay));

  const isLimitDateAfterNow = isAfter(now, limitDate);
  const isLimitDateBeforeNow = isBefore(now, limitDate);

  if (noItems) {
    if (isLimitDateBeforeNow) {
      return 'future';
    }

    if (isLimitDateAfterNow) {
      return 'unpaid';
    }
  }

  return 'paid';
};

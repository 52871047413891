import { Theme as theme } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  height: fit-content;

  gap: ${theme.spacing.xs};
`;

export const Title = styled.h1`
  ${theme.sys.typeface['size-22']['3301-700']}
  margin: 0;
`;
export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: ${theme.spacing.xs};
`;

export const ToggleContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 36rem;
`;

export const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  margin-top: ${theme.spacing.lg};
`;

import { defineMessages } from 'react-intl';

export default defineMessages({
  titleNotFound: {
    id: 'libs.seller-center.ui-layout.NotFound.textNotFound',
    defaultMessage: 'It seems you are lost...',
  },
  subtitleNotFound: {
    id: 'libs.seller-center.ui-layout.NotFound.textNotFoundMessage',
    defaultMessage:
      'Don’t worry, we will help you find your way with these helpful links:',
  },
  errorMessageNotFound: {
    id: 'libs.seller-center.ui-layout.NotFound.errorNotFoundMessage',
    defaultMessage: 'Error 404 - page not found',
  },
});

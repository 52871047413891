import { useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useOutletContext } from 'react-router-dom';
import { MainTemplate } from '@worten-sardines/shared-ui';
import { getPayouts } from '@worten-sardines/seller-center/data-access';
import {
  UserSessionType,
  useFetch,
  useSession,
  useSessionStorage,
} from '@worten-sardines/shared/hooks';
import {
  DataGrid,
  SimpleSelect,
  getSearchParamsUrl,
  setSearchParamsUrl,
} from '@worten-sardines/grill-ui';
import { CreateDataTableRequestType } from '@worten-sardines/shared/utils-apis';
import { StyledPayoutsWrapper } from './styled';
import messages from './messages';
import { Skeleton, payloadPayoutsTable } from './components';

import {
  ShopIdCard,
  UsefulLinks,
} from '@worten-sardines/seller-center-ui-layout';

export type OptionType = { value: string; label: string; selected?: boolean };
type OutletContextType = { intl: IntlShape; user: UserSessionType };
type FilterPropsType = {
  disabled?: boolean;
  filter: {
    label: string;
    name: string;
    value: string;
  };
  onChange: () => void;
};

export const PayoutsPage = () => {
  const user = useSession() || {};
  const intl = useIntl();
  const props: OutletContextType = useOutletContext();
  const getParams = getSearchParamsUrl();
  const isOperator = user.data?.type === 'operator';
  const [args, setArgs] = useState<CreateDataTableRequestType>();
  const { value: shopInfo } = useSessionStorage('shop', null);
  const shopId = shopInfo?.id;
  const showContentPage = !isOperator || !!shopId;
  const hasArgs = typeof args === 'object' && Object.keys(args).length > 0;
  const isToFetch = hasArgs && ((isOperator && shopId) || !isOperator);

  const currentYear = props?.intl.formatDate(Date.now(), { year: 'numeric' });

  const yearFilterOptions = [
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
  ].map((y) => {
    if (!getParams['year'] && y.value === currentYear) {
      return { ...y, selected: true };
    }
    return y;
  });

  const { data: payouts, loading } = useFetch(
    getPayouts().key,
    isToFetch,
    getPayouts(args, shopId).args,
  );

  const yearFetch = args?.filtered?.filter((i) => i.id === 'year')[0].value;

  const payoutsMapped = yearFetch
    ? payloadPayoutsTable(payouts?.data, yearFetch, props?.intl)
    : [];

  const header = [
    {
      cell: 'Year',
      id: 'year',
      Filter: (filterProps: FilterPropsType) => (
        <SimpleSelect
          label="Year"
          name="year"
          options={yearFilterOptions.map((f) =>
            f.value === filterProps.filter.value ? { ...f, selected: true } : f,
          )}
          handleOnChange={filterProps.onChange}
        />
      ),
      show: false,
      advancedFilterCategory: false,
    },
    {
      cell: intl.formatMessage(messages.cycleMonthTitle),
      id: 'month',
      filterable: false,
    },
    {
      cell: intl.formatMessage(messages.cycleTitle, { cycle_number: 1 }),
      id: 'cycle1',
      filterable: false,
    },
    {
      cell: intl.formatMessage(messages.cycleTitle, { cycle_number: 2 }),
      id: 'cycle2',
      filterable: false,
    },
  ];

  const onSetFilter = (filters: Record<string, string>) => {
    if (!getParams['year']) {
      setSearchParamsUrl({
        ...filters,
        year: currentYear,
      });

      setArgs({
        ...filters,
        filtered: [{ id: 'year', value: currentYear }],
      });
    } else {
      setArgs(filters);
    }
  };

  return (
    <>
      <MainTemplate.Content>
        {showContentPage ? (
          <StyledPayoutsWrapper>
            <DataGrid
              bordered
              backgroundColorHead="#f6f6f6"
              enableColumnBorders
              loading={loading}
              columnNavigation
              onFetchData={(filters: Record<string, string>) => {
                onSetFilter(filters);
              }}
              dataTable={{
                header,
                data: payoutsMapped,
              }}
              freezeColumn
              hideColumns={['year']}
            />
          </StyledPayoutsWrapper>
        ) : (
          <Skeleton
            data-testid="skeleton"
            template={[
              { columns: 4 },
              { columns: 1 },
              { columns: 3 },
              { columns: 2 },
            ]}
          />
        )}
      </MainTemplate.Content>
      <MainTemplate.SidebarRight>
        {isOperator && <ShopIdCard isUnset />}
        <UsefulLinks />
      </MainTemplate.SidebarRight>
    </>
  );
};

export default PayoutsPage;

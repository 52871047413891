import { useIntl } from 'react-intl';
import DateDisplay from '../../../DateDisplay';
import { getDayFromDate, normalizePeriod } from '../../helpers/date';
import { SingleEventProps } from '../../types';
import { EventWrapper, IntersectionEventWrapper } from './styled';

export const SingleEvent = ({
  period,
  variant = 'first',
  showMonth = false,
  daySize = 35,
  firstPeriod,
}: SingleEventProps) => {
  const { startDate, endDate, intersectionDate } = period;
  const locale = useIntl().locale;

  const dateType = variant === 'first' ? 'alert' : 'primary';

  const hasIntersection = !!intersectionDate;

  const { days, sizeOfPeriod, months, oneDay, sizeOfIntersectionPeriod, diff } =
    normalizePeriod(
      { startDate, endDate, intersectionDate },
      locale,
      firstPeriod && firstPeriod,
    );

  const sizeOfVariant =
    variant === 'intersection' ? sizeOfIntersectionPeriod : sizeOfPeriod;

  if (hasIntersection && variant === 'second' && oneDay) return null;

  return (
    <EventWrapper
      variant={variant}
      size={sizeOfVariant}
      daySize={daySize}
      hasIntersection={hasIntersection}
      diff={diff}
      oneDay={oneDay}
    >
      <DateDisplay dateNumber={days.start} isRounded variation={dateType} />
      {hasIntersection && variant === 'first' && !oneDay && (
        <IntersectionEventWrapper
          variant={'intersection'}
          size={sizeOfIntersectionPeriod}
          daySize={daySize}
          hasIntersection={hasIntersection}
        >
          {diff < 1 ||
            (!oneDay && (
              <DateDisplay
                dateNumber={getDayFromDate(intersectionDate)}
                isRounded
              />
            ))}
          <DateDisplay
            dateNumber={days.end}
            isRounded
            variation={dateType}
            label={showMonth ? months.end.toString() : ''}
          />
        </IntersectionEventWrapper>
      )}
      {!oneDay && (
        <DateDisplay
          dateNumber={days.end}
          isRounded
          variation={dateType}
          label={showMonth ? months.end.toString() : ''}
        />
      )}
    </EventWrapper>
  );
};

export default SingleEvent;

export function generateRange(start: number, end: number) {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

export function generatePageNumbers(
  currentPage: number,
  totalPages: number,
): number[] {
  if (totalPages <= 5) {
    return generateRange(1, totalPages);
  }

  if (currentPage < 4) {
    return [...generateRange(1, 4), 0, totalPages];
  }

  if (currentPage >= totalPages - 2) {
    return [1, 0, ...generateRange(totalPages - 3, totalPages)];
  }

  return [1, 0, currentPage - 1, currentPage, currentPage + 1, 0, totalPages];
}

export function validatePaginationProps(page: number, totalPages: number) {
  let current = page;
  let total = totalPages;

  if (page < 1) current = 1;

  if (page > totalPages) current = totalPages;

  if (totalPages < 1) total = 0;

  return { current, total };
}

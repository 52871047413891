import { StyledHeaderBar } from './styled';

export type HeaderBarProps = {
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const HeaderBar = ({ children, ...props }: HeaderBarProps) => {
  return <StyledHeaderBar {...props}>{children}</StyledHeaderBar>;
};

export default HeaderBar;

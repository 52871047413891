import { ArrowLink, Svg } from '@worten-sardines/grill-ui';
import { AnchorHTMLAttributes, HTMLAttributes } from 'react';
import { DividerTitle, StyledDivider } from './styled';

type DividerTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | HTMLAttributes<HTMLElement>;

export type DividerProps = {
  title: string;
  icon?: JSX.Element;
  href?: string;
  as?: React.ElementType;
} & DividerTypes;

export const Divider = ({
  icon,
  title,
  as,
  href = '',
  ...props
}: DividerProps) => {
  const isAnchor = as === 'a' && href?.length > 0 && href !== '#';
  const hasHref = isAnchor ? { href } : {};
  const renderAs = isAnchor ? 'a' : as === 'a' && !isAnchor ? 'div' : as;

  return (
    <StyledDivider as={renderAs} tabIndex={0} {...hasHref} {...props}>
      {icon && (
        <Svg svg={icon} width="24" height="24" title={`${title} icon`} />
      )}
      <DividerTitle>{title}</DividerTitle>

      {isAnchor && (
        <Svg
          className="icon-arrow"
          svg={<ArrowLink />}
          width={24}
          title="arrow icon"
        />
      )}
    </StyledDivider>
  );
};

export default Divider;

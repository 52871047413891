import { defineMessages } from 'react-intl';

export default defineMessages({
  reports: {
    id: 'apps.seller-center.routes.reports',
    defaultMessage: 'Reports',
  },
  campaigns: {
    id: 'apps.seller-center.routes.campaigns',
    defaultMessage: 'Campaigns',
  },
  payouts: {
    id: 'apps.seller-center.routes.payouts',
    defaultMessage: 'Payouts',
  },
  sellerPremium: {
    id: 'apps.seller-center.routes.sellerPremium',
    defaultMessage: 'Seller Premium',
  },
  cookiePolicy: {
    id: 'apps.seller-center.routes.cookiePolicy',
    defaultMessage: 'Cookie policy',
  },
  services: {
    id: 'apps.seller-center.routes.services',
    defaultMessage: 'Services',
  },
  login: {
    id: 'apps.seller-center.routes.login',
    defaultMessage: 'Login',
  },
  notFound: {
    id: 'apps.seller-center.routes.notFound',
    defaultMessage: 'Page not found',
  },
  premium: {
    id: 'apps.seller-center.routes.premium',
    defaultMessage: 'Seller Premium',
  },
});

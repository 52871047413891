import { formatWithLocale } from '@worten-sardines/grill-ui';
import ButtonRow from '../components/ButtonRow';

import {
  DateDetailCard,
  formatDate,
  Info,
} from '@worten-sardines/seller-center-ui-shared';
import {
  CampaignsArgs,
  CampaignsSchema,
  DataTableType,
  Header,
  Sorting,
} from '../../types';

const FORMAT_DATE = 'dd MMM';
const FORMAT_DATE_LIST = 'yyyy-MM-dd';

export const createTableData = (
  header: Header,
  campaigns: Array<CampaignsSchema>,
  locale: string,
  action: (e: CampaignsArgs) => void,
) => {
  const { format: formatFn } = formatWithLocale(locale);

  if (!campaigns) {
    return { header, data: [] };
  }

  const data = campaigns
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((campaign: any) => {
      const { hasWarning, isInitiated, isClosed } = formatDate(
        new Date(campaign.submissionStartDate),
        new Date(campaign.submissionDate),
        campaign.daysInAdvance,
        locale,
      );

      if (!isInitiated || isClosed) return null;

      return {
        campaignName: (
          <ButtonRow
            primary
            label={campaign.title}
            info={campaign}
            action={action}
            badge={<Info />}
            showBadge={hasWarning}
          />
        ),
        submissionPeriod: (
          <ButtonRow
            label={formatFn(new Date(campaign.submissionDate), FORMAT_DATE)}
            info={campaign}
            action={action}
          >
            <DateDetailCard
              startDate={new Date(campaign.submissionStartDate)}
              endDate={new Date(campaign.submissionDate)}
              highlightEndDate
              noBorder
            />
          </ButtonRow>
        ),
        runningTime: (
          <ButtonRow
            label={formatFn(new Date(campaign.startDate), FORMAT_DATE)}
            info={campaign}
            action={action}
          >
            <DateDetailCard
              startDate={new Date(campaign.startDate)}
              endDate={new Date(campaign.endDate)}
              noBorder
            />
          </ButtonRow>
        ),
        _data: {
          id: campaign.name,
          campaignName: campaign.title,
          action: action,
          info: campaign,
          showBadge: hasWarning,
          firstPeriod: {
            startDate: formatFn(
              new Date(campaign.submissionStartDate),
              FORMAT_DATE_LIST,
            ),
            endDate: formatFn(
              new Date(campaign.submissionDate),
              FORMAT_DATE_LIST,
            ),
          },
          secondPeriod: {
            startDate: formatFn(new Date(campaign.startDate), FORMAT_DATE_LIST),
            endDate: formatFn(new Date(campaign.endDate), FORMAT_DATE_LIST),
          },
        },
      };
    })
    .filter((i) => i);

  return { header, data };
};

export const sortDataTable = (
  data: DataTableType['data'],
  updaterOrValue: Sorting,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sortData = data.sort((a: any, b: any) => {
    if (
      updaterOrValue.id === 'submissionPeriod' &&
      updaterOrValue.sortBy === 'asc'
    ) {
      return a[updaterOrValue.id].props.children.props.startDate <
        b[updaterOrValue.id].props.children.props.startDate
        ? -1
        : 1;
    }

    if (updaterOrValue.sortBy === 'desc' || updaterOrValue.sortBy === 'asc') {
      return a[updaterOrValue.id].props.label.localeCompare(
        b[updaterOrValue.id].props.label,
      );
    }

    return b[updaterOrValue.id].props.label.localeCompare(
      a[updaterOrValue.id].props.label,
    );
  }) as DataTableType['data'];

  return sortData;
};

import styled, { css, DefaultTheme } from 'styled-components';

type Props = {
  hasError?: boolean | null;
  disabled?: boolean;
  isVisible?: boolean;
  active?: boolean;
  isOpen?: boolean;
};

const bolderColor = '#767676';

const activatedLabel = (theme: DefaultTheme) => css`
  top: -${theme.spacing.xxs};
  font-size: ${theme.fontsize.desktop.xs};
  left: 1rem;
  color: ${theme.color.neutral['neu-07']};
`;

export const StyledIcon = styled.button<Props>`
  ${({ theme, disabled, isVisible, hasError }) => css`
    position: absolute;
    width: 28px;
    height: 28px;
    right: 0;
    margin: 5px 10px;
    text-align: center;
    z-index: 1;
    transform: rotate(90deg);

    & svg {
      transition: all ${theme.animation.duration.medium} ease-in;
      ${isVisible &&
      css`
        transform: rotate(180deg);
        transform-origin: center center;
      `}
    }

    ${disabled &&
    css`
      cursor: not-allowed;
    `}

    ${!!hasError &&
    css`
      svg {
        color: ${theme.color.extend['m-01--light-01']};
      }
    `}
  `}
`;

export const SelectContainer = styled.div<Props>`
  ${({ disabled }) => css`
    position: relative;
    display: flex;
    width: 100%;
    background-color: inherit;
    flex-direction: column;
    cursor: pointer;

    ${disabled &&
    css`
      opacity: 0.2;
    `}
  `}
`;

export const SelectBodyOptions = styled.div<Props>`
  ${({ theme, disabled, isVisible, hasError }) => css`
    width: 100%;
    position: absolute;
    top: ${theme.size.md};
    z-index: 2;
    color: ${bolderColor};
    background-color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.13rem solid ${theme.color.neutral['neu-05']};
    border-top: none;
    border-radius: 0 0 0.4rem 0.4rem;

    font-size: ${theme.fontsize.desktop.m};

    &:before {
      top: 2px;
      content: '';
      height: 1px;
      position: relative;
      width: calc(100% - 32px);
      background-color: ${theme.color.neutral['neu-11']};
    }

    ${!isVisible &&
    css`
      display: none;
    `}

    ${!disabled &&
    !hasError &&
    css`
      &:hover,
      &:focus,
      &:not(:placeholder-shown) {
        border-color: ${theme.color.neutral['neu-11']};
        border-top: none;
      }
    `}

    ${hasError &&
    css`
      border-color: ${theme.color.extend['m-01--light-01']};
      &:focus,
      :hover {
        border-color: ${theme.color.extend['m-01--light-01']};
      }
      ~ label {
        color: ${theme.color.extend['m-01--light-01']};
      }
    `}
  `}
`;

export const StyledList = styled.ul<Props>`
  padding: 0;
  list-style: none;
  left: 0;
  margin: 0;
  width: 100%;
  max-height: 144px;
  background: inherit;
  overflow: auto;
  border-radius: 0 0 0.4rem 0.4rem;
`;

export const StyledListItem = styled.li<Props>`
  ${({ theme, active }) => css`
    & button {
      width: 100%;
      text-align: left;
      padding: ${theme.spacing.xs};
      line-height: ${theme.spacing.xs};

      ${active &&
      css`
        color: ${theme.color.neutral['neu-11']};
        background-color: ${theme.color.neutral['neu-02']};
      `}

      &:hover,
      :focus,
      :focus:hover {
        background-color: ${theme.color.neutral['neu-02']};
        color: ${theme.color.neutral['neu-11']};
        outline: none;
      }
    }
  `}
`;

export const StyledInput = styled.input<Props>`
  ${({ theme, hasError, disabled, isOpen }) => css`
    width: 100%;
    outline: none;
    color: inherit;
    cursor: pointer;
    position: relative;
    background: inherit;
    font-family: inherit;
    border-radius: 0.4rem;
    padding: ${theme.spacing.xxs} ${theme.spacing.lg} ${theme.spacing.xxs}
      ${theme.spacing.xs};
    margin-bottom: ${theme.spacing.xxs};
    min-height: ${theme.size.md};
    font-weight: ${theme.weight.semibold};
    font-size: ${theme.fontsize.desktop.m};
    border: 0.13rem solid ${theme.color.neutral['neu-05']};
    transition: border ${theme.animation.duration.medium} ease-in;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:disabled {
      background: ${theme.color.neutral['neu-05']};
      cursor: not-allowed;
    }

    ~ label {
      max-width: calc(100% - 40px);
    }

    ${isOpen &&
    css`
      &:hover,
      &:focus,
      &:not(:focus) {
        border-color: ${theme.color.neutral['neu-11']};
        border-radius: 0.4rem 0.4rem 0 0;
      }
    `}
    &:focus
      ~ label,
    &:not(:placeholder-shown) ~ label {
      ${activatedLabel(theme)}
    }

    ${isOpen &&
    css`
      &:not(:focus) ~ label {
        ${activatedLabel(theme)}
      }
    `}

    ${disabled &&
    !hasError &&
    css`
      border-color: ${theme.color.neutral['neu-11']};
    `}

    ${hasError &&
    css`
      border-color: ${theme.color.extend['m-01--light-01']};
      &:focus,
      :hover {
        border-color: ${theme.color.extend['m-01--light-01']};
      }
      ~ label {
        color: ${theme.color.extend['m-01--light-01']};
      }
    `}
  `}
`;

export const StyledButton = styled.button<Props>`
  ${({ theme }) => css`
    background: none repeat scroll 0 0 transparent;
    border: medium none;
    border-spacing: 0;
    color: inherit;
    font-size: ${theme.fontsize.desktop.m};
    font-weight: normal;
    list-style: none outside none;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: none;
    text-indent: 0:
  `}
`;

import { SkeletonContainer, SkeletonLine, SkeletonRectangle } from './styled';

type Columns = {
  columns: number;
};

type SkeletonTemplate = {
  template: Array<Columns>;
};

export const SkeletonLoader = ({ template }: SkeletonTemplate) => (
  <SkeletonContainer>
    {template.map(({ columns }, index) => (
      <SkeletonLine key={`${columns}_${index}`} columns={columns}>
        {[...Array(columns)].fill(null).map((_, rowIndex) => (
          <SkeletonRectangle key={`row_${columns}_${rowIndex}`} />
        ))}
      </SkeletonLine>
    ))}
  </SkeletonContainer>
);

export default SkeletonLoader;

import { Svg } from '@worten-sardines/grill-ui';
import DropdownMenu, { dropdownItem } from '../DropdownMenu';
import { StyledButton } from './styled';

export type UserMenuProps = {
  svg: JSX.Element;
  name?: string;
  dropdownItems?: dropdownItem[];
};

export const UserMenu = ({
  name = 'Test User',
  svg,
  dropdownItems = [],
}: UserMenuProps) => {
  return (
    <DropdownMenu items={dropdownItems} width="140px">
      <StyledButton
        icon={<Svg title="User Menu" svg={svg} />}
        variation="tertiary"
        size="small"
        fullWidth
      >
        {name}
      </StyledButton>
    </DropdownMenu>
  );
};

export default UserMenu;

import { defineMessages } from 'react-intl';

export default defineMessages({
  cycleInfo: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.cycleInfo',
    defaultMessage: '{from} - {to}',
  },
  cycleStatus: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.cycleStatus',
    defaultMessage: 'Payed on the {payed_day} of {payed_month}',
  },
  cycleDropdownLabel: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.cycleDropdownLabel',
    defaultMessage: 'Download file {count}',
  },
  cycleDocTypeInvoice: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.cycleDocTypeInvoice',
    defaultMessage: 'Invoice',
  },
  cycleDocTypeCreditNote: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.cycleDocTypeCreditNote',
    defaultMessage: 'Credit Note',
  },
  cycleMonthTitle: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.cycleMonthTitle',
    defaultMessage: 'Month',
  },
  cycleTitle: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.cycleTitle',
    defaultMessage: 'Cycle {cycle_number}',
  },
  cycleWithNoPayment: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.cycleWithNoPayment',
    defaultMessage: 'No payments for this cycle',
  },
  cycleStatusUnpaid: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.cycleStatusUnpaid',
    defaultMessage: 'Payment not effected',
  },
  cycleStatusFuture: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.cycleStatusFuture',
    defaultMessage: 'Future payment',
  },
  svgDownloadAltText: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.svgDownloadAltText',
    defaultMessage: 'Download payout file',
  },
  docTypeCommissionCreditNote: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.docTypeCommissionCreditNote',
    defaultMessage: 'C_CN {issuerCountryCode} {invoiceId}',
  },
  docTypeCommissionCreditNoteDetails: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.docTypeCommissionCreditNoteDetails',
    defaultMessage: 'C_CND {issuerCountryCode} {invoiceId}',
  },
  docTypeCreditNote: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.docTypeCreditNote',
    defaultMessage: 'C_CN {issuerCountryCode} {invoiceId}',
  },
  docTypeCommissionInvoice: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.docTypeCommissionInvoice',
    defaultMessage: 'C_I {issuerCountryCode} {invoiceId}',
  },
  docTypeCommissionInvoiceDetails: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.docTypeCommissionInvoiceDetails',
    defaultMessage: 'C_ID {issuerCountryCode} {invoiceId}',
  },
  docTypeInvoice: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.docTypeInvoice',
    defaultMessage: 'C_I {issuerCountryCode} {invoiceId}',
  },
  docTypeSubscriptionInvoice: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.docTypeSubscriptionInvoice',
    defaultMessage: 'S_I {issuerCountryCode} {invoiceId}',
  },
  docTypeSubscriptionCreditNote: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.docTypeSubscriptionCreditNote',
    defaultMessage: 'S_CN {issuerCountryCode} {invoiceId}',
  },
  docTypeNCAInvoice: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.docTypeNCAInvoice',
    defaultMessage: 'NCA_I {issuerCountryCode} {invoiceId}',
  },
  docTypeNCACreditNote: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.docTypeNCACreditNote',
    defaultMessage: 'NCA_CN {issuerCountryCode} {invoiceId}',
  },
  docTypePayoutDetails: {
    id: 'libs.seller-center.feature.PayoutsPage.PayoutCell.docTypePayoutDetails',
    defaultMessage: 'PD {issuerCountryCode} {invoiceId}',
  },
});

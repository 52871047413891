/**
 * Grid request utilities
 */

import { PAGINATION_CONFIG } from '@worten-sardines/grill-ui';

type Filtered = {
  id: string;
  value: string;
};

export type CreateDataTableRequestType = {
  offset?: string;
  limit?: string;
  filtered?: Filtered[];
  sorted?: [];
};
export type OptionsType = {
  pagination: boolean;
};

export function createDataTableRequest(
  payload?: CreateDataTableRequestType,
  options?: OptionsType,
) {
  const {
    offset = PAGINATION_CONFIG.offset,
    limit = PAGINATION_CONFIG.limit,
    filtered = [],
    sorted = {},
  } = payload || {
    offset: PAGINATION_CONFIG.offset,
    limit: PAGINATION_CONFIG.limit,
    filtered: [],
    sorted: {},
  };

  const paginationPayload = options?.pagination && {
    offset,
    limit,
  };

  /**
   * Converts DataTable filtered format: [{ id: columnId, value: ... }, ...]
   *
   * To a reduced object that will be converted to query parameters: { columnId: value, ... }
   */
  /* eslint-disable no-param-reassign */
  let filterPayload = {};
  if (filtered && filtered.length) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterPayload = filtered.reduce((accum: any, filter: Filtered) => {
      const filterId = filter.id;
      const filterValue = filter.value;

      if (Array.isArray(filterValue) && filterValue.length > 0) {
        accum[filterId] = filterValue.map((v) => v.value || v).toString();
      } else if (
        typeof filterValue !== 'undefined' &&
        filterValue !== null &&
        filterValue.toString().length > 0
      ) {
        accum[filterId] = filterValue;
      }
      return accum;
    }, {});
  }
  /* eslint-enable no-param-reassign */

  const sorting = options?.pagination && { ...sorted };

  return { ...paginationPayload, ...filterPayload, ...sorting };
}

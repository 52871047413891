/*
 *
 * useAuth constants
 *
 */

// export const getRedirectUri = () => `https://dev-sellercenter.worten.com/oauth`;
export const getRedirectUri = () => `${window.location.origin}/oauth`;

export const getExternalAuthUrl = (url: string) => {
  const extraParams = new URLSearchParams({
    redirect_uri: getRedirectUri(),
  });

  return `${url}?${extraParams.toString()}`;
};

export const IGNORED_ROUTES_FOR_REDIRECT = ['/not-found'];

import styled, { css } from 'styled-components';
import { PbiIframeProps } from '.';

type PbiIframeWrapperProps = Pick<PbiIframeProps, 'fullPage'>;

export const PbiIframeWrapper = styled.div<PbiIframeWrapperProps>`
  width: 100%;
  ${({ theme, fullPage }) => css`
  .default-report-class {
    ${
      fullPage &&
      css`
        height: calc(100vh - ${theme.size.xxl});
      `
    }
    width: 100%;
    padding: 0 ${theme.size.xs};
  }

  & iframe {
    border: 0 !important;
  `}
`;

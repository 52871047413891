import {
  useFetch,
  useSession,
  useSessionStorage,
} from '@worten-sardines/shared/hooks';
import { useEffect, useState } from 'react';

import {
  ShopIdCard,
  UsefulLinks,
} from '@worten-sardines/seller-center-ui-layout';
import {
  ContentCMS,
  IndicatorBar,
  SkeletonLoader,
  StatusProps,
} from '@worten-sardines/seller-center-ui-shared';
import { Content, MainTemplate } from '@worten-sardines/shared-ui';

import { PremiumIndicator, SellerPremiumList } from './components';

import { Loading } from '@worten-sardines/grill-ui';
import { getShopEvaluations } from '@worten-sardines/seller-center/data-access';
import { ChannelType } from './components/SellerPremiumList/types';
import { getChannelToShow } from './helper/getChannelToShow';
import { getStatusByChannel } from './helper/getStatusByChannel';
import { ContentArea, Wrapper } from './styled';

export const SellerPremium = () => {
  const user = useSession() || {};
  const isOperator = user.data?.type === 'operator';
  const { value: operatorShopInfo } = useSessionStorage('shop', null);
  const shopInfo = isOperator ? operatorShopInfo : user.data.shop;
  const shopId = shopInfo?.id;

  const [status, setStatus] = useState<StatusProps>([]);
  const [channelToShow, setChannelToShow] =
    useState<ChannelType>('WRT_PT_ONLINE');

  const date = new Date().toISOString();

  const { data: evaluationsData, loading } = useFetch(
    getShopEvaluations(shopId).key,
    !!shopId,
    getShopEvaluations(shopId, date, channelToShow).args,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
  );

  useEffect(() => {
    if (shopInfo?.channels) {
      const status = getStatusByChannel(shopInfo);
      status.sort((a, b) => (a.channel === 'pt' ? -1 : 1));
      setStatus(status);
      setChannelToShow(getChannelToShow(status, shopInfo.channels));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopInfo]);

  return (
    <>
      <MainTemplate.Content>
        {shopInfo ? (
          <Wrapper>
            <Content>
              <IndicatorBar shopName={shopInfo.name} status={status} />
              <ContentArea>
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    <PremiumIndicator
                      evaluations={evaluationsData.evaluations}
                      channels={status}
                    />
                    <SellerPremiumList
                      evaluations={evaluationsData.evaluations}
                      channel={channelToShow}
                      shopId={shopInfo.id}
                    />
                  </>
                )}
              </ContentArea>
              <ContentCMS />
            </Content>
          </Wrapper>
        ) : (
          <SkeletonLoader
            data-testid="skeleton"
            template={[
              { columns: 4 },
              { columns: 1 },
              { columns: 3 },
              { columns: 2 },
            ]}
          />
        )}
      </MainTemplate.Content>
      <MainTemplate.SidebarRight>
        {isOperator && <ShopIdCard isUnset />}
        <UsefulLinks />
      </MainTemplate.SidebarRight>
    </>
  );
};

export default SellerPremium;

import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { formatWithLocale } from '@worten-sardines/grill-ui';
import Month from './Month';

import { useTimeline } from './hooks/useTimeline';

import { DateTimelineProps } from './types';

import { getTimelineWidth, getTotalDaysUntilToday } from './utils';
import messages from './messages';

import {
  ButtonBackToday,
  ContainerTimeline,
  NavButton,
  StyledDateTimeline,
  Timeline,
} from './styled';

const DAY_WIDTH = 35;
const PADDING_LEFT = 40;
const INITIAL_MONTHS_TO_LOAD = 2;

export const DateTimeline = ({
  disableYearsLabel = false,
  disableMonthsLabel = false,
  disablePrevButton = false,
  disableNextButton = false,
  showBackButton = false,
  daysBeforeToday = 0,
  shift = 1,
  locale,
  limitDate,
  callback,
}: DateTimelineProps) => {
  const intl = useIntl();
  const currentLocale = locale || intl.locale;

  const { format } = formatWithLocale(currentLocale);
  const currentMonth = format(new Date(), 'MMMM');

  const shiftPosition = DAY_WIDTH * shift;

  const { timeline, loadNext, loadPrev, disableLoadNext } = useTimeline({
    totalMonths: INITIAL_MONTHS_TO_LOAD,
    locale: currentLocale,
    limitDate,
  });
  const refContainerTimeline = useRef<HTMLDivElement>(null);
  const refTimeline = useRef<HTMLDivElement>(null);
  const [timelineInitialPosition, setTimelineInitialPosition] =
    useState<number>(0);

  const [itemCombineMethod, setItemCombineMethod] = useState<string>('');
  const [containerTimelineWidth, setContainerTimelineWidth] =
    useState<number>(0);
  const [timelinePos, setTimelinePos] = useState<number>(0);
  const [currentDayPos, setCurrentDayPos] = useState<number>(0);
  const [scrollLeftPosition, setScrollLeftPosition] = useState<number>(0);

  const [disablePrev, setDisablePrev] = useState<boolean>(disablePrevButton);
  const [disableNext, setDisableNext] = useState<boolean>(disableNextButton);
  const [disableBackButton, setDisableBackButton] =
    useState<boolean>(showBackButton);

  const [timelineElement, setTimelineElement] = useState<HTMLDivElement>();

  const navigateTo = (shift: number) => {
    const combineMethod = Math.sign(shift) === -1 ? 'prev' : 'next';
    setItemCombineMethod(combineMethod);

    if (timelineElement) {
      timelineElement.scrollLeft += shift;
      setScrollLeftPosition(scrollLeftPosition + shift);
      setTimelinePos(timelineElement.scrollLeft);

      if (timelineElement.scrollLeft === 0) {
        loadPrev();
        getTimelinePosition();
      }

      if (callback)
        callback({
          timelineX: timelineElement.scrollLeft,
          currentDayX: currentDayPos,
          direction: combineMethod,
        });
    }
  };

  const handleBackCurrentDate = () => {
    if (timelineElement) {
      const days = getTotalDaysUntilToday(timeline, currentMonth);
      timelineElement.scrollLeft = getTimelineWidth(
        days,
        DAY_WIDTH,
        daysBeforeToday,
      );

      setTimelinePos(timelineElement.scrollLeft);
      setScrollLeftPosition(0);

      if (callback)
        callback({
          timelineX: timelineElement.scrollLeft,
          currentDayX: currentDayPos,
          direction: 'initial',
        });
    }
  };

  const getTimelinePosition = useCallback(() => {
    if (timelineElement) {
      const days = getTotalDaysUntilToday(timeline, currentMonth);
      const timelineWidth = getTimelineWidth(days, DAY_WIDTH, daysBeforeToday);

      if (itemCombineMethod === 'prev') {
        timelineElement.scrollLeft = timelineWidth - timelineElement.scrollLeft;
      }
      if (timelineInitialPosition === 0 || itemCombineMethod === '') {
        timelineElement.scrollLeft = timelineWidth;
      }

      setTimelineInitialPosition(days);
      setTimelinePos(timelineElement.scrollLeft);

      const currentDayPos = timelineElement.getElementsByClassName(
        'isToday',
      )[0] as HTMLElement;
      setCurrentDayPos(currentDayPos.offsetLeft);

      if (callback)
        callback({
          timelineX: timelineElement.scrollLeft,
          currentDayX: currentDayPos.offsetLeft,
          direction: 'initial',
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeline]);

  useLayoutEffect(() => {
    if (refContainerTimeline.current) {
      setContainerTimelineWidth(refContainerTimeline.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      if (refContainerTimeline.current) {
        setContainerTimelineWidth(refContainerTimeline.current.clientWidth);
      }
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useLayoutEffect(() => {
    if (refTimeline.current) {
      const total =
        refTimeline.current.offsetWidth +
        refTimeline.current.scrollLeft +
        PADDING_LEFT;

      if (
        timelinePos > 0 &&
        total >= refTimeline.current.scrollWidth &&
        !disableLoadNext
      ) {
        loadNext();
      }

      if (!disableNextButton) {
        if (total >= refTimeline.current.scrollWidth && disableLoadNext)
          return setDisableNext(true);

        setDisableNext(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableLoadNext, timelinePos, containerTimelineWidth, disableNextButton]);

  useLayoutEffect(() => {
    if (refContainerTimeline.current)
      setContainerTimelineWidth(refContainerTimeline.current.offsetWidth);

    if (refTimeline.current) setTimelineElement(refTimeline.current);

    if (timeline.length > 0) getTimelinePosition();
  }, [getTimelinePosition, timeline]);

  useEffect(() => {
    const maxWidthView = DAY_WIDTH * daysBeforeToday;
    const minWidthView = currentDayPos - (timelinePos - DAY_WIDTH);

    if (timelineElement) {
      if (showBackButton) {
        if (
          containerTimelineWidth <= minWidthView ||
          scrollLeftPosition >= maxWidthView
        ) {
          setDisableBackButton(true);
        }

        if (
          scrollLeftPosition === 0 ||
          (containerTimelineWidth >= minWidthView &&
            scrollLeftPosition <= maxWidthView)
        ) {
          setDisableBackButton(false);
        }
      }

      if (disablePrevButton) {
        if (scrollLeftPosition > maxWidthView) {
          return setDisablePrev(false);
        }
        setDisablePrev(true);
      }
    }
  }, [
    containerTimelineWidth,
    currentDayPos,
    daysBeforeToday,
    disablePrevButton,
    scrollLeftPosition,
    showBackButton,
    timelineElement,
    timelinePos,
  ]);

  return (
    <StyledDateTimeline ref={refContainerTimeline}>
      <ContainerTimeline>
        <NavButton
          direction="left"
          onClick={() => navigateTo(-shiftPosition)}
          disabled={disablePrev}
        />
        <NavButton
          direction="right"
          onClick={() => navigateTo(+shiftPosition)}
          disabled={disableNext}
        />

        <Timeline ref={refTimeline}>
          <Month
            timeline={timeline}
            disableMonthsLabel={disableMonthsLabel}
            disableYearsLabel={disableYearsLabel}
          />
        </Timeline>
      </ContainerTimeline>
      {disableBackButton && (
        <ButtonBackToday link onClick={handleBackCurrentDate}>
          {intl.formatMessage(messages.backToToday)}
        </ButtonBackToday>
      )}
    </StyledDateTimeline>
  );
};

export default DateTimeline;

import styled, { css } from 'styled-components';
import Flex from '../Flex';

type PageNumberProps = {
  active: boolean;
};

export const PageNumberWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  user-select: none;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.sm};
    gap: ${theme.spacing.xs};
  `}
`;
export const PageNumber = styled.button<PageNumberProps>`
  cursor: pointer;

  ${({ theme, active }) => css`
    color: ${active
      ? theme.color.neutral['neu-11']
      : theme.color.neutral['neu-06']};
    font-size: ${theme.fontsize.desktop.m};
  `}
`;

export const Ellipsis = styled.div`
  margin: 0 0.5rem;
`;

export const Arrow = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  border-radius: 50%;
  cursor: pointer;
  transform-origin: center;

  ${({ theme }) => css`
    width: ${theme.size.md};
    height: ${theme.size.md};

    border: 1px solid ${theme.color.neutral['neu-04']};

    & svg {
      fill: ${theme.color.neutral['neu-11']};
    }

    & svg[aria-label*='left'] {
      transform: rotate(180deg);
    }

    &:disabled {
      cursor: not-allowed;
    }
  `}
`;

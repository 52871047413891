import { UsefulLinks } from '@worten-sardines/seller-center-ui-layout';
import { ReactElement } from 'react';

type SharedSidebarProps = {
  name: string;
};
export const SharedSidebar = ({
  name,
}: SharedSidebarProps): ReactElement | null => {
  if (name === 'usefullLinks') {
    return <UsefulLinks />;
  }

  return null;
};

export default SharedSidebar;

import { HTMLProps, useEffect, useRef } from 'react';
import Checkbox from '../../../Checkbox';

export const IndeterminateCheckbox = ({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, indeterminate]);

  return (
    <span ref={ref} style={{ height: '15px' }}>
      <Checkbox
        id="checkbox"
        name="checkbox"
        className="cursor-pointer"
        size={15}
        {...rest}
      />
    </span>
  );
};

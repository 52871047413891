import styled, { css, DefaultTheme } from 'styled-components';

import {
  ButtonProps,
  TypesOfLink,
  TypesOfPrimary,
  TypesOfSecondary,
  TypesOfTertiary,
  TypesIconSide,
  TypesOfSize,
} from '.';
import { transitionTimingCurve } from '../utils';

export type WrapperProps = {
  hasIcon: boolean;
} & Pick<
  ButtonProps,
  'variation' | 'type' | 'link' | 'size' | 'fullWidth' | 'iconSide'
>;

const typesPrimaryVariations = {
  dark: (theme: DefaultTheme) => css`
    background: ${theme.color.neutral['neu-08']};
    color: ${theme.color.neutral['neu01']};
  `,
  light: (theme: DefaultTheme) => css`
    background: ${theme.color.neutral['neu01']};
    color: ${theme.color.neutral['neu-11']};
    &:hover,
    &:active {
      color: ${theme.color.neutral['neu-11']};
    }
  `,
  cta: (theme: DefaultTheme) => css`
    background: ${theme.color.main['m-01']};
    color: ${theme.color.neutral['neu01']};
  `,
  interactive: (theme: DefaultTheme) => css`
    background: ${theme.color.aditional['Add-02']};
    color: ${theme.color.neutral['neu01']};
  `,
};

const typesSecondaryVariations = {
  light: (theme: DefaultTheme) => css`
    border: 0.2rem solid ${theme.color.neutral.neu01};
    color: ${theme.color.neutral.neu01};
    &:hover,
    &:active {
      color: ${theme.color.neutral.neu01};
    }
  `,
  cta: (theme: DefaultTheme) => css`
    border: 0.2rem solid ${theme.color.main['m-01']};
    color: ${theme.color.main['m-01']};
    &:hover,
    &:active {
      color: ${theme.color.main['m-01']};
    }
  `,
};

const typesTertiaryVariations = {
  light: (theme: DefaultTheme) => css`
    color: ${theme.color.neutral.neu01};
  `,
  cta: (theme: DefaultTheme) => css`
    color: ${theme.color.main['m-01']};
    &:hover,
    &:active {
      color: ${theme.color.neutral['neu-12']};
    }
  `,
  medium: (theme: DefaultTheme) => css`
    color: ${theme.color.neutral['neu-06']};
  `,
};

const typesLinkVariations = {
  light: (theme: DefaultTheme) => css`
    color: ${theme.color.neutral.neu01};
    &::after {
      content: '';
      border: 1px solid ${theme.color.neutral.neu01};
    }
    &:hover,
    &:active {
      color: ${theme.color.neutral.neu01};
      &::after {
        content: '';
        border: 1px solid ${theme.color.neutral['neu-12']};
      }
    }
  `,
  cta: (theme: DefaultTheme) => css`
    color: ${theme.color.main['m-01']};
    &::after {
      content: '';
      border: 1px solid ${theme.color.main['m-01']};
    }
    &:hover,
    &:active {
      color: ${theme.color.neutral.neu01};
      &::after {
        content: '';
        border: 1px solid ${theme.color.neutral['neu-12']};
      }
    }
  `,
};

const wrapperModifiers = {
  primary: (theme: DefaultTheme, type: TypesOfPrimary | undefined) => css`
    background: ${theme.color.neutral['neu-11']};
    border-radius: ${theme.Radius.xxg};
    color: ${theme.color.neutral.neu01};
    font-weight: ${theme.weight.semibold};

    &:hover,
    &:active {
      color: ${theme.color.neutral.neu01};
      transform: scale(1.1);
    }

    ${!!type &&
    type in typesPrimaryVariations &&
    typesPrimaryVariations[type](theme)};
  `,
  secondary: (theme: DefaultTheme, type: TypesOfSecondary | undefined) => css`
    background: none;
    border: 0.2rem solid ${theme.color.neutral['neu-11']};
    border-radius: ${theme.Radius.xxg};
    color: ${theme.color.neutral['neu-11']};
    font-weight: ${theme.weight.semibold};

    &:hover,
    &:active {
      color: ${theme.color.neutral['neu-11']};
    }

    ${!!type &&
    type in typesSecondaryVariations &&
    typesSecondaryVariations[type](theme)};
  `,
  tertiary: (theme: DefaultTheme, type: TypesOfTertiary | undefined) => css`
    background: none;
    color: ${theme.color.neutral['neu-11']};
    font-weight: ${theme.weight.semibold};
    border-radius: ${theme.Radius.none};
    border: 0;

    &:hover,
    &:active {
      color: ${theme.color.main['m-01']};

      &:disabled {
        color: ${theme.color.neutral['neu-11']};
      }
    }

    ${!!type &&
    type in typesTertiaryVariations &&
    typesTertiaryVariations[type](theme)};
  `,
  neutral: (theme: DefaultTheme, type: TypesOfPrimary | undefined) => css`
    background: ${theme.color.neutral.neu01};
    border-radius: ${theme.Radius.xxg};
    color: ${theme.color.neutral['neu-11']};
    font-weight: ${theme.weight.semibold};

    &:hover,
    &:active {
      color: ${theme.color.main['m-01']};
      transform: scale(1.1);
    }

    ${!!type &&
    type in typesPrimaryVariations &&
    typesPrimaryVariations[type](theme)};
  `,
  small: (theme: DefaultTheme) => css`
    font-size: ${theme.fontsize.desktop.s2};
    height: ${theme.size.md};
    padding: 0 ${theme.sys.dimension.size.Medium};
    svg {
      & + span {
        margin-left: 0.4rem;
      }
    }
  `,
  medium: (theme: DefaultTheme, iconSide?: TypesIconSide) => css`
    font-size: ${theme.fontsize.desktop.m};
    height: 4rem;
    padding: 0 2.4rem;
    svg {
      & + span {
        margin-left: ${iconSide === 'left' ? '0.4rem' : '0'};
        margin-right: ${iconSide === 'left' ? '0' : '1.5rem'};
      }
    }
  `,
  large: (theme: DefaultTheme) => css`
    font-size: ${theme.fontsize.desktop.m};
    height: 6rem;
    padding: 0 2.4rem;
    svg {
      & + span {
        margin-left: 0.8rem;
      }
    }
  `,
  link: (
    size: TypesOfSize,
    theme: DefaultTheme,
    type: TypesOfLink | undefined,
    disabled: boolean,
  ) => css`
    position: relative;
    background: none;
    border-radius: ${theme.Radius.none};
    border: 0;
    color: ${theme.color.neutral['neu-11']};
    font-size: ${theme.fontsize.desktop.m};
    font-weight: ${theme.weight.semibold};
    text-decoration: none;
    cursor: ${disabled ? 'default' : 'point'};
    padding: 0 0.2rem;
    height: auto;
    opacity: ${disabled ? theme.opacity.low : 1};
    transition: all ${theme.animation.duration.fast};

    ${size === 'small' &&
    css`
      font-size: ${theme.fontsize.desktop.s1};
    `}
    ${size === 'medium' &&
    css`
      font-size: ${theme.fontsize.desktop.m};
    `}
    ${size === 'large' &&
    css`
      font-size: ${theme.fontsize.desktop.m};
    `}

    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
      border: 1px solid ${theme.color.neutral['neu-11']};
    }

    &:hover {
      color: ${disabled
        ? theme.color.neutral['neu-11']
        : theme.color.neutral.neu01};
      background-color: ${disabled ? 'none' : theme.color.neutral['neu-11']};
    }

    ${!!type &&
    type in typesLinkVariations &&
    typesLinkVariations[type](theme)};
  `,
  fullWidth: () => css`
    width: 100%;
  `,
  withIcon: (theme: DefaultTheme, iconSide?: TypesIconSide) => css`
    svg {
      order: ${iconSide === 'left' ? 0 : 1};
      width: ${theme.size.sm};
    }
  `,
  disabled: (theme: DefaultTheme) => css`
    &:disabled {
      cursor: not-allowed;
      pointer-events: all;
      opacity: ${theme.opacity.low};
      transform: scale(1);
    }
  `,
};

export const WrapperButton = styled.button<WrapperProps>`
  ${({
    theme,
    variation,
    type,
    size,
    hasIcon,
    fullWidth,
    link,
    disabled,
    iconSide,
  }) =>
    css`
      background: ${theme.color.neutral['neu-11']};
      border-radius: ${theme.Radius.xxg};
      border: 0;
      color: ${theme.color.neutral.neu01};
      cursor: pointer;
      font-weight: ${theme.weight.semibold};
      letter-spacing: ${theme.letterspacing.desktop.m}rem;
      text-decoration: none;
      width: fit-content;

      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: all ${theme.animation.duration.fast};
      ${transitionTimingCurve(theme.animation.curve.productive)};

      ${!!variation && wrapperModifiers[variation](theme, type)};
      ${!!link &&
      !!size &&
      wrapperModifiers.link(size, theme, type, !!disabled)};
      ${!!size && !link && wrapperModifiers[size](theme, iconSide)};
      ${!!hasIcon && wrapperModifiers.withIcon(theme, iconSide)};
      ${!!fullWidth && wrapperModifiers.fullWidth};
      ${disabled && wrapperModifiers.disabled(theme)};
    `};
`;

import {
  EnFlag,
  EsFlag,
  PtFlag,
} from '@worten-sardines/seller-center-ui-shared';
import { setOnSharedLocalStorageHelper } from '@worten-sardines/shared/helpers';
import { Svg } from '@worten-sardines/grill-ui';

export enum Locale {
  es = 'es',
  pt = 'pt',
  en = 'en',
}

const changeLanguage = (locale: Locale) => {
  setOnSharedLocalStorageHelper('locale', locale);
  window.location.reload();
};

export const flag = (currentLocale: Locale) =>
  ({
    es: <EsFlag height={24} title={Locale.es} />,
    pt: <PtFlag height={24} title={Locale.pt} />,
    en: <EnFlag height={24} title={Locale.en} />,
  }[currentLocale] || <EnFlag height={24} title={Locale.en} />);

export const languagesMenu = (currentLocale: Locale = Locale.en) => {
  const filteredLanguages = Object.values(Locale)
    .filter((locale) => locale !== currentLocale)
    .map((locale) => {
      return {
        id: locale,
        label: locale.toUpperCase(),
        flag: <Svg width={24} title="User Menu" svg={flag(locale)} />,
        onClick: () => {
          changeLanguage(locale);
        },
      };
    });

  return filteredLanguages;
};

export const languageTitle = (currentLocale: Locale) =>
  ({
    [Locale.es]: 'ES',
    [Locale.pt]: 'PT',
    [Locale.en]: 'EN',
  }[currentLocale] || 'EN');

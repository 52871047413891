import {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  PaginationState,
  SortingState,
  VisibilityState,
  ExpandedState,
  getExpandedRowModel,
} from '@tanstack/react-table';

import { GenericObject, Pagination, Svg } from '../../../';
import { SubRow, AdvancedColumns } from './components';

import {
  getSearchParamsUrl,
  GrillUtils,
  numberOfPagesOnPagination,
  PAGINATION_CONFIG,
  setSearchParamsUrl,
  checkObjectHaveSpecificProperty,
} from '../../utils';

import { ColumnPropsType, DataTableProps } from '../types';

import { ArrowUpDown } from '../../assets/images';

import {
  StyledTable,
  StyledPaginationWrapper,
  StyledArrowButton,
  Wrapper,
} from './styled';

type TableProps = {
  bordered?: boolean;
  enableColumnBorders?: boolean;
  borderRadius?: GenericObject;
  borderColor?: string;
  backgroundColor?: string;
  backgroundColorHead?: string;
  columnNavigation?: boolean;
} & Omit<DataTableProps, 'filtered' | 'loading'>;
export type TableHandle = {
  getRowsSelected: () => void;
};

const WIDTH_COLUMN = 200;
const MINIMUM_WIDTH_VIEW = 500;

export const Table = forwardRef<TableHandle, TableProps>((props, ref) => {
  const {
    onFetchData,
    dataTable,
    summary,
    className,
    pagination: hasPagination,
    totalItens,
    enableSorting,
    freezeColumn = false,
    enableSelectRows = false,
    hideColumns,
    bordered = false,
    borderColor,
    enableColumnBorders,
    borderRadius,
    backgroundColor,
    backgroundColorHead,
    columnNavigation = false,
    ...rest
  } = props;

  const columnPinnedIndex = enableSelectRows && freezeColumn ? 2 : 1;
  const showSelectRow = enableSelectRows;

  const getParams = getSearchParamsUrl();
  const tableRef = useRef<HTMLTableElement>(null);

  const [columnPinned, setColumnPinned] = useState<number>(columnPinnedIndex);
  const [hasColumnPinning, setHasColumnPinning] =
    useState<boolean>(freezeColumn);
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const [showColumnsNavigation, setShowColumnsNavigation] =
    useState<boolean>(true);
  const [disablePrevButton, setDisablePrevButton] = useState<boolean>(false);
  const [disableNextButton, setDisableNextButton] = useState<boolean>(false);

  //Sort by queryParameters
  const initialSortState = () => {
    const { sort_by, order_by } = getParams || {};

    if (sort_by && order_by) {
      const isDesc = order_by.toLowerCase() === 'desc';

      return [
        {
          id: sort_by,
          desc: isDesc,
        },
      ];
    }

    return [];
  };
  const [sorting, setSorting] = useState<SortingState>(initialSortState());

  const data = useMemo(() => {
    return [...dataTable.data];
  }, [dataTable.data]);

  const hasSubRows = checkObjectHaveSpecificProperty(data, 'subRows');

  //columns pagination
  const [widthFirstColumn, setWidthFirstColumn] = useState('');
  const [totalColumns, setTotalColumns] = useState(0);

  const widthDefaultColumn = WIDTH_COLUMN;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any = useMemo<ColumnDef<ColumnPropsType>[]>(() => {
    const dataFiltered = hideColumns
      ? {
          header: dataTable.header.filter((col: ColumnPropsType) =>
            Array.isArray(hideColumns)
              ? !hideColumns.includes(col.id)
              : col.id !== hideColumns,
          ),
          data: dataTable.data,
        }
      : dataTable;

    if (dataTable.footer) {
      return dataTable.header.map((col: ColumnPropsType, index: number) => ({
        accessorKey: col.id,
        header: col.cell,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cell: (info: any) => info.renderValue(),
        footer: dataTable.footer ? dataTable.footer[index] : '',
      }));
    }

    const dataTableColumns = dataFiltered.header.map(
      (col: ColumnPropsType) => ({
        accessorKey: col.id,
        header: col.cell,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cell: (info: any) => info.renderValue(),
      }),
    );

    const firstColumnWidth = hasSubRows && showSelectRow ? '8rem' : '6rem';
    setWidthFirstColumn(firstColumnWidth);

    return showSelectRow || hasSubRows
      ? AdvancedColumns({ showSelectRow, hasSubRows, dataTableColumns })
      : dataTableColumns;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTable, showSelectRow, hasSubRows]);

  const limit = getParams?.['limit']
    ? Number(getParams['limit'])
    : PAGINATION_CONFIG.limit;

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: getParams?.['offset']
      ? Number(getParams['offset']) / Number(limit)
      : Number(PAGINATION_CONFIG.offset),
    pageSize: Number(limit),
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const handleSearchParamsUrl = () => {
    if (sorting.length) {
      return setSearchParamsUrl({
        ...getParams,
        sort_by: sorting[0].id,
        order_by: sorting[0].desc ? 'DESC' : 'ASC',
      });
    }

    const cleanSearchParam = Object.fromEntries(
      Object.entries({
        ...getParams,
        sort_by: '',
        order_by: '',
      }).filter(([_, valor]) => valor),
    );
    return setSearchParamsUrl(cleanSearchParam);
  };

  useEffect(() => {
    handleSearchParamsUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParams]);

  useEffect(() => {
    handleSearchParamsUrl();
    if (sorting.length) {
      return onFetchData({
        ...getParams,
        sort_by: sorting[0].id,
        order_by: sorting[0].desc ? 'DESC' : 'ASC',
      });
    }

    const { sort_by, order_by, ...rest } = getParams;
    return onFetchData(rest);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  useImperativeHandle(ref, () => {
    return {
      getRowsSelected() {
        const keys = Object.keys(rowSelection).filter(
          (k) => rowSelection[k as keyof unknown],
        );
        const rowsSelected = [];

        for (let i = 0; i < keys.length; i++) {
          rowsSelected.push(data[keys[i] as keyof unknown]);
        }

        return rowsSelected;
      },
    };
  });

  const table = useReactTable({
    data: data,
    columns,
    state: {
      rowSelection,
      pagination,
      sorting,
      columnVisibility,
      expanded,
    },
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.subRows,
    onColumnVisibilityChange: setColumnVisibility,
    onExpandedChange: setExpanded,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    enableRowSelection: true,
    manualPagination: true,
  });

  useEffect(() => {
    const columns = table
      .getAllLeafColumns()
      .slice(totalColumns, table.getAllLeafColumns().length)
      .reduce((prev, curr) => ({ ...prev, [curr.id]: false }), {});

    setColumnVisibility(columns);
  }, [table, totalColumns]);

  const checkColumnsNavigation = useCallback(
    (totalColumns: number) => {
      if (
        !columnNavigation ||
        totalColumns === table.getAllFlatColumns().length
      ) {
        setDisableNextButton(true);
        return setShowColumnsNavigation(false);
      }
      setDisableNextButton(false);
      return setShowColumnsNavigation(true);
    },
    [table, columnNavigation],
  );

  const checkColumnsPinned = useCallback(
    (width: number) => {
      if (width <= MINIMUM_WIDTH_VIEW) {
        const columnPinned = enableSelectRows || hasSubRows ? 1 : 0;
        setHasColumnPinning(false);
        setColumnPinned(columnPinned);
        return;
      }
      setHasColumnPinning(freezeColumn);
      setColumnPinned(columnPinnedIndex);
    },
    [columnPinnedIndex, enableSelectRows, freezeColumn, hasSubRows],
  );

  const handleResize = useCallback(() => {
    if (tableRef.current) {
      const width = tableRef.current.offsetWidth;

      let totalColumns = columnNavigation
        ? Math.floor(width / widthDefaultColumn)
        : table.getAllFlatColumns().length;
      totalColumns =
        totalColumns >= table.getAllFlatColumns().length
          ? table.getAllFlatColumns().length
          : totalColumns;

      setTotalColumns(totalColumns);
      checkColumnsNavigation(totalColumns);
      setDisablePrevButton(true);
      checkColumnsPinned(width);
    }
  }, [
    checkColumnsNavigation,
    checkColumnsPinned,
    columnNavigation,
    table,
    widthDefaultColumn,
  ]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const currentPage = () => {
    if (
      Number(getParams?.['offset']) / pageSize ===
      table.getState().pagination.pageIndex
    ) {
      return table.getState().pagination.pageIndex + 1;
    }
    return table.getState().pagination.pageIndex;
  };

  const checkPrevColumnEnable = (currentColumnPosition: number) => {
    if (
      currentColumnPosition === 0 ||
      (currentColumnPosition === columnPinned &&
        (showSelectRow || hasSubRows || hasColumnPinning))
    )
      return setDisablePrevButton(true);
    return setDisablePrevButton(false);
  };

  const checkNextColumnEnable = (currentColumn: string, lastColumn: string) => {
    if (currentColumn === lastColumn) return setDisableNextButton(true);
    return setDisableNextButton(false);
  };

  const handlePreviousColumn = () => {
    const columnIndex =
      showSelectRow || hasSubRows || hasColumnPinning ? columnPinned : 0;
    const allColumns = table.getAllLeafColumns();
    const prevColumnPosition =
      allColumns.indexOf(table.getVisibleFlatColumns()[columnIndex]) - 1;

    if (prevColumnPosition >= columnIndex) {
      const prevColumnName = allColumns[prevColumnPosition].id;
      const lastColumnName =
        table.getVisibleFlatColumns()[table.getVisibleFlatColumns().length - 1]
          .id;

      setDisableNextButton(false);
      checkPrevColumnEnable(prevColumnPosition);
      setColumnVisibility((prev) => ({
        ...prev,
        [prevColumnName]: true,
        [lastColumnName]: false,
      }));
    }
  };

  const handleNextColumn = () => {
    const columnIndex =
      showSelectRow || hasSubRows || hasColumnPinning ? columnPinned : 0;
    const allColumns = table.getAllLeafColumns();
    const firstColumnName = table.getVisibleFlatColumns()[columnIndex].id;

    const nextColumnPosition =
      allColumns.indexOf(
        table.getVisibleFlatColumns()[table.getVisibleFlatColumns().length - 1],
      ) + 1;

    if (nextColumnPosition < allColumns.length) {
      const nextColumnName = allColumns[nextColumnPosition].id;

      checkNextColumnEnable(
        nextColumnName,
        table.getAllLeafColumns()[table.getAllLeafColumns().length - 1].id,
      );

      setDisablePrevButton(false);
      setColumnVisibility((prev) => ({
        ...prev,
        [firstColumnName]: false,
        [nextColumnName]: true,
      }));
    }
  };

  return (
    <>
      <Wrapper
        ref={tableRef}
        className={className}
        bordered={bordered}
        borderRadius={borderRadius}
        backgroundColor={backgroundColor}
      >
        <StyledTable
          summary={summary}
          enableColumnBorders={enableColumnBorders}
          backgroundColorHead={backgroundColorHead}
          {...(rest as typeof GrillUtils)}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => {
                  const showPreviuosButton =
                    index ===
                      (showSelectRow || hasSubRows || hasColumnPinning
                        ? columnPinned
                        : 0) && showColumnsNavigation;

                  const showNextButton =
                    index === table.getVisibleFlatColumns().length - 1 &&
                    showColumnsNavigation;

                  return (
                    <th
                      scope="col"
                      key={header.id}
                      style={{
                        width:
                          (showSelectRow || hasSubRows) && index === 0
                            ? widthFirstColumn
                            : 'auto',
                      }}
                    >
                      <div
                        className={
                          showPreviuosButton && !showNextButton
                            ? 'arrow-only-left'
                            : undefined
                        }
                      >
                        {showPreviuosButton && showColumnsNavigation && (
                          <StyledArrowButton
                            direction="left"
                            onClick={handlePreviousColumn}
                            disabled={disablePrevButton}
                          />
                        )}

                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className:
                                header.column.getCanSort() && enableSorting
                                  ? 'cursor-pointer select-none'
                                  : 'header-cell',
                              onClick: enableSorting
                                ? header.column.getToggleSortingHandler()
                                : () => null,
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {index !== 0 && enableSorting && (
                              <Svg
                                svg={<ArrowUpDown />}
                                title="sorting column"
                                width={12}
                                className="sorting-icon"
                              />
                            )}
                          </div>
                        )}

                        {showNextButton && (
                          <StyledArrowButton
                            direction="right"
                            onClick={handleNextColumn}
                            disabled={disableNextButton}
                          />
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              if (row.depth === 1 && row.original?.$$typeof) {
                return (
                  <tr key={row.id}>
                    <td
                      colSpan={row.getVisibleCells().length}
                      className="sub-row"
                    >
                      {SubRow({ row })}
                    </td>
                  </tr>
                );
              }
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell, index) => {
                    return index !== 0 && dataTable.header ? (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ) : (
                      <th scope="row" key={cell.id}>
                        <div>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {dataTable.footer && (
            <tfoot>
              {table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext(),
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </StyledTable>
      </Wrapper>
      {hasPagination && (
        <StyledPaginationWrapper>
          <Pagination
            totalPages={numberOfPagesOnPagination(pageSize, totalItens)}
            currentPage={currentPage()}
            onPageChange={(e) => {
              const page = Number(e) - 1;
              table.setPageIndex(page);
              onFetchData({
                ...getParams,
                offset: (pageSize * (Number(e) - 1)).toString(),
              });
            }}
          />
        </StyledPaginationWrapper>
      )}
    </>
  );
});

export default Table;

import { Svg } from '@worten-sardines/grill-ui';
import { WortenLoader } from '../assets';
import { Logo } from './styled';

export const SpinnerWorten3D = () => {
  return (
    <Logo>
      <Svg svg={<WortenLoader />} width={40} title="logo worten" />
    </Logo>
  );
};

export default SpinnerWorten3D;

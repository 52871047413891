import React from 'react';
import { MakeSpacing } from '../utils/types';

import { LoadingWrapper, StyledLoading } from './styled';

export type LoadingProps = {
  size?: 'sm' | 'md' | 'lg';
  color?: string;
  children?: React.ReactNode;
} & MakeSpacing;

export const Loading = ({
  color,
  size = 'md',
  children,
  ...rest
}: LoadingProps) => {
  return (
    <LoadingWrapper {...rest} role="progressbar" aria-busy="true">
      {children || <StyledLoading color={color} size={size} />}
    </LoadingWrapper>
  );
};

export default Loading;

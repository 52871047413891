import styled, { css } from 'styled-components';
import {
  makeColor,
  makeHeight,
  makeResponsive,
  makeSpacing,
  makeText,
  makeWidth,
} from '../utils';
import {
  MakeColor,
  MakeHeight,
  MakeResponsive,
  MakeSpacing,
  MakeText,
  MakeWidth,
} from '../utils/types';
import { omitProps } from '../_common';

export type TextProps = {
  truncated?: boolean;
  wrapped?: boolean;
} & MakeText &
  MakeColor &
  MakeWidth &
  MakeHeight &
  MakeResponsive &
  MakeSpacing;

const truncatedCSS = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
`;

const wrappedCSS = css`
  display: block;
  white-space: pre-line;
`;

export const Text = styled.p.withConfig<TextProps>({
  shouldForwardProp: (prop) => omitProps(['truncated', 'wrapped'], prop),
})`
  ${makeText}
  ${makeColor}

  ${({ truncated, wrapped }) => css`
    ${truncated && truncatedCSS}
    ${wrapped && wrappedCSS}
  `}

  ${makeWidth}
  ${makeHeight}
  ${makeResponsive}
  ${makeSpacing}
`;

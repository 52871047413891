import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {
  NOTICES_FLAG,
  NOTICE_QTY_DAYS,
} from '@worten-sardines/configs/config-seller-center';
import { StyledBadge } from './styled';
import messages from './messages';
import { useIntl } from 'react-intl';

export type NoticeFlagProps = {
  type: 'newFeature';
  name: string;
};

export const NoticeFlag = ({ type, name }: NoticeFlagProps) => {
  const intl = useIntl();
  const notice = NOTICES_FLAG[type as keyof typeof NOTICES_FLAG]?.find(
    (i) => i.name === name,
  );

  const value = type === 'newFeature' && intl.formatMessage(messages.new);

  const distanceBetweenDates =
    !!notice &&
    notice.visible &&
    differenceInCalendarDays(new Date(), new Date(notice.start));
  const showNotice =
    typeof distanceBetweenDates === 'number' &&
    distanceBetweenDates <= NOTICE_QTY_DAYS &&
    distanceBetweenDates >= 0;

  if (!showNotice) {
    return null;
  }

  return <StyledBadge noticeType={type}>{value}</StyledBadge>;
};

export default NoticeFlag;

import React from 'react';
import { Divider, LinkCard, NoticeFlagProps, Variations } from '../../index';
import { useIntl, IntlShape } from 'react-intl';
import { StyledLinksList, StyledLinksListLinks } from './styled';

type DividerProps = {
  key: string;
  partialUrl?: string;
  externalUrl?: string;
  icon?: JSX.Element;
  displayed: boolean;
};

export type ItemLinksProps = {
  key: string;
  displayed?: boolean;
  partialUrl?: string;
  externalUrl?: string;
  icon?: JSX.Element;
  disabled?: boolean;
  configNotice?: NoticeFlagProps;
  variation?: Variations;
};

export type ListProps = {
  divider: DividerProps;
  links: ItemLinksProps[][];
};

type LinksListProps = {
  menu: ListProps[];
  messages: MessagesIntl;
};

type MessagesIntl = {
  [key: string]: { id: string; defaultMessage: string };
};

const renderDivider = (
  divider: DividerProps,
  messages: MessagesIntl,
  intl: IntlShape,
) => {
  const { key: title, partialUrl, externalUrl, icon, ...rest } = divider;
  const linkDivider: { as?: React.ElementType; href?: string } =
    partialUrl || externalUrl
      ? { as: 'a', href: partialUrl || externalUrl }
      : {};
  const targetLinkDivider = externalUrl ? { target: '_blank' } : {};
  return (
    <Divider
      title={intl.formatMessage(messages[title])}
      icon={icon}
      {...linkDivider}
      {...targetLinkDivider}
      {...rest}
    />
  );
};

const renderLinks = (
  links: ItemLinksProps[],
  messages: MessagesIntl,
  intl: IntlShape,
) => {
  return links.map((link) => {
    const {
      key: title,
      icon,
      displayed = true,
      disabled = false,
      partialUrl,
      externalUrl,
      configNotice,
      ...rest
    } = link;

    const navigation: { href?: string; as: keyof HTMLElementTagNameMap } =
      partialUrl || externalUrl
        ? { as: 'a', href: partialUrl || externalUrl }
        : { as: 'button' };

    const targetLink = externalUrl ? { target: '_blank' } : {};

    if (!displayed) {
      return null;
    }

    return (
      <LinkCard
        key={title}
        externalUrl={!!externalUrl}
        title={intl.formatMessage(messages[title])}
        icon={icon}
        disabled={disabled}
        configNotice={
          configNotice && { type: configNotice.type, name: configNotice.name }
        }
        {...navigation}
        {...targetLink}
        {...rest}
      />
    );
  });
};

export const LinksList = ({ menu, messages }: LinksListProps) => {
  const intl = useIntl();

  return (
    <StyledLinksList>
      {menu.map(({ divider, links }) => {
        return (
          <React.Fragment key={divider.key}>
            {divider.displayed && renderDivider(divider, messages, intl)}
            <StyledLinksListLinks>
              {links.map((group, i) => (
                <div key={i} className="group">
                  {renderLinks(group, messages, intl)}
                </div>
              ))}
            </StyledLinksListLinks>
          </React.Fragment>
        );
      })}
    </StyledLinksList>
  );
};

export default LinksList;

import { Button, MakeWidth } from '@worten-sardines/grill-ui';
import React, { ReactNode } from 'react';
import {
  StyledDropdownContent,
  StyledDropdownMenu,
  StyledDropdownWrapper,
} from './styled';

export type dropdownItem = {
  id: string;
  label: string;
  flag?: ReactNode;
  onClick?: () => void;
  href?: string;
};

export type DropdownMenuProps = {
  children: React.ReactNode;
  items: dropdownItem[];
} & MakeWidth;

export const DropdownMenu = ({
  children,
  items,
  ...props
}: DropdownMenuProps) => {
  const showDropdown = items.length > 0;

  return (
    <StyledDropdownWrapper>
      <StyledDropdownMenu className="dropdown" {...props}>
        {children}
        <StyledDropdownContent className="dropdown_item" show={showDropdown}>
          {items.map((item) => {
            return (
              <Button
                as={item.href ? 'a' : 'button'}
                variation="tertiary"
                key={item.id}
                onClick={item.onClick}
                href={item.href}
                size="small"
                style={{ justifyContent: 'flex-start' }}
                icon={item.flag}
                fullWidth
              >
                {item.label}
              </Button>
            );
          })}
        </StyledDropdownContent>
      </StyledDropdownMenu>
    </StyledDropdownWrapper>
  );
};

export default DropdownMenu;

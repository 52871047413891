import { Theme as theme } from '@worten-sardines/grill-ui';
import { TitleSchema } from '../../types';
import { Wrapper, StyledTitle } from './styled';

export const Title = ({
  schema: {
    svgIcon,
    titleLevel,
    titleColor,
    contentAlignment,
    title,
    subtitle,
    subTitleLevel,
    subTitleColor,
    position,
  },
}: {
  schema: TitleSchema;
}) => {
  return (
    <Wrapper position={position}>
      <StyledTitle as={titleLevel} color={titleColor} align={contentAlignment}>
        {svgIcon && (
          <span
            aria-hidden="true"
            dangerouslySetInnerHTML={{ __html: svgIcon }}
          />
        )}
        {title}
      </StyledTitle>
      {subtitle && (
        <StyledTitle
          as={subTitleLevel || 'h4'}
          color={subTitleColor || theme.color.neutral['neu-08']}
          align={contentAlignment}
        >
          {subtitle}
        </StyledTitle>
      )}
    </Wrapper>
  );
};

export default Title;

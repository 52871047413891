import styled, { css, DefaultTheme, keyframes } from 'styled-components';
import { LoadingProps } from '.';
import { MakeSpacing } from '../utils/types';
import { makeSpacing } from '../utils';

type WrapperProps = Pick<LoadingProps, 'size' | 'color'> & MakeSpacing;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const spinReverse = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
`;

const wrapperModifiers = {
  sm: (theme: DefaultTheme, color?: string) => css`
    width: 18px;
    height: 18px;
    &:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      inset: 4px;

      border-radius: 50%;
      border: 1px solid ${color || theme.color.main['m-01']};
      border-right-color: transparent;
      animation: ${spinReverse} 0.6s linear infinite;
    }

    &:after {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      inset: 1px;

      border-radius: 50%;
      border: 1px solid ${color || theme.color.main['m-01']};
      border-right-color: transparent;
      animation: ${spin} 1s linear infinite;
    }
  `,
  md: (theme: DefaultTheme, color?: string) => css`
    width: 28px;
    height: 28px;
    &:before {
      content: '';
      position: absolute;
      inset: 6px;

      border-radius: 50%;
      border: 1px solid ${color || theme.color.main['m-01']};
      border-right-color: transparent;
      animation: ${spinReverse} 0.6s linear infinite;
    }

    &:after {
      content: '';
      position: absolute;
      inset: 2px;

      border-radius: 50%;
      border: 1px solid ${color || theme.color.main['m-01']};
      border-right-color: transparent;
      animation: ${spin} 1s linear infinite;
    }
  `,
  lg: () => css`
    width: 36px;
    height: 36px;
  `,
};

export const LoadingWrapper = styled.span<WrapperProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${makeSpacing};
`;

export const StyledLoading = styled.div<WrapperProps>`
  ${({ theme, size, color }) => css`
    display: block;
    position: relative;

    border-radius: 50%;
    border: 1px solid ${color || theme.color.main['m-01']};
    border-right-color: transparent;

    animation: ${spin} 1.7s linear infinite;
    z-index: 11;

    &:before {
      content: '';
      position: absolute;
      inset: 6px;

      border-radius: 50%;
      border: 1px solid ${color || theme.color.main['m-01']};
      border-right-color: transparent;
      animation: ${spinReverse} 0.6s linear infinite;
    }

    &:after {
      content: '';
      position: absolute;
      inset: 2px;

      border-radius: 50%;
      border: 1px solid ${color || theme.color.main['m-01']};
      border-right-color: transparent;
      animation: ${spin} 1s linear infinite;
    }

    ${!!size && wrapperModifiers[size](theme, color)};
  `}
`;

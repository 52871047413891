import { memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { sanitizeHTML, setLazyLoadingImages } from '../../helpers/lazy-loading';

import { EnhancedWrapper } from './styled';
import { SchemaObjects } from '../../types';

type CustomHTMLProps = {
  schema: SchemaObjects;
  id: string;
  enableLazyLoading?: boolean;
};

export const CustomHTML = ({
  schema,
  id,
  enableLazyLoading = true,
}: CustomHTMLProps) => {
  const {
    cssContent,
    jsContent,
    content,
    safeHTML,
    responsiveImages = false,
    position,
  } = schema;
  const [htmlContent, setHtmlContent] = useState<{ __html: string } | null>(
    null,
  );

  useEffect(() => {
    if (safeHTML && enableLazyLoading) {
      setLazyLoadingImages(id);
    }
  }, [enableLazyLoading, id, safeHTML]);

  useEffect(() => {
    if (safeHTML) {
      return setHtmlContent({
        __html: sanitizeHTML(content, enableLazyLoading),
      });
    }
    return setHtmlContent({ __html: content });
  }, [content, enableLazyLoading, safeHTML]);

  useEffect(() => {
    let script: HTMLScriptElement | null = null;
    if (jsContent) {
      script = document.createElement('script');
      script.setAttribute('id', `${id}-js`);
      script.setAttribute('async', '');
      script.setAttribute('type', 'module');
      script.innerHTML = jsContent;

      document.body.appendChild(script);
    }
    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [id, jsContent]);

  return (
    <>
      {cssContent ? (
        <Helmet prioritizeSeoTags>
          <style type="text/css">{cssContent}</style>
        </Helmet>
      ) : null}
      {htmlContent ? (
        <EnhancedWrapper
          id={id}
          position={position}
          responsiveImages={responsiveImages}
          dangerouslySetInnerHTML={htmlContent}
        />
      ) : null}
    </>
  );
};

export default memo(CustomHTML);

import { LANGUAGE_DEFAULT } from '@worten-sardines/configs/config-seller-center';
import { getFromSharedLocalStorageHelper } from '@worten-sardines/shared/helpers';
declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

export type PayloadType = {
  viewType?: string;
  userType?: 'seller' | 'operator' | null;
  userShopId?: string;
  userShopName?: string;
  userShopTier?: string;
  pageType?: string;
  siteSection?: string;
  eventAction?: string;
  eventCategory?: string;
  eventLabel?: string;
};
export type TypeType =
  | 'TOGGLE_VIEW_CAMPAIGN'
  | 'EVENT_LOGIN'
  | 'EVENT_LOGOUT'
  | 'VIRTUAL_PAGEVIEW'
  | 'EVENT_GENERIC_USER_BEHAVIOR';

export const REGISTER_DATALAYER = (type: TypeType, payload: PayloadType) => {
  window.dataLayer = window.dataLayer || [];

  const upperType = type.toUpperCase();
  const siteLanguage =
    getFromSharedLocalStorageHelper('locale') || LANGUAGE_DEFAULT;
  const userShopId =
    payload.userType === 'seller' ? payload.userShopId : undefined;
  const userShopName =
    payload.userType === 'seller' ? payload.userShopName : undefined;
  const userShopTier =
    payload.userType === 'seller' ? payload.userShopTier : undefined;

  try {
    switch (upperType) {
      //TODO legacy
      case 'TOGGLE_VIEW_CAMPAIGN':
        window.dataLayer.push({
          event: 'toggle-view-campaign',
          viewType: payload.viewType,
        });
        break;
      case 'EVENT_LOGIN':
      case 'EVENT_LOGOUT':
        window.dataLayer.push({
          event: upperType === 'EVENT_LOGIN' ? 'login' : 'logout',
          userType: payload.userType,
          userShopId,
          userShopName,
          userShopTier,
          siteLanguage,
        });
        break;
      case 'VIRTUAL_PAGEVIEW':
        window.dataLayer.push({
          event: 'virtual.pageview',
          pageType: payload.pageType,
          siteSection: payload.siteSection,
          userType: payload.userType,
          userShopId,
          userShopName,
          userShopTier,
          siteLanguage,
        });
        break;
      case 'EVENT_GENERIC_USER_BEHAVIOR':
        window.dataLayer.push({
          event: 'event.generic',
          eventAction: payload.eventAction,
          eventCategory: payload.eventCategory,
          eventLabel: payload.eventLabel,
        });
        break;
      default:
        break;
    }
  } catch (err) {
    console.error('Error sending analytics', err);
  }
};

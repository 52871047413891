import { EventCell, Info } from '@worten-sardines/seller-center-ui-shared';
import { Grid } from '@worten-sardines/grill-ui';
import { LI, UL, Timeline } from './styled';
import ButtonRow from '../ButtonRow';
import { CampaignsArgs, DataTableType } from '../../../types';

type TimelineCallback = {
  timelineX: number;
  currentDayX: number;
  direction: 'prev' | 'next' | 'initial';
};

export type ListData = {
  dataList: DataTableType;
  currentCampaign?: CampaignsArgs | null;
  timelinePosition?: TimelineCallback;
};

const List = ({ currentCampaign, dataList, timelinePosition }: ListData) => {
  return (
    <UL>
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataList?.data.map(({ _data }: any) => (
          <LI
            key={_data.id}
            currentCampaign={currentCampaign?.name === _data.id}
          >
            <Grid gridTemplateColumns="33.3% 66.7%">
              <ButtonRow
                primary
                label={_data.campaignName}
                info={_data.info}
                action={_data.action}
                badge={<Info />}
                showBadge={_data.showBadge}
              />
              <Timeline
                className="actived"
                onClick={() => _data.action(_data.info)}
              >
                <EventCell
                  data={{
                    firstPeriod: _data.firstPeriod,
                    secondPeriod: _data.secondPeriod,
                  }}
                  timelinePosition={timelinePosition}
                />
              </Timeline>
            </Grid>
          </LI>
        ))
      }
    </UL>
  );
};

export default List;

import { Button, Theme } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

export const StyledSettingsButton = styled(Button)`
  position: relative;
  background-color: ${Theme.color.neutral.neu01};
  width: 100%;
  height: 100%;

  svg path {
    transition: all 0.3s ease-in-out;
    fill: ${Theme.color.neutral['neu-11']};
  }

  &:hover {
    svg path {
      fill: ${Theme.color.main['m-01']};
    }
  }
`;

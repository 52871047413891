import styled, { css } from 'styled-components';
import { omitProps } from '../_common';
import { GrillUtils, makeColor } from '../utils';
import { GrillUtilsProps } from '../utils/types';

export type BlockProps = {
  as?: React.ElementType;
  border?: string;
  borderRadius?: string;
  children?: React.ReactNode;
} & Omit<
  GrillUtilsProps,
  | 'flexDirection'
  | 'flexWrap'
  | 'gridTemplateAreas'
  | 'gridTemplateColumns'
  | 'gridTemplateRows'
  | 'gridAutoFlow'
>;

const BlockStyled = styled.div.withConfig<BlockProps>({
  shouldForwardProp: (prop) =>
    omitProps(['border', 'borderRadius', 'gutter', 'inline'], prop),
})`
  ${({ border }) => {
    if (!border) {
      return;
    }
    return border
      .split(' ')
      .map((attr, idx) => {
        switch (idx) {
          case 0:
            return `border-width: ${attr};`;
          case 1:
            return `border-style: ${attr};`;
          case 2:
            return makeColor({ borderColor: attr });
          default:
            return null;
        }
      })
      .join('\n');
  }}

  ${({ borderRadius }) => css`
    border-radius: ${borderRadius || 'initial'};
  `}

  ${GrillUtils}
`;

export const Block = ({ as, ...props }: BlockProps) => {
  return <BlockStyled as={as} {...props} />;
};

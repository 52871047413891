import { useCallback } from 'react';
import { Container, Wrapper } from './styled';
import { ToastBar } from './ToastBar';
import { DefaultToastOptions, ToastPosition } from './types';
import { useToaster } from './hooks/useToaster';

export type WrapperToastProps = {
  id: string;
  children?: React.ReactNode;
  onHeightUpdate: (id: string, height: number) => void;
  className?: string;
};

type ToasterProps = {
  position?: ToastPosition;
  toastOptions?: DefaultToastOptions;
};

const WrapperToast = ({
  id,
  children,
  onHeightUpdate,
  className,
}: WrapperToastProps) => {
  const ref = useCallback(
    (el: HTMLElement | null) => {
      if (el) {
        const updateHeight = () => {
          const height = el.getBoundingClientRect().height;
          onHeightUpdate(id, height);
        };
        updateHeight();
        new MutationObserver(updateHeight).observe(el, {
          subtree: true,
          childList: true,
          characterData: true,
        });
      }
    },
    [id, onHeightUpdate],
  );

  return (
    <Container ref={ref} className={className}>
      {children}
    </Container>
  );
};

export const Toaster = ({
  position = 'top-center',
  toastOptions,
}: ToasterProps) => {
  const { toasts, handlers } = useToaster(toastOptions);

  return (
    <Wrapper position={position}>
      {toasts.map((t) => {
        return (
          <WrapperToast
            id={t.id}
            key={t.id}
            onHeightUpdate={handlers.updateHeight}
            className={t.visible ? 'activeClass' : ''}
          >
            <ToastBar toastbar={t} />
          </WrapperToast>
        );
      })}
    </Wrapper>
  );
};

export default Toaster;

import styled, { css } from 'styled-components';
import Flex from '../Flex';
import { Text } from '../Text';

export const WrapperRating = styled(Flex).attrs({
  alignItems: 'center',
})`
  ${({ theme }) => css`
    gap: ${theme.spacing.xxs};
  `}
`;

export const Wrapper = styled.span`
  ${({ theme }) => css`
    position: relative;
    display: inline-flex;
    color: ${theme.sys.color.neu['03']};
    width: min-content;

    .half {
      width: 50%;
      overflow: hidden;
    }

    .empty {
      width: 0;
      overflow: hidden;
    }
  `}
`;

export const Rate = styled.span`
  ${({ theme }) => css`
    position: relative;
    margin-top: -3px;

    &:not(:last-child) {
      margin-right: ${theme.sys.dimension.spacing.Micro};
    }
  `}
`;

export const RatingValue = styled(Text)`
  ${({ theme, fontSize, color }) => css`
    font-size: ${fontSize || theme.fontsize.desktop.m};
    color: ${color || theme.sys.color.neu['09']};
    margin: ${theme.sys.dimension.spacing.None};
  `}
`;

export const IconContainer = styled.span`
  ${({ theme }) => css`
    position: absolute;
    color: ${theme.sys.color.add['01']};
    top: 0;
    left: 0;
  `}
`;

import {
  createDataTableRequest,
  CreateDataTableRequestType,
} from '@worten-sardines/shared/utils-apis';

export function getPayouts(
  payload?: CreateDataTableRequestType,
  shop_id?: string,
) {
  const query = {
    ...createDataTableRequest(payload),
    shopId: shop_id,
  };

  const args = {
    query,
  };

  return {
    key: '/finance/v1/payouts',
    args,
  };
}

export default { getPayouts };

import styled from 'styled-components';
import { Text, Theme } from '@worten-sardines/grill-ui';

export const StyledText = styled(Text)`
  color: ${Theme.color.neutral['neu-08']};
  font-size: ${Theme.fontsize.desktop.s1};
  margin: ${Theme.spacing.none};
`;
export const StyledTemplateLinkWrapper = styled.div`
  padding: 0 ${Theme.spacing.xs};

  a > span {
    text-transform: uppercase;
    font-size: ${Theme.fontsize.desktop.s1};
  }
`;

import { Flex, breakpoints } from '@worten-sardines/grill-ui';
import styled, { css } from 'styled-components';

export const IndicatorBarContainer = styled(Flex)`
  ${({ theme }) => css`
    flex-direction: column;
    padding: ${theme.spacing.xs};
    width: 36rem;

    gap: ${theme.bas.dimension[16]};
    margin-bottom: ${theme.bas.dimension[16]};

    color: ${theme.sys.color.neu['01']};
    background-color: ${theme.sys.color.neu['09']};

    border-radius: ${theme.bas.dimension[8]};

    @media screen and ${breakpoints.md} {
      width: 100%;
    }

    @media screen and ${breakpoints.lg} {
      flex-direction: row;
      justify-content: space-between;

      min-height: 53px;
    }
  `}
`;

export const StatusArea = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    gap: ${theme.spacing.xxs};

    @media screen and ${breakpoints.lg} {
      flex-direction: row;
    }
  `}
`;

export const ShopName = styled.span`
  ${({ theme }) => css`
    ${theme.sys.typeface['size-14']['2101-400']}
    min-width: 28rem;
  `};
`;

export const Status = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xxs};

    ${theme.sys.typeface['size-14']['2101-700']}

    white-space: nowrap;
  `};
`;

import { SimpleSelect, Toggle } from '@worten-sardines/grill-ui';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';
import {
  FiltersContainer,
  FiltersWrapperBottomLine,
  FiltersWrapperTopLine,
} from './styled';
import { VIEW_MODE_CAMPAIGN } from '../constants';
import { Grid, Timeline } from '@worten-sardines/seller-center-ui-shared';
import { REGISTER_DATALAYER } from '@worten-sardines/trackings/tracking-seller-center';
import { DataTableType } from '../../../types';

export type FilterSelectedProps = {
  toggle: string;
  market: string;
  modeView: string;
};

type FiltersProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  onFilterChange: (data: DataTableType['data']) => void;
  onModeView: (args: FilterSelectedProps) => void;
  modeView: string;
};

const Filters = ({
  modeView,
  onModeView,
  onFilterChange,
  data,
}: FiltersProps) => {
  const intl = useIntl();

  const [filtersSelected, setFiltersSelected] = useState<FilterSelectedProps>({
    toggle: 'all',
    market: 'worten-online-pt',
    modeView,
  });

  const toggleItemsStatus = [
    {
      id: 'all',
      text: intl.formatMessage(messages.filterToggleAllMessage),
      active: true,
    },
    {
      id: 'ongoing',
      text: intl.formatMessage(messages.filterToggleOngoingMessage),
      active: false,
    },
    {
      id: 'next',
      text: intl.formatMessage(messages.filterToggleNextMessage),
      active: false,
    },
  ];

  const toggleItemsModeView = [
    {
      id: VIEW_MODE_CAMPAIGN.first_option,
      name: VIEW_MODE_CAMPAIGN.first_option,
      badge: <Timeline width={14} height={18} title="timeline" />,
      active: modeView === VIEW_MODE_CAMPAIGN.first_option,
    },
    {
      id: VIEW_MODE_CAMPAIGN.second_option,
      name: VIEW_MODE_CAMPAIGN.second_option,
      badge: <Grid width={14} height={18} title="grid" />,
      active: modeView === VIEW_MODE_CAMPAIGN.second_option,
    },
  ];

  const marketFilterOptions = [
    {
      label: 'Worten Online PT',
      value: 'worten-online-pt',
      selected: true,
    },
    {
      label: 'Worten Online ES',
      value: 'worten-online-es',
    },
  ];

  const filterData = (toggle: string, market: string) => {
    if (!data) return;
    let onFilterData = data;

    switch (toggle) {
      case 'all':
        onFilterData = data;
        break;
      case 'ongoing':
        onFilterData = data.filter(
          (item) =>
            new Date(item.runningTime?.props.info.startDate) <= new Date() &&
            new Date(item.runningTime?.props.info.endDate) >= new Date(),
        );
        break;
      case 'next':
        onFilterData = data.filter(
          (item) =>
            new Date(item.runningTime?.props.info.startDate) > new Date(),
        );
        break;
    }

    switch (market) {
      case 'worten-online-pt':
        onFilterData = onFilterData.filter(
          (item) =>
            item.runningTime?.props.info.marketType === 'worten-online-pt',
        );
        break;
      case 'worten-online-es':
        onFilterData = onFilterData.filter(
          (item) =>
            item.runningTime?.props.info.marketType === 'worten-online-es',
        );
        break;
    }

    return onFilterData;
  };

  useEffect(() => {
    const dataFiltered = filterData(
      filtersSelected.toggle,
      filtersSelected.market,
    ) as typeof data;

    onFilterChange(dataFiltered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filtersSelected.toggle, filtersSelected.market]);

  const registerEvent = (arg: string) => {
    if (!window.dataLayer) {
      return;
    }

    REGISTER_DATALAYER('TOGGLE_VIEW_CAMPAIGN', { viewType: arg });
  };

  return (
    <FiltersContainer>
      <FiltersWrapperTopLine>
        <Toggle
          items={toggleItemsModeView}
          filteredData={(arg: string) => {
            onModeView({ ...filtersSelected, modeView: arg });
            registerEvent(arg);
          }}
        />

        <SimpleSelect
          label={intl.formatMessage(messages.campaignMarket)}
          name="marketFilter"
          options={marketFilterOptions}
          handleOnChange={(option) =>
            setFiltersSelected({
              toggle: filtersSelected.toggle,
              market: option.value,
              modeView,
            })
          }
        />
      </FiltersWrapperTopLine>
      <FiltersWrapperBottomLine>
        <Toggle
          items={toggleItemsStatus}
          filteredData={(arg) => {
            setFiltersSelected({
              toggle: arg,
              market: filtersSelected.market,
              modeView,
            });
          }}
        />
      </FiltersWrapperBottomLine>
    </FiltersContainer>
  );
};

export default Filters;

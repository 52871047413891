import {
  Badge,
  Loading,
  Svg,
  Toggle,
  ToggleItemProps,
  formatWithLocale,
} from '@worten-sardines/grill-ui';
import {
  ComingSoon,
  ProgressTracker,
} from '@worten-sardines/seller-center-ui-shared';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { getTooltipMessage } from './helper/defineTooltipMessages';
import nextAssessmentDate from './helper/getNextAssessmentDate';
import messages from './messages';
import {
  Container,
  ImageBox,
  Title,
  TitleContainer,
  ToggleContainer,
} from './styled';
import { PremiumIndicatorProps } from '../../types';

export const PremiumIndicator = ({
  evaluations,
  channels,
}: PremiumIndicatorProps) => {
  const { locale, formatMessage } = useIntl();
  const { format } = formatWithLocale(locale);
  const [loading, setLoading] = useState(true);
  const showToggle = channels && channels.length > 1;

  // TODO must be changed to the correct logic when API is ready
  const isPremium =
    channels?.find(({ channel }) => channel === 'pt')?.premium ?? false;

  const toggleItems: ToggleItemProps[] = channels.map(
    ({ channel, premium = false, show }, index) => ({
      text: `worten.${channel}`,
      id: channel,
      active: index === 0, // TODO must be changed to the correct logic when API is ready
      disabled: !show,
      ...(channel === 'es' && {
        badge: <Badge type="soon">{formatMessage(messages.comingSoon)}</Badge>,
        alignBadge: 'right',
      }),
    }),
  );

  const evaluationsByChannel = evaluations.find((evaluation) =>
    evaluation.channel.toLowerCase().includes('pt'),
  );

  const grade = evaluationsByChannel?.grade ?? 0;

  const tooltipMessage = getTooltipMessage(isPremium, grade);
  const formatStr = locale === 'en' ? 'MM/dd' : 'dd/MM';
  const indicatorsProgress = Number((grade * 100).toFixed(2));
  const noChannels = toggleItems.every(({ disabled }) => disabled === true);
  const nextAssessment = format(nextAssessmentDate(), formatStr);

  useEffect(() => {
    if (toggleItems.length > 0) return setLoading(false);
  }, [toggleItems]);

  if (loading) return <Loading />; // TODO must be changed to the correct loading component maybe a skeleton

  return (
    <Container>
      <TitleContainer>
        <Title>{formatMessage(messages.title)}</Title>
        {noChannels && (
          <Badge type="soon">{formatMessage(messages.comingSoon)}</Badge>
        )}
      </TitleContainer>

      {showToggle && (
        <ToggleContainer>
          <Toggle items={toggleItems} />
        </ToggleContainer>
      )}

      {noChannels ? (
        <ImageBox>
          <Svg
            svg={<ComingSoon />}
            title={formatMessage(messages.comingSoon)}
          />
        </ImageBox>
      ) : (
        <ProgressTracker
          title={formatMessage(messages.titleProgress)}
          progress={indicatorsProgress}
          description={formatMessage(messages.nextEvaluation, {
            date: nextAssessment,
          })}
          tooltipMessage={tooltipMessage}
          showTooltip
        />
      )}
    </Container>
  );
};

export default PremiumIndicator;

import { defineMessages } from 'react-intl';

export default defineMessages({
  remain: {
    id: 'libs.seller-center.src.remain',
    defaultMessage: '{count, plural, one {# day} other {# days}} remaining',
  },
  days: {
    id: 'libs.seller-center.src.days',
    defaultMessage: '{count, plural, one {# day} other {# days}}',
  },
  lastDay: {
    id: 'libs.seller-center.src.lastDay',
    defaultMessage: 'Last day',
  },
});

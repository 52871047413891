import { post } from '@worten-sardines/shared/utils-apis';
import { API_URL_AUTH_PUBLIC } from '@worten-sardines/configs/config-seller-center';

type ArgsType = {
  [key: string]: string;
};

export const login = async ({ code }: ArgsType) => {
  return post('/auth/v1/exchangeCode', {
    apiUrl: API_URL_AUTH_PUBLIC,
    body: {
      code,
    },
  });
};

export const logout = async () => {
  return post('/auth/v1/logout', {});
};

export function getUserInfo() {
  return {
    key: '/auth/v1/me',
  };
}

export const setSignin = async ({
  email,
}: ArgsType): Promise<{ url: string }> => {
  return post('/auth/v1/signin', {
    apiUrl: API_URL_AUTH_PUBLIC,
    query: { email: email },
  });
};

export default { login, logout, getUserInfo, setSignin };

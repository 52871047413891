import { useIntl } from 'react-intl';
import styled from 'styled-components';
import messages from '../messages';
import { CriteriasProps } from '../types';
import { MetricNameItem } from './MetricNameItem';

const CriteriaName = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin: 0;
`;

const CriteriaNameArea = styled.div`
  display: flex;
  flex-direction: column;

  & ul {
    margin: 0;
  }
`;

export const CriteriaArea = ({ criteria }: { criteria: CriteriasProps }) => {
  const intl = useIntl();

  const criteriaName = criteria.name as keyof typeof messages;

  const metricNames = criteria.metrics.map((metric) => (
    <MetricNameItem key={metric.name} metric={metric} />
  ));

  return (
    <CriteriaNameArea>
      <CriteriaName>{intl.formatMessage(messages[criteriaName])}</CriteriaName>
      <ul>{metricNames}</ul>
    </CriteriaNameArea>
  );
};

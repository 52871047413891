import { ReactNode } from 'react';
import { StyledMenuList } from './styled';

export type MenuListProps = {
  children?: ReactNode;
};

export const MenuList = ({ children }: MenuListProps) => {
  return <StyledMenuList>{children}</StyledMenuList>;
};

export default MenuList;

import { IntlShape } from 'react-intl';
import messages from '../../messages';
import { Payout } from '../../types/payouts.types';

type GetDocumentTypeIntl = Pick<
  Payout,
  'documentType' | 'issuerVatId' | 'issuerCountryCode' | 'invoiceId'
>;

export const getDocumentTypeIntl = (
  {
    documentType,
    issuerVatId,
    issuerCountryCode,
    invoiceId,
  }: GetDocumentTypeIntl,
  intl: IntlShape,
) => {
  const countryCode = issuerCountryCode || issuerVatId?.substring(0, 2);

  return {
    commission_credit_note: intl.formatMessage(
      messages.docTypeCommissionCreditNote,
      {
        issuerCountryCode: countryCode,
        invoiceId: invoiceId,
      },
    ),
    credit_note: intl.formatMessage(messages.docTypeCommissionCreditNote, {
      issuerCountryCode: countryCode,
      invoiceId: invoiceId,
    }),
    invoice: intl.formatMessage(messages.docTypeCommissionInvoice, {
      issuerCountryCode: countryCode,
      invoiceId: invoiceId,
    }),
    commission_invoice: intl.formatMessage(messages.docTypeInvoice, {
      issuerCountryCode: countryCode,
      invoiceId: invoiceId,
    }),
    commission_invoice_details: intl.formatMessage(
      messages.docTypeCommissionInvoiceDetails,
      {
        issuerCountryCode: countryCode,
        invoiceId: invoiceId,
      },
    ),
    payout_details: intl.formatMessage(messages.docTypePayoutDetails, {
      issuerCountryCode: countryCode,
      invoiceId: invoiceId,
    }),
    commission_credit_note_details: intl.formatMessage(
      messages.docTypeCommissionCreditNoteDetails,
      {
        issuerCountryCode: countryCode,
        invoiceId: invoiceId,
      },
    ),
    subscription_invoice: intl.formatMessage(
      messages.docTypeSubscriptionInvoice,
      {
        issuerCountryCode: countryCode,
        invoiceId: invoiceId,
      },
    ),
    subscription_credit_note: intl.formatMessage(
      messages.docTypeSubscriptionCreditNote,
      {
        issuerCountryCode: countryCode,
        invoiceId: invoiceId,
      },
    ),
    nca_invoice: intl.formatMessage(messages.docTypeNCAInvoice, {
      issuerCountryCode: countryCode,
      invoiceId: invoiceId,
    }),
    nca_credit_note: intl.formatMessage(messages.docTypeNCACreditNote, {
      issuerCountryCode: countryCode,
      invoiceId: invoiceId,
    }),
  }[documentType];
};

import styled from 'styled-components';
import { Flex, Theme as theme } from '@worten-sardines/grill-ui';

export const StyledSellerPremiumPBIWrapper = styled(Flex).attrs({
  flexDirection: 'column',
})`
  border: 1px solid ${theme.color.neutral['neu-03']};
  border-radius: ${theme.Radius.xxs};
  background-color: ${theme.color.neutral.neu01};
  font-size: ${theme.fontsize.desktop.s1};
  font-weight: ${theme.weight.semibold};
  color: ${theme.color.neutral['neu-08']};

  margin: ${theme.spacing.xs};
  padding-top: ${theme.spacing.xs};

  p {
    margin: 0;
    padding: 0.2rem 0.5rem;
  }
`;

import { Theme as theme } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

export const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${theme.spacing.xxs};

  svg {
    margin-right: 0.2rem;
  }

  span {
    font-size: ${theme.fontsize.desktop.xxs};
  }
`;

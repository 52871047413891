import { StrictMode } from 'react';
import * as Sentry from '@sentry/react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import {
  Theme,
  GlobalStyles,
  Toaster,
  LoadingOverlay,
} from '@worten-sardines/grill-ui';
import { IntlProviderWrapperFeature } from '@worten-sardines/intl-provider-wrapper/feature';
import { LANGUAGE_DEFAULT } from '@worten-sardines/configs/config-seller-center';
import { SpinnerWorten3D } from '@worten-sardines/seller-center-ui-shared';
import { ThemeProvider } from 'styled-components';
import { initializeSentry, router } from './monitoring/sentry';

initializeSentry();

const Main = () => (
  <StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={Theme}>
        <IntlProviderWrapperFeature
          projectName="seller-center"
          languageDefault={LANGUAGE_DEFAULT}
        >
          <RouterProvider
            router={router}
            fallbackElement={
              <LoadingOverlay isVisible>
                <SpinnerWorten3D />
              </LoadingOverlay>
            }
          />
          <Toaster position="top-right" />
        </IntlProviderWrapperFeature>
        <GlobalStyles />
      </ThemeProvider>
    </HelmetProvider>
  </StrictMode>
);
const MainWithProfiler = Sentry.withProfiler(Main);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<MainWithProfiler />);

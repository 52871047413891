import { useIntl } from 'react-intl';
import { Svg } from '@worten-sardines/grill-ui';
import { languagesMenu, flag, languageTitle } from './helpers';

import DropdownMenu from '../DropdownMenu';
import { StyledButton } from './styled';
import { Locale } from './helpers';

export const LanguageMenu = () => {
  const intl = useIntl();
  const currentLocale = Locale[intl.locale as keyof typeof Locale];
  const flagIcon = <Svg title="User Menu" svg={flag(currentLocale)} />;
  return (
    <DropdownMenu items={languagesMenu(currentLocale)} width="120px">
      <StyledButton icon={flagIcon} variation="tertiary" size="small" fullWidth>
        {languageTitle(currentLocale)}
      </StyledButton>
    </DropdownMenu>
  );
};

export default LanguageMenu;

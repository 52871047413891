import { useIntl } from 'react-intl';
import { useSession, useSessionStorage } from '@worten-sardines/shared/hooks';
import { Form123FormSchema } from '../../types';
import messages from './messages';
import { StyledIframe } from './styled';

const encodeText = (text?: string) => {
  if (!text) {
    return undefined;
  }

  return text
    .replace(/\+/g, '&#43;')
    .replace(/%/g, '%25')
    .replace(/&/g, '%26')
    .replace(/#/g, '%23');
};

export const Form123Form = ({
  schema: {
    name,
    fullWidth,
    iframeTitle,
    contactFormId,
    shopName: shopNameSquidex,
    shopId: shopIdSquidex,
    email: emailSquidex,
    otherFields: otherFieldsSquidex,
    position,
    height,
  },
}: {
  schema: Form123FormSchema;
}) => {
  const intl = useIntl();
  const user = useSession() || {};
  const { value: shopInfo } = useSessionStorage('shop', null) || {};

  const shopInfoId = shopInfo?.id || '';
  const shopInfoName = encodeText(shopInfo?.name) || '';

  const isOperator = user.data.type === 'operator';
  const shopIdValue = isOperator ? shopInfoId : user.data.shop?.id;
  const shopNameValue = isOperator
    ? shopInfoName
    : encodeText(user.data.shop?.name);

  const shopId = shopIdSquidex ? `&control${shopIdSquidex}=${shopIdValue}` : '';
  const email = emailSquidex
    ? `&control${emailSquidex}=${encodeText(user.data.email)}`
    : '';
  const shopName = shopNameSquidex
    ? `&control${shopNameSquidex}=${shopNameValue}`
    : '';
  const otherFields = otherFieldsSquidex?.length
    ? otherFieldsSquidex?.map(
        ({ dataId, value }) => `&control${dataId}=${encodeText(value)}`,
      )
    : '';

  const src = `https://app-eu.123formbuilder.com/sf.php?s=123formbuilder-${contactFormId}${shopId}${shopName}${email}${otherFields}`;

  return (
    <StyledIframe
      position={position}
      title={iframeTitle}
      fullWidth={fullWidth}
      height={height}
      id={name}
      name={name}
      frameBorder="0"
      src={src}
    >
      <p>{intl.formatMessage(messages.iframeMessage)}</p>
    </StyledIframe>
  );
};

export default Form123Form;

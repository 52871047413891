import { Theme as theme } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

export const Milestone = styled.div`
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: ${theme.color.main['m-01']};
  z-index: 2;
`;

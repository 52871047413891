import { useState, useEffect } from 'react';

import {
  subscribe,
  getSessionValue,
  setSessionValue as setGlobalSessionValue,
  removeSessionValue as removeGlobalSessionValue,
} from './session-storage-manager';

export const useSessionStorage = (
  key: string,
  initialValue: unknown | null,
) => {
  const [value, setValue] = useState(
    () => getSessionValue(key) || initialValue,
  );

  useEffect(() => {
    const unsubscribe = subscribe(() => {
      setValue(getSessionValue(key));
    });
    return unsubscribe;
  }, [key]);

  const setSessionValue = (newValue: unknown) => {
    setGlobalSessionValue(key, newValue);
  };

  const removeSessionValue = () => {
    removeGlobalSessionValue(key);
  };

  return { value, setSessionValue, removeSessionValue };
};

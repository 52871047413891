import { formatWithLocale } from '@worten-sardines/grill-ui';
import { differenceInCalendarDays } from 'date-fns';

export const pluralize = (count: number, word: string) => {
  return count <= 1 ? word : word + 's';
};

export const formatDate = (
  startDate: Date,
  endDate: Date,
  daysInAdvance: number,
  locale: string,
  hasWarning?: boolean,
) => {
  const today = new Date();

  const { format } = formatWithLocale(locale);

  // pick days, months and years from received dates
  const dayOfStart = format(startDate, 'dd');
  const dayOfEnd = format(endDate, 'dd');
  const monthOfStart = format(startDate, 'MMM');
  const monthOfEnd = format(endDate, 'MMM');
  const yearOfStart = format(endDate, 'yyyy');
  const yearOfEnd = format(endDate, 'yyyy');

  // Format Distance between dates
  const durationPeriod = differenceInCalendarDays(endDate, startDate) + 1;

  // Check if the campaign is initiated
  const isInitiated = differenceInCalendarDays(startDate, today) <= 0;

  // Calculate remaining days to show on warning badge
  const remainingDays = differenceInCalendarDays(endDate, today);
  const isClosed = remainingDays < 0;

  // Check if the campaign is initiated and if it has on range defined by daysInAdvance to show warning badge
  if (
    isInitiated &&
    daysInAdvance !== 0 &&
    remainingDays <= daysInAdvance &&
    remainingDays >= 0
  ) {
    hasWarning = true;
  }

  return {
    isInitiated,
    isClosed,
    dayOfStart,
    dayOfEnd,
    monthOfStart,
    monthOfEnd,
    yearOfStart,
    yearOfEnd,
    durationPeriod,
    remainingDays,
    hasWarning,
  };
};

export default {
  pluralize,
  formatDate,
};

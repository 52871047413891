import { Svg } from '@worten-sardines/grill-ui';
import { useIntl } from 'react-intl';
import { ArrowRight, Info } from '../assets';
import messages from './messages';
import {
  CustomBadge,
  DateCard,
  DatesGroup,
  Day,
  Details,
  DurationSpan,
  IconDiv,
  Month,
  StyledDateDetailCard,
  StyledDateDetailCardWrapper,
  TitleDate,
} from './styled';

import { formatDate } from './utils/helpers';

export type DateDetailCardProps = {
  startDate: Date;
  endDate: Date;
  title?: string;
  daysInAdvance?: number;
  locale?: string;
  noBorder?: boolean;
  highlightEndDate?: boolean;
};

export const DateDetailCard = ({
  title,
  startDate,
  endDate,
  daysInAdvance = 0,
  locale,
  noBorder = false,
  highlightEndDate = false,
}: DateDetailCardProps) => {
  const intl = useIntl();

  const appLocale = locale || intl.locale;

  const {
    dayOfEnd,
    dayOfStart,
    monthOfEnd,
    monthOfStart,
    durationPeriod,
    remainingDays,
    hasWarning,
  } = formatDate(startDate, endDate, daysInAdvance, appLocale);

  const badgeMessage =
    remainingDays === 0
      ? intl.formatMessage(messages.lastDay)
      : intl.formatMessage(messages.remain, {
          count: remainingDays,
        });

  return (
    <StyledDateDetailCardWrapper>
      {hasWarning && (
        <CustomBadge type="recommended">
          <Svg name="info-icon-badge" svg={<Info />} title="info icon badge" />
          <span>{badgeMessage}</span>
        </CustomBadge>
      )}
      <StyledDateDetailCard noBorder={noBorder}>
        <DatesGroup>
          <DateCard>
            <Day>{dayOfStart}</Day>
            <Month>{monthOfStart}</Month>
          </DateCard>
          <IconDiv>
            <Svg
              name="arrow-right-icon"
              svg={<ArrowRight />}
              title="arrow icon"
            />
          </IconDiv>
          <DateCard
            data-testid="endDate"
            highlightEndDate={highlightEndDate || hasWarning}
          >
            <Day>{dayOfEnd}</Day>
            <Month>{monthOfEnd}</Month>
          </DateCard>
        </DatesGroup>
        <Details>
          {title && <TitleDate>{title}</TitleDate>}
          <DurationSpan>
            {intl.formatMessage(messages.days, {
              count: durationPeriod,
            })}
          </DurationSpan>
        </Details>
      </StyledDateDetailCard>
    </StyledDateDetailCardWrapper>
  );
};

export default DateDetailCard;

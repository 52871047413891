import { Badge } from '@worten-sardines/grill-ui';
import { IndicatorBarContainer, ShopName, Status, StatusArea } from './styled';

type ChannelProps = {
  channel: string;
  premium?: boolean;
  show: boolean;
};

export type StatusProps = ChannelProps[] | [];

export type IndicatorBarProps = {
  shopName: string;
  status: StatusProps;
};

export const IndicatorBar = ({ shopName, status }: IndicatorBarProps) => {
  return (
    <IndicatorBarContainer>
      <ShopName>{shopName}</ShopName>
      <StatusArea>
        {status.map(({ channel, premium, show }) => {
          if (show) {
            return (
              <Status key={`${channel}`}>
                {`Worten.${channel}`}
                <Badge type={premium ? 'premium' : 'notPremium'}>Premium</Badge>
              </Status>
            );
          }
          return null;
        })}
      </StatusArea>
    </IndicatorBarContainer>
  );
};

export default IndicatorBar;

import { IntlShape } from 'react-intl';
import { getSchemaFromPathname, getContent } from './constants';

export const useContentCms = (
  pathname: string,
  slug?: string,
  preview?: string,
  lang?: string,
  intl?: IntlShape,
) => {
  const { schema, allowSlug } = getSchemaFromPathname(pathname, slug);

  let resolvedSlug = null;
  if (allowSlug) {
    // Request with slug, only for allowed schemas
    resolvedSlug = slug;
  }

  const { data, loading, error } = getContent(
    schema,
    resolvedSlug,
    preview,
    lang || intl?.locale, //lang is only used for preview mode
  );

  return [data?.data?.config, loading, error];
};

export default useContentCms;

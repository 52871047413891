import { css } from 'styled-components';

export const numberOfPagesOnPagination = (
  limit: number,
  count: number,
): number => {
  return Math.ceil(count / limit);
};

export const checkObjectHaveSpecificProperty = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[],
  property: string,
) => {
  return data.some(
    (prop) =>
      typeof prop === 'object' &&
      Object.prototype.hasOwnProperty.call(prop, property),
  );
};

export const sizeValueConvert = (size = 0) => {
  if (Number.isFinite(size)) {
    return size >= 0 && size <= 1 ? `${100 * size}%` : `${size}px`;
  }
  return size;
};

export function toHyphenCase(camelCaseString = '') {
  return camelCaseString.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export const isObject = (obj: Record<string, unknown>) =>
  Object.prototype.toString.call(obj) === '[object Object]';

export const isColor = (color = '') => {
  if (color && typeof color === 'string') {
    if (
      color.startsWith('#') ||
      color.startsWith('rgb') ||
      color.startsWith('rgba')
    ) {
      // Returns any valid CSS color
      return color;
    }
  }
  return null;
};

/**
 * Converts an HEX color code (#FFFFFF) to an RGB color value (rgba(r,g,b,opacity))
 *
 * @param {*} hex HEX color code
 * @param {*} opacity Value between 0 and 1
 */
export function hex2rgb(hex: string, opacity = 1) {
  function hexToR(h: string) {
    return parseInt(cutHex(h).substring(0, 2), 16);
  }
  function hexToG(h: string) {
    return parseInt(cutHex(h).substring(2, 4), 16);
  }
  function hexToB(h: string) {
    return parseInt(cutHex(h).substring(4, 6), 16);
  }
  function cutHex(h: string) {
    return h.charAt(0) === '#' ? h.substring(1, 7) : h;
  }

  return `rgba(${hexToR(hex)},${hexToG(hex)},${hexToB(hex)},${opacity})`;
}

export const transitionTimingCurve = (value: string) => {
  const curveValue = value.replace(/[\])}[{(]/g, '');

  return css`
    transition-timing-function: cubic-bezier(${curveValue});
  `;
};

import { ArrowButton, Button, Text } from '@worten-sardines/grill-ui';
import styled, { css } from 'styled-components';

export const StyledDateTimeline = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: ${theme.spacing.xs} 0;
    background-color: ${theme.color.neutral.neu01};
  `}
`;

export const ContainerTimeline = styled.div`
  position: relative;
`;

export const Timeline = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  padding-right: 35px;

  & > :first-child {
    & > :last-child {
      padding-left: 40px;
    }
  }
`;

export const ContainerLabels = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.color.neutral.neu01};
    left: 0;
    position: sticky;
    padding: 0 ${theme.spacing.xs};
    width: fit-content;
    min-width: 11rem;
  `}
`;

export const YearLabel = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral['neu-07']};
    font-size: ${theme.fontsize.desktop.s1};
    font-weight: ${theme.weight.regular};
  `};
`;

export const MonthLabel = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral['neu-07']};
    font-size: ${theme.fontsize.desktop.m};
    font-weight: ${theme.weight.bold};
  `};
`;

export const ButtonBackToday = styled(Button).attrs({
  size: 'small',
})`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
`;

export const NavButton = styled(ArrowButton)`
  ${({ direction }) => css`
    width: 40px;
    height: 45px;
    position: absolute;
    bottom: 0;
    z-index: 1;

    ${direction === 'left' &&
    css`
      left: 0;
    `}
    ${direction === 'right' &&
    css`
      right: 0;
    `}
  `}
`;

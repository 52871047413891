import { useState } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { Input, Label, StyledCheckbox, Wrapper } from './styled';

export type CheckboxProps = {
  id: string;
  name: string;
  label?: string;
  children?: React.ReactNode;
  register?: UseFormRegister<FieldValues>;
  size?: number;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = ({
  id,
  children,
  name,
  label,
  register,
  size = 24,
  ...rest
}: CheckboxProps) => {
  const registerRH = register ? { ...register(name) } : null;

  const initCheck = rest.checked || rest.defaultChecked;
  const [isChecked, setIsChecked] = useState<boolean>(initCheck || false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (rest.readOnly) return;

    setIsChecked(e.target.checked);
  };

  return (
    <Wrapper>
      <StyledCheckbox size={size}>
        <Input
          id={id}
          name={name}
          type="checkbox"
          onChange={handleChange}
          aria-checked={isChecked}
          {...registerRH}
          {...rest}
        />
      </StyledCheckbox>
      <Label htmlFor={id}>
        {label}
        {children}
      </Label>
    </Wrapper>
  );
};

export default Checkbox;

import { Theme, Grid } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

type SkeletonLineProps = {
  columns?: number;
};

export const SkeletonContainer = styled(Grid)`
  grid-template-rows: 77px auto auto auto;
  margin: ${Theme.spacing.xs};
  height: 95%;
  overflow: hidden;
  border-radius: ${Theme.Radius.xs};
  border: solid 1px ${Theme.color.neutral['neu-03']};
  background-color: ${Theme.color.neutral['neu01']};

  & div:first-child {
    border-bottom: 1px solid ${Theme.color.neutral['neu-03']};
  }
`;

export const SkeletonLine = styled(Grid)<SkeletonLineProps>`
  width: 100%;
  height: ${Theme.size.md};
  height: 100%;
  gap: ${Theme.spacing.md};
  padding: ${Theme.spacing.sm} ${Theme.spacing.md};
  grid-template-columns: ${({ columns }) =>
    columns ? `repeat(${columns}, 1fr)` : '1fr'};
`;

export const SkeletonRectangle = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Theme.color.neutral['neu-03']};
  border-radius: ${Theme.Radius.xxs};
  min-height: 2.8rem;
`;

import { LoadingOverlay } from '@worten-sardines/grill-ui';
import { SpinnerWorten3D } from '@worten-sardines/seller-center-ui-shared';
import { useAuth } from '@worten-sardines/shared/hooks';
import { useEffect } from 'react';

export const Oauth = () => {
  const authFn = useAuth();
  const externalOAuthCode = new URLSearchParams(window.location.search).get(
    'code',
  );

  useEffect(() => {
    if (externalOAuthCode) {
      // Redirect is already done inside authFn
      authFn(externalOAuthCode);
    }
  }, [externalOAuthCode, authFn]);

  if (externalOAuthCode) {
    return (
      <LoadingOverlay isVisible>
        <SpinnerWorten3D />
      </LoadingOverlay>
    );
  }

  return null;
};

export default Oauth;

export const spreadRatingValue = (rating: number, maxRating: number) => {
  const rateInteger = Math.trunc(rating);

  if (rateInteger >= maxRating)
    return Array.from({ length: Math.trunc(maxRating) }, () => 'full');

  if (rating >= 0.1 && rating <= 0.9)
    return Array.from({ length: Math.trunc(maxRating) }, (_, i: number) =>
      i > 0 ? 'empty' : 'half',
    );

  if (rateInteger <= 0)
    return Array.from({ length: Math.trunc(maxRating) }, () => 'empty');

  const isFloat = !Number.isInteger(rating) && Number.isFinite(rating);
  const rest = isFloat ? maxRating - rateInteger - 1 : maxRating - rateInteger;
  const head = Array.from({ length: Math.trunc(rating) }, () => 'full');
  const tail = Array.from({ length: rest }, () => 'empty');

  return isFloat ? [...head, 'half', ...tail] : [...head, ...tail];
};

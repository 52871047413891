import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'libs.seller-center.ui-layout.SellerPremium.PremiumIndicator.text',
    defaultMessage: 'Your Premium Seller evaluation',
  },
  titleProgress: {
    id: 'libs.seller-center.ui-layout.SellerPremium.PremiumIndicator.titleProgress',
    defaultMessage: 'Your current status',
  },
  nextEvaluation: {
    id: 'libs.seller-center.ui-layout.SellerPremium.PremiumIndicator.nextEvaluation',
    defaultMessage: 'Upcoming evaluation {date}',
  },
  isPremium: {
    id: 'libs.seller-center.ui-layout.SellerPremium.PremiumIndicator.isPremium',
    defaultMessage:
      'Well done! Continue to meet quality standards and be a {premium}',
  },
  turnPremium: {
    id: 'libs.seller-center.ui-layout.SellerPremium.PremiumIndicator.turnPremium',
    defaultMessage:
      'Well done! Continue to meet quality standards and will be {premium}',
  },
  losingPremium: {
    id: 'libs.seller-center.ui-layout.SellerPremium.PremiumIndicator.losingPremium',
    defaultMessage:
      'Watch out! You run the risk of no longer being a Premium Seller',
  },
  becomePremium: {
    id: 'libs.seller-center.ui-layout.SellerPremium.PremiumIndicator.becomePremium',
    defaultMessage:
      'Become a Premium Seller by fulfilling the requirements below',
  },
  comingSoon: {
    id: 'libs.seller-center.ui-layout.SellerPremium.PremiumIndicator.comingSoon',
    defaultMessage: 'Coming soon',
  },
});

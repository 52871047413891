import { Button, Heading, Svg, Paper } from '@worten-sardines/grill-ui';
import { WortenSeller } from '@worten-sardines/seller-center-ui-shared';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import messages from './messages';
import { Content, Header, Subtitle, Wrapper } from './styled';

export type ErrorBoundaryProps = {
  error: Error;
  resetErrorBoundary?: () => void;
};

export const ErrorBoundary = ({
  error,
  resetErrorBoundary,
}: ErrorBoundaryProps) => {
  const intl = useIntl();

  return (
    <Wrapper>
      <Paper>
        <Paper.Header>
          <Header>
            <NavLink to="/" end>
              <Svg svg={<WortenSeller />} width={107} title="Worten Seller" />
            </NavLink>
            <Heading as="h3" className="title">
              Ooops!
            </Heading>
            <Subtitle
              fontSize="1.4rem"
              fontWeight="semibold"
              className="subtitle"
            >
              {intl.formatMessage(messages.title)}
            </Subtitle>
          </Header>
        </Paper.Header>
        <Content>
          <p>{error.message}</p>
        </Content>
        <Paper.Footer>
          {resetErrorBoundary && (
            <Button variation="tertiary" onClick={resetErrorBoundary}>
              {intl.formatMessage(messages.label)}
            </Button>
          )}
        </Paper.Footer>
      </Paper>
    </Wrapper>
  );
};

export default ErrorBoundary;

import styled, { css } from 'styled-components';
import { ArrowButtonProps } from '.';

export const directionMap = {
  right: css``,
  left: css`
    transform: rotate(180deg);
  `,
  up: css`
    transform: rotate(-90deg);
  `,
  down: css`
    transform: rotate(90deg);
  `,
};

export const StyledArrowButton = styled.button<ArrowButtonProps>`
  ${({ theme, direction, backgroundMode = 'default' }) => {
    const backgroundModes = {
      transparent: 'transparent',
      default: `${theme.color.neutral.neu01}`,
    };

    const modeValue = backgroundModes[backgroundMode];

    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: ${modeValue};
      border: solid 1px ${modeValue};
      transition: all ${theme.animation.duration.fast};

      &:hover:not(:disabled) {
        cursor: pointer;
        background: ${theme.color.neutral['neu-03']};
        border: solid 1px ${theme.color.neutral['neu-05']};
      }

      &:active:not(:disabled) {
        border: solid 1px ${theme.color.neutral['neu-03']};
        background: ${theme.color.neutral['neu-03']};
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }

      > svg {
        transform-origin: center;
        ${!!direction && direction in directionMap && directionMap[direction]};
      }
    `;
  }}
`;

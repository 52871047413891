import { ColumnDef } from '@tanstack/react-table';
import { IndeterminateCheckbox } from './';

import { ArrowButton, Flex } from '../../../../';

import { ColumnPropsType } from '../../types';

type AdvancedColumnsProps = {
  showSelectRow: boolean;
  hasSubRows: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataTableColumns: any;
};

export const AdvancedColumns = ({
  showSelectRow,
  hasSubRows,
  dataTableColumns,
}: AdvancedColumnsProps): ColumnDef<ColumnPropsType>[] => [
  {
    id: 'select',
    header: ({ table }) => (
      <Flex
        alignItems="center"
        justifyContent={
          showSelectRow && hasSubRows ? 'space-between' : 'space-around'
        }
        width="100%"
      >
        {showSelectRow && (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        )}
        {hasSubRows && (
          <button
            {...{
              onClick: table.getToggleAllRowsExpandedHandler(),
            }}
          >
            {table.getIsAllRowsExpanded() ? (
              <ArrowButton
                as="span"
                direction="up"
                backgroundMode="transparent"
              />
            ) : (
              <ArrowButton
                as="span"
                direction="down"
                backgroundMode="transparent"
              />
            )}
          </button>
        )}
      </Flex>
    ),
    cell: ({ row }) => (
      <Flex
        alignItems="center"
        justifyContent={
          showSelectRow && hasSubRows ? 'space-between' : 'space-around'
        }
        pl={`${row.depth * 1.2}rem`}
      >
        {showSelectRow && (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        )}
        {row.getCanExpand() ? (
          <button
            {...{
              onClick: row.getToggleExpandedHandler(),
              style: { cursor: 'pointer' },
            }}
          >
            {row.getIsExpanded() ? (
              <ArrowButton
                as="span"
                direction="up"
                backgroundMode="transparent"
              />
            ) : (
              <ArrowButton
                as="span"
                direction="down"
                backgroundMode="transparent"
              />
            )}
          </button>
        ) : null}
      </Flex>
    ),
  },
  ...dataTableColumns,
];

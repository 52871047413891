import styled, { css, DefaultTheme } from 'styled-components';
import { BadgeProps } from '.';
import { omitProps } from '../_common';
import { makeColor, makeHeight, makeWidth } from '../utils';

export type BadgeStyleProps = Pick<BadgeProps, 'type'>;

const crossBadgeType = {
  online: (theme: DefaultTheme) => css`
    background-color: ${theme.color.aditional['add-04']};
  `,
  brand: (theme: DefaultTheme) => css`
    background-color: ${theme.color.aditional['Add-02']};
  `,
  recommended: (theme: DefaultTheme) => css`
    background-color: ${theme.color.aditional['add-05']};
  `,
  general: (theme: DefaultTheme) => css`
    background-color: ${theme.color.aditional['add-06']};
  `,
  dangerous: (theme: DefaultTheme) => css`
    background-color: ${theme.color.main['m-01']};
  `,
  warning: (theme: DefaultTheme) => css`
    background-color: ${theme.color.aditional['add-01']};
  `,
  success: (theme: DefaultTheme) => css`
    background-color: ${theme.color.aditional['add-07']};
  `,
  black: (theme: DefaultTheme) => css`
    background-color: ${theme.color.neutral['neu-11']};
  `,
  custom: (theme: DefaultTheme) => css`
    background-color: ${theme.color.neutral['neu-11']};
  `,
  premium: (theme: DefaultTheme) => css`
    background: conic-gradient(
        from 174deg at -110.34% 194.44%,
        #e3a455 26.24999910593033deg,
        #f6dba6 88.12500178813934deg,
        #ffebc3 156.58468008041382deg,
        #f0be79 191.74442768096924deg,
        #8f6d3b 213.59200716018677deg,
        #ba8f3b 255.19062280654907deg,
        #eebc70 278.6032176017761deg,
        #3f260f 331.875deg
      ),
      radial-gradient(1196.32% 171.99% at -6.36% 0%, #f5cc4e 0%, #d3a92a 100%);
  `,
  notPremium: (theme: DefaultTheme) => css`
    color: ${theme.color.neutral['neu-07']};
    background-color: ${theme.color.neutral['neu-08']};
  `,
  soon: (theme: DefaultTheme) => css`
    background-color: ${theme.color.neutral['neu-06']};
  `,
};

export const StyledBadge = styled.span.withConfig<BadgeStyleProps>({
  shouldForwardProp: (prop) => omitProps(['type'], prop),
})`
  display: inline-flex;
  align-items: center;
  text-align: center;
  width: fit-content;
  min-height: 20px;

  ${({ theme, type }) => css`
    padding: ${theme.Radius.none} ${theme.Radius.xxs};
    gap: ${theme.Radius.xxs};

    border-radius: ${theme.sys.dimension.border.radius.Micro};

    font-family: ${theme.family.primary};
    font-weight: ${theme.weight.bold};
    font-size: ${theme.fontsize.desktop.xxs};
    letter-spacing: ${theme.letterspacing.desktop.xs};
    line-height: ${theme.lineheight.desktop.xxs};

    color: ${theme.color.neutral.neu01};

    ${type && crossBadgeType[type](theme)}

    & > svg {
      width: ${theme.size.xs};
      height: ${theme.size.xs};
    }
  `};

  ${makeColor}
  ${makeWidth}
  ${makeHeight}
`;

import styled, { css } from 'styled-components';

import { omitProps, GUTTER_CSS } from '../_common';
import { makeSpacing, GrillUtils } from '../utils';
import { GrillUtilsProps } from '../utils/types';

export type FlexProps = {
  inline?: boolean;
  gutter?: boolean;
  gap?: number;
} & Omit<
  GrillUtilsProps,
  | 'gridTemplateAreas'
  | 'gridTemplateColumns'
  | 'gridTemplateRows'
  | 'gridAutoFlow'
>;

const wrapperModifiers = {
  row: (gap: number) => css`
    & > :not(:last-child) {
      ${makeSpacing({ mr: gap })}
    }
    & > :last-child {
      margin-right: 0;
    }
  `,
  'row-reverse': (gap: number) => css`
    & > :not(:first-child) {
      ${makeSpacing({ mr: gap })}
    }
    & > :first-child {
      margin-right: 0;
    }
  `,
  column: (gap: number) => css`
    & > :not(:last-child) {
      ${makeSpacing({ mb: gap })}
    }
    & > :last-child {
      margin-bottom: 0;
    }
  `,
  'column-reverse': (gap: number) => css`
    & > :not(:first-child) {
      ${makeSpacing({ mb: gap })}
    }
    & > :first-child {
      margin-bottom: 0;
    }
  `,
};

export const Flex = styled.div.withConfig<FlexProps>({
  shouldForwardProp: (prop) => omitProps(['inline', 'gap', 'gutter'], prop),
})`
  ${({ flexDirection = 'row', gap, gutter, inline }) => css`
    display: ${inline ? 'inline-flex' : 'flex'};

    ${!!gap && wrapperModifiers[flexDirection](gap)}
    ${gutter && GUTTER_CSS};
  `};

  ${GrillUtils}
`;

export const FlexRow = styled(Flex).attrs({ flexDirection: 'row' })``;
export const FlexColumn = styled(Flex).attrs({ flexDirection: 'column' })``;
export default Flex;

import styled, { css } from 'styled-components';

export const StyledCollapse = styled.div`
  ${({ theme }) => css`
    transition: max-height ${theme.animation.duration.medium} ease-out;

    & > div.expand-inner-wrapper {
      transition: opacity ${theme.animation.duration.medium} ease-out;
    }
  `}
`;

import styled, { css } from 'styled-components';

type WrapperProps = {
  position?: number | null;
};

export const StyledHeroSlider = styled.section<WrapperProps>`
  ${({ position }) => css`
    max-width: 100%;
    grid-row: ${position};
  `}
`;

import { Button, Svg } from '@worten-sardines/grill-ui';
import {
  HeaderBar,
  MenuList,
  UserMenu,
  LanguageMenu,
  SettingsOption,
} from '@worten-sardines/seller-center-ui-layout';
import { MainTemplate } from '@worten-sardines/shared-ui';
import { useLogout } from '@worten-sardines/shared/hooks';
import { NavLink, Outlet } from 'react-router-dom';
import { NoticeFlag } from '@worten-sardines/seller-center-ui-shared';
import { Logo, UserIcon } from '../assets/images';
import { ProtectedProps } from './protected-route';
import { StyledRightMenu } from './styled';
import messages from './messages';

export function App(props: ProtectedProps) {
  const { intl, user } = props;
  const logoutFn = useLogout();

  const isOperator = user.data.type === 'operator';

  return (
    <MainTemplate>
      <MainTemplate.Header>
        <HeaderBar>
          <MenuList>
            <NavLink to="/" end>
              <Svg svg={<Logo />} width={40} title="Logo Worten" />
            </NavLink>
            <NavLink to="/reports" end>
              <Button
                id="menu-reports"
                as="span"
                variation="tertiary"
                size="small"
              >
                {intl.formatMessage(messages.reports)}
              </Button>
            </NavLink>
            <NavLink to="/campaigns" end>
              <Button
                id="menu-campaigns"
                as="span"
                variation="tertiary"
                size="small"
              >
                {intl.formatMessage(messages.campaigns)}
              </Button>
            </NavLink>

            <NavLink to="/payouts" end>
              <Button
                id="menu-payouts"
                as="span"
                variation="tertiary"
                size="small"
              >
                {intl.formatMessage(messages.payouts)}
              </Button>
            </NavLink>
            <NavLink to="/services" end>
              <Button
                id="menu-services"
                as="span"
                variation="tertiary"
                size="small"
              >
                {intl.formatMessage(messages.services)}
                <NoticeFlag type="newFeature" name="services" />
              </Button>
            </NavLink>
            <NavLink to="/seller-premium" end>
              <Button
                id="menu-seller-premium"
                as="span"
                variation="tertiary"
                size="small"
              >
                {intl.formatMessage(messages.sellerPremium)}
                <NoticeFlag type="newFeature" name="seller-premium" />
              </Button>
            </NavLink>
          </MenuList>
          <StyledRightMenu>
            <UserMenu
              name={user.data?.shop?.name || user.data?.email}
              svg={<UserIcon />}
              dropdownItems={[
                {
                  id: 'logout',
                  label: intl.formatMessage(messages.logout),
                  onClick: () => {
                    logoutFn();
                  },
                },
              ]}
            />
            <LanguageMenu />
            {isOperator && <SettingsOption />}
          </StyledRightMenu>
        </HeaderBar>
      </MainTemplate.Header>
      <Outlet context={props} />
    </MainTemplate>
  );
}

export default App;

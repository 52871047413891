import { Flex } from '@worten-sardines/grill-ui';
import DateDisplay from '../DateDisplay';

import { ContainerLabels, MonthLabel, YearLabel } from './styled';

import { TimeLine, WeeksProps } from './types';

const Month = ({
  timeline,
  disableYearsLabel,
  disableMonthsLabel,
}: WeeksProps) => {
  return (
    <>
      {timeline.map(({ year, month, weeks }: TimeLine) => (
        <Flex flexDirection="column" key={`${year}-${month}`}>
          <ContainerLabels>
            {!disableYearsLabel && <YearLabel m={0}>{year}</YearLabel>}
            {!disableMonthsLabel && <MonthLabel m={0}>{month}</MonthLabel>}
          </ContainerLabels>
          <Flex>
            {weeks.map(({ dayOfWeek, day, variation, isToday }) => (
              <div
                className={isToday ? 'isToday' : 'day'}
                key={`${timeline}-${day}`}
              >
                <DateDisplay
                  label={dayOfWeek}
                  dateNumber={day}
                  variation={variation}
                  isActived={isToday}
                />
              </div>
            ))}
          </Flex>
        </Flex>
      ))}
    </>
  );
};

export default Month;

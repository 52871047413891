import { Link } from 'react-router-dom';

const WrapperLink = ({
  hasExternalLink,
  path,
  children,
}: {
  hasExternalLink: boolean;
  path: string | undefined;
  children: JSX.Element;
}) => {
  if (!hasExternalLink && path) {
    return (
      <Link to={path} tabIndex={0}>
        {children}
      </Link>
    );
  }
  return children;
};

export default WrapperLink;

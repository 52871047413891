import { formatWithLocale } from '@worten-sardines/grill-ui';
import { EventCellProps, PeriodProps } from '../types';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isSameDay from 'date-fns/isSameDay';

export function getEventCellPeriodsProps({ data }: EventCellProps) {
  return {
    firstPeriod: {
      startDate: data.firstPeriod.startDate,
      endDate: data.firstPeriod.endDate,
      intersectionDate: getIntersectionDate({ data }),
    },
    secondPeriod: {
      startDate: data.secondPeriod.startDate,
      endDate: data.secondPeriod.endDate,
      intersectionDate: getIntersectionDate({ data }),
    },
  };
}

export function sortDatesFromArray(dates: string[]) {
  return dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
}

export function getIntersectionDate({ data }: EventCellProps) {
  const firstPeriodEndDate = new Date(data.firstPeriod.endDate);
  const secondPeriodStartDate = new Date(data.secondPeriod.startDate);
  const intersectionDate =
    differenceInCalendarDays(secondPeriodStartDate, firstPeriodEndDate) <= 0
      ? data.secondPeriod.startDate
      : undefined;

  return intersectionDate;
}

export function getDayFromDate(date: string) {
  const day = new Date(date).getDate();

  return '' + day;
}

export function getTotalNumberOfDays({ startDate, endDate }: PeriodProps) {
  const totalNumberOfDays = differenceInCalendarDays(
    new Date(endDate),
    new Date(startDate),
  );

  return totalNumberOfDays;
}

export function getDistanceBetweenDates(initialDate: string) {
  const today = new Date();
  const distanceInDays = differenceInCalendarDays(new Date(initialDate), today);

  return distanceInDays;
}

export function startBeforeToday(initialDate: string) {
  const diff = differenceInCalendarDays(new Date(initialDate), new Date());

  return diff < 0;
}

export function getIntervalBetweenPeriods({ data }: EventCellProps) {
  const firstPeriodEndDate = new Date(data.firstPeriod.endDate);
  const secondPeriodStartDate = new Date(data.secondPeriod.startDate);
  const interval = differenceInCalendarDays(
    secondPeriodStartDate,
    firstPeriodEndDate,
  );

  return interval - 1;
}

export function normalizePeriod(
  { startDate, endDate, intersectionDate }: PeriodProps,
  locale: string,
  firstPeriod?: PeriodProps,
) {
  const { format } = formatWithLocale(locale);

  let firstPeriodStartDate;
  let firstPeriodEndDate;
  let firstPeriodIntDate;

  if (firstPeriod) {
    firstPeriodStartDate = new Date(firstPeriod.startDate);
    firstPeriodEndDate = new Date(firstPeriod.endDate);
    firstPeriodIntDate =
      firstPeriod.intersectionDate && new Date(firstPeriod.intersectionDate);

    firstPeriodStartDate.setHours(0, 0, 0, 0);
    firstPeriodEndDate.setHours(0, 0, 0, 0);
    if (firstPeriodIntDate) firstPeriodIntDate.setHours(0, 0, 0, 0);
  }

  const normalizedStartDate = new Date(startDate);
  const normalizedEndDate = new Date(endDate);
  const normalizedIntersectionDate =
    intersectionDate && new Date(intersectionDate);

  normalizedStartDate.setHours(0, 0, 0, 0);
  normalizedEndDate.setHours(0, 0, 0, 0);
  if (normalizedIntersectionDate)
    normalizedIntersectionDate.setHours(0, 0, 0, 0);

  const days = {
    start: getDayFromDate(startDate),
    end: getDayFromDate(endDate),
    intersection: intersectionDate && getDayFromDate(intersectionDate),
  };

  const months = {
    start: format(new Date(startDate), 'MMM'),
    end: format(new Date(endDate), 'MMM'),
    intersection: intersectionDate && format(new Date(intersectionDate), 'MMM'),
  };

  const oneDay = isSameDay(normalizedStartDate, normalizedEndDate);
  const sizeOfPeriod =
    differenceInCalendarDays(normalizedEndDate, normalizedStartDate) + 1;

  const sizeOfIntersectionPeriod = normalizedIntersectionDate
    ? differenceInCalendarDays(normalizedEndDate, normalizedIntersectionDate) +
      1
    : 0;

  const diffBetweenEndSubmissionAndIntersection =
    firstPeriodEndDate && normalizedIntersectionDate
      ? differenceInCalendarDays(
          firstPeriodEndDate,
          normalizedIntersectionDate,
        ) + 1
      : normalizedIntersectionDate
      ? differenceInCalendarDays(normalizedEndDate, normalizedIntersectionDate)
      : 0;

  return {
    startDate: normalizedStartDate,
    endDate: normalizedEndDate,
    intDate: normalizedIntersectionDate,
    sizeOfIntersectionPeriod,
    diff: diffBetweenEndSubmissionAndIntersection,
    oneDay,
    days,
    months,
    sizeOfPeriod,
  };
}

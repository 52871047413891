import { memo } from 'react';
import { Close } from '@worten-sardines/seller-center-ui-shared';
import {
  ShopIdDetails,
  ShopNameText,
  StyledShopIdCard,
  UnsetButton,
} from './styled';
import { Svg } from '@worten-sardines/grill-ui';
import { ShopType, useSessionStorage } from '@worten-sardines/shared/hooks';

export type ShopIdCardProps = {
  shopInfo?: ShopType;
  isUnset?: boolean;
  setFn?: () => void;
  unsetFn?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const ShopIdCard = ({
  shopInfo,
  isUnset = false,
  setFn,
  unsetFn,
  ...props
}: ShopIdCardProps) => {
  const {
    value: shopInfoStorage,
    setSessionValue,
    removeSessionValue,
  } = useSessionStorage('shop', null);

  const shopIdStored = shopInfoStorage?.id;
  const shopNameStored = shopInfoStorage?.name;

  const name = shopInfo?.name || shopNameStored;
  const id = shopInfo?.id || shopIdStored;

  const handleSet = () => {
    if (isUnset) return;
    if (!shopInfo) return;

    setSessionValue({ id, name, channels: shopInfo?.channels, isPremium: shopInfo?.isPremium });
    setFn?.();
  };

  const handleUnset = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    unsetFn?.();
    removeSessionValue();
  };

  if (isUnset && !id) return null;

  return (
    <StyledShopIdCard
      isUnset={isUnset}
      onClick={handleSet}
      tabIndex={isUnset ? -1 : 0}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          handleSet();
        }
      }}
      {...props}
    >
      <ShopIdDetails>
        <ShopNameText>{name}</ShopNameText>
        <span>{id}</span>
      </ShopIdDetails>

      {isUnset && (
        <UnsetButton
          type="button"
          aria-label="unset shop"
          onClick={handleUnset}
          tabIndex={isUnset ? 0 : -1}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              handleUnset(event);
            }
          }}
        >
          <Svg svg={<Close />} width={24} title="icon close modal" />
        </UnsetButton>
      )}
    </StyledShopIdCard>
  );
};

export default memo(ShopIdCard);

import { defineMessages } from 'react-intl';

export default defineMessages({
  summaryMessage: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.summary',
    defaultMessage: 'Campaigns table.',
  },
  emptyTableMessage: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.emptyTable',
    defaultMessage: 'No available campaigns!',
  },
  campaignNameColumn: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.campaignNameColumn',
    defaultMessage: 'Campaign',
  },
  campaignSubmissionColumn: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.campaignSubmissionColumn',
    defaultMessage: 'Submission deadline',
  },
  campaignStartDateColumn: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.campaignStartDateColumn',
    defaultMessage: 'Start Date',
  },
  campaignEndDateColumn: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.campaignEndDateColumn',
    defaultMessage: 'End date',
  },
  campaignCaption: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.campaignCaption',
    defaultMessage: 'Caption:',
  },
  campaignIconImportantAriaLabel: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.campaignIconImportantAriaLabel',
    defaultMessage: 'important information for submission',
  },
  campaignLegendSubmissionDeadline: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.campaignLegendSubmissionDeadline',
    defaultMessage: 'Submission deadline approaching',
  },
  campaignRunningTimeColumn: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.campaignRunningTime',
    defaultMessage: 'Running time',
  },
  campaignSubmissionPeriodColumn: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.campaignSubmissionPeriodColumn',
    defaultMessage: 'Submission period',
  },
  learnMoreAboutCampaigns: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.learnMoreAboutCampaigns',
    defaultMessage: 'Learn more about Campaigns',
  },
  learnMoreAboutCampaignsUrl: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.learnMoreAboutCampaignsUrl',
    defaultMessage: 'order-management/seller-center/campaigns',
  },
  submissionCaptionLabel: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.submissionCaptionLabel',
    defaultMessage: 'Submission Deadline',
  },
  campaignCaptionLabel: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.campaignCaptionLabel',
    defaultMessage: 'Campaign running time',
  },
  campaignCaptionNewLabel: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.campaignCaptionNewLabel',
    defaultMessage: 'New',
  },
  campaignCaptionSubmittedLabel: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.campaignCaptionSubmittedLabel',
    defaultMessage: 'Submitted',
  },
});

import { UserShop } from '@worten-sardines/shared/hooks';

const channelsMap: { [key: string]: string } = {
  WRT_PT_ONLINE: 'pt',
  WRT_ES_ONLINE: 'es',
};

export const getStatusByChannel = ({ channels, isPremium }: UserShop) => {
  const status =
    channels?.map((channel) => {
      return {
        channel: channelsMap[channel],
        premium: channel === 'WRT_PT_ONLINE' ? isPremium : false,
        show: channel === 'WRT_PT_ONLINE',
      };
    }) || [];

  return status;
};

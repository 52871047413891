import styled, { css } from 'styled-components';

type Props = {
  withPlaceholder?: boolean;
  disabled?: boolean;
  hasError?: string | null;
  hasIcon?: boolean;
  iconPosition?: 'left' | 'right';
  isRequired?: boolean;
};

type IconProps = {
  iconPosition?: 'left' | 'right';
  hasError?: string | null;
  withPlaceholder?: boolean;
};

export const WrapperTextField = styled.div`
  background-color: inherit;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  width: 100%;
`;

export const ContainerInput = styled.div<Props>`
  ${({ theme, disabled, hasError, withPlaceholder }) => css`
    position: relative;
    display: flex;
    background-color: inherit;
    flex-direction: column;
    min-width: 0;
    width: 100%;

    ${withPlaceholder
      ? css`
          flex-direction: column-reverse;
        `
      : css`
          flex-direction: column;
        `};

    &:not(${hasError}):hover {
      input {
        border-color: ${theme.color.neutral['neu-11']};
      }
    }

    ${disabled &&
    css`
      opacity: 0.2;
      cursor: no-drop;
    `}
  `}
`;

const paddingModifiers = {
  left: () =>
    css`
      padding-left: 44px;
    `,
  right: () =>
    css`
      padding-right: 44px;
    `,
};

export const StyledInput = styled.input<Props>`
  ${({
    theme,
    disabled,
    hasError,
    hasIcon,
    iconPosition,
    withPlaceholder,
  }) => css`
    background: inherit;
    border-radius: 0.4rem;
    border: 0.13rem solid ${theme.color.neutral['neu-05']};
    color: ${theme.color.neutral['neu-11']};
    font-family: inherit;
    font-size: ${theme.fontsize.desktop.m};
    font-weight: ${theme.weight.semibold};
    min-height: ${theme.size.md};
    margin-bottom: ${theme.spacing.xxs};
    outline: none;
    padding: ${theme.spacing.xxs} ${theme.spacing.xs};

    ${hasIcon && iconPosition && paddingModifiers[iconPosition]};

    position: relative;
    width: 100%;
    transition: border ${theme.animation.duration.medium} ease-in;

    &[placeholder] {
      color: ${theme.color.neutral['neu-11']};
      opacity: 0.8;
      text-overflow: ellipsis;
    }

    &:disabled {
      background: ${theme.color.neutral['neu-05']};
      border-color: ${theme.color.neutral['neu-11']};
      cursor: no-drop;
    }

    ${!disabled &&
    css`
      &:hover,
      &:focus {
        border-color: ${theme.color.neutral['neu-11']};
      }
    `}

    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
      ${!withPlaceholder &&
      css`
        top: -${theme.spacing.xxs};
        font-size: ${theme.fontsize.desktop.xs};
        left: 1rem;
        color: ${theme.color.neutral['neu-07']};
      `};
    }

    ${!!hasError &&
    css`
      border-color: ${theme.color.extend['m-01--light-01']};
      &:focus,
      &:hover {
        border-color: ${theme.color.extend['m-01--light-01']};
      }
      ~ label {
        color: ${theme.color.extend['m-01--light-01']};
      }
    `}
  `}
`;

export const Label = styled.label<Props>`
  ${({
    theme,
    disabled,
    hasIcon,
    iconPosition,
    isRequired,
    withPlaceholder,
  }) => css`
    background-color: ${disabled && !withPlaceholder
      ? theme.color.neutral['neu-05']
      : 'inherit'};
    color: #767676; // this color doesn't have in the design tokens, but we adopt it for contrast accessibility
    cursor: ${disabled ? 'no-drop' : 'text'};
    font-size: ${theme.fontsize.desktop.m};
    font-weight: ${theme.weight.semibold};
    max-width: ${hasIcon ? 'calc(100% - 50px)' : 'calc(100% - 24px)'};
    overflow: hidden;

    ${withPlaceholder
      ? css`
          position: relative;
          margin-bottom: ${theme.spacing.xxs};
        `
      : css`
          position: absolute;
          top: ${theme.spacing.xxs};
          left: ${iconPosition === 'left' ? '44px' : theme.spacing.xxs};
          padding: 0 ${theme.spacing.xxs};
        `};

    text-overflow: ellipsis;
    white-space: nowrap;
    transition: top ${theme.animation.duration.medium} ease-in,
      left ${theme.animation.duration.medium} ease-in,
      font-size ${theme.animation.duration.medium} ease-in;

    ${isRequired &&
    css`
      ${withPlaceholder
        ? css`
            &::after {
              content: '*';
              color: ${theme.color.main['m-01']};
              float: none;
            }
          `
        : css`
            &::before {
              content: '*';
              color: ${theme.color.main['m-01']};
              float: right;
            }
          `}
    `}
  `}
`;

export const ErrorMessage = styled.div`
  ${({ theme }) => css`
    color: ${theme.color.extend['m-01--light-01']};
    font-size: ${theme.fontsize.desktop.s1};
    font-weight: ${theme.weight.regular};
  `}
`;

export const HelpText = styled.span`
  ${({ theme }) => css`
    color: #767676; // this color doesn't have in the design tokens, but we adopt it for contrast accessibility
    font-size: ${theme.fontsize.desktop.s1};
    font-weight: ${theme.weight.semibold};
  `}
`;

export const WrapperIcon = styled.span<IconProps>`
  ${({ theme, iconPosition, hasError, withPlaceholder }) => css`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    right: 0;
    margin: 5px 10px;
    text-align: center;

    ${withPlaceholder &&
    css`
      top: 3rem;
    `}

    ${iconPosition === 'left' &&
    css`
      left: 0;
    `}

    ${!!hasError &&
    css`
      svg {
        color: ${theme.color.extend['m-01--light-01']};
      }
    `}
  `}
`;

export const ContainerHelpers = styled.div`
  position: absolute;
  margin-top: -1rem;
`;

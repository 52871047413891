import { Block, Button, Heading, Svg, Text } from '@worten-sardines/grill-ui';
import { WortenSeller } from '@worten-sardines/seller-center-ui-shared';
import { useSession } from '@worten-sardines/shared/hooks';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { withErrorBoundary, ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundary as ErrorBoundaryPage } from '../ErrorBoundary';

import messages from './messages';

import {
  CardNotFound,
  ContentNotFound,
  DividerLineNotFound,
  HeadNotFound,
  StyledNotFound,
} from './styled';

export type NotFoundProps = {
  children?: React.ReactNode;
};

const links = [
  {
    label: 'Reports',
    href: '/reports',
    target: '_self',
  },
  {
    label: 'Seller Academy',
    href: 'https://selleracademy.worten.com/?utm_source=seller-center&utm_medium=reports',
    target: '_blank',
  },
  {
    label: 'Mirakl',
    href: 'https://marketplace.worten.pt/?utm_source=seller-center&utm_medium=reports',
    target: '_blank',
  },
];

export const NotFound = () => {
  const intl = useIntl();
  const {
    data: { isAuthenticated },
  } = useSession();

  if (isAuthenticated === false) {
    window.location.href = '/login';

    return null;
  }

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <ErrorBoundaryPage
          error={error}
          resetErrorBoundary={resetErrorBoundary}
        />
      )}
    >
      <StyledNotFound>
        <CardNotFound>
          <HeadNotFound>
            <NavLink to="/" end>
              <Svg svg={<WortenSeller />} width={107} title="Worten Seller" />
            </NavLink>
            <Heading as="h3" className="title">
              Ooops!
            </Heading>
            <Text fontSize="1.4rem" fontWeight="semibold" className="subtitle">
              {intl.formatMessage(messages.titleNotFound)}
            </Text>
          </HeadNotFound>

          <ContentNotFound>
            <Text fontSize="1.2rem" className="message">
              {intl.formatMessage(messages.subtitleNotFound)}
            </Text>
            <DividerLineNotFound />
            <Block>
              {links.map((link, index) => (
                <Button
                  key={index}
                  as="a"
                  variation="tertiary"
                  size="small"
                  href={link.href}
                  target={link.target}
                >
                  {link.label}
                </Button>
              ))}
            </Block>
          </ContentNotFound>
          <Text fontSize="1.2rem" className="errorText">
            {intl.formatMessage(messages.errorMessageNotFound)}
          </Text>
        </CardNotFound>
      </StyledNotFound>
    </ErrorBoundary>
  );
};

export default withErrorBoundary(NotFound, {
  FallbackComponent: ({ error, resetErrorBoundary }) => (
    <ErrorBoundaryPage error={error} resetErrorBoundary={resetErrorBoundary} />
  ),
});

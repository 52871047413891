import { getUserInfo } from '@worten-sardines/seller-center/data-access';
import { useEffect, useState } from 'react';
import { useFetch } from '../../shared-use-fetch';
import { useSearchShop } from '../../shared-use-search-shop/useSearchShop';
/**w
 * useSession
 *
 * Custom Session hook.
 *
 * @returns {sessionData}
 */

export type UserShop = {
  id: string;
  name: string;
  tier?: string;
  channels?: string[];
  isPremium?: boolean;
};

type UserType = {
  type: 'seller' | 'operator';
};

export type User = {
  email?: string;
  shop?: UserShop;
  user?: UserType;
  type: 'seller' | 'operator' | null | undefined;
  isAuthenticated?: boolean;
};

export type UserSessionType = {
  loading: boolean;
  data: User;
};

export function useSession(): UserSessionType {
  const [init, setInit] = useState<boolean>();
  const { data: user, loading } = useFetch(getUserInfo().key);
  const [shopId, setShopId] = useState(user?.shop?.id);
  const { shopInfo, error } = useSearchShop(shopId);

  useEffect(() => {
    if (error) {
      //hack to stop calls.
      setShopId('');
    }
  }, [error]);

  useEffect(() => {
    if (!user) {
      setInit(false);
    }
  }, [loading, user]);

  if (user?.email) {
    return {
      loading,
      data: {
        ...user,
        shop: {
          ...user.shop,
          tier: shopInfo?.tier,
          channels: shopInfo?.channels,
          isPremium: shopInfo?.isPremium,
        },
        type: user?.user?.type || 'seller',
        isAuthenticated: true,
      },
    };
  }

  return { loading, data: { isAuthenticated: init, type: null } };
}

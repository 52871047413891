import { Theme as theme } from '@worten-sardines/grill-ui';
import styled, { css } from 'styled-components';

type ButtonRowProps = {
  primary?: boolean;
};

export const StyledButtonRow = styled.button<ButtonRowProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  color: ${theme.color.neutral['neu-08']};
  font-weight: 400;
  font-size: ${theme.fontsize.desktop.s1};

  ${({ primary }) =>
    primary &&
    css`
      padding-left: 1.6rem;
      justify-content: left;
      font-weight: 600;
    `}

  svg {
    margin-right: 0.4rem;
    overflow: visible;
  }
`;

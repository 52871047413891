import { Buffer } from 'buffer';
import { useEffect, useState } from 'react';
import toast from '@worten-sardines/grill-ui';
import { getContentCms } from '@worten-sardines/seller-center/data-access';
import { CMS_PREVIEW_TOKEN } from '@worten-sardines/configs/config-seller-center';
import { useFetch } from '../shared-use-fetch/index';

const PATHS = {
  CAMPAIGN_PAGE: '/campaigns',
  SERVICES_PAGE: '/services',
  HYBRID_PAGE: '/hybrid-page',
};

const checkPathname = (pathname = '', path: string) =>
  pathname && pathname === path;

const isCampaign = (pathname: string) =>
  checkPathname(pathname, PATHS.CAMPAIGN_PAGE);

const isServices = (pathname: string) => {
  return checkPathname(pathname, `${PATHS.SERVICES_PAGE}`);
};

const isServicesPost = (pathname: string, slug?: string) => {
  const params = slug ? `/${slug}` : '';
  return checkPathname(pathname, `${PATHS.SERVICES_PAGE}${params}`);
};

const isHybridPage = (pathname: string, slug?: string) => {
  const params = slug ? `/${slug}` : '';
  return checkPathname(pathname, `${PATHS.HYBRID_PAGE}${params}`);
};

export function getSchemaFromPathname(pathname: string, slug?: string) {
  const sanitizePathname = pathname.replace(/\//g, '');
  let schema = null;

  // Single instance schemas do not support 'slug' as query parameter.
  // To prevent API errors, allow slug just for valid schemas.
  let allowSlug = false;

  switch (true) {
    case isCampaign(pathname):
      schema = 'campaign';
      allowSlug = false;
      break;
    case isServices(pathname):
      schema = 'services';
      allowSlug = false;
      break;
    case isServicesPost(pathname, slug):
      schema = 'services-post';
      allowSlug = true;
      break;
    case isHybridPage(pathname, slug):
      schema = 'hybrid-page';
      allowSlug = true;
      break;
    default:
      schema = sanitizePathname;
      allowSlug = true;
      break;
  }
  return { schema, allowSlug };
}

const getEncodedPreviewToken = () =>
  Buffer.from(CMS_PREVIEW_TOKEN || '').toString('base64');

const checkPreviewMode = (preview: string | null | undefined) =>
  preview === getEncodedPreviewToken();

export function getContent(
  schemaName = '',
  slug?: string | null,
  preview?: string | null,
  locale?: string,
) {
  const isPreviewMode = checkPreviewMode(preview);

  let query = null;
  const currentLocale = locale;

  query = {
    ...(slug ? { slug: slug } : {}),
    ...(isPreviewMode ? { previewContent: preview } : {}),
  };

  const [doFetch, setDoFetch] = useState(false);
  const { data, loading, error } = useFetch(
    getContentCms(schemaName).key,
    doFetch,
    {
      query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': currentLocale,
      },
    },
    undefined,
    ({ key, error }) => {
      if (!(key === getContentCms(schemaName).key && error.status === 404)) {
        toast.error(error.message);
      }
    },
  );

  useEffect(() => {
    if (slug) {
      query = { slug };
    }

    setDoFetch(true);
  }, []);

  return { data, loading, error };
}

import { TimeLine } from '../types';

export const getTotalDaysUntilToday = (
  arrayOfDates: TimeLine[],
  currentMonth: string,
) => {
  let totalDays = 0;

  for (let month = 0; month < arrayOfDates.length; month++) {
    for (const item of arrayOfDates[month].weeks) {
      totalDays += 1;
      if (item.isToday) break;
    }
    if (arrayOfDates[month].month === currentMonth) break;
  }

  return totalDays;
};

export const getTimelineWidth = (
  totalDays: number,
  itemWidth: number,
  gap: number,
) => {
  const days = gap === 0 ? totalDays - 1 : totalDays - (gap + 1);

  return days * itemWidth;
};

import styled, { css } from 'styled-components';
import { Theme as theme } from '@worten-sardines/grill-ui';

type EnhancedWrapperProps = {
  responsiveImages: boolean;
  position?: number | null;
};

export const EnhancedWrapper = styled.section<EnhancedWrapperProps>`
  ${({ responsiveImages, position }) => css`
    max-width: 100%;
    height: auto;
    object-fit: contain;
    margin-bottom: ${theme.spacing.xs};
    grid-row: ${position};

    ${responsiveImages &&
    css`
      img {
        max-width: 100%;
        height: auto;
        object-fit: contain;
      }
    `}
  `}
`;

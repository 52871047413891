import { useCallback, useEffect, useMemo, useState } from 'react';
import { PageNumber, Ellipsis, Arrow, PageNumberWrapper } from './styled';
import { generatePageNumbers, validatePaginationProps } from './helpers';
import { ChevronIcon } from '../assets/images';
import Svg from '../Svg';
import Flex from '../Flex';

type PaginationProps = {
  totalPages: number;
  currentPage: number;
  onPageChange?: (pageNumber: number) => void;
};

export const Pagination = ({
  totalPages,
  currentPage,
  onPageChange,
}: PaginationProps) => {
  const [activePage, setActivePage] = useState<number>(currentPage);

  const allPageNumbers = useMemo(
    () => generatePageNumbers(currentPage, totalPages),
    [currentPage, totalPages],
  );

  const [pageNumbersState, setPageNumbersState] =
    useState<number[]>(allPageNumbers);

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      if (
        pageNumber !== activePage &&
        pageNumber > 0 &&
        pageNumber <= totalPages
      ) {
        setActivePage(pageNumber);
        setPageNumbersState(generatePageNumbers(pageNumber, totalPages));
        onPageChange?.(pageNumber);
      }
    },
    [activePage, onPageChange, setPageNumbersState, totalPages],
  );

  useEffect(() => {
    const { current, total } = validatePaginationProps(currentPage, totalPages);

    setActivePage(current);
    setPageNumbersState(generatePageNumbers(current, total));
  }, [currentPage, totalPages]);

  if (totalPages < 1) return null;

  return (
    <Flex>
      <Arrow
        aria-label="arrow-pagination-previous-page"
        onClick={() => handlePageChange(activePage - 1)}
        disabled={activePage === 1}
      >
        <Svg
          title={`arrow-pagination-left-icon`}
          svg={<ChevronIcon />}
          width={16}
          height={16}
        />
      </Arrow>

      <PageNumberWrapper>
        {pageNumbersState.map((number: number, index: number) => {
          if (number === 0) {
            return <Ellipsis key={`ellipsis-${index}`}>{'...'}</Ellipsis>;
          }
          return (
            <PageNumber
              key={`page-${number}-${index}`}
              active={number === activePage}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </PageNumber>
          );
        })}
      </PageNumberWrapper>

      <Arrow
        aria-label="arrow-pagination-next-page"
        onClick={() => handlePageChange(activePage + 1)}
        disabled={activePage === totalPages}
      >
        <Svg
          title={`arrow-pagination-right-icon`}
          svg={<ChevronIcon />}
          width={16}
          height={16}
        />
      </Arrow>
    </Flex>
  );
};

export default Pagination;

import Carousel, { EventObject, Props } from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { WrapperHeroSlider } from './styled';
import ArrowButton from '../ArrowButton';
import { useEffect, useState } from 'react';

export type CustomDotProps = { dot: number; color: string };

interface HeroSliderProps extends Props {
  borderColor?: string;
  borderRadius?: number;
  controlsStrategy?: 'default' | 'responsive' | 'alternate';
  dotActiveColor?: string;
  dotColor?: string;
  dotColors?: CustomDotProps[];
  enableBorder?: boolean;
  heroHeight?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items?: any[];
  mouseTracking?: boolean;
  children?: unknown;
}

const DEFAULT_COLOR_DOT_ITEM = '#ffffff';
const DEFAULT_COLOR_ACTIVE_DOT_ITEM = '#141414';

export const HeroSlider = ({
  borderColor,
  borderRadius = 0,
  controlsStrategy = 'responsive',
  dotActiveColor = DEFAULT_COLOR_ACTIVE_DOT_ITEM,
  dotColor = DEFAULT_COLOR_DOT_ITEM,
  dotColors,
  enableBorder,
  heroHeight,
  items,
  mouseTracking = true,
  children,
  ...rest
}: HeroSliderProps) => {
  const [currentItem, setCurrentItem] = useState<number>(1);
  const [dotCustomActiveColor, setDotCustomActiveColor] =
    useState<string>(dotActiveColor);

  useEffect(() => {
    if (dotColors?.length) {
      const index = dotColors.findIndex((item) => item.dot === currentItem);
      if (index !== -1) return setDotCustomActiveColor(dotColors[index].color);
      setDotCustomActiveColor(dotActiveColor);
    }
  }, [currentItem, dotActiveColor, dotColors]);

  const renderCustomDotsItem = () => {
    return <div />;
  };

  const renderCustomPrevButton = (isDisabled: boolean | undefined) => (
    <ArrowButton direction="left" disabled={isDisabled} />
  );

  const renderCustomNextButton = (isDisabled: boolean | undefined) => (
    <ArrowButton direction="right" disabled={isDisabled} />
  );

  const onSlideChanged = ({ item }: EventObject) => {
    setCurrentItem(item + 1);
  };

  return (items && items.length) || children ? (
    <WrapperHeroSlider
      dotColor={dotColor}
      dotActiveColor={dotCustomActiveColor}
      enableBorder={enableBorder}
      borderColor={borderColor}
      borderRadius={borderRadius}
      heroHeight={heroHeight}
    >
      <Carousel
        items={items}
        controlsStrategy={controlsStrategy}
        mouseTracking={mouseTracking}
        onSlideChanged={onSlideChanged}
        renderPrevButton={({ isDisabled }) =>
          renderCustomPrevButton(isDisabled)
        }
        renderNextButton={({ isDisabled }) =>
          renderCustomNextButton(isDisabled)
        }
        renderDotsItem={renderCustomDotsItem}
        {...rest}
      >
        {children}
      </Carousel>
    </WrapperHeroSlider>
  ) : null;
};

export default HeroSlider;

import { Theme, makeWidth } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

type DropdownContentProps = {
  show: boolean;
};

export const StyledDropdownWrapper = styled.div`
  position: relative;
  height: 100%;
  z-index: 1;
`;

export const StyledDropdownContent = styled.div<DropdownContentProps>`
  position: absolute;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
  visibility: hidden;
  bottom: 0;
  background-color: ${Theme.color.neutral.neu01};
  width: fit-content;
  box-shadow: ${Theme.level2};
  border: 1px solid ${Theme.color.main['m-01']};
  border-top: ${Theme.color.neutral.neu01};
  border-radius: 0 0 ${Theme.Radius.xxs} ${Theme.Radius.xxs};
  padding: ${Theme.spacing.xxs} 0 ${Theme.spacing.xxs};
  transition: all ${Theme.animation.duration.medium};
  z-index: -1;

  transform: translateY(0);
`;

export const StyledDropdownMenu = styled.div`
  position: relative;
  display: grid;
  height: 100%;
  place-items: center;

  &:hover {
    ${StyledDropdownContent} {
      transform: translateY(100%);
      visibility: visible;
    }
  }

  ${makeWidth}
`;

import { defineMessages } from 'react-intl';

export default defineMessages({
  reports: {
    id: 'apps.seller-center.app.reports',
    defaultMessage: 'Reports',
  },
  campaigns: {
    id: 'apps.seller-center.app.campaigns',
    defaultMessage: 'Campaigns',
  },
  payouts: {
    id: 'apps.seller-center.app.payouts',
    defaultMessage: 'Payouts',
  },
  services: {
    id: 'apps.seller-center.app.services',
    defaultMessage: 'Services',
  },
  sellerPremium: {
    id: 'apps.seller-center.app.sellerPremium',
    defaultMessage: 'Seller Premium',
  },
  logout: {
    id: 'apps.seller-center.app.logout',
    defaultMessage: 'Logout',
  },
});

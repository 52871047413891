import { Theme } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

export const StyledMenuList = styled.nav`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 100%;
  gap: ${Theme.spacing.md};

  & .active > * {
    color: ${Theme.color.main['m-01']};
  }
`;

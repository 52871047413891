import { Button, Theme } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  height: 100%;
  background-color: ${Theme.color.neutral.neu01};
  span {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

import { HR, StyledFooter, StyledPaper } from './styled';

export type PaperProps = {
  children?: React.ReactNode;
};

const Header = ({ children }: PaperProps) => {
  return <div>{children}</div>;
};
const Footer = ({ children }: PaperProps) => {
  return (
    <StyledFooter>
      <HR />
      {children}
    </StyledFooter>
  );
};

export const Paper = ({ children }: PaperProps) => {
  return <StyledPaper>{children}</StyledPaper>;
};

Paper.Header = Header;
Paper.Footer = Footer;

export default Paper;

import { Flex, transitionTimingCurve } from '@worten-sardines/grill-ui';
import styled, { css, DefaultTheme } from 'styled-components';
import { LinkCardProps } from '.';

export type Props = Pick<LinkCardProps, 'variation' | 'disabled'>;

const renderTypeColor = (theme: DefaultTheme) => {
  return {
    warning: theme.color.aditional['add-05'],
    interactive: theme.color.aditional['Add-02'],
    error: theme.color.main['m-01'],
  };
};

export const StyledLinkCard = styled(Flex)<Props>`
  ${({ theme, variation, disabled }) => css`
    width: 100%;
    background: ${theme.color.neutral.neu01};
    overflow: hidden;
    padding: ${theme.spacing.xxs} ${theme.spacing.xs};
    position: relative;
    line-height: ${theme.lineheight.desktop['l']};
    border-bottom: 1px solid ${theme.color.neutral['neu-02']};
    transition: all ${theme.animation.duration.medium};
    ${disabled &&
    css`
      opacity: ${theme.opacity.low};
      cursor: not-allowed;
      pointer-events: all;
    `}

    svg {
      color: ${variation && variation in renderTypeColor(theme)
        ? renderTypeColor(theme)[variation]
        : theme.color.neutral['neu-08']};
      margin: 0;
      margin-right: 5px;
      width: 28px;
    }

    .icon-arrow {
      transition: all ${theme.animation.duration.fast};
      color: ${theme.color.neutral['neu-04']};
      margin-right: 0;
    }

    &::after {
      content: '';
      position: absolute;
      background: ${variation && variation in renderTypeColor(theme)
        ? renderTypeColor(theme)[variation]
        : theme.color.main['m-01']};
      height: 0;
      left: 0;
      top: 0;
      transition: all ${theme.animation.duration.fast};
      width: 1px;
    }

    ${!disabled &&
    css`
      :hover,
      :focus,
      :focus-within {
        box-shadow: ${theme.level2};
        z-index: 1;
        &::after {
          height: 100%;
        }
        .icon-arrow {
          color: ${variation && variation in renderTypeColor(theme)
            ? renderTypeColor(theme)[variation]
            : theme.color.main['m-01']};
          margin-right: -${theme.spacing.xxs};
        }

        .title-card {
          ::after {
            content: '';
            position: absolute;
            background-color: ${theme.color.main['m-01']};
            width: 0;
          }
        }
      }
    `}

    ${transitionTimingCurve(theme.animation.curve.productive)};
  `}
`;

export const Title = styled.span<Props>`
  ${({ theme, variation }) => css`
    font-weight: ${theme.weight.regular};
    font-size: ${theme.fontsize.desktop.s2};
    color: ${theme.color.neutral['neu-08']};
    color: ${variation && variation in renderTypeColor(theme)
      ? renderTypeColor(theme)[variation]
      : theme.color.neutral['neu-08']};
    width: 100%;
    text-align: left;
  `}
`;

import React, { KeyboardEvent, memo, useRef, useState } from 'react';
import ErrorMessageField from '../ErrorMessageField';
import { HelpText, Label } from '../Input/styled';
import { useOnClickOutside } from '../utils/hooks';
import SelectInput from './SelectInput';
import { SelectContainer } from './styled';
import { SimpleSelect as SimpleSelectType } from './types';

export const SimpleSelect = ({
  options,
  name,
  isRequired = false,
  label,
  disabled = false,
  error = {},
  helpText,
  register,
  validationSchema,
  isEmptyOption = false,
  handleOnChange,
  ...props
}: SimpleSelectType) => {
  const inputContainerRef =
    useRef() as React.MutableRefObject<HTMLInputElement>;

  const hasError = !!error[name as string];

  useOnClickOutside(inputContainerRef, () => setOpen(false));

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleOpen();
    }
  };

  return (
    <SelectContainer
      role="combobox"
      aria-haspopup="listbox"
      aria-owns="custom-select-list"
      ref={inputContainerRef}
      disabled={disabled}
      isVisible={open}
      onKeyPress={handleKeyPress}
      aria-expanded={open}
    >
      <SelectInput
        options={options}
        name={name}
        isOpen={open}
        disabled={disabled}
        isRequired={isRequired}
        label={label}
        hasError={hasError}
        handleOpen={handleOpen}
        handleOnChange={handleOnChange}
        handleClose={handleClose}
        isEmptyOption={isEmptyOption}
        register={register}
        {...props}
      />

      <Label
        htmlFor={name}
        className="label"
        disabled={disabled}
        isRequired={!!validationSchema?.['required'] || isRequired}
      >
        {label}
      </Label>

      {hasError && (
        <ErrorMessageField message={error[name as string]?.message} />
      )}

      {Object.keys(error).length === 0 && helpText && (
        <HelpText>{helpText}</HelpText>
      )}
    </SelectContainer>
  );
};

export default memo(SimpleSelect);

import { addMonths, getDate, set } from 'date-fns';

/**
 * Calculates the next assessment date based on the given date.
 * If no date is provided, the current date is used.
 *
 * @param date - The date to calculate the next assessment date from.
 * @returns The next assessment date.
 */
export const nextAssessmentDate = (date?: Date) => {
  const today = date || new Date();
  const day = getDate(today);

  if (day > 20) return set(addMonths(today, 1), { date: 5 });

  if (day < 5) return set(today, { date: 5 });

  return set(today, { date: day + 1 });
};

export default nextAssessmentDate;

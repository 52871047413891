import { AuthenticationHocFeature as withAuth } from '@worten-sardines/authentication-feature-hoc';
import { ErrorBoundary } from '@worten-sardines/seller-center-feature';
import { UserSessionType, useSession } from '@worten-sardines/shared/hooks';
import { withErrorBoundary } from 'react-error-boundary';
import { injectIntl, IntlShape } from 'react-intl';
import type { Location, Params } from 'react-router-dom';
import { Navigate, useLocation, useParams } from 'react-router-dom';

type Props = {
  component: React.ComponentType;
  aclPermissions?: [];
  intl: IntlShape;
  [rest: string]: unknown;
};

type MatchProps = {
  location: Location;
  params: Params;
  path: string;
};

export type ProtectedProps = {
  match: MatchProps;
  user: UserSessionType;
  intl: IntlShape;
  [rest: string]: unknown;
};

const ProtectedRoute = ({
  component: Component,
  aclPermissions = [],
  intl,
  ...rest
}: Props) => {
  const getRoutePath = (location: Location, params: Params): string => {
    const { pathname } = location;

    if (!Object.keys(params).length) {
      return pathname; // we don't need to replace anything
    }

    let path = pathname;
    Object.entries(params).forEach(([paramName, paramValue]) => {
      if (paramValue) {
        path = path.replace(paramValue, `:${paramName}`);
      }
    });
    return path;
  };

  const location = useLocation();
  const params = useParams();
  const path = getRoutePath(location, params);

  const match: MatchProps = { location, params, path };

  const { data, loading } = useSession();

  if (!Component) {
    return <Navigate to="/not-found" replace />;
  }

  const ProtectedComponent = withAuth(Component, aclPermissions);

  return (
    <ProtectedComponent
      intl={intl}
      match={match}
      user={{ data, loading }}
      {...rest}
    />
  );
};

export default injectIntl(
  withErrorBoundary(ProtectedRoute, {
    FallbackComponent: ({ error, resetErrorBoundary }) => (
      <ErrorBoundary error={error} resetErrorBoundary={resetErrorBoundary} />
    ),
    onError(error, info) {
      // Do something with the error
      // E.g. log to an error logging client here
    },
  }),
);

import styled from 'styled-components';
import { makeResponsive, makeSpacing } from '../utils';
import { EmojiProps } from '.';

type Emoji = Pick<EmojiProps, 'size'>;

export const StyledEmoji = styled.span<Emoji>`
  font-size: ${({ size, theme }) => size || theme.fontsize.desktop.l};

  ${makeResponsive}
  ${makeSpacing}
`;

import { useIntl } from 'react-intl';
import { Button } from '@worten-sardines/grill-ui';

import messages from './messages';

import { StyledText, StyledTemplateLinkWrapper } from './styled';

export const CampaignSubmissionTemplateFile = () => {
  const intl = useIntl();
  return (
    <StyledTemplateLinkWrapper>
      <StyledText>{intl.formatMessage(messages.title)}</StyledText>
      <Button
        link
        as="a"
        href="https://worten.pt/i/74fb433e01685643d501db4d134e6187733dfefb"
      >
        {intl.formatMessage(messages.downloadLink)}
      </Button>
    </StyledTemplateLinkWrapper>
  );
};

export default CampaignSubmissionTemplateFile;

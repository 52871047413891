// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFromSharedLocalStorageHelper = (name: string) =>
  localStorage.getItem(name);

export const setOnSharedLocalStorageHelper = (name: string, value: string) => {
  localStorage.setItem(name, value);
};

export function removeSharedLocalStorageHelper(name: string) {
  localStorage.removeItem(name);
}

export const getContentCms = (schemaName: string) => {
  switch (schemaName) {
    case 'campaign':
      return {
        key: '/cms/v1/content/campaigns',
      };
    case 'services':
      return {
        key: '/cms/v1/content/services',
      };
    case 'services-post':
      return {
        key: '/cms/v1/content/services-post',
      };
    case 'hybrid-page':
      return {
        key: '/cms/v1/content/hybrid-page',
      };
    default:
      return {
        key: `/cms/v1/content/custom-page`,
      };
  }
};

export default { getContentCms };

import {
  CMSPage,
  NotFound,
  Oauth,
  PayoutsPage,
  SellerCenterFeatureLogin,
  SellerCenterFeatureSellerAnalytics,
  SellerPremium,
  SellerPremiumPBI,
} from '@worten-sardines/seller-center-feature';
import { SEO } from '@worten-sardines/shared-ui';
import { useSession } from '@worten-sardines/shared/hooks';
import { REGISTER_DATALAYER } from '@worten-sardines/trackings/tracking-seller-center';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import App from '../app/app';
import ProtectedRoute from '../app/protected-route';
import messages from './messages';
import { FEATURE } from '@worten-sardines/configs/config-seller-center';

const SEORoute = ({ disableAnalytics, title, analyticsPageType }) => {
  const intl = useIntl();
  const LOCALE = useIntl().locale;

  const { data: userData } = useSession() || {};

  if (!disableAnalytics && analyticsPageType) {
    REGISTER_DATALAYER('VIRTUAL_PAGEVIEW', {
      pageType: analyticsPageType,
      userType: userData.user?.type,
      userShopId: userData.shop?.id,
      userShopName: userData.shop?.name,
      userShopTier: userData.shop?.tier,
    });
  }

  return (
    <SEO title={`Seller Center - ${intl.formatMessage(title)}`}>
      <script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-culture={`${LOCALE}`}
        data-cbid="b9c0051d-839c-4cb4-8505-e6eb06e16f7f"
        data-blockingmode="auto"
        type="text/javascript"
      ></script>
    </SEO>
  );
};

export default [
  {
    path: '/',
    element: (
      <>
        <SEO title="Seller Center" />
        <ProtectedRoute component={App} />
      </>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="reports" replace />,
      },
      {
        path: 'reports',
        element: (
          <>
            <SEORoute
              title={messages.reports}
              pageType="reports"
              disableAnalytics
            />
            <SellerCenterFeatureSellerAnalytics />
          </>
        ),
      },
      {
        path: 'campaigns',
        element: (
          <>
            <SEORoute
              title={messages.campaigns}
              analyticsPageType="campaigns"
              disableAnalytics
            />
            <CMSPage />
          </>
        ),
      },
      {
        path: 'services/*',
        element: (
          <>
            <SEORoute
              title={messages.services}
              analyticsPageType="services"
              disableAnalytics
            />
            <CMSPage />
          </>
        ),
      },
      {
        path: 'payouts',
        element: (
          <>
            <SEORoute title={messages.payouts} analyticsPageType="payouts" />
            <PayoutsPage />
          </>
        ),
      },
      {
        path: 'seller-premium',
        element: (
          <>
            <SEORoute
              title={messages.sellerPremium}
              analyticsPageType="seller-premium"
            />
            {FEATURE.showMenuSellerPremium ? (
              <SellerPremium />
            ) : (
              <SellerPremiumPBI />
            )}
          </>
        ),
      },
      {
        path: '*',
        element: <CMSPage />,
      },
      {
        path: '*',
        element: (
          <>
            <SEORoute title={messages.notFound} analyticsPageType="not-found" />
            <Navigate to="not-found" replace />
          </>
        ),
      },
    ],
  },
  {
    path: '/*',
    element: (
      <>
        <SEORoute title={messages.notFound} analyticsPageType="not-found" />
        <Navigate to="not-found" replace />
      </>
    ),
  },
  {
    path: 'not-found',
    element: (
      <>
        <SEORoute title={messages.notFound} analyticsPageType="not-found" />
        <NotFound />
      </>
    ),
  },
  {
    path: 'login',
    element: (
      <>
        <SEORoute title={messages.login} disableAnalytics />
        <SellerCenterFeatureLogin />
      </>
    ),
  },
  {
    path: 'oauth',
    element: (
      <>
        <SEORoute title={messages.login} />
        <Oauth />
      </>
    ),
  },
];

import styled from 'styled-components';
import { Theme as theme } from '@worten-sardines/grill-ui';

export const WrapperHTML = styled.div`
  border: 1px solid ${theme.color.neutral['neu-03']};
  border-radius: ${theme.Radius.xxs};
  background-color: ${theme.color.neutral.neu01};
  font-size: ${theme.fontsize.desktop.m};
  font-weight: ${theme.weight.semibold};
  color: ${theme.color.neutral['neu-08']};

  padding: ${theme.spacing.xs};
  margin: ${theme.spacing.xs};

  p {
    margin: 0;
    padding: 0;
  }
`;

export const Content = styled.section`
  display: grid;
  height: calc(100vh - 125px);
  overflow: hidden;
  overflow-y: auto;
  grid-template-columns: 100%;
  align-content: flex-start;
`;

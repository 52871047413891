import { useEffect, useMemo, useState } from 'react';
import {
  ColumnDef,
  Row,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Svg } from '@worten-sardines/grill-ui';
import { SwapVertical } from '@worten-sardines/seller-center-ui-shared';
import { Section, WrapperTable } from './styled';
import { CampaignsArgs, DataTableType, Sorting } from '../../../types';

export type TableData = {
  dataTable: DataTableType;
  summary?: string;
  currentCampaign?: CampaignsArgs | null;
  onSortingChange?: (e: Sorting) => void;
};

const Table = ({
  dataTable,
  summary,
  currentCampaign,
  onSortingChange,
}: TableData) => {
  const [rowSelection, setRowSelection] = useState({});
  const [data, setData] = useState<DataTableType['data']>([]);

  useEffect(() => {
    setData(dataTable.data);
  }, [dataTable.data]);

  const columns = useMemo<ColumnDef<unknown>[]>(() => {
    return dataTable.header.map((col) => ({
      accessorKey: col.id,
      header: col.cell,
      cell: (info) => info.getValue(),
    }));
  }, [dataTable.header]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Section>
      <WrapperTable summary={summary}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  scope="col"
                  key={header.id}
                  onClick={() => {
                    onSortingChange &&
                      onSortingChange({
                        id: header.getContext().header.id,
                        sortBy: header.column.getIsSorted(),
                      });
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className:
                          header.column.getCanSort() && onSortingChange
                            ? 'cursor-pointer select-none'
                            : '',
                        onClick:
                          onSortingChange &&
                          header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {onSortingChange && (
                        <div className="icon">
                          <Svg width={18} svg={<SwapVertical />} title="Sort" />
                        </div>
                      )}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            table.getRowModel().rows.map((row: Row<any>) => (
              <tr
                key={row.id}
                onClick={() => {
                  setRowSelection(row.id);
                }}
                className={
                  rowSelection === row.id ||
                  currentCampaign?.name === row.original?._data?.id
                    ? 'activated'
                    : ''
                }
              >
                {row.getVisibleCells().map((cell, index) =>
                  index !== 0 ? (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ) : (
                    <th scope="row" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </th>
                  ),
                )}
              </tr>
            ))
          }
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </WrapperTable>
    </Section>
  );
};

export default Table;

import styled, { css } from 'styled-components';

export const StyledLinksList = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.size.xs};
    border-left: 1px solid ${theme.color.neutral['neu-02']};
  `}
`;

export const StyledLinksListLinks = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.Radius.xxs};
  `}
  overflow: hidden;
  > div {
    &:not(:last-child) {
      ${({ theme }) => css`
        border-bottom: 8px solid ${theme.color.neutral['neu-02']};
      `}
    }
  }
`;

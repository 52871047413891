import { Block } from '../Block';
import Svg from '../Svg';
import { StyledHighlightImage } from './styled';

type HighlightImage = {
  highlightImageURL: string | JSX.Element;
  highlightImageAlt?: string;
};

const IMAGE_WIDTH = 210;
const IMAGE_HEIGHT = 210;

export const HighlightImage = ({
  highlightImageURL,
  highlightImageAlt = '',
}: HighlightImage) => {
  if (!highlightImageURL) return null;

  return (
    <Block
      width={IMAGE_WIDTH}
      height={IMAGE_HEIGHT}
      responsive={{
        marginBottom: { xs: '3.2rem', md: '0' },
        marginRight: { xs: '0', md: '3.2rem' },
      }}
    >
      {typeof highlightImageURL === 'object' ? (
        <Svg
          svg={highlightImageURL}
          title={highlightImageAlt}
          width={IMAGE_WIDTH}
        />
      ) : (
        <StyledHighlightImage
          src={highlightImageURL}
          alt={highlightImageAlt}
          loading="lazy"
        />
      )}
    </Block>
  );
};

export default HighlightImage;

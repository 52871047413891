import { defineMessages } from 'react-intl';

export default defineMessages({
  inputLabel: {
    id: 'apps.seller-center.feature.popupShopId.inputLabel',
    defaultMessage: 'Shop ID',
  },
  instruction: {
    id: 'apps.seller-center.feature.popupShopId.instruction',
    defaultMessage: 'To access this menu, insert the shop id.',
  },
  searchButton: {
    id: 'apps.seller-center.feature.popupShopId.searchButton',
    defaultMessage: 'Search',
  },
  noResult: {
    id: 'apps.seller-center.feature.popupShopId.noResult',
    defaultMessage: 'No results found.',
  },
});

import { useCallback } from 'react';
import { useMutate } from '../../shared-use-mutate';
import {
  logout,
  getUserInfo,
} from '@worten-sardines/seller-center/data-access';

/**w
 * useLogout
 *
 * Custom logout hook.
 *
 * @returns [logoutFn]
 */
export function useLogout() {
  const [, logoutMutate] = useMutate(logout, [], false);

  const logoutFn = useCallback(() => {
    logoutMutate([[[getUserInfo().key]]]);
  }, [logoutMutate]);

  return logoutFn;
}

export default useLogout;

import { StyledButton, StyledListItem } from './styled';
import { Option } from './types';

type SelectItemProps = {
  option: Option;
  selectedValue?: string;
  handleChange: (option: Option) => void;
  id: string;
};

const SelectItem = ({
  option,
  handleChange,
  selectedValue,
  id,
}: SelectItemProps) => {
  const arialLabel = option?.label.length > 0 ? option?.label : 'empty option';

  return (
    <StyledListItem
      id={id}
      role="option"
      className="option"
      active={option?.value === selectedValue}
      value={option?.value}
      onClick={() => handleChange(option)}
    >
      <StyledButton role="listitem" aria-label={arialLabel}>
        {option?.label}
      </StyledButton>
    </StyledListItem>
  );
};

export default SelectItem;

import styled from 'styled-components';
import { Theme, Text, Flex, omitProps } from '@worten-sardines/grill-ui';

type StyledCMSSidebarWrapperProps = {
  position?: string;
};

export const StyledCMSSidebarWrapper = styled(Flex).withConfig({
  shouldForwardProp: (prop) => omitProps(['position'], prop),
})`
  flex-direction: ${({ position }: StyledCMSSidebarWrapperProps) =>
    position === 'top' ? 'column-reverse' : 'column'};
`;

export const StyledCustomCMSSidebarWrapper = styled.div`
  background: ${Theme.color.neutral.neu01};
  border-radius: ${Theme.Radius.xxs};
  padding: ${Theme.spacing.xs} ${Theme.spacing.none};
  margin-top: ${Theme.spacing.xs};
`;

export const StyledCMSSidebarDetails = styled.div`
  position: relative;
  padding-top: ${Theme.spacing.xxs};
  margin-top: ${Theme.spacing.xs};
  border-top: 1px solid ${Theme.color.neutral['neu-03']};
  transition: all ${Theme.animation.duration.fast}ms;
`;

export const StyledCMSSidebarTitle = styled(Text).attrs({
  as: 'h3',
})`
  margin: ${Theme.spacing.none};
  padding: ${Theme.spacing.none} ${Theme.spacing.xs};
`;

export const StyledCMSSidebarShortDescription = styled(Text).attrs({
  as: 'p',
})`
  margin: ${Theme.spacing.none} ${Theme.spacing.none} ${Theme.spacing.xs};
  padding: ${Theme.spacing.none} ${Theme.spacing.xs};
  color: ${Theme.color.neutral['neu-08']};
  font-size: ${Theme.fontsize.desktop.s1};
`;

export const CloseButton = styled.button`
  position: absolute;
  right: ${Theme.spacing.xs};
  transform: scale(0.5);

  &:hover {
    transform: scale(0.6);
  }
`;

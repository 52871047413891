import { EventHandler, PowerBIEmbed } from 'powerbi-client-react';
import { PbiIframeWrapper } from './styled';
import { models } from 'powerbi-client';

export type PbiIframeProps = {
  reportSettings: models.IReportEmbedConfiguration;
  eventHandlers?: Map<string, EventHandler>;
  fullPage?: boolean;
  customClass?: string;
};

export const PbiIframe = ({
  reportSettings,
  eventHandlers,
  fullPage = true,
  customClass = '',
}: PbiIframeProps) => {
  return (
    <PbiIframeWrapper fullPage={fullPage}>
      <PowerBIEmbed
        cssClassName={`${customClass} default-report-class`}
        embedConfig={reportSettings}
        eventHandlers={eventHandlers}
      />
    </PbiIframeWrapper>
  );
};

export default PbiIframe;

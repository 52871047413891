import { Badge, omitProps } from '@worten-sardines/grill-ui';
import styled, { DefaultTheme, css } from 'styled-components';

type NoticeType = {
  noticeType: string;
  theme: DefaultTheme;
};

const noticeStyleType = (theme: DefaultTheme) => ({
  newFeature: {
    color: theme.color.neutral['neu-11'],
    backgroundColor: theme.color.aditional['add-01'],
  },
});

export const StyledBadge = styled(Badge).withConfig({
  shouldForwardProp: (prop) => omitProps(['noticeType'], prop),
})`
  ${({ theme, noticeType }: NoticeType) => css`
    font-weight: ${theme.weight.bold};
    text-transform: uppercase;
    margin: 0 ${theme.spacing.xxs};

    ${noticeType &&
    noticeStyleType(theme)[noticeType as keyof typeof noticeStyleType]};
  `}
`;

import { useEffect } from 'react';
import { ActionType, dispatch, useStore } from '../core/store';
import { toast } from '../core/toast';
import { DefaultToastOptions } from '../types';

const updateHeight = (toastId: string, height: number) => {
  dispatch({
    type: ActionType.UPDATE_TOAST,
    toast: { id: toastId, height },
  });
};

export const useToaster = (toastOptions?: DefaultToastOptions) => {
  const { toasts } = useStore(toastOptions);

  useEffect(() => {
    const now = Date.now();
    const timeouts = toasts.map((t) => {
      if (t.duration === Infinity) {
        // eslint-disable-next-line array-callback-return
        return;
      }

      const durationLeft = (t.duration || 0) + (now - t.createdAt);

      if (durationLeft < 0) {
        if (t.visible) {
          toast.dismiss(t.id);
        }
        // eslint-disable-next-line array-callback-return
        return;
      }
      return setTimeout(() => toast.dismiss(t.id), durationLeft);
    });

    return () => {
      timeouts.forEach((timeout) => timeout && clearTimeout(timeout));
    };
  }, [toasts]);

  return {
    toasts,
    handlers: {
      updateHeight,
    },
  };
};

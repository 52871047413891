import Svg from '../Svg';
import { ChevronIcon } from '../assets/images';
import { StyledArrowButton } from './styled';

type DirectionType = 'left' | 'right' | 'up' | 'down';

export type backgroundModeType = 'default' | 'transparent';

export type ArrowButtonProps = {
  direction?: DirectionType;
  backgroundMode?: backgroundModeType;
  as?: React.ElementType;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ArrowButton = ({
  direction = 'right',
  backgroundMode = 'default',
  ...rest
}: ArrowButtonProps) => {
  return (
    <StyledArrowButton
      aria-label={`arrow-button-${direction}`}
      direction={direction}
      backgroundMode={backgroundMode}
      {...rest}
    >
      <Svg
        title={`arrow-button-${direction}-icon`}
        svg={<ChevronIcon />}
        width={16}
        height={16}
      />
    </StyledArrowButton>
  );
};

export default ArrowButton;

import { Svg } from '@worten-sardines/grill-ui';
import {
  Close,
  DateDetailCard,
} from '@worten-sardines/seller-center-ui-shared';
import { ShopIdCard } from '@worten-sardines/seller-center-ui-layout';
import { useIntl } from 'react-intl';

import CampaignSubmission from './CampaignSubmission';
import CampaignSubmissionTemplateFile from './CampaignSubmissionTemplateFile';

import messages from './messages';

import {
  CloseButton,
  StyledCMSSidebarDetails,
  StyledCMSSidebarShortDescription,
  StyledCMSSidebarTitle,
  StyledCMSSidebarWrapper,
  StyledCustomCMSSidebarWrapper,
} from './styled';
import { useSession } from '@worten-sardines/shared/hooks';
import { CampaignsArgs, SidebarObjectSchema, SidebarSchema } from '../../types';
import SharedSidebar from '../../components/SharedSidebar';

type CampaignCMSSidebarProps = {
  data: CampaignsArgs | null;
  sidebarSections?: SidebarSchema | null;
  resetCampaign?: () => void;
};

export const CampaignCMSSidebar = ({
  data,
  sidebarSections,
  resetCampaign,
}: CampaignCMSSidebarProps) => {
  const intl = useIntl();
  const { data: user } = useSession() || {};
  const isOperator = user?.type === 'operator';

  const {
    title,
    startDate,
    endDate,
    daysInAdvance,
    submissionDate,
    submissionStartDate,
    shortDescription,
  } = data || {};

  const formatDate = (date: string) => new Date(date);

  return (
    <StyledCMSSidebarWrapper position={sidebarSections?.position}>
      {isOperator && <ShopIdCard isUnset />}
      <StyledCustomCMSSidebarWrapper>
        <CampaignSubmissionTemplateFile />
        {title && (
          <StyledCMSSidebarDetails>
            <CloseButton
              type="button"
              aria-label="close modal"
              onClick={resetCampaign}
            >
              <Svg
                svg={<Close />}
                width={20}
                title="icon close campaign details"
              />
            </CloseButton>
            <StyledCMSSidebarTitle>{title}</StyledCMSSidebarTitle>
            <StyledCMSSidebarShortDescription>
              {shortDescription}
            </StyledCMSSidebarShortDescription>
            {submissionStartDate && submissionDate && (
              <DateDetailCard
                title={intl.formatMessage(messages.submissionPeriod)}
                startDate={formatDate(submissionStartDate)}
                endDate={formatDate(submissionDate)}
                daysInAdvance={daysInAdvance}
                highlightEndDate
              />
            )}
            {startDate && endDate && (
              <DateDetailCard
                title={intl.formatMessage(messages.runningTime)}
                startDate={formatDate(startDate)}
                endDate={formatDate(endDate)}
              />
            )}
            <CampaignSubmission campaign={title} />
          </StyledCMSSidebarDetails>
        )}
      </StyledCustomCMSSidebarWrapper>
      {sidebarSections?.sidebars.map((sidebar: SidebarObjectSchema) => (
        <SharedSidebar
          key={sidebar.componentType}
          name={sidebar.componentType}
        />
      ))}
    </StyledCMSSidebarWrapper>
  );
};

export default CampaignCMSSidebar;

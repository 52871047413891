import { transitionTimingCurve } from '@worten-sardines/grill-ui';
import styled, { css } from 'styled-components';
import { DividerProps } from '.';

export const StyledDivider = styled.div<DividerProps>`
  ${({ theme, as }) => css`
    display: flex;
    align-items: center;
    background: ${theme.color.neutral.neu01};
    padding: ${theme.spacing.xs};
    position: relative;
    overflow: hidden;
    gap: ${theme.spacing.xxs};

    svg {
      color: ${theme.color.neutral['neu-11']};
      margin: 0;
    }

    ${as === 'a' &&
    css`
      :hover,
      :focus,
      :focus-within {
        &::after {
          content: '';
          position: absolute;
          height: 100%;
          left: 0;
          top: 0;
          transition: all ${theme.animation.duration.fast};
        }
        box-shadow: ${theme.level2};

        .icon-arrow {
          color: ${theme.color.main['m-01']};
          transition: all ${theme.animation.duration.fast};
          right: ${theme.spacing.xxs};
        }

        ${transitionTimingCurve(theme.animation.curve.productive)};
      }

      &::after {
        content: '';
        position: absolute;
        background: ${theme.color.main['m-01']};
        height: 0;
        left: 0;
        top: 0;
        transition: all ${theme.animation.duration.fast};
        width: 1px;
      }

      .icon-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: ${theme.spacing.xs};
        transition: all ${theme.animation.duration.fast};
        color: ${theme.color.neutral['neu-04']};
      }
    `}
  `}
`;

export const DividerTitle = styled.span`
  ${({ theme }) => css`
    width: 100%;
    padding-right: ${theme.spacing.sm};
    font-size: ${theme.fontsize.desktop.l};
    font-weight: ${theme.weight.bold};
    line-height: ${theme.lineheight.desktop.l};
    letter-spacing: ${theme.letterspacing.desktop.l};
    color: ${theme.color.neutral['neu-11']};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    a {
      color: ${theme.color.neutral['neu-11']};
    }
  `}
`;

import { LOCAL_STORAGE_REDIRECT_KEY } from '@worten-sardines/configs/config-seller-center';
import { LoadingOverlay } from '@worten-sardines/grill-ui';
import { SpinnerWorten3D } from '@worten-sardines/seller-center-ui-shared';
import { setOnSharedLocalStorageHelper } from '@worten-sardines/shared/helpers';
import { IGNORED_ROUTES_FOR_REDIRECT } from '@worten-sardines/shared/hooks';
import React from 'react';

export function AuthenticationHocFeature<P>(
  Component: React.ComponentType<P>,
  aclPermissions: [],
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function Authentication(props: any) {
    const { match, user } = props;
    const { data: userSession, loading } = user;
    const currentPath = match.location.pathname;
    const redirectAfterCerberusReturn = IGNORED_ROUTES_FOR_REDIRECT.includes(
      currentPath,
    )
      ? null
      : currentPath;

    if (loading) {
      return (
        <LoadingOverlay isVisible>
          <SpinnerWorten3D />
        </LoadingOverlay>
      );
    }

    if (userSession.isAuthenticated === false) {
      if (redirectAfterCerberusReturn) {
        // Set redirect path in localStorage until external OAuth finishes
        setOnSharedLocalStorageHelper(
          LOCAL_STORAGE_REDIRECT_KEY,
          redirectAfterCerberusReturn,
        );
      }

      // Redirect to login page
      window.location.href = '/login';
    }

    //TODO: AWAITING INTEGRATION WITH USER ROLE
    if (userSession.isAuthenticated) {
      // If route is protected by ACL action, check that user was given access to it
      // const forbiddenAccess =
      //   aclActions.length > 0 &&
      //   !aclActions.every((axn) => {
      //     const [feature] = axn.split('.');
      //     return (
      //       userSession.features &&
      //       Object.keys(userSession.features).includes(feature) &&
      //       userSession.features[feature].actions.find((a) => a.key === axn)
      //     );
      //   });

      // // TODO: Redirect to Forbidden if user has no permission to access route
      // if (forbiddenAccess) {
      //   return <Redirect to="/not-found" />;
      // }

      return <Component user={userSession} {...(props as P)} />;
    }

    return null;
  }

  return Authentication;
}

export default AuthenticationHocFeature;

import { defineMessages } from 'react-intl';

export default defineMessages({
  filterToggleAllMessage: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.components.filters.all',
    defaultMessage: 'All',
  },
  filterToggleOngoingMessage: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.campaigns.components.filters.ongoing',
    defaultMessage: 'On-going',
  },
  filterToggleNextMessage: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.campaigns.components.filters.next',
    defaultMessage: 'Next',
  },
  campaignMarket: {
    id: 'libs.seller-center.feature.CMSPage.campaigns.campaigns.components.filters.campaignMarket',
    defaultMessage: 'Market',
  },
});

import { forwardRef, InputHTMLAttributes } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import ErrorMessageField from '../ErrorMessageField';

import {
  ContainerHelpers,
  ContainerInput,
  HelpText,
  Label,
  StyledInput,
  WrapperIcon,
  WrapperTextField,
} from './styled';

export type InputProps = {
  name: string;
  label: string;
  placeholder?: string;
  isRequired?: boolean;
  type?: 'text' | 'number' | 'email' | 'password';
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: { [x: string]: any };
  helpText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: { [x: string]: any };
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  register?: UseFormRegister<FieldValues>;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      isRequired = false,
      label,
      placeholder = ' ',
      type = 'text',
      disabled = false,
      error = {},
      helpText,
      register,
      validationSchema,
      icon,
      iconPosition = 'right',
      ...props
    },
    ref,
  ) => {
    const registerRH = register
      ? { ...register(name, validationSchema) }
      : null;

    const withPlaceholder = placeholder !== ' ' ? placeholder : false;

    return (
      <WrapperTextField>
        <ContainerInput disabled={disabled} withPlaceholder={!!withPlaceholder}>
          <StyledInput
            id={name}
            name={name}
            type={type}
            autoComplete="off"
            withPlaceholder={!!withPlaceholder}
            placeholder={placeholder}
            hasError={error[name as string]}
            disabled={disabled}
            hasIcon={!!icon}
            iconPosition={iconPosition}
            required={isRequired}
            ref={ref}
            {...registerRH}
            {...props}
          />
          <Label
            htmlFor={name}
            className="label"
            disabled={disabled}
            withPlaceholder={!!withPlaceholder}
            hasIcon={!!icon}
            iconPosition={iconPosition}
            isRequired={!!validationSchema?.['required'] || isRequired}
          >
            {label}
          </Label>

          {!!icon && (
            <WrapperIcon
              iconPosition={iconPosition}
              hasError={error[name as string]}
              withPlaceholder={!!withPlaceholder}
            >
              {icon}
            </WrapperIcon>
          )}
        </ContainerInput>
        <ContainerHelpers>
          {error[name as string]?.message && (
            <ErrorMessageField message={error[name as string]?.message} />
          )}
          {Object.keys(error).length === 0 && helpText && (
            <HelpText>{helpText}</HelpText>
          )}
        </ContainerHelpers>
      </WrapperTextField>
    );
  },
);

export default Input;

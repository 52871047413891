import styled from 'styled-components';
import { Theme, breakpoints } from '@worten-sardines/grill-ui';
import { MainTemplateProps } from '.';

export const StyledMainTemplate = styled.div<MainTemplateProps>`
  display: grid;
  grid-template-areas:
    'header'
    'content'
    'sidebarLeft'
    'sidebarRight'
    'footer';
  grid-template-rows: auto 1fr auto;

  @media screen and ${breakpoints.md} {
    grid-template-areas:
      'header header header'
      'sidebarLeft content sidebarRight'
      'footer footer footer';
    grid-template-columns: auto minmax(300px, 1fr) auto;
  }

  background-color: ${Theme.color.neutral['neu-02']};

  height: 100%;
  width: 100%;
  min-height: 100vh;
`;

export const Header = styled.div`
  grid-area: header;
`;
export const Content = styled.div`
  grid-area: content;
`;
export const Footer = styled.div`
  grid-area: footer;
`;
export const SidebarLeft = styled.div`
  grid-area: sidebarLeft;
  padding: 0 ${Theme.size.xs} ${Theme.size.xs} ${Theme.size.xs};
  width: 100%;
  @media screen and ${breakpoints.md} {
    width: 350px;
    padding-right: 0;
  }
`;
export const SidebarRight = styled.div`
  grid-area: sidebarRight;
  padding: 0 ${Theme.size.xs} ${Theme.size.xs} ${Theme.size.xs};
  width: 100%;

  @media screen and ${breakpoints.md} {
    width: 350px;
    padding-left: 0;
  }
`;

import { Theme as theme } from '@worten-sardines/grill-ui';
import { transitionTimingCurve } from '@worten-sardines/grill-ui';
import styled, { css } from 'styled-components';

type LIProps = {
  currentCampaign: boolean;
};

const actived = css`
  background-color: ${theme.color.neutral['neu-02']};
  .actived {
    div:nth-child(2) {
      > div {
        box-shadow: ${theme.level3};
        transition: all ${theme.animation.duration.slow};
        ${transitionTimingCurve(theme.animation.curve.productive)};

        > div {
          box-shadow: ${theme.level1};
          transition: all ${theme.animation.duration.slow};
          ${transitionTimingCurve(theme.animation.curve.productive)};
        }
      }
    }
  }
`;

export const UL = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const LI = styled.li<LIProps>`
  ${({ currentCampaign }) => css`
    min-height: 8rem;
    background-color: none;
    position: relative;
    transition: all ${theme.animation.duration.medium};
    ${transitionTimingCurve(theme.animation.curve.productive)};

    &::before {
      content: '';
      position: absolute;
      background: ${theme.color.main['m-01']};
      height: 0;
      left: 0;
      top: 0;
      transition: all ${theme.animation.duration.medium};
      width: 1px;
    }

    &:first-child {
      border-top: 1px solid #f6f6f6;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #f6f6f6;
    }

    &:hover {
      background-color: ${theme.color.neutral['neu-02']};
      transition: all ${theme.animation.duration.medium};
      ${transitionTimingCurve(theme.animation.curve.productive)};

      &::before {
        height: 100%;
      }

      ${actived}
    }

    ${currentCampaign && actived}
  `}
`;

export const Timeline = styled.div`
  border-left: 1px solid ${theme.color.neutral['neu-03']};
  cursor: pointer;
  position: relative;
`;

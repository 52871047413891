import { Svg } from '@worten-sardines/grill-ui';
import { StyledButtonRow } from './styled';
import { CampaignsArgs } from '../../../types';

type ButtonRow = {
  primary?: boolean;
  label: string;
  info: CampaignsArgs;
  action: (e: CampaignsArgs) => void;
  badge?: JSX.Element;
  showBadge?: boolean;
  children?: React.ReactNode;
};

const ButtonRow = ({
  primary = false,
  label,
  info,
  action,
  badge,
  showBadge = false,
  children,
}: ButtonRow) => {
  return (
    <StyledButtonRow primary={primary} onClick={() => action(info)}>
      {showBadge && badge && (
        <Svg svg={badge} width="16" title={label} color="red" />
      )}
      {children ? children : <span>{label}</span>}
    </StyledButtonRow>
  );
};

export default ButtonRow;

import { useIntl } from 'react-intl';
import styled from 'styled-components';
import messages from '../messages';
import { MetricProps } from '../types';

const ListMetrics = styled.li`
  font-size: 12px;
`;

export const MetricNameItem = ({ metric }: { metric: MetricProps }) => {
  const intl = useIntl();

  const metricName = metric.name as keyof typeof messages;

  return (
    <ListMetrics key={metricName}>
      {intl.formatMessage(messages[metricName])}
    </ListMetrics>
  );
};

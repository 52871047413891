import { useIntl } from 'react-intl';
import {
  Download,
  Dropdown,
  DropdownItemProps,
  Svg,
} from '@worten-sardines/grill-ui';

import messages from '../../messages';
import {
  PayoutCellIcon,
  PayoutCellInfo,
  PayoutCellStatus,
  PayoutCellTitle,
  PayoutCellWrapper,
} from './styled';
import {
  formatDayMonth,
  getNextMonth,
  getPayoutStatus,
} from '../PayoutsDetails/utils';

type CycleInfoProps = {
  from: string;
  to: string;
};

type PayoutCellProps = {
  items: DropdownItemProps[];
  month: string;
  cycle: 1 | 2;
  cycleInfo: CycleInfoProps;
};

const PayoutCell = ({ month, items, cycle, cycleInfo }: PayoutCellProps) => {
  const intl = useIntl();
  const hasItems = items && items.length > 1;
  const noItems = items && items.length === 0;
  const { from, to } = cycleInfo || {};
  const payedDay = cycle === 1 ? '20' : '5';
  const shortMonth =
    cycle === 1
      ? month.slice(0, 3)
      : getNextMonth(month, intl.locale).slice(0, 3);

  const status = getPayoutStatus(cycle, new Date(to), noItems);

  const statusMessage = {
    paid: messages.cycleStatus,
    future: messages.cycleStatusFuture,
    unpaid: messages.cycleStatusUnpaid,
  };

  const fromDate = formatDayMonth(from, intl.locale);
  const toDate = formatDayMonth(to, intl.locale);

  return (
    <PayoutCellWrapper>
      <PayoutCellInfo>
        <PayoutCellTitle empty={noItems}>
          {intl.formatMessage(messages.cycleInfo, {
            from: fromDate,
            to: toDate,
          })}
        </PayoutCellTitle>
        <PayoutCellStatus status={status}>
          {intl.formatMessage(statusMessage[status], {
            payed_day: payedDay,
            payed_month: shortMonth,
          })}
        </PayoutCellStatus>
      </PayoutCellInfo>
      {!noItems && (
        <PayoutCellIcon>
          {hasItems ? (
            <Dropdown items={items} disableOnHover maxHeight="24.5rem">
              <Svg
                svg={<Download />}
                title={intl.formatMessage(messages.svgDownloadAltText)}
                width={24}
                height={24}
              />
            </Dropdown>
          ) : (
            items[0].children
          )}
        </PayoutCellIcon>
      )}
    </PayoutCellWrapper>
  );
};

export default PayoutCell;

import { API_URL_SELLER } from '@worten-sardines/configs/config-seller-center';
import { buildQueryString } from '@worten-sardines/shared/utils-apis';

export function getShopData(shopId?: string) {
  const args = {
    apiUrl: API_URL_SELLER,
  };
  const url = `/v1/shops${shopId ? '/' + shopId : ''}`;

  return {
    key: url,
    args,
  };
}

export function getShopEvaluations(
  shopId: string,
  date?: string,
  channel?: string,
) {
  const args = {
    apiUrl: API_URL_SELLER,
  };

  const queryParams = buildQueryString({ date, channel });

  const url = `/v1/shops/${shopId}/evaluations${queryParams}`;

  return {
    key: url,
    args,
  };
}

export default { getShopData };

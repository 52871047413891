import { LOCAL_STORAGE_REDIRECT_KEY } from '@worten-sardines/configs/config-seller-center';
import { getUserInfo, login } from '@worten-sardines/seller-center/data-access';
import {
  getFromSharedLocalStorageHelper,
  removeSharedLocalStorageHelper,
} from '@worten-sardines/shared/helpers';
import { REGISTER_DATALAYER } from '@worten-sardines/trackings/tracking-seller-center';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMutate from '../../shared-use-mutate';
import { useSearchShop } from '../../shared-use-search-shop/useSearchShop';
import { useSession } from '../useSession';

/**w
 * useAuth
 *
 * Custom Auth hook.
 *
 * @returns [authFn]
 */
export function useAuth() {
  const navigate = useNavigate();
  const [{ data, error }, authMutate] = useMutate(login, [], false);
  const { data: userData } = useSession() || {};

  const [shopId, setShopId] = useState('');
  const { shopInfo, error: errorShop } = useSearchShop(shopId);

  const redirectAfterLogin = getFromSharedLocalStorageHelper(
    LOCAL_STORAGE_REDIRECT_KEY,
  );
  const removeRedirectPath = () =>
    removeSharedLocalStorageHelper(LOCAL_STORAGE_REDIRECT_KEY);

  useEffect(() => {
    if (errorShop) {
      setShopId('');
    }

    if (userData?.shop?.id) {
      setShopId(userData.shop.id);
    }

    if (
      data &&
      !error &&
      (shopInfo || errorShop || userData.user?.type === 'operator')
    ) {
      REGISTER_DATALAYER('EVENT_LOGIN', {
        userType: userData.user?.type,
        userShopId: userData.shop?.id,
        userShopName: userData.shop?.name,
        userShopTier: shopInfo?.tier,
      });
      navigate(redirectAfterLogin || '/');
      removeRedirectPath();
    } else if (error) {
      navigate('/');
    }
  }, [
    data,
    error,
    shopInfo,
    errorShop,
    userData.user?.type,
    userData?.shop?.id,
  ]);

  const authFn = useCallback(
    (code: string) => {
      authMutate([[[getUserInfo().key]], { code }]);
    },
    [authMutate],
  );

  return authFn;
}

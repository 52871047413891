import { Grid, Theme } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

export const FiltersContainer = styled.div`
  display: block;
  padding: ${Theme.spacing.xs} 0;
`;

export const FiltersWrapperTopLine = styled(Grid)`
  grid-template-columns: '80px 1fr';
  width: 100%;
  gap: ${Theme.spacing.xs};
  padding: 0 ${Theme.spacing.xs};
  background-color: ${Theme.color.neutral['neu01']};
  border-radius: ${Theme.spacing.xs};
`;

export const FiltersWrapperBottomLine = styled.div`
  padding: 0 ${Theme.spacing.xs};
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${Theme.spacing.xxs};
`;

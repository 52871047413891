import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import type { Location, Params } from 'react-router-dom';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Loading, getSearchParamsUrl } from '@worten-sardines/grill-ui';
import {
  CMSConfig,
  Content,
  MainTemplate,
  SEO,
  WrapperHTML,
} from '@worten-sardines/shared-ui';
import { useContentCms, useSession } from '@worten-sardines/shared/hooks';
import ErrorBoundary from '../ErrorBoundary';
import Campaigns from './Campaigns';
import CMSSidebar from './components/CMSSidebar';
import { SchemaObjectsWithSidebarAndCampaign } from './types';

import { REGISTER_DATALAYER } from '@worten-sardines/trackings/tracking-seller-center';

type MatchProps = {
  location: Location;
  params: Params;
  path: string;
};

type CMSPageProps = {
  match: MatchProps;
};

type PageProps = {
  name: string;
  type: string;
  title: string;
  description: string;
  sections: Array<SchemaObjectsWithSidebarAndCampaign>;
};

export const CMSPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { data: userData } = useSession() || {};

  const [page, setPage] = useState<PageProps>();
  const {
    match: { location, params },
  }: CMSPageProps = useOutletContext();
  const slug = params && params['*'];
  const { preview, lang } = getSearchParamsUrl(location.search);

  const [data, loading, error] =
    useContentCms(location.pathname, slug, preview, lang, intl) || [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatchEvent = (page: string, user: any) => {
    REGISTER_DATALAYER('VIRTUAL_PAGEVIEW', {
      pageType: page,
      userType: user?.type,
      userShopId: user?.shop?.id,
      userShopName: user?.shop?.name,
      userShopTier: user?.shop?.tier,
    });
  };

  useEffect(() => {
    if (data) {
      const page = data[0];
      dispatchEvent(location.pathname.slice(1), userData);
      setPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error && error.status === 404) {
      navigate('/not-found');
    }
  }, [error, navigate]);

  if (error) {
    return <ErrorBoundary error={error} />;
  }

  if (loading) {
    return (
      <MainTemplate.Content>
        <Loading mt="40vh" />
      </MainTemplate.Content>
    );
  }

  const renderPage = ({ name, sections }: PageProps) => {
    const sidebar = sections.filter(
      (camp: SchemaObjectsWithSidebarAndCampaign) =>
        camp.type === 'sidebar-set',
    )[0];

    if (name === 'campaign') return <Campaigns schema={sections} />;

    return (
      <>
        <MainTemplate.Content>
          <WrapperHTML>
            <Content>
              <CMSConfig schemas={sections} />
            </Content>
          </WrapperHTML>
        </MainTemplate.Content>
        {sidebar ? (
          <MainTemplate.SidebarRight>
            <CMSSidebar
              key={`${sidebar.type}_${sidebar.name}`}
              id={`${sidebar.type}_${sidebar.name}`}
              schema={sidebar}
            />
          </MainTemplate.SidebarRight>
        ) : null}
      </>
    );
  };

  return (
    <>
      {page?.title ? (
        <SEO
          title={`Seller Center - ${page.title}`}
          description={page.description}
        />
      ) : null}
      {page?.name && page?.sections ? renderPage(page) : null}
    </>
  );
};

export default CMSPage;

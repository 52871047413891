import {
  CardSet,
  CMSHeroBanner,
  CMSHeroSlider,
  CTAButtonSet,
  CustomHTML,
  Form123Form,
  Title,
} from '../components';

import { SchemaObjects } from '../types';

type CustomHTMLProps = {
  schemas: Array<SchemaObjects>;
  isHybridPage?: boolean;
};

export const CMSConfig = ({
  schemas,
  isHybridPage = false,
}: CustomHTMLProps) => {
  return (
    <>
      {schemas.map((schemaComponent, index) => {
        const schema = {
          ...schemaComponent,
          position: isHybridPage ? schemaComponent.position : null,
        };

        const componentKey = `${schema.type}_${schema.name || index}`;
        switch (schema.type) {
          case 'title':
            return <Title key={componentKey} schema={schema} />;
          case 'custom-html':
            return (
              <CustomHTML
                key={componentKey}
                id={componentKey}
                schema={schema}
              />
            );
          case 'card-set':
            return <CardSet key={componentKey} schema={schema} />;
          case 'cta-button-set':
            return <CTAButtonSet key={componentKey} schema={schema} />;
          case '123form':
            return <Form123Form key={componentKey} schema={schema} />;
          case 'hero-banner':
            return <CMSHeroBanner key={componentKey} schema={schema} />;
          case 'hero-slider':
            return <CMSHeroSlider key={componentKey} schema={schema} />;
          default:
            return null;
        }
      })}
    </>
  );
};

export default CMSConfig;

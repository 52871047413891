import { Flex, Theme, Text } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

export const StyledLoginFeature = styled(Flex).attrs({
  flexWrap: 'wrap',
})`
  background: ${Theme.color.neutral['neu-04']};
  padding: ${Theme.spacing.xl};
  height: 100vh;
  width: 100%;
`;

export const StyledLogin = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'space-between',
})`
  div > svg {
    margin-bottom: 9px;
  }
  background: ${Theme.color.neutral.neu01};
  width: 392px;
  border-radius: ${Theme.Radius.sm};
  box-shadow: ${Theme.level2};
  padding: ${Theme.spacing.xxl};
  margin-right: calc(${Theme.spacing.xl} + ${Theme.spacing.xl});
`;

export const StyledTitle = styled(Text)`
  font-size: ${Theme.fontsize.desktop.m};
  margin-bottom: ${Theme.spacing.sm};
`;

export const StyledCopyright = styled(Flex)`
  font-size: ${Theme.fontsize.desktop.xs};
  color: ${Theme.color.neutral['neu-07']};
  min-width: 170px;
  flex: 1;
`;

export const StyledLink = styled.a`
  appearance: none;
  color: ${Theme.color.neutral['neu-08']};
  font-size: ${Theme.fontsize.desktop.m};

  line-height: 1.5;
  text-decoration: underline;
  cursor: pointer;
`;

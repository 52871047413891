import { SkeletonContainer, SkeletonLine, SkeletonRectangle } from './styled';

type Columns = {
  columns: number;
};

type SkeletonTemplate = {
  template: Array<Columns>;
};

export const Skeleton = ({ template }: SkeletonTemplate) => (
  <SkeletonContainer>
    {template.map(({ columns }, index) => (
      <SkeletonLine key={`${columns}_${index}`} columns={columns}>
        {[...Array(columns)].map((_, rowIndex) => (
          <SkeletonRectangle key={`row_${columns}_${rowIndex}`} />
        ))}
      </SkeletonLine>
    ))}
  </SkeletonContainer>
);

export default Skeleton;

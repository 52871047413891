import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useOutletContext } from 'react-router-dom';
import type { Location, Params } from 'react-router-dom';
import { Loading, getSearchParamsUrl } from '@worten-sardines/grill-ui';
import { useContentCms } from '@worten-sardines/shared/hooks';
import { CMSConfig, SEO, SchemaObjects } from '@worten-sardines/shared-ui';

type MatchProps = {
  location: Location;
  params: Params;
  path: string;
};

type ContentCMSProps = {
  match: MatchProps;
};

type PageProps = {
  name: string;
  type: string;
  title: string;
  description: string;
  sections: Array<SchemaObjects>;
};

export const ContentCMS = () => {
  const intl = useIntl();
  const [CMSContent, setCmsContent] = useState<PageProps>();
  const {
    match: { location },
  }: ContentCMSProps = useOutletContext();
  const slug = location.pathname?.split('/').filter((path) => path !== '');
  const { preview, lang } = getSearchParamsUrl(location.search);

  const [data, loading] =
    useContentCms('hybrid-page', slug[0], preview, lang, intl) || [];

  useEffect(() => {
    if (data) setCmsContent(data[0]);
  }, [data]);

  if (loading) {
    return <Loading mt="40vh" />;
  }

  const renderComponent = ({ sections }: PageProps) => {
    return <CMSConfig schemas={sections} isHybridPage={true} />;
  };

  return (
    <>
      {CMSContent?.title ? (
        <SEO
          title={`Seller Center - ${CMSContent.title}`}
          description={CMSContent.description}
        />
      ) : null}
      {CMSContent?.name && CMSContent?.sections
        ? renderComponent(CMSContent)
        : null}
    </>
  );
};

export default ContentCMS;

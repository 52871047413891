import * as Sentry from '@sentry/react';
import { buildQueryString, Query } from '../build-query-string';

import { API_URL } from '@worten-sardines/configs/config-seller-center';

export interface PropsRequest {
  apiUrl?: string;
  mode?: string;
  uri?: string;
  method?: string;
  body?: object | string | [];
  credentials?: string;
  query?: Query;
  headers?: string;
  rawBody?: string;
}

export interface PropsParsedErrors {
  requestId?: string;
  detail?: string;
}

export async function request(options: PropsRequest) {
  const {
    apiUrl = API_URL,
    credentials = 'include',
    mode = 'cors',
    uri,
    method,
    body,
    query,
    headers,
    rawBody = null,
  } = options;

  const url = `${apiUrl}${uri}${query ? buildQueryString(query) : ''}`;

  const requestOptions: object = {
    method,
    mode,
    credentials,
    headers:
      headers ||
      new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      }),
    body: body ? JSON.stringify(body) : rawBody,
  };

  const returnResponse = (response: Response) => {
    if (response.status === 204) {
      return '{}';
    }

    return response.json();
  };

  const fetchData = fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      // Response is ranged between 200-299 status code.
      // Return raw response to be handled ahead.
      return response;
    } else if (response.status === 401) {
      // Unauthorized response should redirect to login page
      return response.json().then((data) => {
        throw {
          uri,
          status: response.status,
          message: `Details: ${data.detail} - Status: ${response.status}`,
        };
      });
    } else if (response.status === 404) {
      Sentry.captureException(response);
      throw {
        uri,
        status: response.status,
        message: `Request: ${uri} - Status: ${response.status}`,
      };
    }

    // All other error responses.
    // Convert using text() instead of json() to prevent "Unexpected token" or other parsing errors.
    return response.text().then((plainTextError) => {
      let parsedError: PropsParsedErrors = {};
      try {
        // Try to parse plain text error response.
        parsedError = JSON.parse(plainTextError);
      } catch (e) {
        Sentry.captureException(plainTextError);
        throw new Error(plainTextError);
      }
      // Show requestId if available
      const requestId =
        typeof parsedError.requestId !== 'undefined'
          ? `${parsedError.requestId} - `
          : '';
      // Otherwise the error will have the expected format and will be successfully parsed.
      throw new Error(`${requestId}${parsedError.detail}`);
    });
  });

  // This block handles all other successful responses..
  // It catches an exception if the response is an invalid JSON that could not be parsed.
  return (
    fetchData
      //workaround to return empty object if response was null for response status 200
      .then(async (response) => returnResponse(response))
      .catch((error) => {
        throw error;
      })
  );
}

import { HeroBannerSchema } from '../../types';
import CMSHeroBanner from '../CMSHeroBanner';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setHeroBanners = (items: any[]) => {
  const heros = items.map((banner: HeroBannerSchema) => (
    <CMSHeroBanner key={banner.name} schema={banner} />
  ));

  return [...heros];
};

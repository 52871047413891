import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(0deg) rotateY(90deg);
  }
  100% {
    transform: perspective(120px) rotateX(0deg) rotateY(180deg);
  }
`;

export const Logo = styled.div`
  width: 40px;
  height: 40px;
  animation: ${rotate} 1s infinite ease-in-out 0.1s;
`;

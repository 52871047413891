import { Button } from '@worten-sardines/grill-ui';
import { CTAButtonSchema, CTAButtonsSchema } from '../../types';
import { StyledWrapperCTAButton } from './styled';

declare global {
  interface Window {
    [key: string]: () => void;
  }
}

export const CTAButtonSet = ({
  schema: { name, alignment, buttons, position },
}: {
  schema: CTAButtonsSchema;
}) => {
  const argsForButton = (button: CTAButtonSchema) => ({
    fullWidth: !!button.fullWidth,
    iconAlignment: button.iconAlignment,
    size: button.size,
    variation: button.variation,
    iconSide: button.iconAlignment,
    type: button.type,
    id: button.id,
    icon: button.svgIcon ? (
      <span
        aria-hidden="true"
        dangerouslySetInnerHTML={{ __html: button.svgIcon }}
      />
    ) : undefined,
  });

  const argsForLink = (button: CTAButtonSchema) => ({
    href: button.targetURL,
    target: button.openInNewTab ? '_blank' : '_self',
    link: true,
    fullWidth: !!button.fullWidth,
    size: button.size,
    id: button.id,
    type: button.type,
  });

  const contentAlignment: Record<
    'left' | 'right' | 'center',
    'center' | 'flex-start' | 'flex-end'
  > = {
    left: 'flex-start',
    right: 'flex-end',
    center: 'center',
  };

  return (
    <StyledWrapperCTAButton
      data-name={name}
      alignContent={
        contentAlignment[alignment as keyof typeof contentAlignment]
      }
      position={position}
    >
      {buttons.map((button) => {
        if (button.appearance === 'button') {
          return (
            <Button key={button.label} as="button" {...argsForButton(button)}>
              {button.label}
            </Button>
          );
        }

        if (button.appearance === 'link' && !!button.targetURL) {
          return (
            <Button key={button.label} as="a" {...argsForLink(button)}>
              {button.label}
            </Button>
          );
        }
        return null;
      })}
    </StyledWrapperCTAButton>
  );
};

export default CTAButtonSet;

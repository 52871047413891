import { MakeResponsive, MakeSpacing } from '../utils/types';
import { StyledEmoji } from './styled';

type CustomProperties = MakeResponsive & MakeSpacing;

export type EmojiProps = {
  symbol: string;
  label?: string;
  size?: string;
} & MakeResponsive &
  Omit<CustomProperties, 'gridRowGap' | 'gridColumnGap' | 'gridGap'>;

export const Emoji = ({ symbol, label, size, ...rest }: EmojiProps) => {
  return (
    <StyledEmoji
      role="img"
      aria-label={label || ''}
      aria-hidden={label ? 'false' : 'true'}
      size={size}
      {...rest}
    >
      {symbol}
    </StyledEmoji>
  );
};

export default Emoji;

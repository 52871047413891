import { Theme } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

export const ShopIdPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 35.2rem;
`;

export const InputFieldWrapper = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${Theme.color.neutral.neu01};

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    appearance: textfield;
  }
  > div {
    margin: 0;
  }
`;

export const ResultContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InstructionsDetails = styled.span`
  font-size: ${Theme.fontsize.desktop.s2};
  letter-spacing: 0.023rem;
  line-height: 1.42;
  color: ${Theme.color.neutral['neu-08']};
  padding: 0 1.15rem;
`;

export const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Theme.color.neutral['neu-06']};
  width: 3.6rem;
  height: 4rem;

  &:hover {
    color: ${Theme.color.neutral['neu-08']};
  }
`;

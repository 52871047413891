import styled, { css } from 'styled-components';
import { AlignProps } from '.';

type ToggleSlideProps = {
  activeIndex: number;
  itemsLength: number;
  align?: AlignProps;
};

export const StyledToggle = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 34px;
    padding: 0 0.6rem;

    background-color: ${theme.color.neutral['neu-03']};
    border-radius: ${theme.Radius.xxg};

    z-index: 1;
  `};
`;

export const ToggleItem = styled.button<ToggleSlideProps>`
  ${({ theme, itemsLength, align }) => css`
    display: flex;
    flex-direction: ${align === 'right' ? 'row-reverse' : 'row'};
    justify-content: center;
    align-items: center;
    width: calc(100% / ${itemsLength});
    padding: 0.4rem 1rem;
    transition: all 0.2s ease-in-out;
    color: ${theme.color.neutral['neu-07']};
    font-weight: normal;
    font-size: ${theme.fontsize.desktop.s1};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.active {
      color: ${theme.color.main['m-01']};
      font-weight: 600;
    }

    &:disabled {
      color: ${theme.color.neutral['neu-05']};
      cursor: not-allowed;
      path {
        fill: ${theme.color.neutral['neu-05']};
      }
    }
  `}
`;

export const BadgeContent = styled.div`
  ${({ theme }) => css`
    width: fit-content;
    padding: 0;
    margin: 0 ${theme.sys.dimension.spacing.Micro};
  `}
`;

export const ToggleSlideDiv = styled.div<ToggleSlideProps>`
  ${({ theme, activeIndex, itemsLength }) => css`
    display: block;
    width: calc(calc(100% - 0.6rem) / ${itemsLength});
    position: absolute;
    background-color: ${theme.color.neutral['neu01']};
    height: 26px;
    border-radius: ${theme.Radius.sm};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
    transform: translateX(calc(calc(100% - 0.3rem) * ${activeIndex}));
    z-index: -1;
  `}
`;

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'libs.seller-center.ui-layout.Error.title',
    defaultMessage: 'Something went wrong.',
  },
  label: {
    id: 'libs.seller-center.ui-layout.Error.label',
    defaultMessage: 'Try again',
  },
});

import { MIRAKL_URL } from '@worten-sardines/configs/config-seller-center';
import {
  Cart,
  Chart,
  Cookie,
  DSA,
  EsFlag,
  Help,
  Info,
  ListProps,
  PtFlag,
  Sonae,
} from '@worten-sardines/seller-center-ui-shared';

export const usefulLinks: ListProps[] = [
  {
    divider: { key: 'usefulLinks', displayed: false },
    links: [
      [
        {
          key: 'mirakl',
          icon: <Cart />,
          externalUrl: MIRAKL_URL,
        },
        {
          key: 'sonae',
          icon: <Sonae />,
          externalUrl: 'http://www.sonaelink.com',
        },
        {
          key: 'sellerAcademy',
          icon: <Info />,
        },
        {
          key: 'wortenAds',
          icon: <Chart />,
          partialUrl:
            '/worten-ads?utm_source=seller-center&utm_medium=usefullink',
          configNotice: { type: 'newFeature', name: 'wortenAds' },
        },
        {
          key: 'cookiePolicy',
          icon: <Cookie />,
          partialUrl: '/cookie-policy',
        },
        {
          key: 'dsa',
          icon: <DSA />,
          partialUrl: '/illegal-content',
        },
        {
          key: 'sellerPagPT',
          icon: <PtFlag />,
          externalUrl: '#',
          displayed: false,
        },
        {
          key: 'sellerPagES',
          icon: <EsFlag />,
          externalUrl: '#',
          displayed: false,
        },
        {
          key: 'doubt',
          icon: <Help />,
          variation: 'error',
          partialUrl: '/seller-support',
        },
      ],
    ],
  },
];

import { ARGS_UTM } from '@worten-sardines/configs/config-seller-center';
import { Svg } from '@worten-sardines/grill-ui';
import { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';
import { useLocation } from 'react-router-dom';

import NoticeFlag, { NoticeFlagProps } from '../NoticeFlag';
import WrapperLink from './Link';

import { Arrow } from '../assets';
import { StyledLinkCard, Title } from './styled';

type ButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLButtonElement>;

export type Variations = 'warning' | 'interactive' | 'error';

export type LinkCardProps = {
  icon?: JSX.Element;
  title: string;
  variation?: Variations | undefined;
  href?: string;
  as: keyof HTMLElementTagNameMap;
  disabled?: boolean;
  target?: string;
  configNotice?: NoticeFlagProps;
  externalUrl?: boolean;
} & ButtonTypes;

export const LinkCard = ({
  icon,
  title,
  variation,
  href = '',
  disabled = false,
  target,
  configNotice,
  as,
  externalUrl = false,
  ...props
}: LinkCardProps) => {
  const location = useLocation();
  const page = location.pathname.replace(/\//g, '');

  if (as === 'a' || as === 'button') {
    const hasHref = !href.length || href === '#';
    const isDisabled = (as === 'a' && hasHref) || disabled;
    const attributeHref = !hasHref
      ? {
          href: target ? `${href}?${ARGS_UTM(page)}` : href,
        }
      : {};
    const addNoreferrer =
      target === '_blank' && !hasHref ? { rel: 'noreferrer', target } : {};
    const typeAs = as === 'button' && !hasHref ? 'a' : as;

    return (
      <WrapperLink hasExternalLink={externalUrl} path={attributeHref.href}>
        <StyledLinkCard
          as={externalUrl || as === 'button' ? typeAs : 'span'}
          tabIndex={0}
          alignItems="center"
          justifyContent="space-between"
          variation={variation}
          disabled={isDisabled}
          {...attributeHref}
          {...addNoreferrer}
          {...props}
        >
          {icon && <Svg svg={icon} title={title} />}
          <Title variation={variation}>
            {title}
            {!!configNotice && (
              <NoticeFlag type={configNotice.type} name={configNotice.name} />
            )}
          </Title>
          {target === '_blank' && (
            <Svg
              className="icon-arrow"
              svg={<Arrow />}
              width={24}
              title="open"
            />
          )}
        </StyledLinkCard>
      </WrapperLink>
    );
  }

  return null;
};

export default LinkCard;

import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useEventListener } from '../utils/hooks/use-event-listener';
import { StyledCollapse } from './styled';

export type CollapseProps = {
  children?: React.ReactNode;
  expanded: boolean;
};

export const Collapse = ({ children, expanded, ...rest }: CollapseProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  const [visibleChildren, setVisibleChildren] = useState(expanded);

  const changeHeight = useCallback(() => {
    if (!expanded) {
      return;
    }

    if (
      expanded &&
      contentRef.current &&
      contentRef.current.scrollHeight > maxHeight
    ) {
      setMaxHeight(contentRef.current?.scrollHeight);
    }
  }, [expanded, maxHeight]);

  // Watch for window resize events, and change Collapse height if needed.
  useEventListener('resize', changeHeight);

  // Adjust height on first render, depending on initial expanded state
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => changeHeight(), []);

  return (
    <StyledCollapse
      ref={contentRef}
      style={{
        overflow: expanded ? 'visible' : 'hidden',
        maxHeight:
          expanded && contentRef.current
            ? Math.max(contentRef.current.scrollHeight, maxHeight)
            : 0,
      }}
      onTransitionEnd={() => setVisibleChildren(expanded)}
      {...rest}
    >
      <div
        className="expand-inner-wrapper"
        aria-hidden={!expanded}
        style={{ opacity: visibleChildren ? 1 : 0 }}
      >
        {children}
      </div>
    </StyledCollapse>
  );
};

export default Collapse;

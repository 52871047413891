import { ArrowLink, Button } from '@worten-sardines/grill-ui';
import { Helmet } from 'react-helmet-async';

import { CardSchema } from '../../../types';

import {
  Container,
  Content,
  Headline,
  HighlightImage,
  SupportingText,
  Wrapper,
} from './styled';
import { REGISTER_DATALAYER } from '@worten-sardines/trackings/tracking-seller-center';

const IMAGE_WIDTH = 132;
const IMAGE_HEIGHT = 127;

const Card = ({
  fullWidth,
  highlight,
  highlightImageURL,
  label,
  openInNewTab,
  supportingText,
  targetURL,
  title,
}: CardSchema) => {
  const target = openInNewTab ? '_blank' : '_self';
  const type = highlight ? 'light' : 'dark';

  return (
    <>
      {highlightImageURL && (
        <Helmet prioritizeSeoTags>
          <link rel="preload" as="image" href={highlightImageURL} />
        </Helmet>
      )}

      <Wrapper fullWidth={fullWidth} highlight={highlight}>
        <Headline>{title}</Headline>
        <Container>
          <Content>
            <SupportingText>
              <p>{supportingText}</p>
            </SupportingText>
            <Button
              key={label}
              as="a"
              link
              href={targetURL}
              target={target}
              type={type}
              icon={<ArrowLink />}
              iconSide="right"
              onClick={() => {
                REGISTER_DATALAYER('EVENT_GENERIC_USER_BEHAVIOR', {
                  eventAction: 'card click',
                  eventCategory: 'cards',
                  eventLabel: title,
                });
              }}
            >
              {label}
            </Button>
          </Content>
          {highlightImageURL && (
            <HighlightImage>
              <img
                src={highlightImageURL}
                alt={title}
                width={IMAGE_WIDTH}
                height={IMAGE_HEIGHT}
                loading="lazy"
              />
            </HighlightImage>
          )}
        </Container>
      </Wrapper>
    </>
  );
};

export default Card;

import { Theme } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  .seller-premium-table-list {
    thead {
      th {
        font-size: ${Theme.fontsize.desktop.m};
      }
    }

    tbody {
      th,
      td {
        font-size: ${Theme.fontsize.desktop.m};
        background: ${Theme.sys.color.neu['02']};
      }
    }

    th,
    td {
      padding: 16px 8px;
    }
    th:not(:first-child),
    td:not(:first-child) {
      width: 16%;
      box-sizing: content-box;
    }
    th:last-child,
    td:last-child {
      width: 4.2rem;
      box-sizing: content-box;
    }
    th:not(:first-child):not(:last-child),
    td:not(:first-child):not(:last-child) {
      width: 16%;
      box-sizing: content-box;
    }
  }
`;

import styled, { css } from 'styled-components';

import {
  makeColor,
  makeHeight,
  makeResponsive,
  makeSpacing,
  makeText,
  makeWidth,
} from '../utils';
import { omitProps } from '../_common';
import { GenericObject } from '../utils/types';

type TablePropsStyle = {
  bordered?: boolean;
  enableColumnBorders?: boolean;
  borderRadius?: GenericObject;
  cellSpacing?: GenericObject;
  cellText?: GenericObject;
  borderColor?: string;
  backgroundColor?: string;
  backgroundColorHead?: string;
};

export const Container = styled.div<TablePropsStyle>`
  ${({ theme, bordered, borderColor, borderRadius, backgroundColor }) => css`
    overflow: hidden;
    border: 1px solid ${borderColor || theme.sys.color.neu['03']};
    ${borderRadius &&
    css`
      border-radius: ${borderRadius['topLeft']} ${borderRadius['topRight']}
        ${borderRadius['bottomLeft']} ${borderRadius['bottomRight']};
    `}
    ${!bordered &&
    css`
      border: 0;
    `};
    background-color: ${backgroundColor || theme.sys.color.neu['01']};
  `}
`;

export const StyledTable = styled.table.withConfig<TablePropsStyle>({
  shouldForwardProp: (prop) =>
    omitProps(['enableColumnBorders', 'backgroundColorHead'], prop),
})`
  ${({
    theme,
    borderColor,
    cellSpacing,
    cellText,
    enableColumnBorders,
    backgroundColorHead,
  }) => css`
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      &:not(:last-child) {
        border-bottom: 1px solid ${theme.sys.color.neu['03']};
      }
    }

    & th,
    td {
      font-weight: normal;
      text-align: left;
      ${cellText && makeText(cellText)}
      ${cellSpacing && makeSpacing(cellSpacing)}
      padding: ${theme.sys.dimension.spacing.Medium};
    }

    tbody {
      td {
        ${enableColumnBorders &&
        css`
          border-left: 1px solid ${theme.sys.color.neu['03']};
        `}
      }
    }

    thead,
    tfoot {
      > tr > th {
        font-weight: ${theme.weight.bold};
        text-align: left;
      }
    }

    thead {
      tr {
        border-bottom: 1px solid ${theme.sys.color.neu['03']};
      }
      th {
        background-color: ${backgroundColorHead || theme.sys.color.neu['04']};
        &:not(:first-child) {
          ${enableColumnBorders &&
          css`
            border-left: 1px solid transparent;
          `}
        }
      }
    }

    tfoot {
      tr {
        border-top: 1px solid ${theme.sys.color.neu['03']};
      }
      th {
        &:not(:first-child) {
          ${enableColumnBorders &&
          css`
            border-left: 1px solid ${theme.sys.color.neu['03']};
          `}
        }
      }
    }

    ${borderColor &&
    css`
      tr,
      th,
      td {
        border: 1px solid ${borderColor};
      }
    `}

    ${makeColor}
    ${makeResponsive}
    ${makeHeight}
    ${makeWidth}
  `}
`;

import { Button, HeroBanner } from '@worten-sardines/grill-ui';
import { HeroBannerSchema } from '../../types';
import { StyledHeroBanner } from './styled';

export const CMSHeroBanner = ({ schema }: { schema: HeroBannerSchema }) => {
  const CTAButtonVariation = schema.ctaButtonColor || 'neutral';
  const CTAButton = () =>
    schema.ctaButtonText ? (
      <Button
        variation={CTAButtonVariation}
        as="a"
        href={schema.targetURL}
        target={schema.openInNewTab ? '_blank' : '_self'}
        size="small"
      >
        {schema.ctaButtonText}
      </Button>
    ) : null;

  return (
    <StyledHeroBanner position={schema.position}>
      <HeroBanner {...schema} ctaButton={<CTAButton />} />
    </StyledHeroBanner>
  );
};

export default CMSHeroBanner;

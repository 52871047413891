/* eslint-disable @typescript-eslint/no-explicit-any */
import { Download, Svg } from '@worten-sardines/grill-ui';
import { IntlShape } from 'react-intl';
import { PayoutCellDownloadItem } from '../../components/PayoutCell/styled';
import {
  CycleDatesProps,
  Payout,
  PayoutSerializedProps,
} from '../../types/payouts.types';

import { cycleDates } from '../../__mock__/cycleDates.mock';
import { LinkCard } from '@worten-sardines/seller-center-ui-shared';
import { monthConverter } from './utils';
import PayoutCell from '../PayoutCell';

import { getDocumentTypeIntl } from './helpers';

const CYCLE_1 = 1;
const CYCLE_2 = 2;

export const serializePayoutsData = (
  data: Payout[],
  locale: string,
): PayoutSerializedProps => {
  const payoutsPerMonthAndCycle: Record<string, Payout[]> = {};

  // Initialize payoutsPerMonthAndCycle with all months of the year and cycles set to empty arrays
  for (let month = 0; month < 12; month++) {
    for (let cycle = 1; cycle <= 2; cycle++) {
      const key = `${new Date().getFullYear()}-${month}-${cycle}`;
      payoutsPerMonthAndCycle[key] = [];
    }
  }

  // Group payouts by month and cycle
  data.forEach((payout) => {
    const { documentDate, paymentCycle } = payout;
    const year = new Date(documentDate).getFullYear();
    const month = new Date(documentDate).getMonth();
    const cycle = paymentCycle;

    const key = `${year}-${month}-${cycle}`;

    if (!payoutsPerMonthAndCycle[key]) {
      payoutsPerMonthAndCycle[key] = [];
    }

    payoutsPerMonthAndCycle[key].push(payout);
  });

  // Convert to array and map months to their names
  const res = Object.entries(payoutsPerMonthAndCycle).map(([key, payouts]) => {
    const [year, month, cycle] = key.split('-');

    return {
      month: convertMonthNumberToName(month, year, locale).long,
      [`cycle${cycle}`]: payouts,
    };
  });

  // Group by month and combine cycles
  const result: any = res.reduce((acc: any, obj) => {
    const { month, ...rest } = obj;
    const existingMonth = acc.find((item: any) => item.month === month);

    if (existingMonth) {
      Object.assign(existingMonth, rest);
    } else {
      acc.push(obj);
    }

    return acc;
  }, []);

  return result;
};

export const dropdownItems = (cycle: Payout[], intl: IntlShape) => {
  if (!cycle) return [];

  //sorts by documentType and, if equal, by issuerCountryCode.
  const sortedCycle = cycle.sort((firstPayout, secondPayout) => {
    const compareDocumentType = firstPayout.documentType.localeCompare(
      secondPayout.documentType,
    );
    const compareCountry =
      firstPayout.issuerCountryCode?.localeCompare(
        secondPayout.issuerCountryCode ?? '',
      ) || 0;

    return compareDocumentType || compareCountry;
  });

  let docTypeCount = 0;

  const cycleItems = sortedCycle.map((item) => {
    docTypeCount = !item.invoiceId ? docTypeCount + 1 : docTypeCount;

    const label = getDocumentTypeIntl(
      { ...item, invoiceId: item.invoiceId || docTypeCount.toString() },
      intl,
    );

    return {
      label,
      children: (
        <PayoutCellDownloadItem href={item.fileUrl} target="_blank">
          {cycle.length > 1 ? (
            <LinkCard title={label} as="button" />
          ) : (
            <Svg
              svg={<Download />}
              title={item.documentType}
              width={'24px'}
              height={'24px'}
            />
          )}
        </PayoutCellDownloadItem>
      ),
    };
  });

  return cycleItems;
};

const sortPayoutsByDate = (data: Payout[]) =>
  [...data].sort(
    (firstPayout: Payout, secondPayout: Payout) =>
      new Date(firstPayout.documentDate).getTime() -
      new Date(secondPayout.documentDate).getTime(),
  );

export const payloadPayoutsTable = (
  data: Payout[],
  year: string,
  intl: IntlShape,
) => {
  if (!data) return [];

  const payoutData = serializePayoutsData(sortPayoutsByDate(data), intl.locale);

  return payoutData.map((payout) => {
    const { month, cycle1, cycle2 } = payout;

    const monthNumber = monthConverter(month, intl.locale);
    const cycleDates: any = payoutsCycleDates(
      Number(monthNumber),
      Number(year),
    );

    const cycle1Items = dropdownItems(cycle1, intl);
    const cycle2Items = cycle2 && dropdownItems(cycle2, intl);

    return {
      month,
      cycle1: (
        <PayoutCell
          items={cycle1Items || []}
          month={month}
          cycle={CYCLE_1}
          cycleInfo={cycleDates?.cycle1}
        />
      ),
      cycle2: (
        <PayoutCell
          items={cycle2Items || []}
          month={month}
          cycle={CYCLE_2}
          cycleInfo={cycleDates?.cycle2}
        />
      ),
    };
  });
};

export const payoutsCycleDates = (month: number, year: number) => {
  const cyclesYear: CycleDatesProps =
    cycleDates.find((item) => item.year === String(year)) || cycleDates[0];

  const cyclesMonth = cyclesYear.months.find(
    (item) => item.month === monthConverter(month, 'en'),
  );

  return cyclesMonth;
};

export const convertMonthNumberToName = (
  month: string,
  year: string,
  locale: string,
) => {
  return {
    short: new Intl.DateTimeFormat(locale, { month: 'short' }).format(
      new Date(Number(year), Number(month)),
    ),
    long: new Intl.DateTimeFormat(locale, { month: 'long' }).format(
      new Date(Number(year), Number(month)),
    ),
  };
};

import styled, { css } from 'styled-components';

import { omitProps, GUTTER_CSS } from '../_common';
import { GrillUtils } from '../utils';
import { GrillUtilsProps } from '../utils/types';

export type GridProps = {
  gutter?: boolean;
} & Omit<GrillUtilsProps, 'flexDirection' | 'flexWrap'>;

export const Grid = styled.div.withConfig<GridProps>({
  shouldForwardProp: (prop) => omitProps(['gutter', 'columns'], prop),
})`
  ${({ gutter }) => css`
    display: grid;

    ${gutter && GUTTER_CSS};
  `};

  ${GrillUtils}
`;

export default Grid;

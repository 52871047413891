import { useEffect, useRef, useState } from 'react';
import { DropdownItemProps, DropdownProps } from './types';
import {
  DropdownTrigger,
  StyledDropdown,
  DropdownWrapper,
  DropdownItem,
  DropdownItemChildren,
} from './styled';

export const Dropdown = ({
  children,
  items,
  defaultLabel = 'Download file ',
  disableOnHover = false,
  maxHeight,
  placement = 'bottomLeft',
}: DropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    if (disableOnHover) return;
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    if (disableOnHover) return;
    setIsOpen(false);
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <DropdownWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={dropdownRef}
    >
      <DropdownTrigger onClick={toggleDropdown}>{children}</DropdownTrigger>
      {isOpen && (
        <StyledDropdown placement={placement} maxHeight={maxHeight}>
          {items?.map(({ label, children, action }: DropdownItemProps, count) =>
            children ? (
              <DropdownItemChildren key={count}>
                {children}
              </DropdownItemChildren>
            ) : (
              <DropdownItem
                key={count}
                onClick={() => {
                  toggleDropdown();
                  action?.();
                }}
              >
                {label ? label : `${defaultLabel} ${count + 1}`}
              </DropdownItem>
            ),
          )}
        </StyledDropdown>
      )}
    </DropdownWrapper>
  );
};

export default Dropdown;

import styled, { DefaultTheme, css } from 'styled-components';
import { DAY_WIDTH } from '.';
import { HiddenStateProps } from './types';

type StyledEventCellProps = {
  partiallyHidden: HiddenStateProps;
};

type EventCellWrapperProps = {
  interval: number;
};

const crossHiddenSideElement = {
  left: (theme: DefaultTheme) => css`
    display: flex;
    left: 0;
    border-right: 1px solid ${theme.color.neutral['neu-03']};
    svg {
      transform: rotate(180deg);
    }
  `,
  right: (theme: DefaultTheme) => css`
    display: flex;
    right: 0;
    border-left: 1px solid ${theme.color.neutral['neu-03']};
  `,
};

export const StyledEventCellContainer = styled.div`
  width: 100%;
`;

export const StyledEventCellWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ theme }) => theme.spacing.xxg};
  overflow: hidden;
  position: relative;
`;

export const StyledSingleEvents = styled.div<EventCellWrapperProps>`
  display: inline-flex;
  position: relative;
  gap: ${({ interval }) => interval * DAY_WIDTH}px;
`;

export const HideContentIndicatorLeft = styled.div<StyledEventCellProps>`
  ${({ theme, partiallyHidden }) => css`
    display: none;
    position: absolute;
    top: 0;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 35px;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(05px);
    transform-origin: center;

    z-index: 3;

    svg {
      fill: ${theme.color.neutral['neu-06']};
    }

    ${partiallyHidden.left && crossHiddenSideElement.left(theme)};
  `};
`;

export const HideContentIndicatorRight = styled.div<StyledEventCellProps>`
  ${({ theme, partiallyHidden }) => css`
    display: none;
    position: absolute;
    top: 0;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 35px;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(05px);
    transform-origin: center;

    z-index: 3;

    svg {
      fill: ${theme.color.neutral['neu-06']};
    }

    ${partiallyHidden.right && crossHiddenSideElement.right(theme)};
  `};
`;

import { SELLER_ACADEMY_URL } from '@worten-sardines/configs/config-seller-center';
import {
  ItemLinksProps,
  LinksList,
  ListProps,
} from '@worten-sardines/seller-center-ui-shared';
import { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import messageUsefulLinks from './messages';
import { usefulLinks } from './useful-links';

const updateLinkWithExternalUrl = (
  item: ItemLinksProps,
  locale: keyof typeof SELLER_ACADEMY_URL.ACADEMY,
): ItemLinksProps => {
  if (item.key === 'sellerAcademy') {
    return {
      ...item,
      externalUrl: SELLER_ACADEMY_URL.ACADEMY[locale],
    };
  }
  return item;
};
const transformUsefulLinks = (
  links: typeof usefulLinks,
  locale: keyof typeof SELLER_ACADEMY_URL.ACADEMY,
): Array<ListProps> => {
  return links.map((group) => {
    const transformedLinks = group.links.map((items) =>
      items.map((item) => updateLinkWithExternalUrl(item, locale)),
    );
    return { divider: group.divider, links: transformedLinks };
  });
};

export const UsefulLinks = memo(() => {
  const intl = useIntl();
  const locale = intl.locale as keyof typeof SELLER_ACADEMY_URL.ACADEMY;
  const [menu, setMenu] = useState<Array<ListProps>>();

  useEffect(() => {
    const updatedMenu = transformUsefulLinks(usefulLinks, locale);
    setMenu(updatedMenu);
  }, [locale]);

  if (!menu) return null;

  return <LinksList menu={menu} messages={messageUsefulLinks} />;
});

export default UsefulLinks;

import { Svg } from '@worten-sardines/grill-ui';
import { Legend } from './styled';

type LegendItem = {
  icon: JSX.Element;
  iconTitle: string;
  label: string;
  width?: string;
  color?: string;
};

const LegendItem = ({
  icon,
  iconTitle,
  label,
  width = '16',
  color = '#e51b15',
}: LegendItem) => (
  <Legend>
    <Svg svg={icon} width={width} title={iconTitle} color={color} />
    <span>{label}</span>
  </Legend>
);

export default LegendItem;

import { AchievementStatus } from '../../AchievementStatus';
import { CriteriaArea } from '../components/CriteriaArea';
import { MetricValueItem } from '../components/MetricValueItem';
import { CreateListDataProps } from '../types';

export const createListData = ({
  header,
  data,
  channel,
  locale,
}: CreateListDataProps) => {
  const channelData = data?.find((item) => item.channel === channel);

  if (!channelData) {
    return {
      header,
      data: [],
    };
  }

  const dataMapped = channelData.criterias
    ?.map((criteria) => {
      const metrics = criteria.metrics.sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      const sellerMetricValues = metrics.map((metric) => (
        <MetricValueItem
          key={`${metric.name}-currentValue`}
          name={metric.name}
          value={metric.currentValue ?? '-'}
          locale={locale}
        />
      ));

      const goalValues = metrics.map((metric) => (
        <MetricValueItem
          key={`${metric.name}-targetValue`}
          name={metric.name}
          value={metric.targetValue}
          locale={locale}
          showPrefix
        />
      ));

      return {
        evaluation: <CriteriaArea criteria={criteria} />,
        sellerMetricValue: sellerMetricValues,
        goalValue: goalValues,
        achievedStatus: <AchievementStatus achieved={criteria.achieved} />,
      };
    })
    .sort((a, b) =>
      a.evaluation.props.criteria.name.localeCompare(
        b.evaluation.props.criteria.name,
      ),
    );

  return {
    header,
    data: dataMapped,
  };
};

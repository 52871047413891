import { ProgressBar } from '@worten-sardines/grill-ui';
import { useState } from 'react';
import TooltipContent from './TooltipContent';
import { Container, Description, Header, Title } from './styled';

export type ProgressBarProps = {
  title: string;
  progress: number;
  description?: string;
  tooltipMessage?: string | JSX.Element;
  showTooltip?: boolean;
  showPremiumBadge?: boolean;
};

export const ProgressTracker = ({
  title,
  progress,
  description,
  tooltipMessage,
  showTooltip = false,
  showPremiumBadge = false,
}: ProgressBarProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(showTooltip);

  const progressColor = `linear-gradient(97.9deg, #FFF4D8 6.09%, #FFE895 30.07%, #E0AF31 91.75%);
`;

  const verticalPosition = progress < 40 ? 'bottom' : 'top';

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </Header>
      <ProgressBar
        progress={progress}
        labelColor="#fff"
        progressColor={progressColor}
        rounded
        custom={
          tooltipOpen
            ? {
                component: (
                  <TooltipContent
                    showBelow={progress < 40}
                    onClose={() => setTooltipOpen(false)}
                    showPremiumBadge={showPremiumBadge}
                  >
                    {tooltipMessage}
                  </TooltipContent>
                ),
                horizontalPosition: 'right',
                verticalPosition: verticalPosition,
              }
            : undefined
        }
      />
    </Container>
  );
};

export default ProgressTracker;

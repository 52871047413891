import styled, { DefaultTheme, css } from 'styled-components';
import { DateDisplayProps } from './index';
import { omitProps } from '@worten-sardines/grill-ui';

export type DateDisplayStyleProps = Pick<
  DateDisplayProps,
  'isRounded' | 'isActived' | 'isStatic' | 'variation'
>;

const wrapperModifiers = {
  primary: (theme: DefaultTheme) => css`
    color: ${theme.color.neutral['neu-08']};
  `,
  secondary: (theme: DefaultTheme) => css`
    color: ${theme.color.neutral['neu-06']};
  `,
  alert: (theme: DefaultTheme) => css`
    color: ${theme.color.main['m-01']};
  `,
};

export const StyledDateDisplay = styled.div.withConfig<DateDisplayStyleProps>({
  shouldForwardProp: (prop) =>
    omitProps(['isRounded', 'isActived', 'variation', 'isStatic'], prop),
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: fit-content;
  min-width: 35px;

  ${({ theme, variation, isActived, isRounded, isStatic }) => css`
    padding: ${theme.spacing.xxxs};
    font-family: ${theme.family.primary};
    color: ${theme.color.neutral['neu-08']};
    border-radius: ${theme.Radius.xxxs};
    background-color: ${theme.color.neutral.neu01};
    border: 1px solid ${theme.color.neutral.neu01};
    transition: all ${theme.animation.duration.medium};

    .dateNumber {
      font-weight: ${theme.weight.bold};
      font-size: ${theme.fontsize.desktop.s1};
    }
    .label {
      font-size: ${theme.fontsize.desktop.xs};
    }

    ${!!variation && wrapperModifiers[variation](theme)};

    ${isActived &&
    css`
      color: ${theme.color.neutral.neu01};
      background-color: ${theme.color.neutral['neu-10']};
      border-color: ${theme.color.neutral['neu-10']};
    `}

    ${isRounded &&
    css`
      flex-direction: row;
      border-radius: ${theme.Radius.sm}};
      justify-content: center;
      height: 35px;
      gap: ${theme.spacing.xxxs};
      padding: 0 ${theme.spacing.xxs};
    `}

    ${!isStatic &&
    css`
      &:hover {
        border-color: ${theme.color.neutral['neu-08']};
        background-color: ${theme.color.neutral['neu-04']};
        color: ${theme.color.neutral['neu-08']};
      }
    `}
  `};
`;

import { useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';

import { withErrorBoundary, ErrorBoundary } from 'react-error-boundary';
import { getExternalAuthUrl, useMutate } from '@worten-sardines/shared/hooks';
import { useSession } from '@worten-sardines/shared/hooks';
import { WortenSeller } from '@worten-sardines/seller-center-ui-shared';
import { MainTemplate } from '@worten-sardines/shared-ui';
import { ARGS_UTM } from '@worten-sardines/configs/config-seller-center';

import {
  getUserInfo,
  setSignin,
} from '@worten-sardines/seller-center/data-access';

import {
  Button,
  Block,
  Svg,
  Heading,
  Loading,
  Input,
  ArrowLink,
} from '@worten-sardines/grill-ui';

import { ErrorBoundary as ErrorBoundaryPage } from '../ErrorBoundary';
import messages from './messages';

import {
  StyledLoginFeature,
  StyledLogin,
  StyledCopyright,
  StyledLink,
} from './styled';
import { regexValidateEmailPattern } from '@worten-sardines/shared/helpers';

type SigninResponseType = {
  url: string;
};
type FormValues = {
  email: string;
};

// foo

export function SellerCenterFeatureLogin() {
  const intl = useIntl();

  const {
    data: { isAuthenticated },
  } = useSession();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const signinResponse = ({ url }: SigninResponseType) => {
    if (url) {
      window.location.href = getExternalAuthUrl(url);
    }
  };
  const [{ data, loading }, signinMutate] = useMutate(
    setSignin,
    [],
    false,
    signinResponse,
  );

  if (isAuthenticated) {
    window.location.href = '/';
    return null;
  }

  if (isAuthenticated === undefined || !!data) {
    // Show loading when (re)trying sign in, unless the Sign In button was clicked.
    return (
      <Block mt="2rem">
        <Loading size="lg" />
      </Block>
    );
  }

  const onAuthentication: SubmitHandler<FormValues> = (formData) => {
    const email = formData.email;
    // Redirect to external OAuth provider
    signinMutate([[[getUserInfo().key]], { email }]);
  };

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <ErrorBoundaryPage
          error={error}
          resetErrorBoundary={resetErrorBoundary}
        />
      )}
    >
      <MainTemplate>
        <MainTemplate.Content>
          <StyledLoginFeature>
            <StyledLogin>
              <Block>
                <Svg
                  className="icon-worten-seller"
                  svg={<WortenSeller />}
                  title="Worten Seller"
                />

                <Heading as="h1" style={{ fontWeight: 'normal' }}>
                  {intl.formatMessage(messages.titleLogin)}
                </Heading>
              </Block>

              <Block backgroundColor="#ffffff">
                <form>
                  <Input
                    label={`${intl.formatMessage(messages.textfieldLabel)}`}
                    placeholder={`${intl.formatMessage(
                      messages.textfieldPlaceholder,
                    )}`}
                    isRequired
                    autoFocus
                    error={errors}
                    {...register('email', {
                      required: `${intl.formatMessage(
                        messages.textfieldReturnValidateText,
                      )}`,
                      pattern: {
                        value: regexValidateEmailPattern,
                        message: `${intl.formatMessage(
                          messages.textfieldError,
                        )}`,
                      },
                    })}
                    autoComplete="on"
                  />
                  <Button
                    aria-label="login button"
                    icon={<ArrowLink />}
                    iconSide="right"
                    onClick={handleSubmit(onAuthentication)}
                    disabled={loading || !!data}
                  >
                    {intl.formatMessage(messages.buttonLogin)}
                  </Button>
                </form>
                <Block>
                  <StyledLink
                    href={`https://www.worten.pt/marketplace/vender-na-worten?${ARGS_UTM(
                      'joinus',
                    )}`}
                    target="_blank"
                    rel="noopener noreferer"
                  >
                    {intl.formatMessage(messages.linkRegister)}
                  </StyledLink>
                </Block>
              </Block>
            </StyledLogin>
            <StyledCopyright flexDirection="column" justifyContent="flex-end">
              {intl.formatMessage(messages.copyright)}
            </StyledCopyright>
          </StyledLoginFeature>
        </MainTemplate.Content>
      </MainTemplate>
    </ErrorBoundary>
  );
}

export default withErrorBoundary(SellerCenterFeatureLogin, {
  FallbackComponent: ({ error, resetErrorBoundary }) => (
    <ErrorBoundaryPage error={error} resetErrorBoundary={resetErrorBoundary} />
  ),
});

import { useState, useMemo, useEffect } from 'react';
import DataTableFilterManager from '../DataTableFilterManager';
import { Filter } from '../../assets/images';
import { StyledDataTableActionsBarButtonWrapper, StyledBadge } from './styled';
import Button from '../../Button';
import Svg from '../../Svg';
import { ColumnPropsType, FilteredType } from '../types';

type DataTableActionsBarProps = {
  configFilter: Array<ColumnPropsType> | [];
  loading: boolean;
  filtered: FilteredType;
  compactMode?: boolean;
  enableExport: boolean;
  pagination?: boolean;
  onSubmitFilter: (filter: FilteredType) => void;
  onExportData?: () => void;
};

const checkAdvancedFilter = (column: ColumnPropsType) =>
  (typeof column.show !== 'undefined' &&
    !column?.show &&
    (typeof column?.advancedFilterCategory === 'undefined' ||
      column?.advancedFilterCategory)) ||
  column?.advancedFilterCategory;

export const DataTableActionsBar = ({
  loading,
  configFilter,
  filtered,
  compactMode,
  onSubmitFilter,
  pagination,
  onExportData,
  enableExport,
}: DataTableActionsBarProps) => {
  const [isFiltersCollapsed, setIsFiltersCollapsed] = useState(false);
  const [filterCount, setFilterCount] = useState(0);

  //Check if filters are filterable on configFilter
  const filterableColumns = useMemo(
    () =>
      configFilter.filter((c) =>
        typeof c.filterable === 'undefined' ? true : c.filterable,
      ),
    [configFilter],
  );

  //filters visible
  const mainFilters = useMemo(
    () => filterableColumns.filter((c) => !checkAdvancedFilter(c)),
    [filterableColumns],
  );

  //filters hidden
  const advancedFilters = useMemo(
    () => filterableColumns.filter((c) => checkAdvancedFilter(c)),
    [filterableColumns],
  );

  const hasFilters = filterableColumns.length;

  const showAdvancedFiltersButton =
    hasFilters > 0 && (compactMode || advancedFilters.length > 0);

  //checks if its mobile mode
  useEffect(() => {
    if (compactMode) {
      // Whenever there's a swith to compact mode, reset Collapse panel
      setIsFiltersCollapsed(false);
    }
  }, [compactMode]);

  const countAppliedFilters = (filters: FilteredType) => {
    const options = ['offset', 'limit', 'sort_by', 'order_by'];

    return Object.keys(filters).filter((f) => {
      if (options.some((option) => option === f) || !filters[f]) {
        return 0;
      }
      return f;
    }).length;
  };

  const handleSubmitFilter = (filters: FilteredType) => {
    setFilterCount(countAppliedFilters(filters));

    const clean_filters = Object.fromEntries(
      Object.entries(filters).filter(([_, v]) => v),
    );

    onSubmitFilter(clean_filters);
  };

  const getFilterButtonLabel = () => (compactMode ? 'Filters' : 'More filters');

  return (
    <>
      <StyledDataTableActionsBarButtonWrapper>
        {showAdvancedFiltersButton && (
          <Button
            onClick={() => setIsFiltersCollapsed(!isFiltersCollapsed)}
            disabled={loading}
            variation="secondary"
            size="small"
            icon={
              filterCount ? (
                <StyledBadge>{filterCount}</StyledBadge>
              ) : (
                <Svg svg={<Filter />} width={18} title="close" />
              )
            }
          >
            {getFilterButtonLabel()}
          </Button>
        )}
      </StyledDataTableActionsBarButtonWrapper>
      {hasFilters ? (
        <DataTableFilterManager
          filtered={filtered}
          mainFilters={mainFilters}
          advancedFilters={advancedFilters}
          showAdvancedFilters={isFiltersCollapsed}
          onSubmitFilter={handleSubmitFilter}
          onHideAdvancedFilters={() => setIsFiltersCollapsed(false)}
          pagination={pagination}
          compactMode={compactMode}
        />
      ) : null}
    </>
  );
};

export default DataTableActionsBar;

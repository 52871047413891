import { StyledErrorMessageField } from './styled';

export type ErrorMessageFieldProps = {
  message: string;
} & React.HTMLAttributes<HTMLSpanElement>;

export const ErrorMessageField = ({ message }: ErrorMessageFieldProps) => {
  return (
    <StyledErrorMessageField
      role="alert"
      aria-live="polite"
      aria-atomic="true"
      tabIndex={-1}
    >
      {message}
    </StyledErrorMessageField>
  );
};

export default ErrorMessageField;

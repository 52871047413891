import { defineMessages } from 'react-intl';

export default defineMessages({
  submissionPeriod: {
    id: 'libs.seller-center.cmsPage.campaign.submissionPeriod',
    defaultMessage: 'Submission period',
  },
  runningTime: {
    id: 'libs.seller-center.cmsPage.campaign.runningTime',
    defaultMessage: 'Running time',
  },
});

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { GrillUtils } from '../utils';
import { Container, StyledTable } from './styled';
import { ColumnPropsType, DataTableObj } from '../DataGrid/types';
import { GenericObject } from '../utils/types';

type TableProps = {
  dataTable: DataTableObj;
  summary?: string;
  className?: string;
  bordered?: boolean;
  enableColumnBorders?: boolean;
  borderRadius?: GenericObject;
  cellSpacing?: GenericObject;
  cellText?: GenericObject;
  borderColor?: string;
  backgroundColor?: string;
  backgroundColorHead?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};


export const Table = (props: TableProps) => {
  const {
    dataTable,
    summary,
    className,
    bordered = false,
    borderColor,
    enableColumnBorders,
    borderRadius,
    backgroundColor,
    backgroundColorHead,
    ...rest
  } = props;
  const [rowSelection, setRowSelection] = useState({});

  const data = [...dataTable.data];

  const columns = useMemo<ColumnDef<ColumnPropsType>[]>(() => {
    if (dataTable.footer) {
      return dataTable.header.map((col: ColumnPropsType, index: number) => ({
        accessorKey: col.id,
        header: col.cell,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cell: (info: any) => info.renderValue(),
        footer: dataTable.footer ? dataTable.footer[index] : '',
      }));
    }

    return dataTable.header.map((col: ColumnPropsType) => ({
      accessorKey: col.id,
      header: col.cell,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (info: any) => info.renderValue(),
    }));
  }, [dataTable]);

  const columnVisibility = dataTable.header
    .filter((c: ColumnPropsType) => c.show === false)
    .reduce(
      (acc: object, c: ColumnPropsType) => ({
        ...acc,
        [c.id as keyof typeof dataTable.header]: false,
      }),
      {},
    );

  const table = useReactTable({
    data,
    columns: columns,
    state: { rowSelection, columnVisibility },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
  });

  return (
    <Container
      bordered={bordered}
      borderRadius={borderRadius}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    >
      <StyledTable
        summary={summary}
        className={className}
        {...(rest as typeof GrillUtils)}
        enableColumnBorders={enableColumnBorders}
        backgroundColorHead={backgroundColorHead}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th scope="col" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell, index) =>
                index !== 0 && dataTable.header ? (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ) : (
                  <th scope="row" key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </th>
                ),
              )}
            </tr>
          ))}
        </tbody>
        {dataTable.footer && (
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </StyledTable>
    </Container>
  );
};

export default Table;

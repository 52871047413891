import { Svg } from '@worten-sardines/grill-ui';
import { CheckIn, CheckOff } from '@worten-sardines/seller-center-ui-shared';

export const AchievementStatus = ({
  achieved = false,
}: {
  achieved?: boolean;
}) => {
  const title = achieved ? 'check-in' : 'check-off';
  const getSVG = () => {
    if (achieved) return <CheckIn />;
    return <CheckOff />;
  };

  return <Svg svg={getSVG()} title={title} width={25} />;
};

export default AchievementStatus;

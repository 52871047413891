import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ChevronIcon } from '../assets/images';
import { Svg } from '../Svg';
import SelectItem from './SelectItem';
import {
  SelectBodyOptions,
  StyledIcon,
  StyledInput,
  StyledList,
} from './styled';
import { Option, SelectInput as SelectInputTypes } from './types';

export type SelectInputProps = {
  hasError?: boolean;
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
} & SelectInputTypes;

export const SelectInput = ({
  options,
  name,
  isRequired,
  label,
  disabled,
  hasError = false,
  validationSchema,
  handleOpen,
  handleClose,
  isEmptyOption,
  register,
  isOpen,
  handleOnChange,
  ...props
}: SelectInputProps) => {
  const registerRH = register ? { ...register(name, validationSchema) } : null;

  const { setValue } = useForm();

  const hasSelectedOption = options.find((option) => option.selected);

  const [currentValue, setCurrentValue] = useState<Option>(
    hasSelectedOption || [{ label: '', value: '' }][0],
  );

  const controlsName = `${name}-custom-select-list`;

  const hasExternalHandler = typeof handleOnChange === 'function';

  const handleValueChange = (option: Option) => {
    if (hasExternalHandler) {
      handleOnChange(option);
    } else {
      setValue(name, option.value);
    }

    setCurrentValue(option);
  };

  const handleChange = (option: Option) => {
    handleValueChange(option);

    handleClose();
  };

  const listId = `select-input-item-${name}`;

  return (
    <>
      <StyledInput
        aria-controls={controlsName}
        aria-describedby={controlsName}
        type={undefined} // We render a select. We can ignore the type provided by the `Input`.
        placeholder=" "
        id={name}
        hasError={hasError}
        disabled={disabled}
        onClick={() => handleOpen()}
        autoComplete="off"
        readOnly={true}
        isOpen={isOpen}
        value={currentValue?.label || ''}
        {...props}
        {...registerRH}
      />

      <StyledIcon
        isVisible={isOpen}
        tabIndex={0}
        onClick={() => handleOpen()}
        role="button"
        hasError={hasError}
        disabled={disabled}
      >
        <Svg svg={<ChevronIcon />} title="Select Icon" width={24} height={24} />
      </StyledIcon>

      <SelectBodyOptions
        hasError={hasError}
        disabled={disabled}
        isVisible={isOpen}
      >
        <StyledList className="options" role="listbox" id={controlsName}>
          {isEmptyOption && (
            <SelectItem
              id={`${listId}-empty-option`}
              key={listId}
              option={{ label: '', value: '' }}
              handleChange={handleChange}
              aria-label="empty option"
            />
          )}

          {options.map((option, index) => (
            <SelectItem
              id={listId}
              selectedValue={currentValue?.value}
              key={`select-input-item-${name}-${index}`}
              option={option}
              handleChange={handleChange}
            />
          ))}
        </StyledList>
      </SelectBodyOptions>
    </>
  );
};

export default SelectInput;

import { Flex, omitProps } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

type StyledCMSSidebarWrapperProps = {
  position?: string;
};

export const StyledCMSSidebarWrapper = styled(Flex).withConfig({
  shouldForwardProp: (prop) => omitProps(['position'], prop),
})`
  flex-direction: ${({ position }: StyledCMSSidebarWrapperProps) =>
    position === 'top' ? 'column-reverse' : 'column'};
`;

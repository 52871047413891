import { Helmet } from 'react-helmet-async';

export type SEOProps = {
  title: string;
  description?: string;
  children?: React.ReactNode;
};

export const SEO = ({ children, description, title }: SEOProps) => (
  <Helmet prioritizeSeoTags>
    <title>{title}</title>
    {description && <meta name="description" content={description} />}
    {children}
  </Helmet>
);

export default SEO;

import styled, { css } from 'styled-components';

export const StyledErrorMessageField = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.main['m-01']};
    font-size: ${theme.fontsize.desktop.s1};
    font-weight: ${theme.weight.semibold};
  `}
  width: 100%;
`;

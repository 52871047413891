import { Theme } from '@worten-sardines/grill-ui';
import styled from 'styled-components';

export const StyledHeaderBar = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${Theme.size.xxl};
  padding: 0 ${Theme.spacing.gt};
  background-color: ${Theme.color.neutral.neu01};
  border-bottom: 1px solid ${Theme.color.main['m-01']};
  gap: ${Theme.spacing.md};
  position: relative;
  z-index: 100;
`;

import { defineMessages } from 'react-intl';

export default defineMessages({
  evaluation: {
    id: 'libs.seller-center.feature.seller-premium.Metrics.evaluation',
    defaultMessage: 'Evaluation criteria',
  },
  sellerMetricValue: {
    id: 'libs.seller-center.feature.seller-premium.Metrics.sellerMetricValue',
    defaultMessage: 'Metric',
  },
  goalValue: {
    id: 'libs.seller-center.feature.seller-premium.Metrics.goalValue',
    defaultMessage: 'Goal',
  },
  ACTIVITY_TIME: {
    id: 'libs.seller-center.feature.seller-premium.Metrics.activityTime',
    defaultMessage: 'Beginning of activity',
  },
  ACTIVITY_TIME_DAYS: {
    id: 'libs.seller-center.feature.seller-premium.Metrics.activityTimeDays',
    defaultMessage: 'Greater than or equal to 90 days since store activation',
  },
  ACCEPTANCE_RATE: {
    id: 'libs.seller-center.feature.seller-premium.Metrics.acceptanceRate',
    defaultMessage: 'Acceptance Rate',
  },
  'D-30_ACCEPTANCE_RATE': {
    id: 'libs.seller-center.feature.seller-premium.Metrics.d30AcceptanceRate',
    defaultMessage: 'Greater than or equal to 98% in the last 30 days',
  },
  ACCEPTANCE_TIME: {
    id: 'libs.seller-center.feature.seller-premium.Metrics.acceptanceTime',
    defaultMessage: 'Acceptance Time',
  },
  'D-30_ACCEPTANCE_TIME_HOURS': {
    id: 'libs.seller-center.feature.seller-premium.Metrics.d30AcceptanceTimeHours',
    defaultMessage: 'Less than or equal to 6 hours in the last 30 days',
  },
  INCIDENT_RATE: {
    id: 'libs.seller-center.feature.seller-premium.Metrics.incidentRate',
    defaultMessage: 'Incident Rate',
  },
  'D-30_INCIDENT_RATE': {
    id: 'libs.seller-center.feature.seller-premium.Metrics.d30IncidentRate',
    defaultMessage: 'Less than or equal to 2% in the last 30 days',
  },
  LATE_SHIPMENT_RATE: {
    id: 'libs.seller-center.feature.seller-premium.Metrics.lateShipmentRate',
    defaultMessage: 'Late Shipment Rate',
  },
  'D-30_LATE_SHIPMENT_RATE': {
    id: 'libs.seller-center.feature.seller-premium.Metrics.d30LateShipmentRate',
    defaultMessage: 'Less than or equal to 2,5% in the last 30 days',
  },
  NSS_SCORE: {
    id: 'libs.seller-center.feature.seller-premium.Metrics.nssScore',
    defaultMessage: 'NSS Score',
  },
  'D-60_NSS': {
    id: 'libs.seller-center.feature.seller-premium.Metrics.d60Nss',
    defaultMessage: 'Greater than or equal to 42 in the last 60 days',
  },
  NSS_REPLIES: {
    id: 'libs.seller-center.feature.seller-premium.Metrics.nssReplies',
    defaultMessage: 'NSS Replies',
  },
  'D-60_NSS_REPLIES': {
    id: 'libs.seller-center.feature.seller-premium.Metrics.d60NssReplies',
    defaultMessage: 'Greater than or equal to 8 responses in the last 60 days',
  },
  NSS_REPLY_RATE: {
    id: 'libs.seller-center.feature.seller-premium.Metrics.nssReplyRate',
    defaultMessage: 'NSS Reply Rate',
  },
  'D-60_NSS_REPLY_RATE': {
    id: 'libs.seller-center.feature.seller-premium.Metrics.d60NssReplyRate',
    defaultMessage:
      'Greater than or equal to 5% of orders delivered in the last 60 days',
  },
  RATING: {
    id: 'libs.seller-center.feature.seller-premium.Metrics.rating',
    defaultMessage: 'Rating',
  },
  'D-90_AVG_RATING': {
    id: 'libs.seller-center.feature.seller-premium.Metrics.d90AvgRating',
    defaultMessage:
      'Average rating over the last 90 days greater than or equal to 4 stars',
  },
  PROCESSED_ORDERS: {
    id: 'libs.seller-center.feature.seller-premium.Metrics.processedOrders',
    defaultMessage: 'Processed Orders',
  },
  'D-90_PROCESSED_ORDERS': {
    id: 'libs.seller-center.feature.seller-premium.Metrics.d90ProcessedOrders',
    defaultMessage:
      'More than 60 processed orders since since store activation',
  },
  TOTAL_PROCESSED_ORDERS: {
    id: 'libs.seller-center.feature.seller-premium.Metrics.totalProcessedOrders',
    defaultMessage: 'More than 20 orders processed in the last 90 days',
  },
});

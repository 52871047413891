export type Query = {
  [key: string]: number | number[] | string | string[] | null | undefined;
};

export function buildQueryString(query: Query): string {
  const encode = encodeURIComponent;

  const pairs: string[] = [];

  for (const [key, value] of Object.entries(query)) {
    const encodedKey = encode(key);

    if (!value) {
      continue;
    }

    if (Array.isArray(value)) {
      const encodedValues = value.map(encode).join(',');
      pairs.push(`${encodedKey}=[${encodedValues}]`);
    } else {
      pairs.push(`${encodedKey}=${encode(String(value))}`);
    }
  }

  return pairs.length ? `?${pairs.join('&')}` : '';
}

import { MutableRefObject, useCallback, useLayoutEffect, useRef } from 'react';

const NO_OP = (v: string) => v;

/**
 * Safely dispatches reducer actions based on component mount state.
 * Prevents "Can't perform a React state update on an unmounted component" errors.
 *
 * @param {function} dispatch Dispatcher callback from useReducer/useState
 *
 */

export type Dispatch = <A>(action: A) => void;

export const useSafeDispatch = (
  dispatch: Dispatch,
): [Dispatch, MutableRefObject<boolean>] => {
  const isMountedRef = useRef(false);

  useLayoutEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const safeDispatcher = useCallback<Dispatch>(
    (...args) => (isMountedRef.current ? dispatch(...args) : NO_OP),
    [dispatch],
  );

  return [safeDispatcher, isMountedRef];
};

export default useSafeDispatch;

import { omitProps } from '@worten-sardines/grill-ui';
import styled from 'styled-components';
import { Form123FormSchema } from '../../types';

export type Form123FormStyled = Pick<
  Form123FormSchema,
  'fullWidth' | 'height' | 'position'
>;

export const StyledIframe = styled.iframe.withConfig({
  shouldForwardProp: (prop) => omitProps(['fullWidth', 'position'], prop),
})`
  width: ${({ fullWidth }: Form123FormStyled) => (fullWidth ? '100%' : '75%')};
  grid-row: ${({ position }: Form123FormStyled) => position};
  height: ${({ height }: Form123FormStyled) => (height ? height : '100%')};
  overflow: hidden;
`;
  
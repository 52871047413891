import styled, { css } from 'styled-components';
import { Flex, Theme as theme } from '@worten-sardines/grill-ui';

type WrapperProps = {
  position?: number | null;
};

export const StyledWrapperCTAButton = styled(Flex).attrs({
  justifyItems: 'center',
  alignItems: 'center',
  gridGap: 3,
})<WrapperProps>`
  margin-top: ${theme.spacing.sm};
  justify-content: ${(prop) => prop.alignContent};
  a,
  button {
    min-width: fit-content;
  }
  ${({ position }) => css`
    grid-row: ${position};
  `}
`;

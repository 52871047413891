import { useCallback, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { models } from 'powerbi-client';

import { pbi } from '@worten-sardines/seller-center/data-access';
import { PBI } from '@worten-sardines/configs/config-seller-center';
import {
  useMutate,
  UserSessionType,
  useSessionStorage,
} from '@worten-sardines/shared/hooks';

import { Loading } from '@worten-sardines/grill-ui';
import {
  ShopIdCard,
  UsefulLinks,
} from '@worten-sardines/seller-center-ui-layout';

import { MainTemplate, PbiIframe } from '@worten-sardines/shared-ui';
import { SkeletonLoader } from '@worten-sardines/seller-center-ui-shared';
import { StyledSellerPremiumPBIWrapper } from './styled';

type SellerAnalyticsProps = {
  user: UserSessionType;
};

type ValuesPBI = {
  embedTokenId: string;
  embedUrl: string;
  embedToken: string;
};

type Dispatch = <A>(action: A) => void;

type State = {
  data?: ValuesPBI;
  loading: boolean;
};

export const SellerPremiumPBI = () => {
  const { user }: SellerAnalyticsProps = useOutletContext();
  const { value: shopInfo } = useSessionStorage('shop', null);
  const isOperator = user.data?.type === 'operator';
  const shopId = shopInfo?.id;
  const showContentPage = !isOperator || !!shopId;
  const [{ data, loading }, PbiMutate]: [State, Dispatch] = useMutate(
    pbi,
    [],
    false,
  );

  const pbiFn = useCallback(() => {
    const rawBody = JSON.stringify({
      reportId: PBI.SELLER_PREMIUM['report-id'],
      datasetId: PBI.SELLER_PREMIUM['dataset-id'],
      workspaceId: PBI.SELLER_PREMIUM['workspace-id'],
    });

    PbiMutate([[[`/v2/dashboard/generateAccess`]], { shopId }, rawBody]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PbiMutate, shopInfo]);

  useEffect(() => {
    pbiFn();
  }, [pbiFn]);

  return (
    <>
      <MainTemplate.Content>
        {showContentPage ? (
          loading || !data ? (
            <Loading mt="40vh" />
          ) : (
            <StyledSellerPremiumPBIWrapper>
              {!loading && data && (
                <PbiIframe
                  reportSettings={{
                    type: 'report',
                    embedUrl: data?.embedUrl,
                    accessToken: data?.embedToken,
                    tokenType: models.TokenType.Embed,
                  }}
                />
              )}
            </StyledSellerPremiumPBIWrapper>
          )
        ) : (
          <SkeletonLoader
            data-testid="skeleton"
            template={[
              { columns: 4 },
              { columns: 1 },
              { columns: 3 },
              { columns: 2 },
            ]}
          />
        )}
      </MainTemplate.Content>
      <MainTemplate.SidebarRight>
        {isOperator && <ShopIdCard isUnset />}
        <UsefulLinks />
      </MainTemplate.SidebarRight>
    </>
  );
};

export default SellerPremiumPBI;

import { breakpoints } from '@worten-sardines/grill-ui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.sys.color.neu['09']};
    width: 100%;

    padding: ${theme.sys.dimension.spacing.Medium}
      ${theme.sys.dimension.spacing.Compact} 3.4rem;

    color: ${theme.sys.color.neu['01']};
    gap: ${theme.bas.dimension[16]};

    border-radius: ${theme.sys.dimension.border.radius.Compact}
      ${theme.sys.dimension.border.radius.Compact} 0 0;
  `}
`;

export const Header = styled.header`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.sys.dimension.spacing.Compact};

  @media only screen and (${breakpoints.xs}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.sys.dimension.spacing.Micro};
  }
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.sys.typeface['size-18']['2701-700']}
    margin: 0;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    ${theme.sys.typeface['size-11']['1701-400']}
    margin: 0;
  `}
`;

type TooltipContentProps = {
  showBelow: boolean;
};

export const StyledTooltipContent = styled.div<TooltipContentProps>`
  ${({ theme, showBelow }) => css`
    width: 189px;

    padding: ${theme.bas.dimension[24]} ${theme.bas.dimension[16]}
      ${theme.bas.dimension[16]};
    gap: ${theme.bas.dimension[8]};

    background-color: ${theme.sys.color.neu['01a90']};

    box-shadow: ${theme.shadow.level1};
    border-radius: ${theme.sys.dimension.border.radius.Micro};

    color: ${theme.sys.color.neu['11']};

    span {
      ${theme.sys.typeface['size-14']['2101-400']}
    }

    position: absolute;
    right: -1rem;
    bottom: 0;
    margin-bottom: 1rem;
    z-index: 2;

    ${showBelow &&
    css`
      margin-bottom: 0;
      margin-top: 1rem;

      top: 1rem;
      bottom: unset;
      right: unset;

      left: -1rem;
    `}

    &::before {
      content: '';
      position: absolute;
      bottom: -1.6rem;
      right: 0;
      transform: translateX(-0.4rem) rotate(180deg);
      border-width: 0.8rem;
      border-style: solid;
      border-color: transparent transparent ${theme.sys.color.neu['01a90']}
        transparent;

      ${showBelow &&
      css`
        bottom: unset;
        right: unset;
        left: 0;

        top: -1.6rem;
        transform: translateX(0.4rem) rotate(0deg);
      `}
    }
  `}
`;

export const CloseIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  width: 1.6rem;
  height: 1.6rem;

  top: 0.5rem;
  right: 0.8rem;
`;

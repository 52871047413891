import styled, { css } from 'styled-components';
import Flex from '../Flex';

type WrapperHeroSliderProps = {
  enableBorder?: boolean;
  borderColor?: string;
  borderRadius?: number;
  dotActiveColor?: string;
  heroHeight?: number;
  dotColor?: string;
};

export const WrapperHeroSlider = styled.div<WrapperHeroSliderProps>`
  ${({
    theme,
    enableBorder,
    borderColor,
    borderRadius,
    dotColor,
    dotActiveColor,
    heroHeight,
  }) => css`
    position: relative;
    width: 100%;
    height: ${`${heroHeight}px` || 'auto'};
    overflow: hidden;
    background-color: ${theme.sys.color.neu['04']};

    ${enableBorder &&
    css`
      border: 1px solid ${borderColor || theme.sys.color.neu['03']};
      border-radius: ${`${borderRadius}px`};
    `}

    .alice-carousel {
      & ul li img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    .alice-carousel__stage {
      height: ${heroHeight ? `${heroHeight}px` : '100%'};
    }

    .alice-carousel__prev-btn {
      position: absolute;
      left: 0;
      top: 50%;
      width: 3.4rem;
      height: 3.4rem;
      margin-left: 1.6rem;
      transform: translateY(-50%);
      border-radius: 1.7rem;
      padding: 0;
      overflow: hidden;

      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);

      > button {
        &:hover {
          border: 0;
        }
      }
    }
    .alice-carousel__next-btn {
      position: absolute;
      right: 0;
      top: 50%;
      width: 3.4rem;
      height: 3.4rem;
      margin-right: ${theme.sys.dimension.spacing.Medium};
      transform: translateY(-50%);
      border-radius: 50%;
      padding: 0;
      overflow: hidden;

      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);

      > button {
        &:hover {
          border: 0;
        }
      }
    }

    .alice-carousel__dots {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .alice-carousel__dots-item {
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 0.6rem;
      transition: 0.3s ease-in-out all;
      background-color: ${dotColor};

      &:not(:last-child) {
        margin-right: 1.6rem;
      }

      &.__active {
        width: 30px;
        background-color: ${dotActiveColor};
        transition: 0.3s ease-in-out all;
      }
    }

    .alice-carousel__slide-info {
      display: none;
    }
  `}
`;

export const LazyLoaderWrapper = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
})`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.sys.color.neu['04']};
    color: ${theme.sys.color.neu[11]};
    font-size: ${theme.bas.typeface.fontsize[16]};
    min-height: 10rem;
  `}
`;

import { defineMessages } from 'react-intl';

export default defineMessages({
  downloadLink: {
    id: 'libs.seller-center.cmsPage.campaigns.campaignSidebar.campaignSubmissionTemplateFile.downloadLink',
    defaultMessage: 'Download template file',
  },
  title: {
    id: 'libs.seller-center.cmsPage.campaigns.campaignSidebar.campaignSubmissionTemplateFile.title',
    defaultMessage: 'Use this file to submit your campaigns.',
  },
});

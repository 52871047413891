import { CustomIndicatorWrapper } from './styled';
import { CustomIndicatorProps } from './types';

export const CustomIndicator = ({
  component,
  horizontalPosition,
  verticalPosition,
}: CustomIndicatorProps) => (
  <CustomIndicatorWrapper
    verticalPosition={verticalPosition}
    horizontalPosition={horizontalPosition}
  >
    {component}
  </CustomIndicatorWrapper>
);

import { useEffect } from 'react';
import Loading from '../Loading';

import { Label, StyledLoadingOverlay } from './styled';

export type LoadingOverlayProps = {
  isVisible: boolean;
  SupportingText?: string;
  SupportingTextColor?: string;
  zIndex?: number;
  SpinnerSize?: 'sm' | 'md' | 'lg';
  SpinnerColor?: string;
  children?: React.ReactNode;
};

export const LoadingOverlay = ({
  isVisible,
  zIndex,
  SupportingText,
  SupportingTextColor,
  SpinnerColor,
  SpinnerSize = 'md',
  children,
}: LoadingOverlayProps) => {
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  }, [isVisible]);

  return (
    <StyledLoadingOverlay
      isVisible={isVisible}
      zIndex={zIndex}
      aria-busy={isVisible}
    >
      {children || <Loading color={SpinnerColor} size={SpinnerSize} />}
      {SupportingText && (
        <Label color={SupportingTextColor}>{SupportingText}</Label>
      )}
    </StyledLoadingOverlay>
  );
};

export default LoadingOverlay;

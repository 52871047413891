import { memo } from 'react';
import { toast } from './core/toast';
import { Toast } from './types';

import Svg from '../Svg';

import { Close, Info } from '../assets/images';
import { CloseButton, IconInfo, Message, ToastBarBase } from './styled';

type ToastBarProps = {
  toastbar: Toast;
};

export const ToastBar = memo(({ toastbar }: ToastBarProps) => (
  <ToastBarBase type={toastbar.type}>
    <IconInfo>
      <Svg svg={<Info />} width={24} title="info" />
    </IconInfo>
    <Message {...toastbar.ariaProps}>{toastbar.message}</Message>
    <CloseButton
      className="close-button"
      onClick={() => toast.remove(toastbar.id)}
      aria-label="close"
    >
      <Svg svg={<Close />} width={24} title="close" />
    </CloseButton>
  </ToastBarBase>
));

import styled, { css } from 'styled-components';

import {
  makeColor,
  makeHeight,
  makeResponsive,
  makeSpacing,
  makeText,
  makeWidth,
} from '../../utils';
import { omitProps } from '../../_common';
import Flex from '../../Flex';
import ArrowButton from '../../ArrowButton';
import { GenericObject } from '../../utils/types';

type DataTablePropsStyle = {
  bordered?: boolean;
  enableColumnBorders?: boolean;
  borderRadius?: GenericObject;
  cellSpacing?: GenericObject;
  cellText?: GenericObject;
  borderColor?: string;
  backgroundColor?: string;
  backgroundColorHead?: string;
};

export const Wrapper = styled.div<DataTablePropsStyle>`
  ${({ theme, bordered, borderColor, borderRadius, backgroundColor }) => css`
    overflow-y: auto;
    border: 1px solid ${borderColor || theme.sys.color.neu['03']};
    ${borderRadius &&
    css`
      border-radius: ${borderRadius['topLeft']} ${borderRadius['topRight']}
        ${borderRadius['bottomLeft']} ${borderRadius['bottomRight']};
    `}
    ${!bordered &&
    css`
      border: 0;
    `};
    background-color: ${backgroundColor || theme.sys.color.neu['01']};
  `}
`;

export const StyledTable = styled.table.withConfig<DataTablePropsStyle>({
  shouldForwardProp: (prop) =>
    omitProps(['enableColumnBorders', 'backgroundColorHead'], prop),
})`
  ${({
    theme,
    borderColor,
    cellSpacing,
    cellText,
    enableColumnBorders,
    backgroundColorHead,
  }) => css`
    position: relative;
    table-layout: fixed;
    width: 100%;
    font-size: inherit;
    border-collapse: collapse;

    & th,
    td {
      font-weight: ${theme.weight.regular};
      text-align: left;
      ${cellText && makeText(cellText)}
      ${cellSpacing && makeSpacing(cellSpacing)}
    }

    tbody {
      tr {
        height: 56px;
        & > th {
          text-transform: capitalize;
          width: auto;
        }
        &:not(:last-child) {
          border-bottom: 1px solid ${theme.sys.color.neu['03']};
        }
      }
      td,
      th {
        padding: 0 ${theme.spacing.xs};
      }
      td {
        ${enableColumnBorders &&
        css`
          border-left: 1px solid ${theme.sys.color.neu['03']};
        `}
      }
    }

    thead,
    tfoot {
      > tr > th {
        font-weight: ${theme.weight.bold};
        text-align: left;
      }
    }

    thead {
      th {
        padding: 0 ${theme.sys.dimension.spacing.Medium};
        width: auto;
        height: 56px;

        position: sticky;
        top: 0;
        background-color: ${backgroundColorHead || theme.sys.color.neu['04']};
        z-index: 1;

        &:not(:first-child) {
          > div {
            justify-content: space-between;
          }
          ${enableColumnBorders &&
          css`
            border-left: 1px solid inherit;
          `}
        }

        > div {
          display: flex;
          align-items: center;
          &.arrow-only-left {
            justify-content: flex-start;
          }
        }
      }
    }

    tfoot {
      tr {
        height: 56px;
        border-top: 1px solid ${theme.sys.color.neu['03']};
      }
      th {
        padding: 0 ${theme.spacing.xs};
        &:not(:first-child) {
          ${enableColumnBorders &&
          css`
            border-left: 1px solid ${theme.sys.color.neu['03']};
          `}
        }
      }
    }

    ${borderColor &&
    css`
      tr,
      th,
      td {
        border: 1px solid ${borderColor};
      }
    `}

    ${makeColor}
    ${makeResponsive}
    ${makeHeight}
    ${makeWidth}

    .cursor-pointer {
      cursor: pointer;
    }

    .header-cell {
      width: 100%;
    }

    .sub-row {
      border-left: none;
      padding: 0;
      & pre {
        margin: 0;
      }
    }

    .sorting-icon {
      margin-left: 5px;
    }
  `}
`;

export const StyledPaginationWrapper = styled(Flex).attrs({
  justifyContent: 'center',
})`
  ${({ theme }) => css`
    padding: ${theme.sys.dimension.spacing.Large}; 0;
  `}
`;

export const StyledArrowButton = styled(ArrowButton)`
  background-color: transparent;
  border-color: transparent;
  width: fit-content;

  &[direction='left'] {
    margin-right: 20px;
  }

  &[direction='right'] {
    margin-left: 20px;
  }

  &:disabled {
    opacity: 1;
  }
`;

import Cookies from 'js-cookie';
const {
  NX_ENV_TYPE,
  NX_API_URL,
  NX_API_URL_AUTH_PUBLIC,
  NX_CMS_PREVIEW_TOKEN,
  NX_API_URL_SELLER,
  NX_SENTRY_DSN,
} = process.env;
export const LANGUAGE_DEFAULT = 'en';
export const ENV_TYPE = NX_ENV_TYPE;
export const SENTRY_DSN = NX_SENTRY_DSN;
export const LANGUAGES = ['pt', 'es'];
export const DEFAULT_SWR_RETRY_COUNT = 0;
export const LOCAL_STORAGE_REDIRECT_KEY = 'seller-center-worten-redirect';
export const LOCAL_STORAGE_STATE_AUTH = 'seller-center-worten-state';
export const ARGS_UTM = (page) => `utm_source=seller-center&utm_medium=${page}`;
export const API_URL = NX_API_URL;
export const API_URL_AUTH_PUBLIC = NX_API_URL_AUTH_PUBLIC;
export const CMS_PREVIEW_TOKEN = NX_CMS_PREVIEW_TOKEN;
export const API_URL_SELLER = NX_API_URL_SELLER;
export const MIRAKL_URL =
  NX_ENV_TYPE === 'production'
    ? 'https://marketplace.worten.pt'
    : 'https://worten-dev.mirakl.net/login';
export const hiddenInProduction = !(NX_ENV_TYPE === 'production');
export const SELLER_ACADEMY_URL = {
  ACADEMY:
    NX_ENV_TYPE === 'production'
      ? {
          en: 'https://selleracademy.worten.com/en/',
          es: 'https://selleracademy.worten.com/es/',
          pt: 'https://selleracademy.worten.com/pt/',
        }
      : {
          en: 'https://selleracademy-dev.worten.com/en/',
          es: 'https://selleracademy-dev.worten.com/es/',
          pt: 'https://selleracademy-dev.worten.com/pt/',
        },
};

export const CONFIGS_123FORM = {
  CAMPAIGN:
    NX_ENV_TYPE === 'production'
      ? {
          en: {
            idForm: '6401474',
            emailForm: '&control106449533',
            campaignIDForm: '&control106449531',
            shopIDForm: '&control106449530',
            shopNameForm: '&control107269658',
          },
          pt: {
            idForm: '6481280',
            emailForm: '&control108381524',
            campaignIDForm: '&control108381522',
            shopIDForm: '&control108381520',
            shopNameForm: '&control108381521',
          },
          es: {
            idForm: '6481281',
            emailForm: '&control108381536',
            campaignIDForm: '&control108381534',
            shopIDForm: '&control108381532',
            shopNameForm: '&control108381533',
          },
        }
      : {
          en: {
            idForm: '6343249',
            emailForm: '&control105194156',
            campaignIDForm: '&control105284663',
            shopIDForm: '&control105194146',
            shopNameForm: '&control107225272',
          },
          pt: {
            idForm: '6481277',
            emailForm: '&control108381506',
            campaignIDForm: '&control108381504',
            shopIDForm: '&control108381502',
            shopNameForm: '&control108381503',
          },
          es: {
            idForm: '6481275',
            emailForm: '&control108381477',
            campaignIDForm: '&control108381475',
            shopIDForm: '&control108381473',
            shopNameForm: '&control108381474',
          },
        },
};

export const NOTICE_QTY_DAYS = 30;
export const NOTICES_FLAG = {
  newFeature: [
    {
      name: 'services',
      start: '12-05-2023', //format: mm-dd-yyyy
      visible: true,
    },
    {
      name: 'seller-premium',
      start: '8-22-2024', //format: mm-dd-yyyy
      visible: true,
    },
    // INFO: The feature below (name: storybook), is for testing purposes only and to document the feature - please do not remove it!
    {
      name: 'storybook',
      start: `${
        new Date().getMonth() + 1
      }-${new Date().getDate()}-${new Date().getFullYear()}`, // today date in format: mm-dd-yyyy
      visible: true,
    },
  ],
};

export const FEATURE = {
  showMenuSellerPremium: !!Cookies.get('seller-premium'),
};

export const PBI = {
  REPORTS:
    NX_ENV_TYPE === 'production'
      ? {
          'report-id': '70bd125a-646b-4ac7-86da-0c49478ad708',
          'dataset-id': '9f3f7408-bdbc-49e1-bd93-ade70dfacfb9',
          'workspace-id': '58572928-d62c-419e-a48c-1648c6d155a8',
        }
      : {
          'report-id': '687a6706-ee86-4f32-b156-9ebb616f26ea',
          'dataset-id': 'e7ffe979-d167-48f7-9cc7-23cb18b80d5e',
          'workspace-id': '539fd90a-e580-48e5-881a-6cd517e29506',
        },
  SELLER_PREMIUM:
    NX_ENV_TYPE === 'production'
      ? {
          'report-id': '7f44e15b-fd98-4464-ac97-396c1b095b54',
          'dataset-id': 'cd549e54-6621-4b5e-9a61-6411918733b5',
          'workspace-id': '58572928-d62c-419e-a48c-1648c6d155a8',
        }
      : {
          'report-id': '7f44e15b-fd98-4464-ac97-396c1b095b54',
          'dataset-id': 'cd549e54-6621-4b5e-9a61-6411918733b5',
          'workspace-id': '58572928-d62c-419e-a48c-1648c6d155a8',
        },
};

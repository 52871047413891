import { defineMessages } from 'react-intl';

export default defineMessages({
  usefulLinks: {
    id: 'apps.seller-center.navigation.usefulLinks',
    defaultMessage: 'Useful links',
  },
  mirakl: {
    id: 'apps.seller-center.navigation.mirakl',
    defaultMessage: 'Mirakl',
  },
  sonae: {
    id: 'apps.seller-center.navigation.sonae',
    defaultMessage: 'Sonae Link',
  },
  sellerAcademy: {
    id: 'apps.seller-center.navigation.sellerAcademy',
    defaultMessage: 'Seller Academy',
  },
  wortenAds: {
    id: 'apps.seller-center.navigation.wortenAds',
    defaultMessage: 'Worten Ads',
  },
  cookiePolicy: {
    id: 'apps.seller-center.navigation.cookiePolicy',
    defaultMessage: 'Cookie Policy',
  },
  dsa: {
    id: 'apps.seller-center.navigation.dsa',
    defaultMessage: 'Report illegal content',
  },
  sellerPagPT: {
    id: 'apps.seller-center.navigation.sellerPagPT',
    defaultMessage: 'Seller Page Pt.',
  },
  sellerPagES: {
    id: 'apps.seller-center.navigation.sellerPagES',
    defaultMessage: 'Seller Page Es.',
  },
  campaigns: {
    id: 'apps.seller-center.navigation.campaigns',
    defaultMessage: 'Check here all available campaigns',
  },
  doubt: {
    id: 'apps.seller-center.navigation.doubt',
    defaultMessage: 'Do you need some help?',
  },
  opinion: {
    id: 'apps.seller-center.navigation.opinion',
    defaultMessage: 'Your feedback is important to us!',
  },
});

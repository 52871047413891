import styled, { DefaultTheme, css } from 'styled-components';
import { SingleEventProps } from '../../types';

type StyledProps = {
  size: number;
  hasIntersection?: boolean;
  diff?: number;
  oneDay?: boolean;
} & Pick<SingleEventProps, 'variant' | 'daySize'>;

const crossEventVariant = {
  first: (theme: DefaultTheme) => css`
    background-color: ${theme.color.neutral['neu-04']};
  `,
  second: (theme: DefaultTheme) => css`
    background-color: ${theme.color.neutral['neu-07']};
  `,
  light: (theme: DefaultTheme) => css`
    background-color: ${theme.color.neutral.neu01};
  `,
  intersection: (theme: DefaultTheme) => css`
    background-color: ${theme.color.neutral['neu-07']};
  `,
};

const crossDateBorderIntersections = {
  first: (theme: DefaultTheme) => css`
    border: 2px solid ${theme.color.neutral['neu-07']};
  `,
  second: (theme: DefaultTheme) => css`
    border: 2px solid ${theme.color.neutral['neu-04']};
  `,
  light: (theme: DefaultTheme) => css`
    border: 2px solid ${theme.color.neutral['neu-06']};
  `,
  intersection: (theme: DefaultTheme) => css`
    border: 2px solid ${theme.color.neutral.neu01};
  `,
};

const crossDateBorder = {
  first: (theme: DefaultTheme) => css`
    border: 2px solid ${theme.color.neutral['neu-04']};
  `,
  second: (theme: DefaultTheme) => css`
    border: 2px solid ${theme.color.neutral['neu-07']};
  `,
  light: (theme: DefaultTheme) => css`
    border: 2px solid ${theme.color.neutral.neu01};
  `,
  intersection: (theme: DefaultTheme) => css`
    border: 2px solid ${theme.color.neutral['neu-07']};
  `,
};

export const EventWrapper = styled.div<StyledProps>`
  ${({ theme, variant, size, daySize, hasIntersection, diff, oneDay }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: ${theme.Radius.lg};
    box-sizing: border-box;

    ${variant && crossDateBorder[variant](theme)};

    width: ${size * (daySize ? daySize : 35)}px;
    position: relative;

    ${size <= 1 &&
    css`
      width: fit-content;
      justify-content: center;
    `}

    ${variant && crossEventVariant[variant](theme)};

    & > div[class*='DateDisplay'] {
      padding: 0;

      width: 32px;
      min-width: 32px;
      height: 32px;
    }

    ${variant &&
    variant === 'first' &&
    css`
      & {
        z-index: 1;
      }

      & > div[class*='DateDisplay']:first-of-type {
        visibility: hidden;
      }
      & > div[class*='DateDisplay']:not(:first-of-type) {
        ${variant && crossDateBorder[variant](theme)};
      }

      ${oneDay &&
      css`
        & > div[class*='DateDisplay']:first-of-type {
          visibility: visible;

          ${hasIntersection
            ? css`
                ${variant && crossDateBorderIntersections[variant](theme)};
              `
            : css`
                ${variant && crossDateBorder[variant](theme)};
              `}
        }
      `}
    `}

    ${variant &&
    variant === 'second' &&
    css`
      & > div[class*='DateDisplay'] {
        ${variant && crossDateBorder[variant](theme)};
      }

      ${hasIntersection &&
      css`
        & {
          width: ${size * (daySize ? daySize : 35)}px;
          margin-left: -${diff && daySize ? daySize * diff : 0}px;
        }
      `}
    `}

    ${variant &&
    variant === 'intersection' &&
    css`
      & {
        ${variant && crossDateBorder[variant](theme)};
      }

      & > div[class*='DateDisplay'] {
        padding: 0;
        border: 0;
        width: 26px;
        min-width: 26px;
        height: 26px;
        ${variant && crossDateBorderIntersections[variant](theme)};
      }
    `}
  `}
`;

export const IntersectionEventWrapper = styled(EventWrapper)<StyledProps>`
  ${({ theme, variant }) => css`
    position: absolute;
    right: 0;
    ${variant && crossDateBorder[variant](theme)};
  `}
`;

import { useMemo } from 'react';
import { HeroSlider } from '@worten-sardines/grill-ui';
import { HeroSliderSchema } from '../../types';
import { setHeroBanners } from './Heros';
import { StyledHeroSlider } from './styled';

export const CMSHeroSlider = ({ schema }: { schema: HeroSliderSchema }) => {
  const { heros } = schema;

  const dotColors = useMemo(() => {
    if (heros.length) {
      return heros.reduce((acc, curr, index) => {
        return curr.dotColor
          ? [...acc, { dot: index + 1, color: curr.dotColor }]
          : acc;
      }, []);
    }
  }, [heros]);

  return heros && heros.length ? (
    <StyledHeroSlider position={schema.position}>
      <HeroSlider {...schema} infinite={true} dotColors={dotColors} heroHeight={274}>
        {setHeroBanners(heros)}
      </HeroSlider>
    </StyledHeroSlider>
  ) : null;
};

export default CMSHeroSlider;

/**
 * Do not edit directly
 * Generated on Thu Jan 11 2024 14:41:47 GMT+0000 (Western European Standard Time)
 */

export default {
  "bas": {
    "typeface": {
      "font": {
        "primaryFont": "Open Sans, sans-serif"
      },
      "fontsize": {
        "10": "1rem",
        "11": "1.1rem",
        "12": "1.2rem",
        "14": "1.4rem",
        "16": "1.6rem",
        "18": "1.8rem",
        "20": "2rem",
        "22": "2.2rem",
        "28": "2.8rem",
        "34": "3.4rem",
        "44": "4.4rem"
      }
    },
    "dimension": {
      "4": "0.4rem",
      "8": "0.8rem",
      "16": "1.6rem",
      "24": "2.4rem",
      "32": "3.2rem",
      "40": "4rem",
      "48": "4.8rem",
      "56": "5.6rem",
      "64": "6.4rem",
      "72": "7.2rem",
      "80": "8rem",
      "120": "12rem",
      "None": "0rem"
    },
    "Motion": {
      "duration": {
        "75": "75ms",
        "150": "150ms",
        "300": "300ms"
      },
      "curve": {
        "04-02-03-1": "(0.4, 0.2, 0.3, 1)",
        "02-00-04-09": "(0.2, 0, 0.4, 0.9)"
      }
    }
  },
  "sys": {
    "color": {
      "add": {
        "10": "#ebf8fd",
        "01": "#ffcc33",
        "02": "#0028f5",
        "03": "#dde2e7",
        "04": "#ff2d81",
        "05": "#fe712c",
        "06": "#40bf64",
        "07": "#217d55",
        "08": "#e5e8f6",
        "09": "#3bb7ea"
      },
      "m": {
        "01": "#e51b15"
      },
      "neu": {
        "10": "#212121",
        "11": "#141414",
        "12": "#000000",
        "01": "#ffffff",
        "02": "#f6f6f6",
        "03": "#ededed",
        "04": "#dddddd",
        "05": "#cccccc",
        "06": "#9e9e9e",
        "07": "#777777",
        "08": "#4c4c4c",
        "09": "#383838",
        "None": "#ffffff00",
        "11a90": "#141414e6",
        "01a90": "#ffffffe6",
        "10a90": "#212121e6"
      },
      "gra": {
        "01": "#9fdc83",
        "02": "#25ab52",
        "03": "#3bb7ea",
        "04": "#0b6387",
        "05": "#f79d5e",
        "06": "#ea6852",
        "03a10": "#3bb7ea1a",
        "03a30": "#3bb7ea4d"
      },
      "extend": {
        "m-01--light-01": "#fe382c",
        "m-01--dark-01": "#cc1611",
        "add-02--light-01": "#fafcff",
        "add-02--light-02": "#dde2e7"
      },
      "vis": {
        "05": "#6b8f9c",
        "04": "#8fa4af",
        "03": "#acbfc6",
        "02": "#b0bec5",
        "01": "#d0d9dc"
      }
    },
    "dimension": {
      "spacing": {
        "Micro": "0.4rem",
        "Compact": "0.8rem",
        "Medium": "1.6rem",
        "Large": "2.4rem",
        "Ultra": "3.2rem",
        "Mega": "4rem",
        "Giant": "4.8rem",
        "None": "0rem"
      },
      "border": {
        "radius": {
          "Micro": "0.4rem",
          "Compact": "0.8rem",
          "Medium": "1.6rem",
          "Large": "2.4rem",
          "Ultra": "3.2rem",
          "None": "0rem"
        },
        "width": {
          "Nano": "0.2rem"
        }
      },
      "size": {
        "Mega": "4rem",
        "Ultra": "3.2rem",
        "Large": "2.4rem",
        "Medium": "1.6rem"
      }
    },
    "Levels": {
      "Level 1": {
        "inset": false,
        "color": "#00000033",
        "offsetX": "0px",
        "offsetY": "2px",
        "blur": "4px",
        "spread": "0px"
      },
      "Level 2": {
        "inset": false,
        "color": "#0000001a",
        "offsetX": "0px",
        "offsetY": "8px",
        "blur": "16px",
        "spread": "0px"
      },
      "Level 3": {
        "inset": false,
        "color": "#0000000d",
        "offsetX": "0px",
        "offsetY": "64px",
        "blur": "64px",
        "spread": "0px"
      },
      "Level 4": {
        "inset": false,
        "color": "#0000001a",
        "offsetX": "0px",
        "offsetY": "-2px",
        "blur": "16px",
        "spread": "0px"
      }
    },
    "Blur": {
      "strenth-02": {
        "role": "background",
        "blur": "12px"
      }
    },
    "typeface": {
      "size-10": {
        "141-400": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "10px",
          "lineHeight": "14px",
          "letterSpacing": "1%"
        },
        "141-600": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "10px",
          "lineHeight": "14px",
          "letterSpacing": "1%"
        },
        "141-700": {
          "fontFamily": "Open Sans",
          "fontWeight": 700,
          "fontSize": "10px",
          "lineHeight": "14px",
          "letterSpacing": "1%"
        },
        "1401-400": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "10px",
          "lineHeight": "14px",
          "letterSpacing": "0.10000000149011612%"
        },
        "1401-600": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "10px",
          "lineHeight": "14px",
          "letterSpacing": "0.10000000149011612%"
        },
        "1401-700": {
          "fontFamily": "Open Sans",
          "fontWeight": 700,
          "fontSize": "10px",
          "lineHeight": "14px",
          "letterSpacing": "0.10000000149011612%"
        }
      },
      "size-11": {
        "1705-400": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "11px",
          "lineHeight": "16px",
          "letterSpacing": "0.5%"
        },
        "1705-600": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "11px",
          "lineHeight": "17px",
          "letterSpacing": "0.5%"
        },
        "1705-700": {
          "fontFamily": "Open Sans",
          "fontWeight": 700,
          "fontSize": "11px",
          "lineHeight": "17px",
          "letterSpacing": "0.5%"
        },
        "1701-400": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "11px",
          "lineHeight": "17px",
          "letterSpacing": "0.10000000149011612%"
        },
        "1701-600": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "11px",
          "lineHeight": "17px",
          "letterSpacing": "0.10000000149011612%"
        },
        "1701-700": {
          "fontFamily": "Open Sans",
          "fontWeight": 700,
          "fontSize": "11px",
          "lineHeight": "17px",
          "letterSpacing": "0.10000000149011612%"
        }
      },
      "size-12": {
        "181-400": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "12px",
          "lineHeight": "18px",
          "letterSpacing": "1%"
        },
        "181-600": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "12px",
          "lineHeight": "18px",
          "letterSpacing": "1%"
        },
        "181-700": {
          "fontFamily": "Open Sans",
          "fontWeight": 700,
          "fontSize": "12px",
          "lineHeight": "18px",
          "letterSpacing": "1%"
        },
        "1801-400": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "12px",
          "lineHeight": "18px",
          "letterSpacing": "0.10000000149011612%"
        },
        "1801-600": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "12px",
          "lineHeight": "18px",
          "letterSpacing": "0.10000000149011612%"
        },
        "1801-700": {
          "fontFamily": "Open Sans",
          "fontWeight": 700,
          "fontSize": "12px",
          "lineHeight": "18px",
          "letterSpacing": "0.10000000149011612%"
        }
      },
      "size-14": {
        "2101-400": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "14px",
          "lineHeight": "21px",
          "letterSpacing": "0.10000000149011612%"
        },
        "2101-600": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "14px",
          "lineHeight": "21px",
          "letterSpacing": "0.10000000149011612%"
        },
        "2101-700": {
          "fontFamily": "Open Sans",
          "fontWeight": 700,
          "fontSize": "14px",
          "lineHeight": "21px",
          "letterSpacing": "0.10000000149011612%"
        }
      },
      "size-16": {
        "2401-400": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "16px",
          "lineHeight": "24px",
          "letterSpacing": "0.10000000149011612%"
        },
        "2401-600": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "16px",
          "lineHeight": "24px",
          "letterSpacing": "0.10000000149011612%"
        },
        "2401-700": {
          "fontFamily": "Open Sans",
          "fontWeight": 700,
          "fontSize": "16px",
          "lineHeight": "24px",
          "letterSpacing": "0.10000000149011612%"
        }
      },
      "size-18": {
        "2701-400": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "18px",
          "lineHeight": "27px",
          "letterSpacing": "0.10000000149011612%"
        },
        "2701-600": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "18px",
          "lineHeight": "27px",
          "letterSpacing": "0.10000000149011612%"
        },
        "2701-700": {
          "fontFamily": "Open Sans",
          "fontWeight": 700,
          "fontSize": "18px",
          "lineHeight": "27px",
          "letterSpacing": "0.10000000149011612%"
        }
      },
      "size-20": {
        "3001-400": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "20px",
          "lineHeight": "30px",
          "letterSpacing": "0.10000000149011612%"
        },
        "3001-600": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "20px",
          "lineHeight": "30px",
          "letterSpacing": "0.10000000149011612%"
        },
        "3001-700": {
          "fontFamily": "Open Sans",
          "fontWeight": 700,
          "fontSize": "20px",
          "lineHeight": "30px",
          "letterSpacing": "0.10000000149011612%"
        }
      },
      "size-22": {
        "3301-400": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "22px",
          "lineHeight": "33px",
          "letterSpacing": "0.10000000149011612%"
        },
        "3301-600": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "22px",
          "lineHeight": "33px",
          "letterSpacing": "0.10000000149011612%"
        },
        "3301-700": {
          "fontFamily": "Open Sans",
          "fontWeight": 700,
          "fontSize": "22px",
          "lineHeight": "33px",
          "letterSpacing": "0.10000000149011612%"
        }
      },
      "size-28": {
        "4201-400": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "28px",
          "lineHeight": "42px",
          "letterSpacing": "0.10000000149011612%"
        },
        "4201-600": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "28px",
          "lineHeight": "42px",
          "letterSpacing": "0.10000000149011612%"
        },
        "4201-700": {
          "fontFamily": "Open Sans",
          "fontWeight": 700,
          "fontSize": "28px",
          "lineHeight": "42px",
          "letterSpacing": "0.10000000149011612%"
        },
        "4202-400": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "28px",
          "lineHeight": "42px",
          "letterSpacing": "0.20000000298023224%"
        },
        "4202-600": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "28px",
          "lineHeight": "42px",
          "letterSpacing": "0.20000000298023224%"
        },
        "4202-700": {
          "fontFamily": "Open Sans",
          "fontWeight": 700,
          "fontSize": "28px",
          "lineHeight": "42px",
          "letterSpacing": "0.20000000298023224%"
        }
      },
      "size-34": {
        "5402-400": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "34px",
          "lineHeight": "54px",
          "letterSpacing": "0.20000000298023224%"
        },
        "5402-600": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "34px",
          "lineHeight": "54px",
          "letterSpacing": "0.20000000298023224%"
        },
        "5402-700": {
          "fontFamily": "Open Sans",
          "fontWeight": 700,
          "fontSize": "34px",
          "lineHeight": "54px",
          "letterSpacing": "0.20000000298023224%"
        }
      },
      "size-44": {
        "6202-400": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "44px",
          "lineHeight": "62px",
          "letterSpacing": "0.20000000298023224%"
        },
        "6202-600": {
          "fontFamily": "Open Sans",
          "fontWeight": 400,
          "fontSize": "44px",
          "lineHeight": "62px",
          "letterSpacing": "0.20000000298023224%"
        },
        "6202-700": {
          "fontFamily": "Open Sans",
          "fontWeight": 700,
          "fontSize": "44px",
          "lineHeight": "62px",
          "letterSpacing": "0.20000000298023224%"
        }
      }
    }
  },
  "Theme": {
    "Light": {
      "sys": {
        "m": {
          "01": "#e51b15"
        },
        "neu": {
          "10": "#212121",
          "11": "#141414",
          "01": "#ffffff",
          "02": "#f6f6f6",
          "03": "#ededed",
          "04": "#dddddd",
          "05": "#cccccc",
          "06": "#9e9e9e",
          "07": "#777777",
          "08": "#4c4c4c",
          "09": "#383838"
        },
        "add": {
          "10": "#ebf8fd",
          "01": "#ffcc33",
          "02": "#0028f5",
          "03": "#dde2e7",
          "04": "#ff2d81",
          "05": "#fe712c",
          "06": "#40bf64",
          "07": "#217d55",
          "08": "#e5e8f6",
          "09": "#3bb7ea"
        },
        "gra": {
          "01": "#9fdc83",
          "02": "#25ab52",
          "03": "#3bb7ea",
          "04": "#0b6387",
          "05": "#f79d5e",
          "06": "#ea6852"
        },
        "alpha": {
          "neu": {
            "03a": "#3bb7ea1a",
            "11a90": "#141414e6",
            "01a90": "#ffffffe6",
            "10a90": "#212121e6"
          },
          "01-5%": "#ffffff0d",
          "01-20%": "#ffffff33",
          "01-30%": "#ffffff4d",
          "01-40%": "#ffffff66",
          "01-50%": "#ffffff80",
          "01-10%": "#ffffff1a"
        },
        "extend": {
          "m-01--light-01": "#fe382c",
          "m-01--dark-01": "#cc1611",
          "add-02--light-01": "#fafcff",
          "add-02--light-02": "#dde2e7"
        },
        "vis": {
          "05": "#6b8f9c",
          "04": "#8fa4af",
          "03": "#acbfc6",
          "02": "#b0bec5",
          "01": "#d0d9dc"
        }
      },
      "Comp": {
        "Global": {
          "Pure-light": "#ffffff",
          "Pure-dark": "#141414"
        }
      }
    },
    "Dark": {
      "sys": {
        "m": {
          "01": "#e51b15"
        },
        "neu": {
          "10": "#f6f6f6",
          "11": "#ffffff",
          "01": "#141414",
          "02": "#212121",
          "03": "#383838",
          "04": "#4c4c4c",
          "05": "#777777",
          "06": "#9e9e9e",
          "07": "#cccccc",
          "08": "#cccccc",
          "09": "#ededed"
        },
        "add": {
          "10": "#383838",
          "01": "#ffcc33",
          "02": "#0028f5",
          "03": "#dde2e7",
          "04": "#ff2d81",
          "05": "#f79d5e",
          "06": "#40bf64",
          "07": "#9fdc83",
          "08": "#e5e8f6",
          "09": "#3bb7ea"
        },
        "gra": {
          "01": "#40bf64",
          "02": "#9fdc83",
          "03": "#3bb7ea",
          "04": "#0b6387",
          "05": "#ea6852",
          "06": "#f79d5e"
        },
        "alpha": {
          "neu": {
            "03a": "#3bb7ea4d",
            "11a90": "#ffffffe6",
            "01a90": "#212121e6",
            "10a90": "#ffffffe6"
          },
          "01-5%": "#1414140d",
          "01-20%": "#14141433",
          "01-30%": "#1414144d",
          "01-40%": "#14141466",
          "01-50%": "#14141480",
          "01-10%": "#1414141a"
        },
        "extend": {
          "m-01--light-01": "#fe382c",
          "m-01--dark-01": "#fe382c",
          "add-02--light-01": "#0b6387",
          "add-02--light-02": "#3bb7ea4d"
        },
        "vis": {
          "05": "#d0d9dc",
          "04": "#b0bec5",
          "03": "#acbfc6",
          "02": "#8fa4af",
          "01": "#6b8f9c"
        }
      },
      "Comp": {
        "Global": {
          "Pure-light": "#ffffff",
          "Pure-dark": "#141414"
        }
      }
    }
  },
  "Dynamic Border Radius": {
    "No": {
      "Border": {
        "Micro": "0rem",
        "Compact": "0rem",
        "Medium": "0rem",
        "Large": "0rem",
        "Ultra": "0rem"
      }
    },
    "Yes": {
      "Border": {
        "Micro": "0.4rem",
        "Compact": "0.8rem",
        "Medium": "1.6rem",
        "Large": "2.4rem",
        "Ultra": "3.2rem"
      }
    }
  },
  "color": {
    "main": {
      "m-01": "#e51b15"
    },
    "neutral": {
      "neu01": "#ffffff",
      "neu-02": "#f6f6f6",
      "neu-03": "#ededed",
      "neu-04": "#dddddd",
      "neu-05": "#cccccc",
      "neu-06": "#9e9e9e",
      "neu-07": "#777777",
      "neu-08": "#4c4c4c",
      "neu-09": "#383838",
      "neu-10": "#212121",
      "neu-11": "#141414",
      "neu-12": "#000000"
    },
    "aditional": {
      "add-01": "#ffcc33",
      "Add-02": "#1c54b4",
      "add-03": "#dde2e7",
      "add-04": "#ff2d81",
      "add-05": "#fe712c",
      "add-06": "#40bf64",
      "add-07": "#217d55"
    },
    "extend": {
      "m-01--light-01": "#fe382c",
      "m-01--dark-01": "#cc1611",
      "add-02--light-01": "#fafcff",
      "add-02--light-02": "#dde2e7"
    },
    "gradient": {
      "g-01": "linear-gradient(114deg, #fe382c 0%, #e51b15 100%)",
      "g-02": "linear-gradient(114deg, #ff2d81 0%, #fe382c 100%)",
      "g-03": "linear-gradient(114deg, #f6f6f6 0%, #dde2e7 100%)",
      "g-04": "linear-gradient(114deg, #9fdc83 0%, #25ab52 100%)",
      "g-05": "linear-gradient(114deg, #3bb7ea 0%, #0b6387 100%)",
      "g-06": "linear-gradient(114deg, #f79d5e 0%, #ea6852 100%)"
    },
    "gradiantcolor": {
      "gra-01": "#9fdc83",
      "gra-02": "#25ab52",
      "gra-03": "#3bb7ea",
      "gra-04": "#0b6387",
      "gra-05": "#f79d5e",
      "gra-06": "#ea6852"
    }
  },
  "shadow": {
    "level1": "0px 2px 4px 0px rgba(0, 0, 0, 0.2)",
    "level2": "0px 8px 16px 0px rgba(0, 0, 0, 0.1)",
    "level3": "0px 2px 2px 0px rgba(0, 0, 0, 0.05),0px 4px 4px 0px rgba(0, 0, 0, 0.05),0px 8px 8px 0px rgba(0, 0, 0, 0.05),0px 16px 16px 0px rgba(0, 0, 0, 0.05),0px 32px 32px 0px rgba(0, 0, 0, 0.05),0px 64px 64px 0px rgba(0, 0, 0, 0.05)",
    "level4": "0px -2px 16px 0px rgba(0, 0, 0, 0.1)"
  },
  "weight": {
    "bold": "700",
    "semibold": "600",
    "regular": "400"
  },
  "fontsize": {
    "desktop": {
      "xxs": "1rem",
      "xs": "1.1rem",
      "s2": "1.2rem",
      "s1": "1.2rem",
      "m": "1.4rem",
      "l": "1.6rem",
      "xl": "1.8rem",
      "2xl": "2rem",
      "3xl": "2.2rem",
      "4xl": "2.8rem",
      "5xl": "3.4rem",
      "6xl": "4.4rem"
    },
    "mobile": {
      "xxs": "1rem",
      "xs": "1.1rem",
      "s2": "1.1rem",
      "s1": "1.2rem",
      "m": "1.4rem",
      "l": "1.6rem",
      "xl": "1.6rem",
      "2xl": "1.8rem",
      "3xl": "2rem",
      "4xl": "2rem",
      "5xl": "2.8rem",
      "6xl": "2.6rem"
    }
  },
  "lineheight": {
    "desktop": {
      "xxs": "1.4rem",
      "xs": "1.7rem",
      "s2": "1.8rem",
      "s1": "1.8rem",
      "m": "2.1rem",
      "l": "2.4rem",
      "xl": "2.7rem",
      "2xl": "3.1rem",
      "3xl": "3.3rem",
      "4xl": "4.2rem",
      "5xl": "5.4rem",
      "6xl": "6.2rem"
    },
    "mobile": {
      "xxs": "1.4rem",
      "xs": "1.7rem",
      "s2": "1.7rem",
      "s1": "1.8rem",
      "m": "2.1rem",
      "l": "2.4rem",
      "xl": "2.4rem",
      "2xl": "2.7rem",
      "3xl": "3rem",
      "4xl": "3rem",
      "5xl": "4.2rem",
      "6xl": "3.9rem"
    }
  },
  "letterspacing": {
    "desktop": {
      "xxs": "1",
      "xs": "0.1",
      "s2": "1",
      "s1": "0.1",
      "m": "0.1",
      "l": "0.1",
      "xl": "0.1",
      "2xl": "0.1",
      "3xl": "0.1",
      "4xl": "0.2",
      "5xl": "0.2",
      "6xl": "0.2"
    },
    "mobile": {
      "xxs": "1",
      "xs": "0.1",
      "s2": "0.5",
      "s1": "0.1",
      "m": "0.1",
      "l": "0.1",
      "xl": "0.1",
      "2xl": "0.1",
      "3xl": "0.1",
      "4xl": "0.1",
      "5xl": "0.1",
      "6xl": "0.1"
    }
  },
  "spacing": {
    "none": "0",
    "xxxs": "0.4rem",
    "xxs": "0.8rem",
    "xs": "1.6rem",
    "sm": "2.4rem",
    "md": "3.2rem",
    "lg": "4rem",
    "xl": "4.8rem",
    "xxl": "5.6rem",
    "gt": "6.4rem",
    "xg": "7.2rem",
    "xxg": "8rem",
    "xxxg": "12rem"
  },
  "size": {
    "none": "0",
    "xxxs": "0.4rem",
    "xxs": "0.8rem",
    "xs": "1.6rem",
    "sm": "2.4rem",
    "md": "3.2rem",
    "lg": "4rem",
    "xl": "4.8rem",
    "xxl": "5.6rem",
    "gt": "6.4rem",
    "xg": "7.2rem",
    "xxg": "8rem",
    "xxxg": "12rem"
  },
  "Radius": {
    "none": "0",
    "xxxs": "0.4rem",
    "xxs": "0.8rem",
    "xs": "1.6rem",
    "sm": "2.4rem",
    "md": "3.2rem",
    "lg": "4rem",
    "xl": "4.8rem",
    "xxl": "5.6rem",
    "gt": "6.4rem",
    "xg": "7.2rem",
    "xxg": "8rem",
    "xxxg": "12rem"
  },
  "family": {
    "primary": "Open Sans"
  },
  "width": {
    "thin": "1",
    "light": "2"
  },
  "opacity": {
    "none": "0.0",
    "low": "0.2",
    "medium": "0.5",
    "high": "0.8",
    "full": "1"
  },
  "decoration": {
    "underline": "underline",
    "line-through": "line-through"
  },
  "animation": {
    "duration": {
      "fast": "75ms",
      "medium": "150ms",
      "slow": "300ms"
    },
    "curve": {
      "expressve": "(0.4, 0.2, 0.3, 1)",
      "productive": "(0.2, 0, 0.4, 0.9)"
    }
  },
  "level1": "0px 2px 4px 0px rgba(0, 0, 0, 0.2)",
  "level2": "0px 8px 16px 0px rgba(0, 0, 0, 0.1)",
  "level3": "0px 2px 2px 0px rgba(0, 0, 0, 0.05),0px 4px 4px 0px rgba(0, 0, 0, 0.05),0px 8px 8px 0px rgba(0, 0, 0, 0.05),0px 16px 16px 0px rgba(0, 0, 0, 0.05),0px 32px 32px 0px rgba(0, 0, 0, 0.05),0px 64px 64px 0px rgba(0, 0, 0, 0.05)",
  "level4": "0px -2px 16px 0px rgba(0, 0, 0, 0.1)"
} as const
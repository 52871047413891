import { useEffect, RefObject } from 'react';

/**
 * useOnClickOutside
 *
 * Custom hook that handles on click out side given HTML element.
 *
 * @param {*} ref reference to an given HTML element
 * @param {*} handler Callback on every render that will cause the callback/cleanup effect to run every render
 * @returns [ref, handler]
 */
export const useOnClickOutside = (
  ref: RefObject<HTMLDivElement>,
  handler: (event: Event) => void,
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const e = event?.target as Element;

      if (!ref?.current || ref?.current.contains(e)) {
        return;
      }

      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;

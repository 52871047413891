import { useEffect, useRef } from 'react';

interface Options {
  enable?: boolean;
  target?: GlobalEventHandlers;
}

export const useEventListener = (
  targetEvent: keyof GlobalEventHandlersEventMap,
  handler: (e: Event) => void,
  { enable = true, target = document }: Options = {},
) => {
  const handlerRef = useRef(handler);
  const internalHandler = (e: Event) => {
    handlerRef.current(e);
  };
  useEffect(() => {
    handlerRef.current = handler;
  });
  useEffect(() => {
    if (!enable) {
      console.log('enable in');
      return;
    }
    target.addEventListener(targetEvent, internalHandler);
    return () => {
      target.removeEventListener(targetEvent, internalHandler);
    };
  }, [targetEvent, enable, target]);
};

export default useEventListener;

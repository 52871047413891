import { Rating } from '@worten-sardines/grill-ui';
import { MetricValueItemProps } from '../types';
import { formatMetricValue } from '../utils/formatMetricValue';

export const MetricValueItem = ({
  value,
  name,
  locale,
  showPrefix,
}: MetricValueItemProps) => {
  const formattedValue = formatMetricValue(value, name, locale, showPrefix);

  if (name === 'D-90_AVG_RATING' && formattedValue !== '-') {
    return <Rating value={+formattedValue} />;
  }

  return <div key={name}>{formattedValue}</div>;
};

import { Theme } from '@worten-sardines/grill-ui';
import styled from 'styled-components';


export const StyledNotFound = styled.section`
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
  text-align: center;
  margin: auto;
  background: ${Theme.color.gradient['g-03']};
`;

export const CardNotFound = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  width: auto;
  gap: ${Theme.spacing.xxl};
  background-color: ${Theme.color.neutral.neu01};
  padding: ${Theme.spacing.xxl};
  box-shadow: ${Theme.level3};
  border-radius: ${Theme.Radius.sm};
  position: relative;

  .errorText {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);

    color: ${Theme.color.neutral['neu-06']};
  }
`;

export const HeadNotFound = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: ${Theme.spacing.xxs};

  .title {
    color: ${Theme.color.main['m-01']};
    font-weight: ${Theme.weight.regular};
    margin: 0;
  }

  .subtitle {
    color: ${Theme.color.neutral['neu-09']};
    margin: 0;
  }
`;

export const DividerLineNotFound = styled.div`
  width: 100%;
  height: 1px;
  margin: 1rem 0 0;

  background-color: ${Theme.color.neutral['neu-02']};
`;

export const ContentNotFound = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .message {
    width: 70%;
    color: ${Theme.color.neutral['neu-09']};
    margin: 0;
  }
`;

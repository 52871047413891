let subscribers: Array<() => void> = [];

export const subscribe = (callback: () => void) => {
  subscribers.push(callback);
  return () => {
    subscribers = subscribers.filter((sub) => sub !== callback);
  };
};

export const setSessionValue = (key: string, value: unknown) => {
  sessionStorage.setItem(key, JSON.stringify(value));
  subscribers.forEach((callback) => callback());
};

export const getSessionValue = (key: string) => {
  const value = sessionStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

export const removeSessionValue = (key: string) => {
  sessionStorage.removeItem(key);
  subscribers.forEach((callback) => callback());
};

import { ShopIdCard } from '@worten-sardines/seller-center-ui-layout';
import { useSession } from '@worten-sardines/shared/hooks';
import { StyledCMSSidebarWrapper } from './styled';
import SharedSidebar from '../SharedSidebar';
import {
  SchemaObjectsWithSidebarAndCampaign,
  SidebarObjectSchema,
} from '../../types';

type SidebarCMSPageProps = {
  schema: SchemaObjectsWithSidebarAndCampaign;
  id: string;
};

export const CMSSidebar = ({ schema, id }: SidebarCMSPageProps) => {
  const { data: user } = useSession() || {};
  const isOperator = user?.type === 'operator';

  return (
    <StyledCMSSidebarWrapper id={id} position={schema?.position}>
      {isOperator && <ShopIdCard isUnset />}
      {schema.sidebars.map((sidebar: SidebarObjectSchema) => (
        <SharedSidebar
          key={sidebar.componentType}
          name={sidebar.componentType}
        />
      ))}
    </StyledCMSSidebarWrapper>
  );
};

export default CMSSidebar;

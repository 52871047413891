import styled from 'styled-components';
import { Theme } from '@worten-sardines/grill-ui';

export const StyledIframe = styled.div`
  background-color: white;
  height: 270px;
  margin-top: ${Theme.spacing.xs};

  iframe {
    width: 100%;
    border: 0;
    height: 100%;
  }
`;

import styled, { css } from 'styled-components';
import Grid from '../../Grid';
import Svg from '../../Svg';

export const FilterGroupStyled = styled(Grid).attrs({
  alignItems: 'end',
  gridTemplateColumns: {
    xs: '1fr',
    md: 'repeat(2, 1fr)',
    lg: 'repeat(3, 1fr)',
  },
  gridRowGap: '0.8rem',
  gridColumnGap: '1.6rem',
})`
  ${({ theme }) => css`
    background-color: ${theme.color.neutral['neu01']};
    padding: ${theme.spacing.xs};
    + div {
      svg[title='hide'] {
        transform: rotate(0.5turn);
      }
    }
  `}
`;

export const Icon = styled(Svg)`
  transform: rotate(90deg);
`;

import { DragEvent } from 'react';
import { HighlightImage } from './HighlightImage';
import { Block } from '../Block';
import {
  HeroContainer,
  HeroContents,
  HeroWrapper,
  HeroTitle,
  HeroText,
  OverlayLink,
  WrapperCTAButton,
} from './styled';

export type HeroBannerProps = {
  backgroundColor?: string;
  backgroundImageURL?: string | null;
  borderColor?: string;
  borderRadius?: number;
  ctaButton?: JSX.Element | undefined;
  enableBorder?: boolean;
  enableOverlayLink?: boolean;
  highlightImageAlt?: string;
  highlightImageURL?: string | JSX.Element;
  openInNewTab?: boolean;
  smallerText?: string;
  targetURL?: string;
  textColor?: string;
  titleBgColor?: string;
  titleColor?: string;
  titleText: string;
};

export const HeroBanner = ({
  backgroundColor,
  backgroundImageURL,
  borderColor,
  borderRadius = 0,
  ctaButton,
  enableBorder = false,
  enableOverlayLink = false,
  highlightImageAlt = '',
  highlightImageURL,
  openInNewTab = false,
  smallerText,
  targetURL,
  textColor,
  titleBgColor,
  titleColor,
  titleText,
}: HeroBannerProps) => {
  const handleDragStart = (event: DragEvent<HTMLDivElement>) =>
    event.preventDefault();

  return (
    <HeroWrapper
      textColor={textColor}
      enableBorder={enableBorder}
      borderColor={borderColor}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImageURL}
      onDragStart={handleDragStart}
    >
      <HeroContainer>
        {targetURL && enableOverlayLink && (
          <OverlayLink
            target={openInNewTab ? '_blank' : '_self'}
            href={targetURL}
          />
        )}

        {highlightImageURL && (
          <HighlightImage
            highlightImageURL={highlightImageURL}
            highlightImageAlt={highlightImageAlt}
          />
        )}

        <HeroContents>
          {titleText && (
            <Block mb={smallerText && 16}>
              <HeroTitle
                as="h1"
                color={titleColor || 'inherit'}
                backgroundColor={titleBgColor}
              >
                {titleText}
              </HeroTitle>
            </Block>
          )}

          {smallerText && (
            <HeroText as="div" mb={ctaButton && 16}>
              {smallerText}
            </HeroText>
          )}

          {ctaButton && <WrapperCTAButton>{ctaButton}</WrapperCTAButton>}
        </HeroContents>
      </HeroContainer>
    </HeroWrapper>
  );
};

export default HeroBanner;

import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import {
  SettingsIcon,
  usePopup,
  Popup,
} from '@worten-sardines/seller-center-ui-shared';
import messages from './messages';
import { StyledSettingsButton } from './styled';
import ShopIdPopup from '../ShopIdPopup';
import { useSessionStorage } from '@worten-sardines/shared/hooks';
import { PATHS } from './constants';

export const SettingsOption = () => {
  const { formatMessage } = useIntl();
  const { openPopup, closePopup, isOpen } = usePopup();
  const settingsLabel = formatMessage(messages.title);

  const { value: shopInfo } = useSessionStorage('shop', null);

  const [open, setOpen] = useState<boolean>(false);

  const location = useLocation();
  const path = location.pathname;

  const disableClose = PATHS.includes(path) && !shopInfo;

  const handleOpenPopup = () => {
    openPopup();
  };

  const togglePopup = () => {
    setOpen(!open);

    if (open && !disableClose) {
      closePopup();
    }
    if (!open) {
      handleOpenPopup();
    }
  };

  useEffect(() => {
    if (!isOpen && disableClose) {
      handleOpenPopup();
    }
    if (isOpen) {
      closePopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, shopInfo]);

  return (
    <>
      <StyledSettingsButton
        variation="tertiary"
        size="small"
        fullWidth
        onClick={togglePopup}
      >
        <SettingsIcon id="settings-icon" title={settingsLabel} />
      </StyledSettingsButton>

      <Popup
        isOpen={isOpen}
        onClose={closePopup}
        content={<ShopIdPopup />}
        position={'top-right'}
        hasBackdrop={disableClose}
        outsideClick={!disableClose}
      />
    </>
  );
};

export default SettingsOption;

import styled, { css } from 'styled-components';
import { CustomIndicatorProps, HorizontalPositions } from './types';

type WrapperProps = {
  bgColor?: string;
  shadowColor?: string;
  height?: number;
  rounded?: boolean;
};

type ProgressProps = {
  labelHorizontalPosition: HorizontalPositions;
  progressColor?: string;
  width: number;
  rounded?: boolean;
};

type LabelProps = {
  labelColor?: string;
};

type CustomWrapperProps = Omit<CustomIndicatorProps, 'component'>;

const vertical = {
  top: 'flex-end',
  center: 'center',
  bottom: 'flex-start',
};

const horizontal = {
  left: () =>
    css`
      left: 0;
    `,
  center: () => css`
    left: 50%;
    transform: translateX(-50%);
  `,
  right: () =>
    css`
      right: 0;
    `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, bgColor, shadowColor, rounded }) => css`
    background: ${bgColor || theme.sys.color.neu['04']};
    width: 100%;
    height: ${theme.bas.dimension[8]};

    ${shadowColor &&
    css`
      box-shadow: 0px 0px 8px 0px ${shadowColor};
    `}

    ${rounded &&
    css`
      border-radius: ${theme.sys.dimension.border.radius.Medium};
    `}
  `}
`;

export const Indicator = styled.div<ProgressProps>`
  ${({ theme, width, labelHorizontalPosition, progressColor, rounded }) => css`
    position: relative;
    background: ${progressColor || theme.sys.color.neu[11]};
    display: flex;
    justify-content: ${labelHorizontalPosition};
    height: inherit;
    width: ${width}%;
    padding-top: ${theme.bas.dimension[8]};

    ${rounded &&
    css`
      border-radius: ${theme.sys.dimension.border.radius.Medium};
    `}
  `}
`;

export const Label = styled.span<LabelProps>`
  ${({ theme, labelColor }) => css`
    position: absolute;
    font-size: ${theme.bas.typeface.fontsize[12]};
    font-weight: ${theme.weight.semibold};
    color: ${labelColor || theme.sys.color.neu[11]};
    min-width: ${theme.bas.dimension[24]};
  `}
`;

export const CustomIndicatorWrapper = styled.div<CustomWrapperProps>`
  ${({ horizontalPosition, verticalPosition, theme }) => css`
    position: absolute;
    align-self: ${vertical[verticalPosition]};
    ${horizontal[horizontalPosition]};
    width: fit-content;

    ${verticalPosition === 'top' &&
    css`
      margin-bottom: ${theme.bas.dimension[8]};
    `}
  `}
`;

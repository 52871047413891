import { useEffect, useState } from 'react';
import { CustomIndicator } from './CustomIndicator';

import { Indicator, Label, Wrapper } from './styled';
import { CustomIndicatorProps, HorizontalPositions } from './types';

export type ProgressBarProps = {
  animated?: boolean;
  ariaLabel?: string;
  bgColor?: string;
  custom?: CustomIndicatorProps;
  enableLabel?: boolean;
  labelColor?: string;
  labelHorizontalPosition?: HorizontalPositions;
  maxProgress?: number;
  progress: number;
  progressColor?: string;
  shadowColor?: string;
  rounded?: boolean;
};

export const ProgressBar = ({
  animated = true,
  ariaLabel,
  bgColor,
  custom,
  enableLabel = true,
  labelColor,
  labelHorizontalPosition = 'right',
  maxProgress = 100,
  progress = 0,
  progressColor,
  shadowColor,
  rounded = false,
}: ProgressBarProps) => {
  const [loaded, setLoaded] = useState<number>(0);

  useEffect(() => {
    const ratio = Math.round(progress) / maxProgress;
    const endProgress = ratio > 1 ? 100 : ratio * 100;

    let initial = animated ? 0 : endProgress;
    setLoaded(initial);

    if (animated) {
      const timer = setInterval(() => {
        if (initial === endProgress) clearInterval(timer);
        setLoaded(initial);
        initial += 1;
      }, 5);
    }
  }, [animated, maxProgress, progress]);

  return (
    <Wrapper bgColor={bgColor} shadowColor={shadowColor} rounded={rounded}>
      <Indicator
        role="progressbar"
        id={ariaLabel}
        aria-label={ariaLabel}
        aria-valuenow={loaded}
        aria-valuemin={0}
        aria-valuemax={maxProgress}
        width={loaded}
        progressColor={progressColor}
        labelHorizontalPosition={loaded <= 2 ? 'left' : labelHorizontalPosition}
        rounded={rounded}
      >
        {custom && <CustomIndicator {...custom} />}
        {enableLabel && <Label labelColor={labelColor}>{`${loaded}%`}</Label>}
      </Indicator>
    </Wrapper>
  );
};

export default ProgressBar;

import styled, { css } from 'styled-components';
import { DropdownProps } from './types';

type StyledDropdownProps = Pick<DropdownProps, 'placement' | 'maxHeight'>;

const crossPlacement = {
  bottomLeft: css`
    top: 100%;
    right: 0;
  `,
  bottomRight: css`
    top: 100%;
    left: 0;
  `,
  bottom: css`
    top: 100%;
    right: 50%;
    transform: translateX(50%);
  `,
  topLeft: css`
    bottom: 100%;
    right: 0;
  `,
  topRight: css`
    bottom: 100%;
    left: 0;
  `,
  top: css`
    bottom: 100%;
    right: 50%;
    transform: translateX(50%);
  `,
};

export const DropdownWrapper = styled.div`
  position: relative;
  display: block;
  width: fit-content;
`;

export const DropdownTrigger = styled.button`
  cursor: pointer;
`;

export const StyledDropdown = styled.div<StyledDropdownProps>`
  position: absolute;
  width: fit-content;
  min-width: 140px;
  z-index: 100;
  overflow-y: auto;

  ${({ theme, maxHeight }) => css`
    background-color: ${theme.color.neutral.neu01};
    border: 1px solid ${theme.color.neutral['neu-03']};
    border-radius: ${theme.Radius.xxs};
    box-shadow: ${theme.shadow.level2};

    ${maxHeight &&
    css`
      max-height: ${maxHeight};
    `}
  `}

  ${({ placement }) => placement && crossPlacement[placement]};
`;

export const DropdownItem = styled.button`
  ${({ theme }) => css`
    display: inline-flex;
    padding: ${theme.spacing.xxs} ${theme.spacing.xs};
    color: ${theme.color.neutral['neu-08']};
    font-size: ${theme.fontsize.desktop.s2};
    line-height: ${theme.fontsize.desktop.xl};
    width: 100%;
    white-space: nowrap;

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.color.neutral['neu-03']};
    }
  `}
`;

export const DropdownItemChildren = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    width: 100%;
  `}
`;

import { getShopData } from '@worten-sardines/seller-center/data-access';
import useFetch from '../shared-use-fetch';

type ShopIdSearchType = {
  loading: boolean;
  shopInfo?: {
    id: string;
    name?: string;
    tier?: string;
    channels?: string[];
    isPremium?: boolean;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
};

export type ShopType = {
  id: string;
  name?: string;
  tier?: string;
  channels?: string[];
  isPremium?: boolean;
} | null;

export function useSearchShop(shopId: string): ShopIdSearchType {
  const {
    data: shopInfo,
    loading,
    error,
  } = useFetch(
    getShopData(shopId).key,
    !!shopId,
    getShopData(shopId).args,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
  );

  return { shopInfo, loading, error };
}

import styled, { css, DefaultTheme } from 'styled-components';
import { omitProps } from '../_common';
import { makeColor, makeResponsive } from '../utils';
import { MakeColor, MakeResponsive } from '../utils/types';

export type HeadingProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  truncated?: boolean;
  wrapped?: boolean;
} & MakeColor &
  MakeResponsive;

const truncatedCSS = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
`;

const wrappedCSS = css`
  display: block;
  white-space: pre-line;
`;

const wrapperModifiers = {
  h1: (theme: DefaultTheme) =>
    css`
      font-size: ${theme.fontsize.desktop['4xl']};
      letter-spacing: ${theme.letterspacing.desktop['4xl']}px;
      line-height: ${theme.lineheight.desktop['4xl']};
    `,
  h2: (theme: DefaultTheme) =>
    css`
      font-size: ${theme.fontsize.desktop['3xl']};
      letter-spacing: ${theme.letterspacing.desktop['3xl']}px;
      line-height: ${theme.lineheight.desktop['3xl']};
    `,
  h3: (theme: DefaultTheme) =>
    css`
      font-size: ${theme.fontsize.desktop['2xl']};
      letter-spacing: ${theme.letterspacing.desktop['2xl']}px;
      line-height: ${theme.lineheight.desktop['2xl']};
      margin: 0;
    `,
  h4: (theme: DefaultTheme) =>
    css`
      font-size: ${theme.fontsize.desktop.xl};
      letter-spacing: ${theme.letterspacing.desktop.xl}px;
      line-height: ${theme.lineheight.desktop.xl};
      margin: 0;
    `,
  h5: (theme: DefaultTheme) =>
    css`
      font-size: ${theme.fontsize.desktop.l};
      letter-spacing: ${theme.letterspacing.desktop.l}px;
      line-height: ${theme.lineheight.desktop.l};
      margin: 0;
    `,
  h6: (theme: DefaultTheme) =>
    css`
      font-size: ${theme.fontsize.desktop.m};
      letter-spacing: ${theme.letterspacing.desktop.m}px;
      line-height: ${theme.lineheight.desktop.m};
      margin: 0;
    `,
};

export const Heading = styled.h1.withConfig<HeadingProps>({
  shouldForwardProp: (prop) => omitProps(['truncated', 'wrapped'], prop),
})`
  margin: 0 0 2rem 0;
  ${makeColor}

  ${({ as = 'h1', truncated, wrapped, theme }) => css`
    ${wrapperModifiers[as](theme)};
    ${truncated && truncatedCSS};
    ${wrapped && wrappedCSS};
  `}
  ${makeResponsive}
`;

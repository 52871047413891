import { useEffect, useRef, useState } from 'react';
import SingleEvent from './components/SingleEvent/SingleEvent';
import {
  HideContentIndicatorLeft,
  HideContentIndicatorRight,
  StyledEventCellContainer,
  StyledEventCellWrapper,
  StyledSingleEvents,
} from './styled';
import { EventCellProps, HiddenStateProps } from './types';
import { TimelineCallback } from '../DateTimeline/types';
import {
  getDistanceBetweenDates,
  getEventCellPeriodsProps,
  getIntervalBetweenPeriods,
} from './helpers/date';
import { Svg } from '@worten-sardines/grill-ui';

import { ArrowRight } from '../assets';

export const DAY_WIDTH = 35;

export const EventCell = ({ data, timelinePosition }: EventCellProps) => {
  const eventRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [isPartiallyHidden, setIsPartiallyHidden] = useState<HiddenStateProps>({
    left: false,
    right: false,
  });

  const initialPosition =
    getDistanceBetweenDates(data.firstPeriod.startDate) * DAY_WIDTH;

  function slideEventCell({ timelineX, currentDayX }: TimelineCallback) {
    if (eventRef.current) {
      eventRef.current.style.transform = `translateX(${
        currentDayX + initialPosition - timelineX
      }px)`;
    }
  }

  function handlePartiallyHidden(timelinePosition: TimelineCallback) {
    if (!containerRef.current || !eventRef.current) return;

    if (containerRef.current && eventRef.current && timelinePosition) {
      const parentRect = containerRef.current.getBoundingClientRect();
      const childRect = eventRef.current.getBoundingClientRect();

      const showLeftArrow =
        timelinePosition.direction !== 'initial' ||
        timelinePosition.timelineX + 5 >= timelinePosition.currentDayX
          ? true
          : false;

      const left = childRect.left < parentRect.left && showLeftArrow;
      const right = childRect.right > parentRect.right;

      setIsPartiallyHidden({ left, right });
    }
  }

  const periodsInterval = getIntervalBetweenPeriods({ data });

  const events = getEventCellPeriodsProps({ data });

  useEffect(() => {
    if (timelinePosition) {
      slideEventCell(timelinePosition);
      handlePartiallyHidden(timelinePosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelinePosition]);

  return (
    <StyledEventCellContainer ref={containerRef}>
      <StyledEventCellWrapper>
        <HideContentIndicatorLeft partiallyHidden={isPartiallyHidden}>
          <Svg
            svg={<ArrowRight />}
            title="arrow-hidden-eventcell-left"
            height={17}
            width={16}
          />
        </HideContentIndicatorLeft>
        <StyledSingleEvents ref={eventRef} interval={periodsInterval}>
          <SingleEvent period={events.firstPeriod} daySize={DAY_WIDTH} />
          <SingleEvent
            period={events.secondPeriod}
            variant="second"
            daySize={DAY_WIDTH}
            firstPeriod={events.firstPeriod}
          />
        </StyledSingleEvents>
        <HideContentIndicatorRight partiallyHidden={isPartiallyHidden}>
          <Svg
            svg={<ArrowRight />}
            title="arrow-hidden-eventcell-right"
            height={17}
            width={16}
          />
        </HideContentIndicatorRight>
      </StyledEventCellWrapper>
    </StyledEventCellContainer>
  );
};

export default EventCell;

import styled, { css } from 'styled-components';

type CheckboxProps = { size?: number };

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledCheckbox = styled.div<CheckboxProps>`
  ${({ size }) => css`
    align-items: center;
    width: ${`${size}px`};
    height: ${`${size}px`};
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    & {
      appearance: none;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      border: 1px solid ${theme.color.neutral['neu-06']};
      outline: none;
      cursor: pointer;
    }

    &:not(:checked):disabled {
      background-color: ${theme.color.neutral['neu-03']};
    }

    &[readonly],
    &[readonly] + ${Label} {
      cursor: default;
      pointer-events: none;
    }

    &[readonly] + ${Label} > * {
      pointer-events: all;
    }

    &:disabled,
    &:disabled + ${Label} {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:disabled + ${Label} > * {
      pointer-events: none;
    }

    &:focus {
      outline: 2px solid ${theme.color.neutral['neu-06']};
    }

    &:not(:checked, :disabled, [readonly]):hover {
      border-color: ${theme.color.neutral['neu-11']};
      background-color: ${theme.color.neutral['neu-05']};
    }

    &:checked {
      border-color: ${theme.color.neutral['neu-11']};
      background-color: ${theme.color.neutral['neu-11']};
      position: relative;
    }

    &:checked::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 1px;

      margin-left: auto;
      margin-right: auto;
      margin-top: auto;

      width: 40%;
      height: 70%;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.fontsize.desktop.m};
    cursor: pointer;
  `}
`;

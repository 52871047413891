import styled, { css } from 'styled-components';
import { GRID_COLUMNS } from '../constants';

type CardProps = {
  fullWidth?: boolean | null;
  highlight?: boolean | null;
};

export const Wrapper = styled.section<CardProps>`
  ${({ theme, highlight, fullWidth }) => css`
    background-color: ${theme.color.neutral['neu-02']};
    border-radius: ${theme.Radius.xxs};
    padding: ${theme.spacing.xs};

    ${highlight &&
    css`
      background-color: #6b8f9c;
      p,
      h1 {
        color: ${theme.color.neutral.neu01};
      }
    `}

    ${fullWidth &&
    css`
      grid-column: span ${GRID_COLUMNS};
    `};
  `}
`;

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: ${theme.spacing.xs};
    width: 100%;
  `}
`;

export const HighlightImage = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 13.2rem;
  height: 12.7rem;

  img {
    object-fit: cover;
    min-width: 13.2rem;
    max-width: 100%;
    height: auto;
  }
`;

export const Headline = styled.h1`
  ${({ theme }) => css`
    margin: 0 0 ${theme.spacing.xxs};
    font-size: ${theme.fontsize.desktop['3xl']};
    color: ${theme.color.neutral['neu-11']};
    font-weight: ${theme.weight.semibold};
  `}
`;

export const SupportingText = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontsize.desktop.l};
    font-weight: ${theme.weight.semibold};
    color: ${theme.color.neutral['neu-11']};
    line-height: ${theme.lineheight.desktop.l};
    padding: 0;
  `}
`;

import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import {
  ENV_TYPE,
  SENTRY_DSN,
} from '@worten-sardines/configs/config-seller-center';

import routers from '../routes';

/**
 * SENTRY INIT CONFIGURATIONS
 */
const isProdEnvironment = ENV_TYPE === 'production';
const isSentryEnabled = isProdEnvironment || ENV_TYPE === 'staging';

export const initializeSentry = () =>
  Sentry.init({
    environment: ENV_TYPE,
    enabled: isSentryEnabled,
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: isProdEnvironment ? 0.01 : 0,
  });

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(routers);

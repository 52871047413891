import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  CampaignLegend,
  DateTimeline,
  Info,
  SubmissionLegend,
} from '@worten-sardines/seller-center-ui-shared';
import { MainTemplate } from '@worten-sardines/shared-ui';
import { Flex, Grid, Text } from '@worten-sardines/grill-ui';

import CampaignCMSSidebar from './CampaignSidebar';

import { Filters, List, LegendItem, MilestoneLine, Table } from './components';
import { createTableData, sortDataTable } from './utils/helpers';

import {
  CampaignsArgs,
  CampaignsSchema,
  DataTableType,
  Schema,
  SchemaObjectsWithSidebarAndCampaign,
  SidebarSchema,
  Sorting,
  TimelineCallback,
} from '../types';
import {
  CampaignsWrapper,
  ContainerActions,
  ContainerLegends,
  EventsWrapper,
  LegendTitle,
} from './styled';
import messages from './messages';
import { FilterSelectedProps } from './components/Filters';
import { VIEW_MODE_CAMPAIGN } from './components/constants';
import {
  getFromSharedLocalStorageHelper,
  setOnSharedLocalStorageHelper,
} from '@worten-sardines/shared/helpers';

const Campaigns = ({
  schema,
  gridView = VIEW_MODE_CAMPAIGN.first_option,
}: Schema) => {
  const intl = useIntl();
  const eventWrapper = useRef<HTMLDivElement>(null);

  const [gridLayout, setGridLayout] = useState<string>(gridView);

  const [headerTimelinePosition, setHeaderTimelinePosition] =
    useState<TimelineCallback>();

  const [campaignsData, setCampaignsData] = useState<DataTableType | null>(
    null,
  );
  const [filterData, setFilterData] = useState<DataTableType>();
  const [campaignsSidebar, setCampaignsSidebar] =
    useState<SidebarSchema | null>(null);
  const [campaignSelected, setCampaignSelected] =
    useState<CampaignsArgs | null>(null);

  const header = [
    {
      id: 'campaignName',
      cell: intl.formatMessage(messages.campaignNameColumn),
    },
    {
      id: 'submissionPeriod',
      cell: intl.formatMessage(messages.campaignSubmissionPeriodColumn),
    },
    {
      id: 'runningTime',
      cell: intl.formatMessage(messages.campaignRunningTimeColumn),
    },
  ];

  const captions = {
    [VIEW_MODE_CAMPAIGN.first_option]: [
      {
        icon: <Info />,
        iconTitle: intl.formatMessage(messages.campaignIconImportantAriaLabel),
        label: intl.formatMessage(messages.campaignLegendSubmissionDeadline),
        width: '16',
      },
      {
        label: intl.formatMessage(messages.submissionCaptionLabel),
        icon: <SubmissionLegend />,
        iconTitle: intl.formatMessage(messages.submissionCaptionLabel),
        width: '22',
      },
      {
        label: intl.formatMessage(messages.campaignCaptionLabel),
        icon: <CampaignLegend />,
        iconTitle: intl.formatMessage(messages.campaignCaptionLabel),
        width: '32',
      },
    ],
    [VIEW_MODE_CAMPAIGN.second_option]: [
      {
        icon: <Info />,
        iconTitle: intl.formatMessage(messages.campaignIconImportantAriaLabel),
        label: intl.formatMessage(messages.campaignLegendSubmissionDeadline),
        width: '16',
      },
    ],
  };

  useEffect(() => {
    const currentView =
      getFromSharedLocalStorageHelper('campaignLayout') ||
      VIEW_MODE_CAMPAIGN.first_option;
    setGridLayout(currentView);
  }, []);

  useEffect(() => {
    const campaigns = createTableData(
      header,
      schema.filter(
        (camp: SchemaObjectsWithSidebarAndCampaign) =>
          camp.type === 'campaign-set',
      )[0]?.campaigns as CampaignsSchema[],
      intl.locale,
      (e) => handleCampaign(e),
    );
    const sidebar = schema.filter(
      (camp: SchemaObjectsWithSidebarAndCampaign) =>
        camp.type === 'sidebar-set',
    )[0];

    setCampaignsData(campaigns);
    setFilterData(campaigns);
    setCampaignsSidebar(sidebar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema, intl.locale]);

  const getHeaderTimelinePosition = (pos: TimelineCallback) => {
    setHeaderTimelinePosition(pos);
  };

  const handleCampaign = (campaign: CampaignsArgs) => {
    setCampaignSelected(campaign);
  };

  const setInitialCampaign = () => setCampaignSelected(null);

  const defaultSort = { id: 'submissionPeriod', sortBy: 'asc' };

  const onSortingChange = (
    updaterOrValue: Sorting,
    data?: DataTableType['data'],
  ) => {
    if (campaignsData?.data) {
      const sortCampaigns = sortDataTable(
        data ? data : campaignsData.data,
        updaterOrValue,
      );
      setCampaignsData({ header, data: [...sortCampaigns] });
    }
  };

  const handleFilter = (data: DataTableType['data'] | undefined) => {
    if (!data) return;

    // sort table by default rule: submissionPeriod asc (submissionPeriodEndDate)
    onSortingChange(defaultSort, data);
    setInitialCampaign();
  };

  const handleModeView = (args: FilterSelectedProps) => {
    setOnSharedLocalStorageHelper('campaignLayout', args.modeView);
    setGridLayout(args.modeView);
  };

  return (
    <>
      <MainTemplate.Content>
        <CampaignsWrapper>
          {filterData && filterData.data.length >= 0 && (
            <Grid gridTemplateColumns="33.4% 66.6%">
              <ContainerActions viewType={gridLayout}>
                <Filters
                  onModeView={handleModeView}
                  modeView={gridLayout}
                  onFilterChange={handleFilter}
                  data={filterData.data}
                />
              </ContainerActions>
              {gridLayout === VIEW_MODE_CAMPAIGN.first_option && (
                <DateTimeline
                  daysBeforeToday={2}
                  shift={2}
                  showBackButton
                  callback={getHeaderTimelinePosition}
                />
              )}
            </Grid>
          )}
          {campaignsData && campaignsData?.data.length ? (
            gridLayout === VIEW_MODE_CAMPAIGN.second_option ? (
              <Table
                summary={intl.formatMessage(messages.summaryMessage)}
                dataTable={campaignsData}
                currentCampaign={campaignSelected}
              />
            ) : (
              <EventsWrapper ref={eventWrapper}>
                <MilestoneLine
                  parentRef={eventWrapper}
                  timelinePosition={
                    headerTimelinePosition || {
                      timelineX: 0,
                      currentDayX: 0,
                      direction: 'initial',
                    }
                  }
                />
                <List
                  dataList={campaignsData}
                  currentCampaign={campaignSelected}
                  timelinePosition={headerTimelinePosition}
                />
              </EventsWrapper>
            )
          ) : (
            // NOTE: It's a provisory message for no campaign registers. Waiting for UX Team to create this.
            <Flex m="1.6rem" justifyContent="center" backgroundColor="#ffffff">
              <Text color="#e51b15">
                {intl.formatMessage(messages.emptyTableMessage)}
              </Text>
            </Flex>
          )}
          {campaignsData && campaignsData?.data.length > 0 && (
            <ContainerLegends>
              <Flex>
                <LegendTitle>
                  {intl.formatMessage(messages.campaignCaption)}
                </LegendTitle>
                {captions[gridLayout].map((item, index) => (
                  <LegendItem
                    key={item.label + index}
                    icon={item.icon}
                    iconTitle={item.iconTitle}
                    label={item.label}
                    width={item.width}
                  />
                ))}
              </Flex>
              <LegendTitle
                as="a"
                href={`https://selleracademy.worten.com/${
                  intl.locale
                }/${intl.formatMessage(
                  messages.learnMoreAboutCampaignsUrl,
                )}?utm_source=seller-center&utm_medium=campaigns`}
                target="_blank"
              >
                {intl.formatMessage(messages.learnMoreAboutCampaigns)}
              </LegendTitle>
            </ContainerLegends>
          )}
        </CampaignsWrapper>
      </MainTemplate.Content>
      <MainTemplate.SidebarRight>
        <CampaignCMSSidebar
          data={campaignSelected}
          sidebarSections={campaignsSidebar}
          resetCampaign={setInitialCampaign}
        />
      </MainTemplate.SidebarRight>
    </>
  );
};

export default Campaigns;

import styled, { css } from 'styled-components';

import { Badge } from '@worten-sardines/grill-ui';

type DateCardProps = {
  highlightEndDate?: boolean;
};

type StyledDateDetailCardProps = {
  noBorder?: boolean;
};

export const StyledDateDetailCardWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.xxs};
  `}
`;
export const StyledDateDetailCard = styled.div<StyledDateDetailCardProps>`
  display: flex;
  align-items: center;
  height: 54px;
  gap: 16px;
  position: relative;
  width: 100%;

  ${({ theme }) => css`
    background-color: ${theme.color.neutral.neu01};
    border-radius: ${theme.Radius.xxs};
    border: 1px solid ${theme.color.neutral['neu-05']};
    padding: 0 ${theme.spacing.xs};
    margin-bottom: ${theme.spacing.xxs};
  `}

  ${({ noBorder }) =>
    noBorder &&
    css`
      border: none;
      background-color: transparent;
      margin-bottom: 0;
      height: fit-content;
      justify-content: flex-end;
      align-items: flex-end;
    `}
`;

export const CustomBadge = styled(Badge)`
  display: block;
  margin-left: 8px;
  border-radius: 8px 8px 0 0;
  gap: 2px;
`;

export const DatesGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 76px;
`;

export const DateCard = styled.div<DateCardProps>`
  ${({ theme, highlightEndDate }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${theme.color.neutral['neu-08']};

    ${highlightEndDate &&
    css`
      color: ${theme.color.main['m-01']};
    `}
  `}
`;

export const Day = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontsize.desktop.s1};
    font-weight: ${theme.weight.bold};
  `}
`;

export const Month = styled(Day)`
  ${({ theme }) => css`
    text-transform: uppercase;
    font-size: ${theme.fontsize.desktop.xs};
    font-weight: ${theme.weight.regular};
    white-space: nowrap;
  `}
`;

export const IconDiv = styled.div`
  display: flex;
  width: 16px;
  height: 16px;

  ${({ theme }) => css`
    color: ${theme.color.neutral['neu-08']};
  `}
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  overflow: hidden;
`;

export const TitleDate = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontsize.desktop.s1};
    font-weight: ${theme.weight.bold};
    color: ${theme.color.neutral['neu-11']};
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `}
`;

export const DurationSpan = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontsize.desktop.xs};
    font-weight: ${theme.weight.regular};
    color: ${theme.color.neutral['neu-08']};
    width: 100%;
  `}
`;

import styled, { css } from 'styled-components';
import { Grid } from '@worten-sardines/grill-ui';

type SkeletonLineProps = {
  columns?: number;
};

export const SkeletonContainer = styled(Grid)`
  ${({ theme }) => css`
    grid-template-rows: 77px auto auto auto;
    margin: ${theme.spacing.xs};
    height: 95%;
    overflow: hidden;
    border-radius: ${theme.Radius.xs};
    border: solid 1px ${theme.color.neutral['neu-03']};
    background-color: ${theme.color.neutral['neu01']};

    & div:first-child {
      border-bottom: 1px solid ${theme.color.neutral['neu-03']};
    }
  `}
`;

export const SkeletonLine = styled(Grid)<SkeletonLineProps>`
  ${({ theme, columns }) => css`
    width: 100%;
    height: ${theme.size.md};
    height: 100%;
    gap: ${theme.spacing.md};
    padding: ${theme.spacing.sm} ${theme.spacing.md};
    grid-template-columns: ${columns ? `repeat(${columns}, 1fr)` : '1fr'};
  `}
`;

export const SkeletonRectangle = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    background-color: ${theme.color.neutral['neu-03']};
    border-radius: ${theme.Radius.xxs};
    min-height: 2.8rem;
  `}
`;

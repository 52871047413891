import { css } from 'styled-components';
import { isColor, isObject, sizeValueConvert, toHyphenCase } from './helpers';
import { Breakpoints, FontWeight, GenericObject, Spacing } from './types';

export const widthProps = ['width', 'minWidth', 'maxWidth'];
export const heightProps = ['height', 'minHeight', 'maxHeight'];

export const colorProps = [
  'color',
  'fill',
  'stroke',
  'backgroundColor',
  'borderColor',
];

export const flexProps = [
  'flexDirection',
  'flexWrap',
  'justifyContent',
  'alignItems',
  'alignContent',
];

export const flexChildProps = [
  'flex',
  'flexGrow',
  'flexShrink',
  'flexBasis',
  'order',
  'alignSelf',
];

export const gridProps = [
  'gridTemplateAreas',
  'gridTemplateColumns',
  'gridTemplateRows',
  'justifyItems',
  'justifyContent',
  'alignItems',
  'alignContent',
  'gridAutoFlow',
];

export const gridChildProps = [
  'gridArea',
  'gridColumn',
  'gridColumnStart',
  'gridColumnEnd',
  'gridRow',
  'gridRowStart',
  'gridRowEnd',
  'justifySelf',
  'alignSelf',
];

export const responsiveProps = ['responsive'];

export const spacingProps = [
  'm',
  'mt',
  'mb',
  'ml',
  'mr',
  'p',
  'pt',
  'pb',
  'pl',
  'pr',
  'mx',
  'my',
  'px',
  'py',
  'gridRowGap',
  'gridColumnGap',
  'gridGap',
];

// 8-point grid system scale
// Grill-ui theme set the html font-size in 62.5%
// Values in px: [ 0, 8, 16, 24, 32, 40, 48, 56, 64 ]
export const spaces = [
  0,
  '0.8rem',
  '1.6rem',
  '2.4rem',
  '3.2rem',
  '4rem',
  '4.8rem',
  '5.6rem',
  '6.4rem',
];

export const breakpointLimits = {
  xs: 360,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const breakpoints = {
  xs: `(max-width: ${breakpointLimits.xs}px)`,
  sm: `(min-width: ${breakpointLimits.sm}px)`,
  md: `(min-width: ${breakpointLimits.md}px)`,
  lg: `(min-width: ${breakpointLimits.lg}px)`,
  xl: `(min-width: ${breakpointLimits.xl}px)`,
};

export const fontWeight = {
  bold: 700,
  semibold: 600,
  regular: 400,
};

export const textProps = [
  'fontSize',
  'fontWeight',
  'lineHeight',
  'letterSpacing',
  'textAlign',
  'textDecoration',
  'textTransform',
  'whiteSpace',
  'wordBreak',
  'wordWrap',
];

const cssGenerator = (
  componentProps = {} as GenericObject,
  styleProps: Array<string> = [],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propValueConvertCb = (v: any, p: any) => v,
  propNameMapperCb = (p: Spacing) => p,
) => {
  if (componentProps === null || styleProps === null) {
    return '';
  }

  const isResponsiveProp = (v: GenericObject | Array<string>) =>
    Array.isArray(v) || isObject(v);

  const searchProps = (props: GenericObject, propsToSearch: Array<string>) =>
    Array.isArray(propsToSearch)
      ? propsToSearch.filter(
          (prp) => typeof props[prp] !== 'undefined' && props[prp] !== null,
        )
      : [];

  const resultProps = searchProps(componentProps, styleProps);

  if (resultProps.length > 0) {
    //
    // Group responsive props by media query
    //
    const validResponsiveProps = resultProps
      .filter((prop) => isResponsiveProp(componentProps[prop]))
      .reduce((acc: GenericObject, prop) => {
        const cssProp = propNameMapperCb(prop);
        if (Array.isArray(cssProp)) {
          // Handle grouped CSS props like mx/px/my/py
          cssProp.forEach(
            (cssPropInner) => (acc[cssPropInner] = componentProps[prop]),
          );
        } else {
          acc[cssProp] = componentProps[prop];
        }
        return acc;
      }, {});

    const responsiveCSS = generateMediaQueries(
      validResponsiveProps,
      propValueConvertCb,
    );

    //
    // Process non-responsive CSS
    //
    const regularCSS = resultProps
      .filter(
        (prop) =>
          typeof componentProps[prop] !== 'undefined' &&
          componentProps[prop] !== null,
      )
      .map((prop) => {
        const value = componentProps[prop];
        const cssProp = propNameMapperCb(prop);

        if (Array.isArray(cssProp)) {
          // Handle grouped CSS props like mx/px/my/py
          return cssProp
            .map(
              (cssPropInner) =>
                `${toHyphenCase(cssPropInner)}: ${propValueConvertCb(
                  value,
                  cssPropInner,
                )};\n`,
            )
            .join('');
        }
        return `${toHyphenCase(cssProp)}: ${propValueConvertCb(
          value,
          cssProp,
        )};\n`;
      })
      .join('');

    return `${regularCSS}${responsiveCSS}`;
  }

  return null;
};

const generateMediaQueries = (
  responsive: GenericObject,
  convertCallback = (v: string, p: string) => v,
) => {
  if (isObject(responsive)) {
    return Object.keys(breakpoints)
      .map((breakpoint: string, breakpointIndex: number) => {
        const isFirstBreakpoint = breakpointIndex === 0;
        const mediaQuery = breakpoints[breakpoint as keyof Breakpoints];
        if (
          mediaQuery === undefined ||
          mediaQuery === null ||
          mediaQuery === ''
        ) {
          throw new Error(`Invalid media query for breakpoint '${breakpoint}'`);
        }

        let breakpointCSS = '';
        Object.keys(responsive).forEach((cssProp) => {
          // Convert prop object key to CSS attribute name
          const hyphenizedProp = toHyphenCase(cssProp);
          // Extract list/object of CSS values
          const cssPropValues = responsive[cssProp];

          const responsivePropIsValidArray =
            Array.isArray(cssPropValues) &&
            breakpointIndex + 1 <= cssPropValues.length;

          const responsivePropIsValidObject =
            isObject(cssPropValues) &&
            typeof cssPropValues[breakpoint] !== 'undefined' &&
            cssPropValues[breakpoint] !== null;

          if (responsivePropIsValidArray) {
            //
            // Handle responsive values when it's provided as an Array
            //
            const cssPropValue = cssPropValues[breakpointIndex];
            breakpointCSS = `${breakpointCSS}${hyphenizedProp}: ${convertCallback(
              cssPropValue,
              cssProp,
            )};\n`;
          } else if (responsivePropIsValidObject) {
            //
            // Handle responsive values when it's provided as an Object
            // and contains current breakpoint
            //
            const cssPropValue = cssPropValues[breakpoint];
            breakpointCSS = `${breakpointCSS}${hyphenizedProp}: ${convertCallback(
              cssPropValue,
              cssProp,
            )};\n`;
          } else if (
            isFirstBreakpoint &&
            cssPropValues !== null &&
            !isObject(cssPropValues) &&
            !Array.isArray(cssPropValues)
          ) {
            //
            // Handle non-responsive CSS attributes
            //
            breakpointCSS = `${breakpointCSS}${hyphenizedProp}: ${convertCallback(
              cssPropValues,
              cssProp,
            )};\n`;
          }
        });

        // Build media query for the current breakpoint
        const hasCss = breakpointCSS.length > 0;
        if (isFirstBreakpoint && hasCss) {
          return breakpointCSS;
        }
        return hasCss ? `@media ${mediaQuery} {\n${breakpointCSS}}\n\n` : '';
      })
      .join('');
  }
  return '';
};

export const makeSpacing = (props: GenericObject) => {
  const spaceValueConvert = (value = 0) => {
    if (Number.isInteger(value)) {
      if (
        value + 1 > spaces.length ||
        (value < 0 && -1 * value + 1 > spaces.length)
      ) {
        // If spacing value is not found in scale, assume value in pixels
        return `${value}px`;
      }
      return value < 0 ? `-${spaces[-1 * value]}` : spaces[value];
    }
    return value;
  };

  const spacePropMapper = (prop: Spacing) => {
    switch (prop) {
      case spacingProps[0]:
        return 'margin';
      case spacingProps[1]:
        return 'marginTop';
      case spacingProps[2]:
        return 'marginBottom';
      case spacingProps[3]:
        return 'marginLeft';
      case spacingProps[4]:
        return 'marginRight';
      case spacingProps[5]:
        return 'padding';
      case spacingProps[6]:
        return 'paddingTop';
      case spacingProps[7]:
        return 'paddingBottom';
      case spacingProps[8]:
        return 'paddingLeft';
      case spacingProps[9]:
        return 'paddingRight';
      //
      // Special cases: props that map two or more CSS attributes
      //
      case spacingProps[10]:
        return ['marginLeft', 'marginRight'];
      case spacingProps[11]:
        return ['marginTop', 'marginBottom'];
      case spacingProps[12]:
        return ['paddingLeft', 'paddingRight'];
      case spacingProps[13]:
        return ['paddingTop', 'paddingBottom'];
      //
      // Grid props (only takes effect for 'display: grid')
      //
      case 'gridColumnGap':
      case 'gridRowGap':
      case 'gridGap':
        return prop;
      default:
        throw Error('Unknown spacing prop');
    }
  };

  return cssGenerator(props, spacingProps, spaceValueConvert, spacePropMapper);
};

export const makeColor = (componentProps: GenericObject) =>
  cssGenerator(componentProps, colorProps, isColor);

export const makeWidth = (props: GenericObject) =>
  cssGenerator(props, widthProps, sizeValueConvert);

export const makeHeight = (props: GenericObject) => {
  return cssGenerator(props, heightProps, sizeValueConvert);
};

export const makeFlex = (props: GenericObject) =>
  cssGenerator(props, flexProps);

export const makeFlexChild = (props: GenericObject) =>
  cssGenerator(props, flexChildProps);

export const makeGrid = (props: GenericObject) =>
  cssGenerator(props, gridProps);

export const makeGridChild = (props: GenericObject) =>
  cssGenerator(props, gridChildProps);

export const makeResponsive = ({ responsive }: GenericObject) => {
  if (isObject(responsive)) {
    return generateMediaQueries(responsive);
  }
  return null;
};

export const makeText = (props: GenericObject) => {
  const textValueConvert = (value: string, propName: string) => {
    if (propName === 'fontWeight') {
      return fontWeight[value as keyof FontWeight] || value;
    }
    return value;
  };
  return cssGenerator(props, textProps, textValueConvert);
};

export const GrillUtils = () => css`
  ${makeSpacing}
  ${makeWidth}
  ${makeHeight}
  ${makeColor}
  ${makeFlex}
  ${makeFlexChild}
  ${makeGrid}
  ${makeGridChild}
  ${makeResponsive}
  ${makeText}
`;

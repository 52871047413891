import styled, { css } from 'styled-components';
import { PopupProps } from '.';

type PopupContainerProps = Pick<PopupProps, 'position'>;

const positionModifiers = {
  center: css`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  'top-left': css`
    top: 80px;
    left: 80px;
  `,
  'top-right': css`
    top: 64px;
    right: 80px;
  `,
  'bottom-left': css`
    bottom: 60px;
    left: 40px;
  `,
  'bottom-right': css`
    bottom: 60px;
    right: 40px;
  `,
};

const HEADER_HEIGHT = '5.6rem';

export const PopupContainer = styled.div<PopupContainerProps>`
  ${({ theme, position }) => css`
    position: fixed;
    ${positionModifiers[position || 'top-right']}
    border: solid 1px ${theme.color.neutral['neu-03']};
    border-radius: ${theme.Radius.xxs};
    background: ${theme.color.neutral['neu01']};
    padding: ${theme.spacing.xs};
    box-shadow: ${theme.shadow.level3};
    text-align: left;
    z-index: 0;
  `}
`;

export const Backdrop = styled.div`
  ${({ theme }) => css`
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.color.neutral['neu-09']};
    opacity: 0.7;
    margin-top: ${HEADER_HEIGHT};
    z-index: -1;
  `}
`;

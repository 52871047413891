import { Text } from '../Text';
import styled, { css, keyframes } from 'styled-components';
import { LoadingOverlayProps } from '.';

type StyledLoadingOverlayProps = Pick<
  LoadingOverlayProps,
  'isVisible' | 'zIndex'
>;

const rotate = keyframes`
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(0deg) rotateY(90deg);
  }
  100% {
    transform: perspective(120px) rotateX(0deg) rotateY(180deg);
  }
`;

export const StyledLoadingOverlay = styled.div<StyledLoadingOverlayProps>`
  ${({ theme, isVisible, zIndex }) => css`
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    inset: 0px;
    opacity: 0;
    visibility: hidden;
    transition: ${theme.animation.duration.medium} all ease;

    ${isVisible &&
    css`
      opacity: 1;
      visibility: visible;
    `}

    z-index: ${zIndex || '10000'};
  `}
`;

export const Logo = styled.div`
  animation: ${rotate} 1s infinite ease-in-out 0.1s;
`;

export const Label = styled(Text)`
  ${({ theme, color }) => css`
    color: ${color || theme.color.neutral['neu-12']};
    font-weight: ${theme.weight.semibold};
    font-size: ${theme.fontsize.desktop.m};
  `}
`;

import React from 'react';

export type SvgComponentProps = {
  svg: JSX.Element;
  title: string;
  ariaLabel?: string;
  ariaHidden?: boolean;
  focusable?: boolean;
  width?: string | number;
  height?: string | number;
} & React.SVGProps<SVGSVGElement>;

export const Svg = ({
  svg,
  ariaLabel,
  ariaHidden = true,
  focusable = false,
  width = '100%',
  height,
  ...props
}: SvgComponentProps) => {
  const clonedSvg = React.cloneElement(svg, {
    ...props,
    role: 'img',
    'aria-label': ariaLabel || props.title,
    'aria-hidden': ariaHidden,
    width: width,
    focusable: focusable,
  });

  return clonedSvg;
};

export default Svg;

import { useEffect, useState } from 'react';
import {
  BadgeContent,
  StyledToggle,
  ToggleItem,
  ToggleSlideDiv,
} from './styled';

export type AlignProps = 'left' | 'right' | undefined;

export type ToggleItemProps = {
  text?: string;
  badge?: JSX.Element;
  alignBadge?: AlignProps;
  id: string;
  active: boolean;
  action?: () => void;
  disabled?: boolean;
};

export type ToggleProps = {
  items: ToggleItemProps[];
  filteredData?: (arg: string) => void;
};

export const Toggle = ({ items, filteredData }: ToggleProps) => {
  const activeIndex = items.findIndex((item) => item.active);
  const [isActive, setIsActive] = useState(activeIndex || 0);

  const handleClick = (index: number) => {
    setIsActive(index);
    items[index].action?.();
    filteredData && filteredData(items[index].id);
  };

  useEffect(() => {
    filteredData && filteredData(items[isActive].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledToggle>
      {items.map((item, index) => (
        <ToggleItem
          id={item.id}
          key={index + item.id}
          onClick={() => handleClick(index)}
          className={isActive === index ? 'active' : ''}
          align={item.alignBadge}
          disabled={item.disabled}
          activeIndex={isActive}
          itemsLength={items.length}
        >
          {item.badge && <BadgeContent>{item.badge}</BadgeContent>}
          {item.text && item.text}
        </ToggleItem>
      ))}
      {items[activeIndex] && (
        <ToggleSlideDiv
          id={`slideInActiveIndex-${isActive}`}
          activeIndex={isActive}
          itemsLength={items.length}
        />
      )}
    </StyledToggle>
  );
};

export default Toggle;

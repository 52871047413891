import { AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef } from 'react';
import { WrapperButton, WrapperProps } from './styled';

type ButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLButtonElement>;

export type TypesOfSize = 'small' | 'medium' | 'large';
export type TypesOfPrimary = 'dark' | 'light' | 'cta' | 'interactive';
export type TypesOfSecondary = 'light' | 'cta';
export type TypesOfTertiary = 'light' | 'cta' | 'medium';
export type TypesOfLink = 'light' | 'cta';
export type TypesIconSide = 'left' | 'right';

export type ButtonProps = {
  variation?: 'primary' | 'secondary' | 'tertiary' | 'neutral';
  link?: boolean;
  type?:
    | TypesOfPrimary
    | TypesOfSecondary
    | TypesOfTertiary
    | TypesOfLink
    | undefined;
  size?: TypesOfSize;
  fullWidth?: boolean;
  icon?: React.ReactNode;
  iconSide?: TypesIconSide;
  as?: React.ElementType;
} & ButtonTypes;

const Button: React.ForwardRefRenderFunction<WrapperProps, ButtonProps> = (
  {
    children,
    variation = 'primary',
    type,
    size = 'medium',
    fullWidth = false,
    iconSide = 'left',
    icon,
    ...props
  },
  ref,
) => {
  return (
    <WrapperButton
      ref={ref}
      size={size}
      variation={variation}
      type={type}
      iconSide={iconSide}
      fullWidth={fullWidth}
      hasIcon={!!icon}
      {...props}
    >
      {!!icon && icon}
      {!!children && <span>{children}</span>}
    </WrapperButton>
  );
};

export default forwardRef(Button);

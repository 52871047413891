import styled, { css } from 'styled-components';
import { Theme as theme, Heading, omitProps } from '@worten-sardines/grill-ui';

type WrapperProps = {
  position?: number | null;
};

export const Wrapper = styled.section<WrapperProps>`
  ${({ position }) => css`
    max-width: 100%;
    grid-row: ${position};
  `}
`;

export const StyledTitle = styled(Heading).withConfig({
  shouldForwardProp: (prop) => omitProps(['align'], prop),
})<{ align: string }>`
  margin: 0 0 1.6rem;
  ${({ align }) => css`
    text-align: ${align};
  `}

  svg, img {
    margin-right: ${theme.spacing.xxxs};
  }
`;

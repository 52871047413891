export const formatDays = (
  value: number | string,
  locale = 'en',
  prefix = '',
): string => {
  if (typeof value !== 'number') {
    return '-';
  }

  const messages: { [key: string]: string } = {
    es: 'Días',
    pt: 'Dias',
    en: 'Days',
  };

  const valueFormatted = `${value.toLocaleString(locale)} ${messages[locale]}`;

  if (prefix === '') return `${valueFormatted}`;
  return `${prefix} ${valueFormatted}`;
};

export const formatAnswers = (
  value: number | string,
  locale = 'en',
  prefix = '',
): string => {
  if (typeof value !== 'number') {
    return '-';
  }

  const messages: { [key: string]: string } = {
    es: value > 1 ? 'Respuestas' : 'Respuesta',
    pt: value > 1 ? 'Respostas' : 'Resposta',
    en: value > 1 ? 'Answers' : 'Answer',
  };

  if (prefix === '') return `${value} ${messages[locale]}`;
  return `${prefix} ${value} ${messages[locale]}`;
};

export const formatPercentage = (
  value: number | string,
  decimalPlaces = 0,
  isInteger = false,
  locale?: string,
  prefix = '',
): string => {
  if (typeof value !== 'number') {
    return '-';
  }

  const factor = isInteger ? 1 : 100;
  const valueFormatted = (value * factor).toLocaleString(locale, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });

  if (prefix === '') return `${valueFormatted}%`;
  return `${prefix} ${valueFormatted}%`;
};

export const formatHours = (
  value: number | string,
  locale = 'en',
  prefix = '',
): string => {
  if (typeof value !== 'number') {
    return '-';
  }

  const hours = Math.floor(value);
  const minutes = Math.round((value - hours) * 60)
    .toString()
    .padStart(2, '0');

  const messages: { [key: string]: string } = {
    es: hours > 1 ? 'horas' : 'hora',
    pt: hours > 1 ? 'horas' : 'hora',
    en: hours > 1 ? 'hours' : 'hour',
  };

  const hoursString =
    minutes === '00' ? `${hours} ${messages[locale]}` : `${hours}h`;
  const minutesString = minutes === '00' ? '' : `${minutes}`;

  if (prefix === '') return `${hoursString + minutesString}`;
  return `${prefix} ${hoursString + minutesString}`;
};

export const formatRating = (value: number | string): string => {
  if (typeof value !== 'number') {
    return '-';
  }
  return `${value.toFixed(2)}`;
};

export const formatOnlyPrefix = (value: number | string, prefix = ''): string =>
  typeof value === 'number'
    ? `${prefix}${prefix ? ' ' : ''}${
        Number.isInteger(value) ? value : value.toFixed(2)
      }`
    : '-';

export const metricFormatters: {
  [key: string]: (
    value: number | string,
    locale?: string,
    showPrefix?: boolean,
  ) => string;
} = {
  ACTIVITY_TIME_DAYS: (value, locale, showPrefix) =>
    formatDays(value, locale, showPrefix ? '≥' : ''),

  'D-60_NSS_REPLIES': (value, locale, showPrefix) =>
    formatAnswers(value, locale, showPrefix ? '≥' : ''),

  'D-30_ACCEPTANCE_RATE': (value, locale, showPrefix) =>
    formatPercentage(value, 0, false, locale, showPrefix ? '≥' : ''),

  'D-30_INCIDENT_RATE': (value, locale, showPrefix) =>
    formatPercentage(value, 0, false, locale, showPrefix ? '≤' : ''),

  'D-30_LATE_SHIPMENT_RATE': (value, locale, showPrefix) =>
    formatPercentage(value, 1, false, locale, showPrefix ? '≤' : ''),

  'D-60_NSS_REPLY_RATE': (value, locale, showPrefix) =>
    formatPercentage(value, 0, false, locale, showPrefix ? '≥' : ''),

  'D-60_NSS': (value, _, showPrefix) =>
    formatOnlyPrefix(value, showPrefix ? '≥' : ''),

  'D-30_ACCEPTANCE_TIME_HOURS': (value, locale, showPrefix) =>
    formatHours(value, locale, showPrefix ? '≤' : ''),
  'D-90_AVG_RATING': formatRating,
};

export const formatMetricValue = (
  value: number | string,
  metricName: string,
  locale?: string,
  showPrefix = false,
): string => {
  const formatter = metricFormatters[metricName];
  if (!formatter) {
    return String(value);
  }
  return formatter(value, locale, showPrefix);
};

import styled, { css, DefaultTheme, keyframes } from 'styled-components';
import { omitProps } from '../_common';
import { Toast, ToastPosition } from './types';

type ToastBarBaseProps = Pick<Toast, 'type' | 'position'>;

type WrapperProps = {
  position: ToastPosition;
};

const enterAnimation = keyframes`
  0% {
    transform: translate3d(0,${-200}%,0) scale(.6);
    opacity:.5;
  }
  100% {
    transform: translate3d(0,0,0) scale(1);
    opacity:1;
  }
`;

const exitAnimation = keyframes`
  0% {
    transform: translate3d(0,0,-1px) scale(1);
    opacity:1;
  }
  100% {
    transform: translate3d(0,${-150}%,-1px) scale(.6);
    opacity:0;
  }
`;

const snackbarPosition = {
  'top-center': () => css`
    align-items: center;
    justify-content: start;
  `,
  'top-right': () => css`
    align-items: end;
    justify-content: start;
  `,
  'bottom-right': () => css`
    align-items: end;
    justify-content: end;
  `,
  'bottom-center': () => css`
    align-items: center;
    justify-content: end;
  `,
  'bottom-left': () => css`
    align-items: start;
    justify-content: end;
  `,
  'top-left': () => css`
    align-items: start;
    justify-content: start;
  `,
};

const wrapperModifiers = {
  blank: (theme: DefaultTheme) => css`
    background-color: ${theme.color.neutral['neu-11']};
    color: ${theme.color.neutral.neu01};

    .close-button {
      color: ${theme.color.neutral.neu01};
    }
  `,
  success: (theme: DefaultTheme) => css`
    background-color: ${theme.color.aditional['add-06']};
    color: ${theme.color.neutral['neu-12']};
  `,
  error: (theme: DefaultTheme) => css`
    background-color: ${theme.color.main['m-01']};
    color: ${theme.color.neutral.neu01};

    .close-button {
      color: ${theme.color.neutral.neu01};
    }
  `,
  warning: (theme: DefaultTheme) => css`
    background-color: ${theme.color.aditional['add-05']};
    color: ${theme.color.neutral['neu-12']};
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, position }) => css`
    position: fixed;
    z-index: 9999;
    inset: ${theme.spacing.xs};
    pointer-events: none;

    display: flex;
    flex-direction: column;

    ${!!position && snackbarPosition[position]}
  `}
`;

export const Container = styled.div`
  left: 0;
  right: 0;
  display: flex;
  pointer-events: auto;
  opacity: 0;
  animation: ${exitAnimation} 0.4s forwards cubic-bezier(0.06, 0.71, 0.55, 1);

  &.activeClass {
    animation: ${enterAnimation} 0.35s cubic-bezier(0.21, 1.02, 0.73, 1)
      forwards;
    z-index: 9999;

    > * {
      pointer-events: auto;
    }
  }
`;

export const ToastBarBase = styled.div.withConfig<ToastBarBaseProps>({
  shouldForwardProp: (prop) => omitProps(['type'], prop),
})`
 ${({ theme, type }) => css`
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: ${theme.spacing.xs};
   margin-bottom: ${theme.spacing.xxs};
   background-color: ${theme.color.neutral['neu-11']};
   box-shadow: ${theme.level2};
   max-width: 350px;
   color: ${theme.color.neutral.neu01};

   top: 0;
   left: 0;
   right: 0;

   ${!!type && wrapperModifiers[type](theme)};
 `}
`;

export const Message = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 10px;
  color: inherit;
  flex: 1 1 auto;
  white-space: pre-line;
  font-size: 15px;
`;

export const IconInfo = styled.div`
  margin-right: 5px;
  width: 24px;
  height: 24px;

  svg {
    position: relative;
    margin-top: -2px;
  }
`;

export const CloseButton = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  margin-left: auto;

  &:hover {
    transform: scale(1.1);
  }
`;

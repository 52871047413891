import Svg from '../Svg';
import { MakeColor, MakeHeight, MakeWidth } from '../utils/types';
import { StyledBadge } from './styled';

export type BadgeProps = {
  children?: React.ReactNode;
  type?:
    | 'online'
    | 'brand'
    | 'recommended'
    | 'general'
    | 'dangerous'
    | 'warning'
    | 'success'
    | 'black'
    | 'premium'
    | 'notPremium'
    | 'soon';
  icon?: JSX.Element;
} & MakeColor &
  MakeHeight &
  MakeWidth &
  React.HTMLAttributes<HTMLDivElement>;

export const Badge = ({
  children,
  type = 'online',
  icon,
  ...props
}: BadgeProps) => {
  return (
    <StyledBadge type={type} {...props}>
      {children}
      {icon && <Svg name="icon-badge" svg={icon} title="Icon badge" />}
    </StyledBadge>
  );
};

export default Badge;

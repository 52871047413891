import * as Sentry from '@sentry/react';
import toast from '@worten-sardines/grill-ui';
import { useCallback, useEffect, useReducer } from 'react';
import { useSWRConfig } from 'swr';
//import toast from 'components/Toast';
import useSafeDispatch, { Dispatch } from '../shared-use-safe-dispatch';

import { MUTATE_DATA, MUTATE_DATA_ERR, MUTATE_DATA_OK } from './constants';

const NO_OP = (v: string) => v;

const SHOW_ERROR_TOAST = (error: string) => {
  toast.error(error);
};

/**
 * useMutate
 *
 * Custom hooks that handles requests to server.
 *
 * @param {*} api Async function to call
 * @param {*} initialPayload Array with the arguments of the apiCallback (default: [])
 * @param {*} initialFetch true/false: tell hook if fetch effect should run on first mount. Useful when you want to use this hook with POST requests, for instance, in form submissions.
 * @param {*} successCallback Callback function to be executed if promise is resolved
 * @param {*} errorCallback Callback function to be executed if promise is rejected (i.e. on error)
 *
 * @returns [state, fetchData]
 */

export type ApiArgs = {
  [key: string]: string;
};

type State = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  loading: boolean;
  error?: string | null;
  payload: [[string[], ApiArgs]];
};

type InitReducer = {
  initialFetch: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialPayload: any;
};

export const useMutate = (
  api: (args: ApiArgs) => void,
  initialPayload?: [],
  initialFetch = true,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  successCallback?: (dataSuccess: any) => void,
  errorCallback?: (dataError: string) => void,
): [typeof state, Dispatch] => {
  const { mutate } = useSWRConfig();
  const [state, dispatch] = useReducer(
    apiReducer,
    {
      initialFetch,
      initialPayload: initialPayload || [],
    },
    initReducer,
  );

  const [safeDispatch, isMountedRef] = useSafeDispatch(dispatch);

  // Keep fetchData identity stable as it can be used outside this
  // hook by another hook
  const fetchDispatcher = useCallback<Dispatch>((...args) => {
    dispatch({ type: MUTATE_DATA, payload: args });
  }, []);

  const mutateApi = useCallback(
    async (args: [[string[], ApiArgs]]) => {
      const [key, ...payload] = args[0];
      try {
        const result: unknown = await api(...payload);

        const mutateList = key.map((k) => {
          const serializedKey = Array.isArray(k) ? JSON.stringify(k) : k;
          return mutate(serializedKey);
        });

        await Promise.all(mutateList);

        safeDispatch({ type: MUTATE_DATA_OK, data: result });

        if (isMountedRef?.current) {
          if (result) {
            successCallback ? successCallback(result) : NO_OP;
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        safeDispatch({ type: MUTATE_DATA_ERR, error });

        if (isMountedRef.current) {
          if (error) {
            Sentry.captureException(error);
            errorCallback
              ? errorCallback(error)
              : SHOW_ERROR_TOAST(error.message);
          }
        }
      }
    },
    [mutate, isMountedRef, api, safeDispatch, successCallback, errorCallback],
  );

  useEffect(() => {
    if (state.loading) {
      mutateApi(state.payload);
    }
  }, [mutateApi, state.loading, state.payload]);

  return [state, fetchDispatcher];
};

function initReducer({ initialFetch, initialPayload }: InitReducer) {
  return {
    // Request state
    loading: initialFetch,
    // Fetch payload
    payload: initialPayload,
    // Result state
    data: null,
    error: null,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const apiReducer = (state: State, action: any): State => {
  switch (action.type) {
    case MUTATE_DATA:
      return {
        ...state,
        loading: true,
        error: null,
        payload: action.payload,
      };
    case MUTATE_DATA_OK:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case MUTATE_DATA_ERR:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default useMutate;

import styled, { css } from 'styled-components';
import { Theme as theme } from '@worten-sardines/grill-ui';

import { GRID_COLUMNS } from './constants';

type CardProps = {
  fullWidth?: boolean;
  highlight?: boolean;
  position?: number | null;
};

export const CardsGrid = styled.div<CardProps>`
  ${({ position }) => css`
    grid-row: ${position};
  `}
  display: grid;
  grid-template-columns: repeat(${GRID_COLUMNS}, 1fr);
  gap: ${theme.spacing.xs};
  margin-top: ${theme.spacing.sm};
`;

import {
  Theme as theme,
  transitionTimingCurve,
} from '@worten-sardines/grill-ui';
import styled from 'styled-components';

export const Section = styled.section`
  font-size: ${theme.fontsize.desktop.s1};
  font-weight: ${theme.weight.semibold};
  color: ${theme.color.neutral['neu-08']};
`;

export const WrapperTable = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  background-color: transparent;

  font-size: ${theme.fontsize.desktop.s1};
  font-weight: ${theme.weight.semibold};
  color: ${theme.color.neutral['neu-08']};
  transition: all ${theme.animation.duration.slow};

  .activated {
    background-color: ${theme.color.neutral['neu-02']};
    transition: all ${theme.animation.duration.slow};

    th {
      color: ${theme.color.main['m-01']};
      > button {
        color: ${theme.color.main['m-01']};
      }
    }
  }

  .select-none {
    user-select: none;
  }

  .cursor-pointer {
    cursor: pointer;
    width: 100%;
    position: relative;

    &:hover {
      .icon {
        opacity: ${theme.opacity.high};
      }
    }
  }

  .icon {
    position: absolute;
    right: -12px;
    top: 0;
    bottom: 0;
    opacity: ${theme.opacity.low};
    transition: all ${theme.animation.duration.medium};
    ${transitionTimingCurve(theme.animation.curve.productive)};
  }

  .icon-filter-asc {
    svg {
      transform: rotateX(180deg);
    }
  }

  tr {
    &:not(:last-child) {
      border-bottom: 1px solid ${theme.color.neutral['neu-03']};
    }
  }

  tbody {
    td {
      border-left: 1px solid ${theme.color.neutral['neu-03']};
    }
    th {
      font-weight: ${theme.weight.semibold};
      text-align: left;
    }
  }

  thead,
  tfoot {
    > tr > th {
      font-weight: ${theme.weight.bold};
      text-align: left;
    }
  }

  thead {
    tr {
      border-bottom: 1px solid ${theme.color.neutral['neu-03']};
      border-top: 1px solid ${theme.color.neutral['neu-03']};
    }
    th {
      font-size: ${theme.fontsize.desktop.m};
      &:not(:first-child) {
        border-left: 1px solid ${theme.color.neutral['neu-03']};
        text-align: left;
      }
    }
  }

  tfoot {
    tr {
      border-top: 1px solid ${theme.color.neutral['neu-03']};
    }
    th {
      &:not(:first-child) {
        border-left: 1px solid ${theme.color.neutral['neu-03']};
      }
    }
  }

  & thead {
    td,
    th {
      padding: ${theme.spacing.xs};
    }
  }

  tbody > tr {
    background-color: ${theme.color.neutral.neu01};
    cursor: pointer;
    transition: all ${theme.animation.duration.slow};

    th {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background: ${theme.color.main['m-01']};
        height: 0;
        left: 0;
        top: 0;
        transition: all ${theme.animation.duration.medium};
        width: 1px;
      }
    }

    > td {
      text-align: left;
    }

    & :hover {
      background-color: ${theme.color.neutral['neu-02']};
      transition: all ${theme.animation.duration.slow};
      th {
        &::before {
          height: 100%;
        }
      }
    }
  }
`;

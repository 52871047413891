import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Loading } from '@worten-sardines/grill-ui';
import { useSession } from '@worten-sardines/shared/hooks';
import { CONFIGS_123FORM } from '@worten-sardines/configs/config-seller-center';

import messages from './messages';

import { StyledIframe } from './styled';

type CampaignSubmissionProps = {
  campaign: string;
};

export const CampaignSubmission = ({ campaign }: CampaignSubmissionProps) => {
  const intl = useIntl();
  const { data } = useSession() || {};
  const [loading, setLoading] = useState(true);
  const locale = intl.locale as keyof typeof CONFIGS_123FORM.CAMPAIGN;

  if (!data || !data.email) {
    return <Loading />;
  }

  const embedUrl = `https://form.123formbuilder.com/sf.php?s=123formbuilder-${CONFIGS_123FORM.CAMPAIGN[locale].idForm}${CONFIGS_123FORM.CAMPAIGN[locale].campaignIDForm}=${campaign}${CONFIGS_123FORM.CAMPAIGN[locale].emailForm}=${data.email}${CONFIGS_123FORM.CAMPAIGN[locale].shopIDForm}=${data.shop?.id}${CONFIGS_123FORM.CAMPAIGN[locale].shopNameForm}=${data.shop?.name}`;

  return (
    <StyledIframe>
      {loading ? <Loading /> : null}
      <iframe
        title="campaing form"
        id="contactform123"
        name="contactform123"
        onLoad={() => setLoading(false)}
        src={embedUrl}
      >
        <p>{intl.formatMessage(messages.iframeMessage)}</p>
      </iframe>
    </StyledIframe>
  );
};

export default CampaignSubmission;

import { Badge } from '@worten-sardines/grill-ui';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

/**
 * Returns the tooltip message based on the premium status and the seller's grade
 * @param isPremium - A boolean indicating whether the user is a premium seller.
 * @param grade - Seller's grade.
 * @returns The tooltip message component.
 */
export const getTooltipMessage = (isPremium: boolean, grade: number) => {
  if (grade >= 1) {
    return (
      <FormattedMessage
        {...(isPremium ? messages.isPremium : messages.turnPremium)}
        values={{ premium: <Badge type="premium">Premium</Badge> }}
      />
    );
  }

  return (
    <FormattedMessage
      {...(isPremium ? messages.losingPremium : messages.becomePremium)}
    />
  );
};

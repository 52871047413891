import { defineMessages } from 'react-intl';

export default defineMessages({
  titleLogin: {
    id: 'libs.seller-center.login.titleLogin',
    defaultMessage: "Let's walk this journey together",
  },
  textLogin: {
    id: 'libs.seller-center.login.textLogin',
    defaultMessage:
      'To access Seller Center, please click the button below and log in with your Mirakl account',
  },
  buttonLogin: {
    id: 'libs.seller-center.login.buttonLogin',
    defaultMessage: "Let's get started",
  },
  copyright: {
    id: 'libs.seller-center.login.copyright',
    defaultMessage:
      '© WORTEN - EQUIPAMENTOS PARA O LAR, S.A., com sede Rua João Mendonça n.º 505, Senhora da Hora Matosinhos, pessoa coletiva n° 503 630 330, que é também o seu número de matrícula na Conservatória do Registo Comercial do Porto, com o capital social de 21.530.000€.',
  },
  linkRegister: {
    id: 'libs.seller-center.login.linkRegister',
    defaultMessage: 'or Join us',
  },
  textfieldLabel: {
    id: 'libs.seller-center.login.textfieldLabel',
    defaultMessage: 'Email',
  },
  textfieldPlaceholder: {
    id: 'libs.seller-center.login.textfieldPlaceholder',
    defaultMessage: 'Email',
  },
  textfieldError: {
    id: 'libs.seller-center.login.textfieldError',
    defaultMessage: 'Invalid e-mail',
  },
  textfieldReturnValidateText: {
    id: 'libs.seller-center.login.textfieldReturnValidateText',
    defaultMessage: 'Enter a valid email address',
  },
});
